import { createSlice } from "@reduxjs/toolkit";

const user = createSlice({
  name: "user",
  initialState: {
    selectedRole: undefined,
    profile: undefined,
    users: [],
    invites: [],
  },
  reducers: {
    saveProfile: (state, action) => {
      state.profile = action.payload;
      if (action.payload?.roles?.length > 0) state.selectedRole = action.payload.roles[0];
    },
    saveUsers: (state, action) => {
      state.users = action.payload;
    },
    saveInvites: (state, action) => {
      state.invites = action.payload;
    },
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload;
    },
    clearUserStateExcludingProfile: (state) => {
      state.users = [];
      state.invites = [];
    },
  },
});

export const { saveProfile, saveUsers, saveInvites, clearUserStateExcludingProfile, setSelectedRole } = user.actions;

export default user.reducer;
