import { MailOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Tooltip } from "antd";
import { FC, useState } from "react";

type SendButtonProps = {
  id: string;
  invoiceNumber: string;
  displayName: string;
  emails: string;
  onSend: (id: string, onCompleted: () => void) => void;
};

const SendButton: FC<SendButtonProps> = ({ id, invoiceNumber, onSend, displayName, emails }) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Popconfirm
      placement="topRight"
      title="Send Invoice"
      description={
        <pre style={{ fontFamily: "inherit" }}>
          Are you sure you want to send invoice {invoiceNumber}?
          <br />
          <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", gap: 10, paddingTop: 10, paddingBottom: 10 }}>
            <div>Bill To: </div>
            <div>{displayName}</div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", gap: 10, paddingTop: 10, paddingBottom: 10 }}>
            <div>Emails: </div>
            <div>{emails}</div>
          </div>
        </pre>
      }
      onConfirm={() => {
        setLoading(true);
        onSend(id, () => setLoading(false));
      }}
      okText={"Send Invoice"}
      cancelText="No"
    >
      <Tooltip title={"Send Invoice"} placement="left">
        <Button loading={loading} style={{ paddingLeft: 0, paddingRight: 0 }} type="link" icon={<MailOutlined />} />
      </Tooltip>
    </Popconfirm>
  );
};

export default SendButton;
