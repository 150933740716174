import { Alert, Card, Form, Input, InputNumber, Space, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import { isEmpty, set } from "lodash";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import { getRestrictionCalculation } from "@/apis/restriction.api";
import { syncWaterRightCalculation } from "@/apis/waterright.api";
import RemainingYearlyAverageTable from "@/components/RemainingYearlyAverageTable/RemainingYearlyAverageTable";

interface RestrictionCalculationsProps {
  startDate: any;
  endDate: any;
  termQuantity: any;
  waterRights?: any[];
  previousYearQuantities?: any;
  activePreviousYearQuantities?: any;
  createdAt?: any;
  id?: string;
  headerText?: JSX.Element | string;
  showIcon?: boolean;
  showTotals?: boolean;
  isReport?: boolean;
}

const RestrictionCalculations: FC<RestrictionCalculationsProps> = (props) => {
  const {
    startDate,
    endDate,
    termQuantity,
    waterRights,
    previousYearQuantities,
    activePreviousYearQuantities,
    createdAt,
    id,
    headerText,
    showIcon = true,
    showTotals = true,
    isReport = false,
  } = props;

  const [calculation, setCalculation] = useState<any>(undefined);
  const [wrCalculations, setWrCalculations] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingWrCalc, setLoadingWrCalc] = useState<boolean>(true);

  const [values, setValues] = useState<any>({
    startDate: undefined,
    endDate: undefined,
    termQuantity: 0,
    irrigationAcres: 0,
    years: 0,
    baseline: 0,
    remainingYears: 0,
    remaining: 0,
    yearlyRemaining: 0,
    currentUsage: 0,
    currentYearRemaining: 0,
    totalUsed: 0,
    futureRemainingYears: 0,
    futureRemainingYearlyAverage: 0,
    adjustedBaseline: 0,
  });

  useEffect(() => {
    if (calculation) {
      setValues({
        startDate: calculation.startDate,
        endDate: calculation.endDate,
        termQuantity: calculation.termQuantity / 325851,
        irrigationAcres: calculation.authorizedIrrigationAcres,
        years: calculation.years,
        baseline: calculation.baselineQuantity,
        remainingYears: calculation.remainingYears,
        remaining: calculation.remainingUsage,
        yearlyRemaining: calculation.remainingYearlyAverage,
        currentUsage: calculation.currentYearUsage,
        currentYearRemaining:
          calculation.remainingUsage > calculation.adjustedBaselineQuantity - calculation.currentYearUsage
            ? calculation.adjustedBaselineQuantity - calculation.currentYearUsage
            : calculation.remainingUsage,
        totalUsed: calculation.used,
        futureRemainingYears: calculation.futureRemainingYears,
        futureRemainingYearlyAverage: calculation.futureRemainingYearlyAverage,
        previousYearQuantities: calculation.previousYearQuantities,
        yearlyUsage: calculation.yearlyUsage,
        adjustedBaseline: calculation.adjustedBaselineQuantity,
      });
    } else if (!id) {
      const irrigationAcres = Math.max(0, ...(waterRights ?? []).map((x: any) => x.usageIrrigationAcres ?? x.authorizedIrrigationAcres));
      const years = Number(calculateDateRange(startDate, endDate, false));
      const baseline = (termQuantity / years) * 325851;
      const remainingYears = Number(calculateDateRange(dayjs(), endDate, false));
      const remaining = calculateRemaining(termQuantity, activePreviousYearQuantities, startDate, previousYearQuantities, createdAt);
      const yearlyRemaining = remaining / remainingYears;
      const currentUsage = calculateCurrentUsage(waterRights ?? [], wrCalculations);
      const currentYearRemaining = baseline - currentUsage;
      const adjustedBaseline = remaining > 0 ? (remaining + currentUsage) / remainingYears : 0;

      // Check if this is needed
      // futureRemainingYears: 0,
      // futureRemainingYearlyAverage: 0

      setValues({
        startDate,
        endDate,
        termQuantity,
        irrigationAcres,
        years,
        baseline,
        remainingYears,
        remaining,
        yearlyRemaining,
        currentUsage,
        currentYearRemaining,
        waterRights,
        adjustedBaseline,
      });
    }
  }, [calculation, wrCalculations, waterRights, previousYearQuantities, activePreviousYearQuantities, createdAt, startDate, endDate, termQuantity]);

  const loadCalculation = async (id: string) => {
    setLoading(true);
    const response = await getRestrictionCalculation(id);
    if (response.ok) {
      const data = await response.json();
      setCalculation(data.value);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) loadCalculation(id);
    else setLoading(false);
  }, [id]);

  const refreshCalculations = async () => {
    setLoadingWrCalc(true);
    const response = await syncWaterRightCalculation({});
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWrCalculations(data.value);
      }
    }
    setLoadingWrCalc(false);
  };

  useEffect(() => {
    refreshCalculations();
  }, []);

  const calculateDateRange = (startDate: any, endDate: any, asText: boolean = true) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    const years = end.year() - start.year() + 1;

    let message = "";

    if (years > 1) message += `${years} years`;
    else if (years === 1) message += `${years} year`;
    else message += "0 years";

    return asText ? message : years;
  };

  const calculateRemaining = (termQuantity: any, previousYearQuantities: any, startDate: any, existingPreviousYearQuantities: any, createdAt: any) => {
    if (!termQuantity) return 0;

    if (!previousYearQuantities) return termQuantity * 325851;

    let total: number = Number(termQuantity);

    const start = dayjs(startDate);
    const maxYear = existingPreviousYearQuantities
      ? Math.max(...Object.keys(existingPreviousYearQuantities).map((x) => Number(x)))
      : createdAt
        ? dayjs(new Date(createdAt)).year() - 1
        : dayjs().year() - 1;

    const startYear = start.year();

    for (let i = 0; i <= maxYear - startYear; i++) {
      total -= Number(previousYearQuantities[startYear + i]);
    }

    return total * 325851;
  };

  const renderTabbedItemTotal = (label: any, value?: any, description?: string, width: number = 180) => (
    <>
      {value === undefined && <br />}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width }}>{label}</div>
        {value !== undefined && <b style={{ paddingRight: 60 }}>{value}</b>}
        {description && <div>({description})</div>}
      </div>
    </>
  );

  const calculateCurrentUsage = (waterRights: any[], calculations: any[]) => {
    const wrIds = waterRights?.map((x: any) => x.id) ?? [];
    if (wrIds.length > 0) {
      const wrCalculations = calculations.filter((x) => wrIds.includes(x.waterRightId) && x.year === dayjs().year());
      if (wrCalculations.length > 0) {
        return wrCalculations.reduce((partialSum: any, x: any) => partialSum + x.currentUsage, 0);
      }
    }
    return 0;
  };

  return (
    <Card styles={{ body: { padding: isReport ? 0 : undefined } }}>
      <Alert
        showIcon={showIcon}
        description={
          loading || loadingWrCalc ? (
            <div>Loading...</div>
          ) : (
            <>
              {headerText}
              {renderTabbedItemTotal(<b>Restriction Constants</b>, undefined, undefined, 140)}
              {renderTabbedItemTotal("Total Years:", calculateDateRange(values.startDate, values.endDate))}
              {renderTabbedItemTotal("Irrigation Acres:", values.irrigationAcres, "The maximum usage irrigation acres between all water rights in this restriction")}
              {renderTabbedItemTotal("Total Quantity (Gallons):", <UnitsConverter fromUnits="acrefeet" toUnits="gallons" value={values.termQuantity} />)}
              {renderTabbedItemTotal("Total Quantity (Acre Feet):", <UnitsConverter fromUnits="acrefeet" toUnits="acrefeet" value={values.termQuantity} />)}
              {renderTabbedItemTotal("Inches / Acre:", <UnitsConverter fromUnits="acrefeet" toUnits="acreinches" value={values.termQuantity} devideBy={values.irrigationAcres} />)}

              {renderTabbedItemTotal(<b>Baseline Quantity</b>)}
              {renderTabbedItemTotal("The target yearly average usage.", null, undefined, 400)}
              {renderTabbedItemTotal("Calculated as the total quantity divided by the total years.", null, undefined, 400)}
              {renderTabbedItemTotal("Gallons:", <UnitsConverter fromUnits="gallons" toUnits="gallons" value={values.baseline} />)}
              {renderTabbedItemTotal("Acre Feet:", <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={values.baseline} />)}
              {renderTabbedItemTotal("Inches / Acre:", <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={values.baseline} devideBy={values.irrigationAcres} />)}

              {renderTabbedItemTotal(<b>Adjusted Baseline Quantity</b>)}
              {renderTabbedItemTotal("The adjusted target yearly average usage.", null, undefined, 400)}
              {renderTabbedItemTotal("Calculated as the total quantity minus used in previous years divided by the total years remaining.", null, undefined, 400)}
              {renderTabbedItemTotal("Gallons:", <UnitsConverter fromUnits="gallons" toUnits="gallons" value={values.adjustedBaseline} />)}
              {renderTabbedItemTotal("Acre Feet:", <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={values.adjustedBaseline} />)}
              {renderTabbedItemTotal("Inches / Acre:", <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={values.adjustedBaseline} devideBy={values.irrigationAcres} />)}

              {renderTabbedItemTotal(<b>Current Year Usage Quantity</b>, undefined, undefined, 500)}
              {renderTabbedItemTotal("The used quantity for all water rights in this restriction for this year.", null, undefined, 500)}
              {renderTabbedItemTotal("Gallons:", values.remainingYears > 0 ? <UnitsConverter fromUnits="gallons" toUnits="gallons" value={values.currentUsage} /> : 0)}
              {renderTabbedItemTotal("Acre Feet:", values.remainingYears > 0 ? <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={values.currentUsage} /> : 0)}
              {renderTabbedItemTotal(
                "Inches / Acre:",
                values.remainingYears > 0 ? <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={values.currentUsage} devideBy={values.irrigationAcres} /> : 0
              )}

              {renderTabbedItemTotal(<b>Current Year Remaining Quantity</b>, undefined, undefined, 500)}
              {renderTabbedItemTotal("The adjusted baseline quantity minus the current year used quantity.", null, undefined, 500)}
              {renderTabbedItemTotal("Gallons:", values.remainingYears > 0 ? <UnitsConverter fromUnits="gallons" toUnits="gallons" value={values.currentYearRemaining} /> : 0)}
              {renderTabbedItemTotal("Acre Feet:", values.remainingYears > 0 ? <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={values.currentYearRemaining} /> : 0)}
              {renderTabbedItemTotal(
                "Inches / Acre:",
                values.remainingYears > 0 ? <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={values.currentYearRemaining} devideBy={values.irrigationAcres} /> : 0
              )}

              {showTotals ? (
                <>
                  {renderTabbedItemTotal(<b>Total Used</b>)}
                  {renderTabbedItemTotal("The total quantity used over the restriction period for all water rights.", null, undefined, 500)}
                  {renderTabbedItemTotal("Gallons:", <UnitsConverter fromUnits="gallons" toUnits="gallons" value={values.totalUsed} />)}
                  {renderTabbedItemTotal("Acre Feet:", <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={values.totalUsed} />)}
                  {renderTabbedItemTotal("Inches / Acre:", <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={values.totalUsed} devideBy={values.irrigationAcres} />)}

                  {renderTabbedItemTotal(<b>Total Remaining</b>)}
                  {renderTabbedItemTotal("Calculated as the total quantity minus previous year quantities.", null, undefined, 400)}
                  {renderTabbedItemTotal("Gallons:", <UnitsConverter fromUnits="gallons" toUnits="gallons" value={values.remaining} />)}
                  {renderTabbedItemTotal("Acre Feet:", <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={values.remaining} />)}
                  {renderTabbedItemTotal("Inches / Acre:", <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={values.remaining} devideBy={values.irrigationAcres} />)}
                </>
              ) : (
                <>
                  <br />
                  To view the Totals and Remaining Yearly Average, please save the restriction and navigate to the Calculations tab on the restriction view.
                </>
              )}
            </>
          )
        }
        type="warning"
      />
    </Card>
  );
};

export default RestrictionCalculations;
