import { Card, ConfigProvider, Input, message, Space, Table, Tabs, Tag } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import AdminBillingEntitiesExpandRender from "./AdminBillingEntitiesExpandRender";
import ClearPendingBillableButton from "./components/ClearPendingBillableButton";
import { clearPendingBillableItems } from "@/apis/aggregator.api";
import AdminInvoices from "./AdminInvoices";

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

interface Props {
  record: any;
  isBillable: boolean;
  refresh: () => void;
  optimisticBillableStatusUpdate: (id: string, billable: boolean, userId?: string) => void;
  optimisticClearItemsUpdate: (userId: string, billableItemIds: string[]) => void;
}

const AdminBillingUsersExpand: FC<Props> = ({ record, isBillable, refresh, optimisticBillableStatusUpdate, optimisticClearItemsUpdate }) => {
  const [expandColumns, setExpandColumns] = useState<any[]>([]);

  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string>("");

  useEffect(() => {
    calculateExpandColumns();
  }, [record]);

  const handleClearPendingBillableItems = async (userId: string, billableItemIds: string[], onCompleted: () => void) => {
    try {
      const response = await clearPendingBillableItems({ userId, billableItemIds });
      if (response.ok) {
        optimisticClearItemsUpdate(userId, billableItemIds);
      } else {
        message.error("Failed to clear pending billable items");
      }
    } catch (error) {
      message.error("Failed to clear pending billable items");
    }
    onCompleted();
  };

  const calculateExpandColumns = () => {
    let tempColumns = [
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        render: (val: any, record: any) => val,
      },
      {
        title: "Billable",
        key: "billable",
        width: 70,
        align: "center",
        render: (val: any, record: any) => (record.isBillable ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>),
      },
      {
        title: "Wells Quantity",
        key: "wells",
        dataIndex: "wells",
        width: 150,
        align: "center",
        render: (val: any, record: any) => (record?.type === 0 || record?.type === 3 ? record?.quantity : "-"),
      },
      {
        title: "Tokens Quantity",
        key: "tokens",
        dataIndex: "tokens",
        width: 150,
        align: "center",
        render: (val: any, record: any) => (record?.type === 1 || record?.type === 2 ? record?.quantity : "-"),
      },
      {
        title: "Actions",
        width: 115,
        render: (val: any, billingItem: any) => (
          <>
            <ClearPendingBillableButton description={billingItem.description} userId={record.id} billableItemIds={[billingItem.id]} onClearPendingBillableItems={handleClearPendingBillableItems} />
          </>
        ),
      },
    ];

    setExpandColumns(tempColumns);
  };

  const renderTabs = () => {
    const tabs: any[] = [
      {
        label: "Pending Billable Items",
        key: "pendingBillableItems",
        children: renderPendingBillableItems(),
      },
      {
        label: "Entities",
        key: "entities",
        children: renderEntitiesTable(),
      },
      {
        label: "Invoices",
        key: "invoices",
        children: <AdminInvoices userId={record.id} />,
      },
    ];

    return tabs;
  };

  const renderEntitiesTable = () => {
    return <AdminBillingEntitiesExpandRender record={record} isBillable={isBillable} optimisticBillableStatusUpdate={optimisticBillableStatusUpdate} />;
  };

  const filteredPendingBillableItems = useMemo(
    () => (record?.pendingBillableItems ? record?.pendingBillableItems.filter((item: any) => item.description.toLowerCase().includes(searchString.toLowerCase())) : []),
    [record, searchString]
  );

  const renderPendingBillableItems = () => {
    return (
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Space style={{ paddingBottom: 10 }}>
            <Input.Search
              placeholder="Search pending billable items"
              onSearch={setSearchString}
              onChange={(val) => setSearchStringValue(val.currentTarget.value)}
              value={searchStringValue}
              style={{ width: 400 }}
              allowClear
            />
          </Space>
        </Space>
        <Table
          rowKey={(row: any) => row.id}
          // loading={isLoading}
          columns={expandColumns}
          dataSource={filteredPendingBillableItems}
          size="small"
          pagination={false}
          scroll={{ y: 400, scrollToFirstRowOnChange: true }}
          expandable={{
            rowExpandable: (record: any) => record?.additionalDescription,
            expandedRowRender: (record: any, index: number, indent: number, expanded: boolean) => (
              <pre style={{ fontFamily: "unset", paddingLeft: 70, paddingRight: 20, paddingTop: 10, paddingBottom: 10 }}>{record?.additionalDescription}</pre>
            ),
          }}
        />
      </ConfigProvider>
    );
  };

  const renderExpand = () => {
    return (
      <Card styles={{ body: { paddingTop: 0 } }}>
        <Tabs defaultActiveKey="1" items={renderTabs()} />
      </Card>
    );
  };

  return renderExpand();
};

export default AdminBillingUsersExpand;
