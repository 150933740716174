import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Divider, Form, FormInstance, Input, Select, Space, Steps, Switch, Tag, message } from "antd";
import { getCompanyInvite, getCompanyUser, inviteUserToCompany, updateCompanyUser } from "@/apis/company.api";
import { routes } from "@/configs";
import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { TooltipPlacement } from "antd/es/tooltip";
import { getGroupings } from "@/apis/grouping.api";
import { listWaterRightTags } from "@/apis/waterright.api";
import { StatusTag, WaterRightSelector } from "@/components";
import WaterRightTagList from "@/components/WaterRightTagList/WaterRightTagList";
import { UserRole } from "@/dtos/user.dto";
import { useSelector } from "react-redux";
import scrollToTop from "@/services/scrollToTop";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import "./UserAddEdit.scss";
import { removeBreadcrumb } from "@/stores/breadcrumbs.store";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";

const UserAddEdit: FC = () => {
  const dispatch = useAppDispatch();

  const { navigate } = useCustomNavigate();
  const formRef = useRef<FormInstance>(null);

  const { id }: any = useParams();

  const { selectedCompany, selectedCompanyId } = useSelector((state: any) => state.company);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingWaterRightGroups, setLoadingWaterRightGroups] = useState<boolean>(true);

  const [role, setRole] = useState<string>();
  const [addAnother, setAddAnother] = useState<boolean>(false);
  const [waterRightGroups, setWaterRightGroups] = useState<any[]>([]);
  const [type, setType] = useState<any>(undefined);

  const [data, setData] = useState<any>(undefined);

  useEffect(() => {
    if (id) {
      var query = new URLSearchParams(window.location.search);
      var type = query.get("type");
      if (type === "invite") loadInvite();
      else loadUser();
      setType(type);
    } else {
      resetDefaults();
    }
  }, [id]);

  const resetDefaults = () => {
    formRef.current?.setFieldsValue({
      active: true,
    });
    scrollToTop();
  };

  useEffect(() => {
    if (data) {
      const { assignedWaterRightGroups, assignedWaterRights, ...rest } = data;
      formRef.current?.setFieldsValue({
        assignedWaterRightGroups: assignedWaterRightGroups ?? [],
        assignedWaterRights: assignedWaterRights ?? [],
        ...rest,
      });
      setRole(data.role);
    }
  }, [data]);

  const loadInvite = async () => {
    setLoading(true);
    const response = await getCompanyInvite(id);
    if (response.ok) {
      var data = await response.json();
      setData(data.value);
    }
    setLoading(false);
  };

  const loadUser = async () => {
    setLoading(true);
    const response = await getCompanyUser(selectedCompanyId, id);
    if (response.ok) {
      var data = await response.json();
      setData(data.value);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (role && role !== "admin") {
      getWaterRightGroupList();
    }
  }, [role]);

  const onFinish = async (values: any) => {
    setLoading(true);

    const data = { ...values, companyId: selectedCompanyId };

    const response = type === "user" ? await updateCompanyUser(id, data) : await inviteUserToCompany(data);

    if (response.ok) {
      if (id) message.success("User updated successfully");
      else message.success("User added successfully");
    } else {
      if (id) message.error("Failed to update the user");
      else message.error("Failed to add the user");
    }

    if (!addAnother) navigate(routes.userList);
    else {
      formRef.current?.resetFields();
      setRole(undefined);
      resetDefaults();
      navigate(routes.userAdd);
    }

    if (id) {
      dispatch(removeBreadcrumb());
    } else if (!addAnother) dispatch(removeBreadcrumb());

    setLoading(false);
    setAddAnother(false);
  };

  const handleSubmit = () => {
    formRef.current
      ?.validateFields()
      .then((values) => {
        onFinish(values);
      })
      .catch((errors) => {
        scrollToTop();
        message.error(`Please fill in the required fields`, 5);
      });
  };

  const handleAddAnother = () => {
    formRef.current
      ?.validateFields()
      .then((values) => {
        setAddAnother(true);
        formRef.current?.submit();
      })
      .catch((errors) => {
        scrollToTop();
        message.error(`Please fill in the required fields`, 5);
      });
  };

  const getWaterRightGroupList = async () => {
    setLoadingWaterRightGroups(true);
    const response = await getGroupings({ companyId: selectedCompanyId });
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWaterRightGroups(data.value);
      }
    }
    setLoadingWaterRightGroups(false);
  };

  const renderActionButtons = (placement: TooltipPlacement) => (
    <Space>
      {!id && (
        <>
          <Button loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={handleAddAnother}>
            Save + Add Another
          </Button>
          <Button
            loading={loading}
            icon={<CloseOutlined />}
            onClick={() => {
              dispatch(removeBreadcrumb());
              navigate(routes.userList);
            }}
          >
            Cancel
          </Button>
        </>
      )}
      {id && (
        <>
          <Button loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button
            loading={loading}
            icon={<CloseOutlined />}
            onClick={() => {
              dispatch(removeBreadcrumb());
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </>
      )}
    </Space>
  );

  return (
    <Card className="userInvite" id="userInvite" title={id ? `Edit User` : "Add User"} extra={renderActionButtons("bottomRight")} actions={[renderActionButtons("top")]}>
      <Form ref={formRef} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off">
        <Steps
          direction="vertical"
          items={[
            {
              title: "User Details",
              status: "process",
              description: (
                <Card>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={
                      type && type === "user"
                        ? undefined
                        : [
                            {
                              required: true,
                              message: "Please enter the email address",
                            },
                          ]
                    }
                  >
                    <Input placeholder="Enter the email address" disabled={type && type === "user"} />
                  </Form.Item>
                  {type && type === "user" && (
                    <>
                      <Form.Item label="Name" name="name">
                        <Input placeholder="N/A" disabled={true} />
                      </Form.Item>
                      <Form.Item label="Phone Number" name="phone">
                        <Input placeholder="N/A" disabled={true} />
                      </Form.Item>
                    </>
                  )}
                </Card>
              ),
            },
            {
              title: "Configure Access",
              status: "process",
              description: (
                <Card>
                  <Form.Item label="Active" name="active" valuePropName="checked">
                    <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
                  </Form.Item>
                  <Form.Item
                    label="Role"
                    name="role"
                    rules={[
                      {
                        required: true,
                        message: "Please select the user role",
                      },
                    ]}
                    getValueFromEvent={(val: any) => {
                      setRole(val);
                      return val;
                    }}
                  >
                    <Select placeholder="Select the user role">
                      {Object.keys(UserRole).map((key: any) => (
                        <Select.Option value={key}>{UserRole[key].label}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {role && (
                    <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                      <Alert showIcon description={UserRole[role].description} type="info" />
                    </Form.Item>
                  )}

                  {role && role !== "admin" && (
                    <Form.Item label="Assigned Water Right Groups" name="assignedWaterRightGroups">
                      <Select
                        mode="multiple"
                        placeholder="Select water right groups the user may access (Optional)"
                        loading={loadingWaterRightGroups}
                        disabled={loadingWaterRightGroups}
                        showSearch
                        optionFilterProp="label"
                      >
                        {waterRightGroups?.map((waterRightGroup: any) => (
                          <Select.Option value={waterRightGroup.id} key={waterRightGroup.id} label={waterRightGroup.name}>
                            {waterRightGroup.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}

                  {role && role !== "admin" && (
                    <WaterRightSelector formRef={formRef} label={"Assigned Water Rights"} placeholder={"Select water rights the user may access (Optional)"} propertyToSet={"assignedWaterRights"} />
                  )}
                </Card>
              ),
            },
          ]}
        />
      </Form>
    </Card>
  );
};

export default UserAddEdit;
