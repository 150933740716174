import { LookupLabel, RemainingYearlyAverageTable, RestrictionRadialGauge } from "@/components";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import RestrictionCalculations from "@/components/RestrictionCalculations/RestrictionCalculations";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import { constants } from "@/configs";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import useRestrictionReportQuery from "@/queries/useRestrictionReportQuery";
import useWaterRightLookups from "@/queries/useWaterRightLookups";
import useWellLookups from "@/queries/useWellLookups";
import { Card, Descriptions, Image, Space } from "antd";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import { FC, useState } from "react";
import { useSelector } from "react-redux";

interface Props {
  reportConfig: {
    restrictionIds?: string[];
    restrictions?: string;
    generatedDate: string;
    year?: number;
    detailed: boolean;
    active: boolean | null;
  };
}

const GeneratedEndOfYearRestrictionReport: FC<Props> = (props) => {
  const { reportConfig } = props;

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const {
    restrictionReport: reportData,
    isLoading,
    isFetching,
    hasNextPage,
  } = useRestrictionReportQuery({
    restrictionIds: reportConfig?.restrictionIds,
    generatedDate: reportConfig?.generatedDate,
    isActive: reportConfig.active ? reportConfig.active : undefined,
  });

  const { companyConfigs } = useComapnyConfigLookup();
  const { waterRights } = useWaterRightLookups();
  const { wells } = useWellLookups();

  return (
    <PrintPage
      isReport
      content={
        <Card
          loading={isFetching || hasNextPage || isLoading}
          id="generatedEndOfYearRestrictionReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`End Of Year - ${reportConfig.detailed ? "Detailed" : ""} Restriction Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
        >
          {reportData?.map((restriction: any) => {
            return (
              <Card title={restriction?.name} extra={!selectedCompanyId && <CompanyLabel companyId={restriction?.companyId} />}>
                <Descriptions bordered size="small" column={3} labelStyle={{ width: 165 }}>
                  <Descriptions.Item label="Type">
                    <LookupLabel lookupType="restrictiontype" value={restriction?.type} />
                  </Descriptions.Item>
                  <Descriptions.Item label="Start Date">{dayjs(restriction?.startDate).format(constants.dateFormat)}</Descriptions.Item>
                  <Descriptions.Item label="Term Quantity">
                    <UnitsConverter
                      fromUnits="gallons"
                      showUnitsLabel
                      toUnits={companyConfigs?.find((c: any) => c?.id === restriction?.companyId)?.settings?.metric}
                      value={restriction?.termQuantity}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Term">{restriction?.years}</Descriptions.Item>
                  <Descriptions.Item label="End Date">{dayjs(restriction?.endDate).format(constants.dateFormat)}</Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <>
                        Baseline <br />
                        <small>Term Quantity / Years</small>
                      </>
                    }
                  >
                    <UnitsConverter
                      fromUnits="gallons"
                      showUnitsLabel
                      toUnits={companyConfigs?.find((c: any) => c?.id === restriction?.companyId)?.settings?.metric}
                      value={restriction?.baselineQuantity}
                    />
                  </Descriptions.Item>
                </Descriptions>
                <div style={{ flex: 1, flexDirection: "row", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", marginBottom: 20 }}>
                  <RestrictionRadialGauge
                    myfa={restriction?.myfa}
                    type={restriction?.type}
                    remainingYears={restriction.remainingYears}
                    companyId={restriction?.companyId}
                    usedQuantity={restriction?.used}
                    termQuantity={restriction?.termQuantity}
                    style={{ paddingBottom: 0, marginLeft: 30, marginRight: 20, marginTop: 20 }}
                  />
                  <Card
                    styles={{
                      body: {
                        padding: 0,
                      },
                    }}
                    style={{ flex: 1 }}
                  >
                    <RemainingYearlyAverageTable calculation={restriction} />
                  </Card>
                  <br />
                </div>
                <Card styles={{ body: { padding: 13 } }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        fontSize: 12,
                        width: 100,
                        fontWeight: "bold",
                        padding: 0,
                      }}
                    >
                      Water Rights:{" "}
                    </div>
                    <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>
                      {restriction?.waterRightIds?.length > 0
                        ? waterRights
                            ?.filter((waterRight: any) => restriction?.waterRightIds?.includes(waterRight?.id))
                            ?.map((waterRight: any) => waterRight?.fileNumber)
                            ?.join(", ")
                        : "-"}
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        fontSize: 12,
                        width: 100,
                        fontWeight: "bold",
                        padding: 0,
                      }}
                    >
                      Wells:{" "}
                    </div>
                    <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>
                      {restriction?.waterRightIds?.length > 0
                        ? wells
                            ?.filter((well: any) => restriction?.waterRightIds?.includes(well?.waterRightId))
                            ?.map((well: any) => well?.name)
                            ?.join(", ")
                        : "-"}
                    </div>
                  </div>
                </Card>
                {reportConfig.detailed && (
                  <RestrictionCalculations isReport startDate={restriction?.startDate} endDate={restriction?.endDate} termQuantity={restriction?.termQuantity} id={restriction?.id} showIcon={false} />
                )}
              </Card>
            );
          })}
          <div className="page-break" />
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Restrictions">{reportConfig?.restrictions && reportConfig.restrictions?.length > 0 ? reportConfig.restrictions : "-"}</Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
    />
  );
};

export default GeneratedEndOfYearRestrictionReport;
