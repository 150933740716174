import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface IWaterRightState {
  waterRights: any[];
  refreshWaterRightCalculation: boolean;
}

const initialState: IWaterRightState = {
  waterRights: [],
  refreshWaterRightCalculation: false,
};

const waterRightStore = createSlice({
  name: "waterRight",
  initialState,
  reducers: {
    saveWaterRights: (state: IWaterRightState, action: PayloadAction<any>) => {
      state.waterRights = action.payload;
    },
    refreshWaterRightCalculationState: (state: IWaterRightState) => {
      state.refreshWaterRightCalculation = true;
    },
    clearWaterRightCalculationState: (state: IWaterRightState) => {
      state.refreshWaterRightCalculation = false;
    },
  },
});

export const { saveWaterRights, refreshWaterRightCalculationState, clearWaterRightCalculationState } = waterRightStore.actions;

export default waterRightStore.reducer;
