import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import sessionStorage from "redux-persist/es/storage/session";
import company from "./company.store";
import field from "./field.store";
import grouping from "./grouping.store";
import lookups from "./lookups.store";
import restriction from "./restriction.store";
import serverStatus from "./serverStatus.store";
import user from "./user.store";
import waterRight from "./waterright.store";
import well from "./well.store";
import breadcrumb from "./breadcrumbs.store";
import communication from "./communication.store";

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

const persistConfig = {
  key: "root",
  version: 1,
  storage: sessionStorage,
};

const persistedCompanyReducer = persistReducer(persistConfig, company);
// const persistedCommunicationReducer = persistReducer(persistConfig, communication);
const persistedBreadcrumbReducer = persistReducer(persistConfig, breadcrumb);

export const store = configureStore({
  reducer: {
    company: persistedCompanyReducer,
    communication,
    user,
    lookups,
    field,
    grouping,
    well,
    waterRight,
    serverStatus,
    restriction,
    // breadcrumb,
    breadcrumb: persistedBreadcrumbReducer,
  },
  // middleware: [thunk]
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export const clearPersistedStore = () => {
  persistor.purge();
};

export default store;
