import { getRestrictionCalculation } from "@/apis/restriction.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSelector } from "react-redux";

type RestrictionCalculation = {
  id?: string;
};

export default function useRestrictionCalculation({ id }: RestrictionCalculation) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();

  const { isFetching, data: restrictionCalculation } = useQuery({
    queryKey: [
      CacheKeys.restrictions,
      CacheKeys.calculation,
      {
        profileId: profile?.id,
        id,
      },
    ],
    queryFn: async () => {
      const response = await getRestrictionCalculation(id);

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;

        throw new Error("Failed to fetch restriction calculation");
      }
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id && !!id,
  });

  const invalidateRestrictionCalculation = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.restrictions, CacheKeys.calculation],
    });
  }, [queryClient]);

  const isLoading = isFetching;

  return {
    isLoading,
    restrictionCalculation,
    invalidateRestrictionCalculation,
  };
}
