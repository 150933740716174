import { getLookups } from "@/apis/lookups.api";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@/stores";
import { saveLookups } from "@/stores/lookups.store";

interface Props {
  lookupType: string;
  value: any;
  showCode?: boolean;
  dontShowCode?: boolean;
  placeholder?: string;
  onlyShowCode?: boolean;
  additionalOptions?: {
    value: any;
    label: string;
  }[];
}

const LookupLabel: FC<Props> = (props) => {
  const { lookupType, value, showCode = true, dontShowCode = false, placeholder = "-", onlyShowCode = false, additionalOptions } = props;

  const dispatch = useAppDispatch();

  const { lookups } = useSelector((state: any) => state.lookups);

  const [loading, setLoading] = useState<boolean>(true);
  const [label, setLabel] = useState<any>(placeholder);

  useEffect(() => {
    if (lookups?.length === 0) refreshLookupsList();
    else setLoading(false);
  }, []);

  useEffect(() => {
    if (value) {
      var lookup = lookups.find((lookup: any) => lookup.map === lookupType.toLowerCase());
      var option = lookup?.options.find((option: any) => option.value === value?.toString());
      if (!option && (lookupType.toLowerCase() === "metermultiplier" || lookupType.toLowerCase() === "metermaxvalue"))
        option = lookup?.options.find((option: any) => Number(option.value) === Number(value));
      if (option) {
        if (onlyShowCode) setLabel(option.code);
        else if (dontShowCode) setLabel(option.name);
        else setLabel(lookup.showCode && showCode ? `${option.code} - ${option.name}` : option.name);
      }
      if (additionalOptions) {
        const additionalOption = additionalOptions.find((option: any) => option.value === value);
        if (additionalOption) {
          setLabel(additionalOption.label);
        }
      }
    }
  }, [onlyShowCode, value, lookupType, lookups]);

  const refreshLookupsList = async () => {
    setLoading(true);
    const response = await getLookups();
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        dispatch(saveLookups(data.value));
      }
    }
    setLoading(false);
  };

  return loading ? "Loading..." : label;
};

export default LookupLabel;
