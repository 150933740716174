import { ReloadOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Card, Descriptions, Popover } from "antd";
import useDashboardCounts from "@/queries/useDashboardCounts";
import CacheKeys from "@/types/CacheKeys";
import { constants } from "@/configs";
import dayjs from "dayjs";

const descriptionStyle = {
  margin: 0,
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 10,
  paddingRight: 10,
};

type StatsProps = {
  companyId?: string;
  renderCompanyCount?: boolean;
};

export default function Stats({ companyId, renderCompanyCount = false }: StatsProps) {
  const queryClient = useQueryClient();
  const { stats, isPending: initialLoading, isLoading, lastUpdated, isError } = useDashboardCounts(companyId);

  if (isError || initialLoading) return null;

  return (
    <Card
      bordered={false}
      loading={initialLoading}
      styles={{
        body: {
          padding: 0,
          minWidth: 300,
        },
      }}
    >
      <Popover
        arrow
        content={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 10,
            }}
          >
            <small>
              <b>Last Updated:</b> {dayjs(lastUpdated).format(constants.dateTimeFormatWithSeconds)}
            </small>
            <Button
              disabled={isLoading}
              loading={isLoading}
              onClick={() =>
                queryClient.invalidateQueries({
                  queryKey: [CacheKeys.dashboardCounts],
                })
              }
              icon={<ReloadOutlined />}
              size="small"
            >
              Refresh
            </Button>
          </div>
        }
        placement="bottom"
      >
        <Descriptions
          size="small"
          bordered
          column={{ xs: 2, sm: 2, md: 4, lg: 4, xl: 8, xxl: 8 }}
          items={(stats as any[]).slice(renderCompanyCount ? 0 : 1)}
          className="removeBoxShadow"
          style={{ padding: 0, margin: 0 }}
          contentStyle={descriptionStyle}
          labelStyle={descriptionStyle}
        />
      </Popover>
    </Card>
  );
}
