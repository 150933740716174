import { listFinanceDocuments } from "@/apis/aggregator.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import { FinanceDocumentStatus, FinanceDocumentType } from "@/pages/Admin/AdminBilling/AdminInvoices";
import CacheKeys from "@/types/CacheKeys";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

type ListFinanceDocumentsProps = {
  searchString?: string;
  type?: number;
  status?: number;
  userId?: string;
};

const useListFinanceDocumentsQuery = ({ searchString, type, status, userId }: ListFinanceDocumentsProps) => {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();

  const queryKey = useMemo(
    () => [
      CacheKeys.financeDocuments,
      CacheKeys.list,
      {
        profileId: profile?.id,
        searchString,
        type,
        status,
        userId,
      },
    ],
    [profile?.id, searchString, type, status, userId]
  );

  const { data, isFetching, isFetched, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading } = useInfiniteQuery({
    queryKey: queryKey,
    queryFn: async ({ pageParam }) => {
      const response = await listFinanceDocuments({
        searchString,
        type,
        status,
        userId,
        pagination: {
          page: pageParam,
          pageSize: 25,
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch finance documents");
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    gcTime: Infinity,
    staleTime: Infinity,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage?.pagination?.hasNextPage) return undefined;
      return lastPageParam + 1;
    },
  });

  const invalidateFinanceDocuments = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.financeDocuments, CacheKeys.list],
    });
  }, [queryClient]);

  const optimisticMarkAsPaid = useCallback(
    (id: string) => {
      const existingList: any = queryClient.getQueryData(queryKey);

      if (existingList) {
        const updatedList = existingList.pages.map((page: any) => {
          const updatedPage = page.list.map((item: any) => {
            if (item.id === id) {
              return {
                ...item,
                type: FinanceDocumentType.invoice,
                status: FinanceDocumentStatus.paid,
              };
            }
            return item;
          });

          return {
            ...page,
            list: updatedPage,
          };
        });

        queryClient.setQueryData(queryKey, {
          ...existingList,
          pages: updatedList,
        });
      }

      setTimeout(invalidateFinanceDocuments, 5000);
    },
    [queryKey, invalidateFinanceDocuments, queryClient]
  );

  const optimisticMarkAsSent = useCallback(
    (id: string) => {
      const existingList: any = queryClient.getQueryData(queryKey);

      if (existingList) {
        const updatedList = existingList.pages.map((page: any) => {
          const updatedPage = page.list.map((item: any) => {
            if (item.id === id && item.status === FinanceDocumentStatus.draft) {
              return {
                ...item,
                status: FinanceDocumentStatus.sent,
              };
            }
            return item;
          });

          return {
            ...page,
            list: updatedPage,
          };
        });

        queryClient.setQueryData(queryKey, {
          ...existingList,
          pages: updatedList,
        });
      }

      setTimeout(invalidateFinanceDocuments, 5000);
    },
    [queryKey, invalidateFinanceDocuments, queryClient]
  );

  const financeDocuments = useMemo(() => data?.pages?.map((page) => page.list).flat() ?? [], [data]);
  const totalItemCount = useMemo(() => data?.pages?.[(data?.pages?.length ?? 0) - 1]?.pagination?.totalItemCount ?? 0, [data]);

  return {
    financeDocuments,
    isLoading,
    fetchNextPage,
    optimisticMarkAsPaid,
    optimisticMarkAsSent,
    invalidateFinanceDocuments,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
    isFetched,
    totalItemCount,
  };
};

export default useListFinanceDocumentsQuery;
