import { EnvironmentFilled } from "@ant-design/icons";

const colorList = ["red"];

const Marker = ({ text, index }: any) => (
  <div>
    <svg
      fill={colorList[index === 0 ? 0 : colorList.length % index]}
      xmlns="http://www.w3.org/2000/svg"
      width="48px"
      height="48px"
      viewBox="0 0 425.963 425.963"
      // textAnchor={new window.google.maps.Point(17, 34)}
    >
      <g>
        <path
          d="M213.285,0h-0.608C139.114,0,79.268,59.826,79.268,133.361c0,48.202,21.952,111.817,65.246,189.081
		c32.098,57.281,64.646,101.152,64.972,101.588c0.906,1.217,2.334,1.934,3.847,1.934c0.043,0,0.087,0,0.13-0.002
		c1.561-0.043,3.002-0.842,3.868-2.143c0.321-0.486,32.637-49.287,64.517-108.976c43.03-80.563,64.848-141.624,64.848-181.482
		C346.693,59.825,286.846,0,213.285,0z M274.865,136.62c0,34.124-27.761,61.884-61.885,61.884
		c-34.123,0-61.884-27.761-61.884-61.884s27.761-61.884,61.884-61.884C247.104,74.736,274.865,102.497,274.865,136.62z"
        />
      </g>
    </svg>
    {/* <EnvironmentFilled style={{ transform: 'translateY(-48px)', fontSize: 48, color: colorList[index === 0 ? 0 : colorList.length % index] }} /> */}
  </div>
);

export default Marker;
