import { getWells } from "@/apis/well.api";
import { LookupLabel, StatusTag, WellTagList } from "@/components";
import PrintPage from "@/components/PrintPage/PrintPage";
import useFieldLookups from "@/queries/useFieldLookups";
import useWaterRightLookups from "@/queries/useWaterRightLookups";
import useWellLookups from "@/queries/useWellLookups";
import { Card, ConfigProvider, Descriptions, Image, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import "./GeneratedSummaryReport.scss";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import { daysOfWeek } from "@/services/utils";

interface Props {
  reportConfig: {
    wellIds?: string[];
    wells: string;
    generatedDate: string;
    active: boolean | null;
  };
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

export default function GeneratedWellConfigurationReport(props: Props) {
  const { reportConfig } = props;

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const { wells: wellLookups } = useWellLookups(undefined, selectedCompanyId, reportConfig.active ? reportConfig.active : undefined);
  const { fields } = useFieldLookups();
  const { waterRights } = useWaterRightLookups();
  const { companyConfigs } = useComapnyConfigLookup();

  const [loadingWells, setLoadingWells] = useState<boolean>(true);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [tempWells, setWells] = useState<any[]>([]);
  const [wellIds, setWellIds] = useState<string[]>([]);

  const [wellColumns, setWellColumns] = useState<any[]>([]);

  const wells: any[] = useMemo(() => {
    const data = tempWells.map((well: any) => {
      const waterRightForWell = waterRights.find((waterRight: any) => waterRight.id === well.waterRightId);
      const fieldForWaterRight = fields.find((field: any) => field.id === well.fieldId)?.name;

      return {
        ...well,
        waterRight: waterRightForWell,
        fieldName: fieldForWaterRight ? fieldForWaterRight : ["-"],
      };
    });

    return data;
  }, [tempWells, waterRights, fields]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  useEffect(() => {
    if (reportConfig.wellIds !== undefined && reportConfig?.wellIds.length > 0 && wellLookups?.length > 0) {
      setWellIds(reportConfig?.wellIds);
    } else {
      setWellIds(wellLookups?.map((well: any) => well?.id));
    }
    // eslint-disable-next-line
  }, [reportConfig, wellLookups]);

  useEffect(() => {
    if (wellIds.length > 0) {
      refreshWells();
    }
    // eslint-disable-next-line
  }, [wellIds]);

  useEffect(() => {
    calculateWellColumns();
    // eslint-disable-next-line
  }, [wells]);

  const refreshWells = async () => {
    setLoadingWells(true);
    const request = { companyId: selectedCompanyId, wellIds: wellIds };
    const response = await getWells(request);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWells(data.value);
      }
    }
    setLoadingWells(false);
  };

  const calculateWellColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Well Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        width: 300,
        render: (val: any, record: any) => (
          <>
            <div>{val}</div>
            <WellTagList style={{ maxWidth: 200 }} tags={record?.tags} />
          </>
        ),
      },
      {
        title: "Field Name",
        key: "fieldName",
        dataIndex: "fieldName",
        render: (val: any, record: any) => val,
      },
      {
        title: (
          <>
            Water Right /<br /> File Number
          </>
        ),
        key: "waterRight",
        dataIndex: "waterRight",
        render: (val: any, record: any) => (
          <>
            <div style={{ paddingLeft: 5 }}>{record?.waterRight?.fileNumber}</div>
            <div style={{ paddingLeft: 5 }}>PDIV: {record?.waterRight?.pdiv ? record.waterRight.pdiv : "-"}</div>
            <div style={{ paddingLeft: 5 }}>CIN: {record?.waterRight?.cin ? record.waterRight.cin : "-"}</div>
          </>
        ),
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (val: any, record: any) => <StatusTag status={val} />,
      },
      {
        title: (
          <>
            Meter <br />
            Max <br />
            Value
          </>
        ),
        key: "meterMaxValue",
        dataIndex: "meterMaxValue",
        align: "right",
        render: (val: any, record: any) => <LookupLabel lookupType="metermaxvalue" value={record?.meterMaxValue} />,
      },
      {
        title: (
          <>
            Meter <br />
            Multiplier
          </>
        ),
        key: "meterMultiplier",
        dataIndex: "meterMultiplier",
        align: "right",
        render: (val: any, record: any) => val,
      },
      {
        title: (
          <>
            Is <br />
            Digital <br />
            Meter
          </>
        ),
        key: "digital",
        dataIndex: "digital",
        render: (val: any, record: any) => <StatusTag status={val === true ? "YES" : "No"} />,
      },
      {
        title: (
          <>
            Number <br />
            of <br />
            Decimals
          </>
        ),
        key: "decimals",
        dataIndex: "decimals",
        render: (val: any, record: any) => (val ? val : "N/A"),
      },
      {
        title: (
          <>
            Meter <br />
            Units
          </>
        ),
        key: "meterUnits",
        dataIndex: "meterUnits",
        render: (val: any, record: any) => <LookupLabel lookupType="meterunits" value={record?.meterUnits} />,
      },
      {
        title: (
          <>
            Meter <br />
            Serial <br />
            Number
          </>
        ),
        key: "meterSerialNumber",
        dataIndex: "meterSerialNumber",
        render: (val: any, record: any) => (val ? val : "-"),
      },
      {
        title: (
          <>
            Latitude <br />
            Longitude
          </>
        ),
        // align: 'right',
        render: (val: any, record: any) => {
          if (record?.latitude && record?.longitude) {
            return (
              <>
                {record.latitude},
                <br />
                {record.longitude}
              </>
            );
          } else return "-";
        },
      },
      {
        title: (
          <>
            Meter Reading <br />
            Frequency Override
          </>
        ),
        key: "meterMultiplier",
        dataIndex: "meterMultiplier",
        align: "right",
        render: (val: any, record: any) => {
          const companyConfig = companyConfigs?.find((company: any) => company?.id === record?.companyId);

          if (record?.meterReadingFrequency) {
            return (
              <>
                <LookupLabel lookupType={"meterReadingFrequency"} value={record?.meterReadingFrequency} />
                <br />
                {record?.meterReadingFrequencyDay ? record?.meterReadingFrequencyDay : daysOfWeek.find((day) => day.value === record?.meterReadingFrequencyDayOfWeek)?.day}
              </>
            );
          } else if (companyConfig?.meterReadingFrequency) {
            return <>{companyConfig?.meterReadingFrequency ? companyConfig?.meterReadingFrequencyDay : daysOfWeek.find((day) => day.value === companyConfig?.meterReadingFrequencyDayOfWeek)?.day} *</>;
          } else return <>-</>;
        },
      },
    ];

    setWellColumns(tempColumns.filter((x) => x));
  };

  const renderWellExpandData = (record: any) => {
    const wells = wellLookups?.filter((well: any) => record?.reductionWells?.includes(well?.id));
    return (
      <div>
        <div style={{ padding: 5 }}>
          {record?.reductionWells?.length > 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  fontSize: 12,
                  width: 160,
                  fontWeight: "bold",
                  padding: 0,
                }}
              >
                Deduction Wells / Meters:{" "}
              </div>
              <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{wells.map((x: any) => x?.name).join(", ")}</div>
            </div>
          )}
        </div>
        {!selectedCompanyId && (
          <div style={{ padding: 5 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  fontSize: 12,
                  width: 160,
                  fontWeight: "bold",
                  padding: 0,
                }}
              >
                Company:{" "}
              </div>
              <div style={{ fontSize: 12 }}>
                <CompanyLabel companyId={record?.companyId} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedWellSummaryReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Well Configuration Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
          loading={loadingWells}
        >
          {
            <Card title="Wells / Meters">
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                  rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  rowKey={(row: any) => row.id}
                  loading={loadingWells}
                  columns={wellColumns}
                  dataSource={wells ?? []}
                  size="small"
                  pagination={false}
                  expandable={{
                    showExpandColumn: false,
                    expandedRowKeys: wells.filter((well) => well?.reductionWells?.length > 0)?.map((well) => well.id),
                    expandedRowClassName: (record: any, index: number) => (index % 2 === 0 ? "table-row-light" : "table-row-dark"),
                    expandedRowRender: (record: any) => renderWellExpandData(record),
                  }}
                />
              </ConfigProvider>
            </Card>
          }
          <div className="page-break" />
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Wells">{reportConfig.wells === "" ? "-" : reportConfig.wells}</Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
}
