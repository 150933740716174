import useCompanyLookups from "@/queries/useCompanyLookups";

type CompanyLabelProps = {
  companyId: string;
  isActive?: boolean;
};

export default function CompanyLabel(props: CompanyLabelProps) {
  const { companyId, isActive } = props;
  const { company } = useCompanyLookups(companyId, undefined, isActive);

  if (company) return <>{company.name}</>;
  else return <>Loading...</>;
}
