import { useLocalStorage } from "@uidotdev/usehooks";

export enum FeatureFlagType {
  ANALYTICS_DASHBOARD = "ANALYTICS_DASHBOARD",
  IMPERSONATION = "IMPERSONATION",
  PRINT_PAGE = "PRINT_PAGE",
}

type FeatureFlag = {
  feature: FeatureFlagType;
  enabled: boolean;
};

const defaultFeatures: FeatureFlag[] = Object.keys(FeatureFlagType).map((key) => ({ feature: key as FeatureFlagType, enabled: false }));

function useFeatureFlag(feature: FeatureFlagType) {
  const [features] = useLocalStorage<FeatureFlag[]>("features", defaultFeatures);
  return {
    features,
    hasFeature: !!features.find((x) => x.feature === feature && x.enabled),
  };
}

export default useFeatureFlag;
