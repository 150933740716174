import { getWellDashboard } from "@/apis/aggregator.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

type WellDasboardPagedList = {
  searchString?: string;
  sortOrder?: any;
  permitTypeFilters?: string[];
  pageIdentifier?: string;
  halfRemaining?: boolean;
  daysRemaining?: boolean;
  remainingPercentage?: number | undefined;
  remainingDays?: number | undefined;
};

const useListWellDashboardQuery = (props: WellDasboardPagedList) => {
  const { profile } = useSelector((state: any) => state.user);

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const { data, isFetching, isFetched, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading } = useInfiniteQuery({
    queryKey: [
      CacheKeys.wellDashboard,
      CacheKeys.list,
      props.pageIdentifier,
      {
        profileId: profile?.id,
        sortOrder: props.sortOrder,
        searchString: props.searchString,
        companyId: selectedCompanyId,
        permitTypeFilters: props.permitTypeFilters,
        halfRemaining: props.halfRemaining,
        daysRemaining: props.daysRemaining,
        remainingPercentage: props.remainingPercentage,
        remainingDays: props.remainingDays,
      },
    ],
    queryFn: async ({ pageParam }) => {
      const response = await getWellDashboard({
        ...props,
        page: pageParam,
        pageSize: 16,
        companyId: selectedCompanyId,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch well dashboard infinite query");
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    gcTime: 30 * 1000, //30 seconds
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage?.pagination?.hasNextPage) return undefined;
      return lastPageParam + 1;
    },
  });

  const invalidateDashboardWells = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.wellDashboard, CacheKeys.list, props.pageIdentifier],
    });
  }, [props.pageIdentifier, queryClient]);

  const dashboardWells = useMemo(() => data?.pages?.map((page) => page.list).flat() ?? [], [data]);
  const currentItemCount = dashboardWells.length;
  const totalItemCounts = data?.pages?.map((page) => page.pagination?.totalItemCount).flat() ?? [];

  return {
    dashboardWells,
    isLoading,
    currentItemCount,
    totalItemCount: totalItemCounts[totalItemCounts.length - 1],
    fetchNextPage,
    invalidateDashboardWells,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
    isFetched,
  };
};

export default useListWellDashboardQuery;
