import { updateNotBillable } from "@/apis/aggregator.api";
import { ConfigProvider, Input, message, Space, Table, Tag } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import NotBillableButton from "./components/NotBillableButton";

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

interface Props {
  record: any;
  isBillable: boolean;
  optimisticBillableStatusUpdate: (id: string, billable: boolean, userId?: string) => void;
}

const AdminBillingEntitiesExpandRender: FC<Props> = ({ record, isBillable, optimisticBillableStatusUpdate }) => {
  const [loadingEntities, setLoadingEntities] = useState<boolean>(false);

  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);

  const [columns, setColumns] = useState<any[]>([]);

  const entities: any[] = useMemo(() => {
    const tempSearchString = searchString?.toLowerCase() ?? "";
    const notBillableEntities: any[] = record?.notBillableEntities;

    const companiesForUser = record?.companies
      ?.filter((company: any) => company?.name?.toLowerCase()?.includes(tempSearchString))
      .map((company: any) => {
        const mappedCompany: any = {
          ...company,
          id: company?.companyId,
          type: "Company",
          isBillable: !notBillableEntities.includes(company?.companyId) && isBillable,
        };

        mappedCompany.children = mappedCompany.waterRights
          .filter((waterRight: any) => waterRight?.fileNumber?.toLowerCase()?.includes(tempSearchString))
          .map((waterRight: any) => {
            const mappedWaterRight: any = {
              ...waterRight,
              id: waterRight?.waterRightId,
              name: waterRight?.fileNumber,
              type: "Water Right",
              companyName: mappedCompany.name,
              isBillable: !notBillableEntities.includes(waterRight?.waterRightId) && mappedCompany.isBillable,
            };

            mappedWaterRight.children = mappedCompany.wells
              .filter((well: any) => well.waterRightId === waterRight.waterRightId && well?.name?.toLowerCase()?.includes(tempSearchString))
              .map((well: any) => {
                const mappedWell: any = {
                  ...well,
                  id: well?.wellId,
                  type: "Well",
                  companyName: mappedCompany.name,
                  isBillable: !notBillableEntities.includes(well?.wellId) && mappedWaterRight.isBillable,
                };

                return mappedWell;
              });

            if (mappedWaterRight.children.length === 0) mappedWaterRight.children = null;

            return mappedWaterRight;
          });

        if (mappedCompany.children.length === 0) mappedCompany.children = null;
        return mappedCompany;
      });

    return companiesForUser;
  }, [record, searchString]);

  useEffect(() => {
    calculateColumns();
  }, [entities]);

  const updateBillableStatus = async (id: string, billable: boolean, entityType: string, entityName: string, companyName: string | undefined, onCompleted: () => void) => {
    try {
      const response = await updateNotBillable({ id, billable, entityName, entityType, companyName });
      if (response.ok) {
        optimisticBillableStatusUpdate(id, billable, record.id);
      } else {
        message.error("Failed to update billable status");
      }
    } catch (error) {
      message.error("Failed to update billable status");
    }
    onCompleted();
  };

  const calculateColumns = () => {
    let childColumns: any[] = [
      {
        title: "Water Right / File Number / Name",
        key: "name",
        dataIndex: "name",
        render: (val: any, record: any) => val,
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        render: (val: any, record: any) => val,
      },
      {
        title: "Billable",
        key: "isBillable",
        dataIndex: "isBillable",
        width: 50,
        render: (val: any, record: any) => (val === true ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>),
      },
      {
        title: "Actions",
        width: 75,
        render: (val: any, record: any) => (
          <NotBillableButton id={val.id} isBillable={record.isBillable} name={`${record.name}`} type={record.type} companyName={record.companyName} onUpdateBillableStatus={updateBillableStatus} />
        ),
      },
    ];

    setColumns(childColumns);
  };

  const onSearch = (searchString: any) => {
    setSearchString(searchString);
  };

  const onSearchChange = (searchString: any) => {
    setSearchInput(searchString);
  };

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space style={{ paddingBottom: 10 }}>
          <Input.Search
            disabled={loadingEntities}
            placeholder="Search entities"
            onSearch={(searchString) => {
              onSearch(searchString);
            }}
            onChange={(val) => onSearchChange(val.currentTarget.value)}
            value={searchInput}
            style={{ width: 400 }}
            allowClear
          />
        </Space>
      </Space>
      <Table
        rowKey={(row: any) => row.id}
        columns={columns}
        dataSource={entities ?? []}
        size="small"
        pagination={false}
        // loading={loadingEntities}
        // expandable={{
        //     expandedRowKeys: expandedEntities,
        //     onExpand: (expanded, record) => {
        //         if (expanded) setExpandedEntities([...(expandedEntities ?? []), record.entityId]);
        //         else {
        //             setExpandedEntities((expandedEntities ?? []).filter((id: string) => id !== record.entityId));
        //             setExpandAll(false);
        //         }
        //     },
        //     onExpandedRowsChange: setExpandedEntities,
        // }}
      />
    </ConfigProvider>
  );
};

export default AdminBillingEntitiesExpandRender;
