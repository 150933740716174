import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Form, FormInstance, Input, Space, Steps, message } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { TransferDirection } from "antd/es/transfer";
import { addGrouping, getGrouping, updateGrouping } from "@/apis/grouping.api";
import { TableTransfer } from "@/components";
import { routes } from "@/configs";
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useCustomNavigate from "@/services/useCustomNavigate";
import scrollToTop from "@/services/scrollToTop";
import { useAppDispatch } from "@/stores";
import "./GroupingAddEdit.scss";
import { removeBreadcrumb, removeViewEditBreadcrumbs } from "@/stores/breadcrumbs.store";

const GroupingAddEdit: FC = () => {
  const dispatch = useAppDispatch();

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const { id } = useParams<{ id: string }>();
  const formRef = useRef<FormInstance>(null);
  const { navigate } = useCustomNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [addAnother, setAddAnother] = useState<boolean>(false);

  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [groupingData, setGroupingData] = useState<any>([]);

  useEffect(() => {
    if (id) getGroupingData();
    else {
      resetDefaults();
    }
    // eslint-disable-next-line
  }, [id]);

  const resetDefaults = () => {
    scrollToTop();
  };

  useEffect(() => {
    if (groupingData) {
      formRef.current!.setFieldsValue(groupingData);
      formRef.current!.setFieldValue("name", groupingData.name);
    }
    // eslint-disable-next-line
  }, [groupingData]);

  const onKeysChange = (nextTargetsKeys: string[]) => {
    setTargetKeys(nextTargetsKeys.filter((item: any) => item !== undefined));
  };

  const getGroupingData = async () => {
    setLoading(true);
    const response = await getGrouping(id);
    if (response.ok) {
      const data = await response.json();
      if (id) setTargetKeys(data.value.waterRights);
      setGroupingData(data.value);
    }
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const data = {
      name: values.name,
      companyId: groupingData?.companyId ?? selectedCompanyId,
      waterRights: targetKeys,
    };

    if (id) {
      const response = await updateGrouping(id, data);
      if (response.ok) {
        message.success("Successfully updated the grouping");
        navigate(routes.groupingList);
      } else {
        message.error("Failed to update the grouping");
      }
    } else {
      const response = await addGrouping(data);

      if (response.ok) {
        message.success("Successfully added the grouping");
        if (addAnother) {
          setGroupingData(undefined);
          formRef.current?.resetFields();
          resetDefaults();
          navigate(routes.groupingAdd);
        } else navigate(routes.groupingList);
      } else {
        message.error("Failed to add the grouping");
      }
    }

    if (id) {
      dispatch(removeViewEditBreadcrumbs());
    } else if (!addAnother) dispatch(removeBreadcrumb());

    setLoading(false);
    setAddAnother(false);
  };

  const handleSubmit = () => {
    formRef.current
      ?.validateFields()
      .then((values) => {
        onFinish(values);
      })
      .catch((errors) => {
        scrollToTop();
        message.error(`Please fill in the required fields`, 5);
      });
  };

  const handleAddAnother = () => {
    formRef.current
      ?.validateFields()
      .then((values) => {
        setAddAnother(true);
        formRef.current?.submit();
      })
      .catch((errors) => {
        scrollToTop();
        message.error(`Please fill in the required fields`, 5);
      });
  };

  const renderActionButtons = (placement: TooltipPlacement) => (
    <Space>
      {!id && (
        <>
          <Button disabled={loading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button disabled={loading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={handleAddAnother}>
            Save + Add Another
          </Button>
          <Button
            disabled={loading}
            loading={loading}
            icon={<CloseOutlined />}
            onClick={() => {
              dispatch(removeBreadcrumb());
              navigate(routes.groupingList);
            }}
          >
            Cancel
          </Button>
        </>
      )}
      {id && (
        <>
          <Button disabled={loading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button
            disabled={loading}
            loading={loading}
            icon={<CloseOutlined />}
            onClick={() => {
              dispatch(removeBreadcrumb());
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </>
      )}
    </Space>
  );

  const handleSearch = (dir: TransferDirection, value: string) => {
    console.log("search:", dir, value);
  };

  const filterOption = (inputValue: string, option: any) => option.fileNumber.indexOf(inputValue) > -1;

  return (
    <Card className="groupingAddEdit" id="groupingAddEdit" title={id ? "Edit Grouping" : "Add Grouping"} extra={renderActionButtons("bottomRight")} actions={[renderActionButtons("top")]}>
      <Form ref={formRef} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} onFinish={onFinish} autoComplete="off">
        <Steps
          direction="vertical"
          items={[
            {
              title: "Details",
              status: "process",
              description: (
                <Card>
                  <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please enter a name" }]}>
                    <Input placeholder="Enter a name" />
                  </Form.Item>
                </Card>
              ),
            },
            {
              title: "Water Rights",
              status: "process",
              description: (
                <Card>
                  <TableTransfer
                    companyId={groupingData?.companyId ?? selectedCompanyId}
                    filterOption={filterOption}
                    showSearch
                    onSearch={handleSearch}
                    targetKeys={targetKeys}
                    onChange={onKeysChange}
                    disabled={loading}
                    loading={loading}
                    leftHeader="Available Water Rights"
                    rightHeader="Water Rights In Grouping"
                  />
                </Card>
              ),
            },
          ]}
        />
      </Form>
    </Card>
  );
};

export default GroupingAddEdit;
