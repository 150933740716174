import AnalyticsTotals from "@/components/Dashboard/AnalyticsTotals/AnalyticsTotals";
import useWaterRightAnalyticsOverview from "@/queries/useWaterRightAnalyticsOverview";
import { ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Row, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import { FC } from "react";
import dayjs from "dayjs";
import { constants } from "@/configs";
import {
  AllocationByWell,
  CropAllocationByAcres,
  LookupSelector,
  TotalCompanyWaterUsageByMonth,
  UsageByWell,
  WaterAllocationByWaterSource,
  WaterAllocationPerPermitType,
  WaterQuantityByCrop,
  WaterQuantityByProduct,
  WaterQuantitySavedByProduct,
  WaterRightQuantitiesPerCompany,
  WaterSavedByCrop,
} from "@/components";

const AnalyticsDashboard: FC = () => {
  const [form] = Form.useForm();
  const permitType = useWatch("permitType", form);

  const { waterRightsAnalyticsOverview, waterRightAnalyticsOverviewIsLoading, waterRightsAnalyticsOverviewUpdatedAt, invalidateWaterRightAnalyticsOverview } =
    useWaterRightAnalyticsOverview(permitType);

  const handleRefresh = () => {
    invalidateWaterRightAnalyticsOverview();
  };

  return (
    <Form form={form}>
      <Card
        title={<>Analytics Overview </>}
        extra={
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 10 }}>
            {waterRightsAnalyticsOverviewUpdatedAt > 0 && (
              <div style={{ opacity: 0.8 }}>
                Last Updated: <span>{dayjs(waterRightsAnalyticsOverviewUpdatedAt).format(constants.dateTimeFormatWithSeconds)}</span>
              </div>
            )}
            <Form.Item name="permitType" noStyle>
              <LookupSelector
                form={form}
                propertyToSet="permitType"
                placeholder="Select "
                lookupType="permitType"
                style={{ minWidth: 250 }}
                additionalOptions={[
                  {
                    label: "* All *",
                    value: "overview",
                  },
                ]}
                hideCode
                setDefault
                defaultOverride="overview"
              />
            </Form.Item>
            <Button key="refresh" onClick={handleRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </div>
        }
        loading={!permitType || waterRightAnalyticsOverviewIsLoading}
      >
        <Card.Grid hoverable={false} style={{ width: "100%", padding: 0 }}>
          <Row>
            <Col span={6}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <AnalyticsTotals permitType={permitType} waterRightsAnalyticsOverview={waterRightsAnalyticsOverview} />
                <TotalCompanyWaterUsageByMonth permitType={permitType} />
              </Space>
            </Col>
            <Col span={18}>
              {permitType === "overview" && (
                <>
                  <Row>
                    <Col span={8}>{permitType === "overview" && <WaterAllocationPerPermitType waterRightsAnalyticsOverview={waterRightsAnalyticsOverview} />}</Col>
                    <Col span={8}>
                      <WaterRightQuantitiesPerCompany waterRightsAnalyticsOverview={waterRightsAnalyticsOverview} />
                    </Col>
                    <Col span={8}>
                      <WaterAllocationByWaterSource waterRightsAnalyticsOverview={waterRightsAnalyticsOverview} />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <CropAllocationByAcres waterRightsAnalyticsOverview={waterRightsAnalyticsOverview} />
                    </Col>
                    <Col span={8}>
                      <WaterQuantityByProduct waterRightsAnalyticsOverview={waterRightsAnalyticsOverview} />
                    </Col>
                    <Col span={8}>
                      <WaterQuantitySavedByProduct waterRightsAnalyticsOverview={waterRightsAnalyticsOverview} />
                    </Col>
                  </Row>
                </>
              )}
              {permitType === "irrigation" && (
                <>
                  <Row>
                    <Col span={8}>{permitType === "overview" && <WaterAllocationPerPermitType waterRightsAnalyticsOverview={waterRightsAnalyticsOverview} />}</Col>
                    <Col span={8}>
                      <WaterRightQuantitiesPerCompany waterRightsAnalyticsOverview={waterRightsAnalyticsOverview} />
                    </Col>
                    <Col span={8}>
                      <WaterAllocationByWaterSource waterRightsAnalyticsOverview={waterRightsAnalyticsOverview} />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <CropAllocationByAcres waterRightsAnalyticsOverview={waterRightsAnalyticsOverview} />
                    </Col>
                    <Col span={8}>
                      <WaterQuantityByCrop waterRightsAnalyticsOverview={waterRightsAnalyticsOverview} />
                    </Col>
                    <Col span={8}>
                      <WaterSavedByCrop waterRightsAnalyticsOverview={waterRightsAnalyticsOverview} />
                    </Col>
                  </Row>
                </>
              )}
              {permitType === "stockwater" && (
                <>
                  <Row>
                    <Col span={8}>
                      <WaterRightQuantitiesPerCompany waterRightsAnalyticsOverview={waterRightsAnalyticsOverview} />
                    </Col>
                    <Col span={8}>
                      <AllocationByWell />
                    </Col>
                    <Col span={8}>
                      <UsageByWell />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}></Col>
                    <Col span={8}></Col>
                    <Col span={8}></Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Card.Grid>
      </Card>
    </Form>
  );
};

export default AnalyticsDashboard;
