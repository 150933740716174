import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface IRestrictionState {
  restrictions: string[];
  restrictionData: any | undefined;
}

const initialState: IRestrictionState = {
  restrictions: [],
  restrictionData: undefined,
};

const restrictionStore = createSlice({
  name: "restriction",
  initialState,
  reducers: {
    saveRestrictions: (state: IRestrictionState, action: PayloadAction<any>) => {
      state.restrictions = action.payload;
    },
    saveRestriction: (state: IRestrictionState, action: PayloadAction<any>) => {
      state.restrictionData = action.payload;
    },
    clearRestrictionState: (state: IRestrictionState) => {
      state.restrictions = [];
      state.restrictionData = undefined;
    },
  },
});

export const { saveRestrictions, saveRestriction, clearRestrictionState } = restrictionStore.actions;

export default restrictionStore.reducer;
