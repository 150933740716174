import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Select, Space, Steps, Tag, message } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { getPlaceOfUse, upsertPlaceOfUse } from "@/apis/waterright.api";
import { routes } from "@/configs";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { FC, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import scrollToTop from "@/services/scrollToTop";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { removeBreadcrumb } from "@/stores/breadcrumbs.store";

const PlaceOfUse: FC = () => {
  const dispatch = useAppDispatch();

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const user = useAuth();

  const [form] = Form.useForm<any>();

  const { navigate } = useCustomNavigate();

  // const [placeOfUseData, setPlaceOfUseData] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // if (selectedCompanyId) refreshPlaceOfUseData();
  }, [selectedCompanyId]);

  // const refreshPlaceOfUseData = async () => {
  //     setLoading(true);

  //     const response = await getPlaceOfUse(selectedCompanyId);
  //     if (response.ok) {
  //         const data = await response.json();
  //         if (data.isSuccess) setPlaceOfUseData(data.value);
  //     }

  //     setLoading(false);
  // }

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {value}
      </Tag>
    );
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onFinish(values);
      })
      .catch((errors) => {
        scrollToTop();
        message.error("Please fill in the required fields", 5);
      });
  };

  const renderActionButtons = (placement: TooltipPlacement) => (
    <Space>
      <Button loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
        Save
      </Button>
      <Button
        disabled={loading}
        loading={loading}
        icon={<CloseOutlined />}
        onClick={() => {
          dispatch(removeBreadcrumb());
          navigate(routes.placeOfUseList);
        }}
      >
        Cancel
      </Button>
    </Space>
  );

  const onFinish = async (values: any) => {
    setLoading(true);

    const data = { ...values };

    const request = {
      companyId: selectedCompanyId,
      placeOfUse: data.placesOfUse,
    };

    const response = await upsertPlaceOfUse(request);
    if (response.ok) {
      message.success("Successfully add place of use");
      removeBreadcrumb();
      navigate(routes.placeOfUseList);
    } else message.error("Failed to add place of use");

    setLoading(false);
  };

  return (
    <Card className="placeOfUseAddEdit" id="placeOfUseAddEdit" title={loading ? "Loading" : "Add Place of Use"} extra={renderActionButtons("bottomRight")} actions={[renderActionButtons("top")]}>
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} onFinish={onFinish} autoComplete="off">
        <Steps
          direction="vertical"
          items={[
            {
              title: "Details",
              status: "process",
              description: (
                <Card>
                  <Form.Item label="Places of Use" name="placesOfUse" style={{ margin: 0, marginBottom: 10 }}>
                    <Input placeholder="Enter place of use" />
                  </Form.Item>
                </Card>
              ),
            },
          ]}
        />
      </Form>
    </Card>
  );
};

export default PlaceOfUse;
