import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface IFieldState {
  fields: string[];
  fieldData: any | undefined;
}

const initialState: IFieldState = {
  fields: [],
  fieldData: undefined,
};

const fieldStore = createSlice({
  name: "field",
  initialState,
  reducers: {
    saveFields: (state: IFieldState, action: PayloadAction<any>) => {
      state.fields = action.payload;
    },
    saveField: (state: IFieldState, action: PayloadAction<any>) => {
      state.fieldData = action.payload;
    },
    clearFieldState: (state: IFieldState) => {
      state.fields = [];
      state.fieldData = undefined;
    },
  },
});

export const { saveFields, saveField, clearFieldState } = fieldStore.actions;

export default fieldStore.reducer;
