import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { FC } from "react";

interface Props {
  items: string[];
  maxLength: number;
  title: string;
}

const JoinedItems: FC<Props> = (props: Props) => {
  const { items, maxLength, title } = props;

  let indexArr = 0;
  let count = 0;

  if (items?.length > 0) {
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      count += element.length;

      if (count > maxLength) {
        indexArr = index - 1;
        break;
      } else indexArr = index;
    }

    const newArray = items.slice(0, indexArr === items?.length - 1 ? undefined : indexArr + 1);

    if (indexArr === items?.length - 1) return <>{newArray.join(", ")}</>;
    else
      return (
        <Popover content={<div style={{ maxWidth: 400 }}>{items.join(", ")}</div>} title={title}>
          <InfoCircleOutlined /> {`${newArray.join(", ")}`}...
        </Popover>
      );
  } else return <>-</>;
};

export default JoinedItems;
