import { Button, Card, Divider, Form, Space, TreeSelect } from "antd";
import {
  AllConfigurationReport,
  CompanyTotalUsageReport,
  DetailedEndOfYearWaterUsageReport,
  EndOfYearMeterReadingReport,
  EndOfYearRestrictionReport,
  EndOfYearSummaryReport,
  ExportWaterRightDateInformationReport,
  ExportWaterRightInformationReport,
  FieldConfigurationReport,
  GPMSummaryReport,
  GroupingConfigurationReport,
  LastReadingReport,
  NotesReport,
  PlacesOfUseReport,
  RestrictionConfigurationReport,
  RestrictionYearlyBreakdownReport,
  StockConfigurationReport,
  SummaryUsageReport,
  WaterRightConfigurationReport,
  WaterRightTotalUsageReport,
  WellConfigurationReport,
} from "@/pages/Reports";
import FeedLotReport from "@/pages/Reports/FeedLotReport/FeedLotReport";
import { FC, useState } from "react";
import "./ReportList.scss";

const reportData: any[] = [
  {
    value: "endOfYear",
    title: "End Of Year",
    selectable: false,
    key: 0,
    children: [
      {
        value: "detailedWaterUsageReport",
        title: "EOY - Detailed Water Usage Report",
        report: <DetailedEndOfYearWaterUsageReport />,
      },
      {
        value: "summaryReport",
        title: "EOY - Summary Water Usage Report",
        report: <EndOfYearSummaryReport />,
      },
      {
        value: "meterReadingReport",
        title: "EOY - Meter Reading Report",
        report: <EndOfYearMeterReadingReport />,
      },
      {
        value: "restrictionReport",
        title: "EOY - Restriction Report",
        report: <EndOfYearRestrictionReport />,
      },
    ],
  },
  {
    value: "stock",
    title: "Stock",
    selectable: false,
    key: 1,
    children: [
      {
        value: "detailedStock",
        title: "Detailed Stock",
        report: <FeedLotReport />,
      },
      {
        value: "stockConfiguration",
        title: "Stock Configuration",
        report: <StockConfigurationReport />,
      },
    ],
  },
  {
    value: "generalOperations",
    title: "General Operations",
    selectable: false,
    key: 2,
    children: [
      {
        value: "gpmSummary",
        title: "GPM Summary",
        report: <GPMSummaryReport />,
      },
      {
        value: "lastReading",
        title: "Last Reading",
        report: <LastReadingReport />,
      },
      {
        value: "notesReport",
        title: "Notes Report",
        report: <NotesReport />,
      },
      {
        value: "summaryUsageReport",
        title: "Summary Usage Report",
        report: <SummaryUsageReport />,
      },
      {
        value: "restrictionYearlyBreakdownReport",
        title: "Restriction Yearly Breakdown Report",
        report: <RestrictionYearlyBreakdownReport />,
      },
      {
        value: "placesOfUseReport",
        title: "Places of Use Report",
        report: <PlacesOfUseReport />,
      },
    ],
  },
  {
    value: "configurations",
    title: "Configurations",
    selectable: false,
    key: 3,
    children: [
      {
        value: "allConfigurationReport",
        title: "All Configuration Report",
        report: <AllConfigurationReport />,
      },
      {
        value: "groupingConfigurationReport",
        title: "Grouping Configuration Report",
        report: <GroupingConfigurationReport />,
      },
      {
        value: "fieldConfigurationReport",
        title: "Field Configuration Report",
        report: <FieldConfigurationReport />,
      },
      {
        value: "wellConfigurationReport",
        title: "Well Configuration Report",
        report: <WellConfigurationReport />,
      },
      {
        value: "waterRightConfigurationReport",
        title: "Water Right Configuration Report",
        report: <WaterRightConfigurationReport />,
      },
      {
        value: "restrictionConfigurationReport",
        title: "Restriction Configuration Report",
        report: <RestrictionConfigurationReport />,
      },
    ],
  },
  {
    key: 4,
    value: "graphs",
    title: "Graphs",
    selectable: false,
    children: [
      {
        value: "companyTotalUsageReport",
        title: "Company Total Usage Report",
        report: <CompanyTotalUsageReport />,
      },
      {
        value: "waterRightTotalUsageReport",
        title: "Water Right Total Usage Report",
        report: <WaterRightTotalUsageReport />,
      },
    ],
  },
  {
    key: 5,
    value: "exports",
    title: "Exports",
    selectable: false,
    children: [
      {
        value: "waterRightInformation",
        title: "Water Right Information",
        report: <ExportWaterRightInformationReport />,
      },
      {
        value: "waterRightDateInformation",
        title: "Water Right Date Information",
        report: <ExportWaterRightDateInformationReport />,
      },
    ],
  },
];

const ReportList: FC = () => {
  const [form] = Form.useForm();
  const [expandedKeys, setExpandedKeys] = useState<string[]>(["endOfYear", "stock", "generalOperations", "configurations", "graphs", "exports"]);
  const [reportComponent, setReportComponent] = useState<any>(null);

  const getAllKeys = (reportData: any[]) => {
    let result: string[] = [];
    reportData.forEach((treeNode) => {
      let childNodes: any[] = [];
      if (treeNode.children) {
        childNodes = getAllKeys(treeNode.children);
      }

      result.push(...[treeNode.value, ...childNodes]);
    });

    return result;
  };

  return (
    <>
      <Card id="reportList" title="Reports">
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <Form.Item label="Report" name="report" style={{ margin: 0 }}>
            <TreeSelect
              treeDefaultExpandAll={true}
              onSelect={(value: any, node: any) => setReportComponent(node)}
              treeExpandedKeys={expandedKeys}
              treeExpandAction="click"
              showSearch
              style={{ width: "100%" }}
              dropdownStyle={{ maxHeight: 600, overflow: "auto" }}
              listHeight={500}
              placeholder="Please select a report"
              allowClear
              treeData={reportData}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button onClick={() => setExpandedKeys(getAllKeys(reportData))} type="primary">
                      Expand All
                    </Button>
                    <Button onClick={() => setExpandedKeys([])}>Collapse All</Button>
                  </Space>
                </>
              )}
              onTreeExpand={(keys: any[]) => setExpandedKeys(keys)}
            />
          </Form.Item>
        </Form>
      </Card>
      {reportComponent?.report}
    </>
  );
};

export default ReportList;
