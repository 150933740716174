import { Tag } from "antd";
import { constants } from "@/configs";
import { FC } from "react";
import { UserInviteStatus } from "@/dtos/user.dto";
import dayjs from "dayjs";
export interface StatusTagProps {
  status: any;
  date?: string;
  color?: string;
  style?: any;
}

const StatusTag: FC<StatusTagProps> = (props) => {
  const { status, date, color, style } = props;

  const renderDate = () => (date ? <small>{dayjs(date).format(constants.dateTimeFormat)}</small> : <></>);
  switch (status) {
    case "Invited":
    case UserInviteStatus.Invited:
      return (
        <Tag id="statusTag" color={color ?? "blue"}>
          Invited {renderDate()}
        </Tag>
      );

    case "Accepted":
    case UserInviteStatus.Accepted:
      return (
        <Tag id="statusTag" color={color ?? "green"}>
          Accepted {renderDate()}
        </Tag>
      );

    case "active":
    case true:
      return (
        <Tag style={style} id="statusTag" color={color ?? "green"}>
          Active {renderDate()}
        </Tag>
      );

    case "inactive":
    case false:
      return (
        <Tag style={style} id="statusTag" color={color ?? "default"}>
          Inactive {renderDate()}
        </Tag>
      );

    case "Yes":
    case "yes":
      return (
        <Tag id="statusTag" color={color ?? "green"}>
          Yes {renderDate()}
        </Tag>
      );

    case "No":
    case "no":
      return (
        <Tag id="statusTag" color={color ?? "blue"}>
          No {renderDate()}
        </Tag>
      );

    case "archived":
    case "Archived":
      return (
        <Tag id="statusTag" color={color ?? "red"}>
          Yes {renderDate()}
        </Tag>
      );

    case "notarchived":
    case "NotArchived":
      return (
        <Tag id="statusTag" color={color ?? "green"}>
          No {renderDate()}
        </Tag>
      );

    case "Created":
    case "Created":
      return (
        <Tag id="statusTag" color={color ?? "blue"}>
          Created {renderDate()}
        </Tag>
      );

    case "Queued":
    case "Queued":
      return (
        <Tag id="statusTag" color={color ?? "orange"}>
          Queued {renderDate()}
        </Tag>
      );

    case "Processed":
    case "Processed":
      return (
        <Tag id="statusTag" color={color ?? "green"}>
          Processed {renderDate()}
        </Tag>
      );

    case "incomplete":
    case "Incomplete":
      return (
        <Tag id="statusTag" color={color ?? "red"}>
          Incomplete {renderDate()}
        </Tag>
      );

    case "failed":
    case "Failed":
      return (
        <Tag id="statusTag" color={color ?? "red"}>
          Failed {renderDate()}
        </Tag>
      );

    case "yes":
    case "YES":
      return (
        <Tag id="statusTag" color={color ?? "green"}>
          YES {renderDate()}
        </Tag>
      );

    default:
      return <Tag>NOT IMPLEMENTED</Tag>;
  }
};

export default StatusTag;
