import { getStock } from "@/apis/stock.api";
import { AuditLogTable, OccupancyTable, WaterRightTable } from "@/components";
import { AuditLogType } from "@/components/AuditLogTable/AuditLogTable";
import PrintPage from "@/components/PrintPage/PrintPage";
import { routes } from "@/configs";
import useSelectedCompanyData from "@/queries/useSelectedCompanyData";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb, removeBreadcrumb, replaceUrlStateBreadcrumbType } from "@/stores/breadcrumbs.store";
import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Card, Descriptions, Tabs } from "antd";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "./StockView.scss";

interface Props {
  showHeader?: boolean;
  overrideId?: string;
}

const StockView: FC<Props> = (props) => {
  const { showHeader = true, overrideId = undefined } = props;

  const dispatch = useAppDispatch();

  const { navigate } = useCustomNavigate();
  const { id } = useParams<{ id: string }>();

  const pathUrl = window.location.pathname;

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingOccupancyData, setLoadingOccupancyData] = useState<boolean>(true);

  const [stockData, setStockData] = useState<any>();
  const [waterRights, setWaterRights] = useState<any[] | null>(null);
  const [occupancyData, setOccupancyData] = useState<any[]>([]);

  useEffect(() => {
    refreshStockData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pathUrl.includes("view") && stockData) {
      dispatch(replaceUrlStateBreadcrumbType(stockData?.name));
    }
  }, [pathUrl, stockData]);

  const refreshStockData = async () => {
    setLoading(true);

    const response = await getStock(overrideId ?? id);
    if (response.ok) {
      const data = await response.json();
      setWaterRights(data.value.waterRightIds);
      setStockData(data.value);
    }

    setLoading(false);
  };

  const navigateBack = () => {
    dispatch(removeBreadcrumb());
    navigate(-1);
  };

  const navigateEdit = () => {
    dispatch(
      addBreadcrumb({
        type: `Edit`,
        url: `grouping/${id}/edit`,
      })
    );
    navigate(routes.stockEdit, { id: overrideId ?? id });
  };

  const { canEdit } = useSelectedCompanyData(stockData?.companyId);

  const renderContent = () => {
    return (
      <div>
        <Descriptions style={{ paddingLeft: 0, width: 600 }} bordered size="small" labelStyle={{ width: 200 }} column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
          <Descriptions.Item label="Name">{stockData?.name}</Descriptions.Item>
        </Descriptions>
        <br />
        <Tabs
          defaultActiveKey="1"
          type="card"
          size="small"
          style={{ marginBottom: 32 }}
          items={[
            {
              label: "Head Counts",
              key: "occupancy",
              children: <OccupancyTable canEdit={canEdit} stockId={overrideId ?? id} />,
            },
            {
              label: "Water Rights",
              key: "waterRight",
              children: <WaterRightTable showAddWaterRight={false} showHeader waterRightIds={waterRights ?? []} />,
            },
            {
              label: "Audit Log",
              key: "auditLog",
              children: <AuditLogTable entityId={stockData?.id} entityType={AuditLogType.Grouping} />,
            },
          ]}
        />
      </div>
    );
  };

  return (
    <Card
      className="stockView"
      loading={loading}
      id="stockView"
      title={showHeader ? `Stock - ${loading ? "loading..." : stockData?.name}` : undefined}
      extra={
        showHeader ? (
          <>
            <Button icon={<LeftOutlined />} style={{ marginRight: 5 }} onClick={navigateBack}>
              Back
            </Button>
            {canEdit && (
              <Button icon={<EditOutlined />} type="primary" onClick={navigateEdit}>
                Edit
              </Button>
            )}
          </>
        ) : undefined
      }
    >
      {showHeader ? <PrintPage content={renderContent()} /> : renderContent()}
    </Card>
  );
};

export default StockView;
