import { routes } from "@/configs";

export const checkBreadCrumb = (path: any) => {
  switch (path) {
    case routes.companyList.path:
      return true;
    case routes.fieldList.path:
      return true;
    case routes.waterRightList.path:
      return true;
    case routes.wellList.path:
      return true;
    case routes.restrictionList.path:
      return true;
    case routes.notification.path:
      return true;
    case routes.userList.path:
      return true;
    case routes.groupingList.path:
      return true;
    case routes.stockList.path:
      return true;
    case routes.placeOfUseList.path:
      return true;
    case routes.reportList.path:
      return true;
    case routes.adminCommunication.path:
      return true;
    case routes.adminUsers.path:
      return true;
    case routes.adminReports.path:
      return true;
    case routes.adminFaqsList.path:
      return true;
    case routes.adminSupportQueryList.path:
      return true;
    case routes.adminContactUsList.path:
      return true;
    case routes.lookups.path:
      return true;
    case routes.adminBilling.path:
      return true;
    case routes.adminEvents.path:
      return true;
    default:
      return false;
  }
};

export const breadcrumbLabel = (name: string) => {
  switch (name) {
    case "company":
      return "Companies";
    case "field":
      return "Fields";
    case "waterright":
      return "Water Rights";
    case "well":
      return "Wells";
    case "restriction":
      return "Restrictions";
    case "notification":
      return "Notifications";
    case "user":
      return "Users";
    case "grouping":
      return "Groupings";
    case "stock":
      return "Stock";
    case "report":
      return "Reports";
    case "communication":
      return "Communications";
    case "users":
      return "Users";
    case "reports":
      return "Reports";
    case "faqs":
      return "FAQS";
    case "supportquery":
      return "Support Queries";
    case "contactus":
      return "Contact Us Messages";
    case "lookups":
      return "Lookups";
    case "billing":
      return "Billing";
    case "events":
      return "Events";
    case "placesofuse":
      return "Places of Use";
    default:
      return "";
  }
};

export const breadCrumbViewEditBreadcrumbs = (pathArray: any[]) => {
  switch (pathArray[0]) {
    case "company":
      if (pathArray.length === 2) {
        return "Add";
      } else {
        if (pathArray[2] === "view") return pathArray[1];
        else return "Edit";
      }
    case "field":
      if (pathArray.length === 2) {
        return "Add";
      } else {
        if (pathArray[2] === "view") return pathArray[1];
        else return "Edit";
      }
    case "placesofuse":
      return "Places of Use Add";
    case "waterright":
      if (pathArray.length === 2) {
        return "Add";
      } else {
        if (pathArray[2] === "view") return pathArray[1];
        else return "Edit";
      }
    case "well":
      if (pathArray.length === 2) {
        return "Add";
      } else {
        if (pathArray[2] === "view") return pathArray[1];
        else return "Edit";
      }
    case "restriction":
      if (pathArray.length === 2) {
        return "Add";
      } else {
        if (pathArray[2] === "view") return pathArray[1];
        else return "Edit";
      }
    case "notification":
      if (pathArray.length === 2) {
        return "Add";
      } else {
        if (pathArray[2] === "view") return pathArray[1];
        else return "Edit";
      }
    case "user":
      if (pathArray.length === 2) {
        return "Add";
      } else {
        if (pathArray[2] === "view") return pathArray[1];
        else return "Edit";
      }
    case "grouping":
      if (pathArray.length === 2) {
        return "Add";
      } else {
        if (pathArray[2] === "view") return pathArray[1];
        else return "Edit";
      }
    case "stock":
      if (pathArray.length === 2) {
        return "Add";
      } else {
        if (pathArray[2] === "view") return pathArray[1];
        else return "Edit";
      }
    case "report":
      if (pathArray.length === 2) {
        return "Add";
      } else {
        if (pathArray[2] === "view") return pathArray[1];
        else return "Edit";
      }
    case "communication":
      if (pathArray.length === 2) {
        return "Add";
      } else {
        if (pathArray[2] === "view") return pathArray[1];
        else return "Edit";
      }
    case "faqs":
      if (pathArray.length === 2) {
        return "Add";
      } else {
        if (pathArray[2] === "view") return pathArray[1];
        else return "Edit";
      }
    case "supportquery":
      if (pathArray.length === 2) {
        return "Add";
      } else {
        if (pathArray[2] === "view") return pathArray[1];
        else return "Edit";
      }
    case "contactus":
      // TODO: Check
      if (pathArray.length === 2) {
        return "Add";
      } else {
        if (pathArray[2] === "view") return pathArray[1];
        else return "Edit";
      }
    default:
      return "";
  }
};
