import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getSelectedCompany } from "@/apis/company.api";
import { useCallback } from "react";
import CacheKeys from "@/types/CacheKeys";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import { useSelector } from "react-redux";
import { UserRole } from "@/dtos/user.dto";

export default function useSelectedCompanyData(companyId?: string) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();
  const {
    data: companyData,
    isFetching,
    isRefetching,
    isFetched,
  } = useQuery({
    queryKey: [
      CacheKeys.companies,
      CacheKeys.identity,
      {
        profileId: profile?.id,
        companyId,
      },
    ],
    queryFn: async () => {
      const response = await getSelectedCompany(companyId as string);

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch company identity");
    },
    gcTime: Infinity,
    staleTime: 2 * 60 * 1000, //2 mins
    ...defaultQueryConfig,
    enabled: !!profile?.id && !!companyId,
  });

  const invalidateCompanyData = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.companies, CacheKeys.lookup],
    });
  }, [queryClient]);

  const isLoading = isFetching || isRefetching;

  const canEdit = companyData?.userRole === UserRole.admin.value;

  return { companyData, canEdit, isFetched, isLoading, invalidateCompanyData };
}
