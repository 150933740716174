import { getRestriction, getRestrictionAuditLogs } from "@/apis/restriction.api";
import { AuditLogTable, LookupLabel, RemainingYearlyAverageTable, RestrictionRadialGauge, StatusTag, WaterRightTable } from "@/components";
import { AuditLogType } from "@/components/AuditLogTable/AuditLogTable";
import PrintPage from "@/components/PrintPage/PrintPage";
import RestrictionCalculations from "@/components/RestrictionCalculations/RestrictionCalculations";
import { constants, routes } from "@/configs";
import useRestrictionCalculation from "@/queries/useRestrictionCalculation";
import useSelectedCompanyData from "@/queries/useSelectedCompanyData";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb, removeBreadcrumb, replaceUrlStateBreadcrumbType } from "@/stores/breadcrumbs.store";
import { clearRestrictionState, saveRestriction } from "@/stores/restriction.store";
import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Card, Descriptions, Tabs } from "antd";
import dayjs from "dayjs";
import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import "./RestrictionView.scss";

interface RestrictionViewProps {
  refreshRestrictionListCalculations?: () => void;
  showHeader?: boolean;
  overrideId?: string;
}

const RestrictionView: FC<RestrictionViewProps> = (props) => {
  const { refreshRestrictionListCalculations, showHeader = true, overrideId = undefined } = props;

  const dispatch = useAppDispatch();

  const { restrictionData } = useSelector((state: any) => state.restriction);

  const { id } = useParams<{ id: string }>();

  const pathUrl = window.location.pathname;

  const { navigate } = useCustomNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [waterRights, setWaterRights] = useState<any[] | null>(null);
  const [auditLogs, setAuditLogs] = useState<any[]>([]);
  const [activeKey, setActiveKey] = useState("waterRights");

  const { restrictionCalculation, invalidateRestrictionCalculation } = useRestrictionCalculation({ id: overrideId ?? id });

  useEffect(() => {
    return () => {
      dispatch(clearRestrictionState());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pathUrl.includes("view") && restrictionData) {
      dispatch(replaceUrlStateBreadcrumbType(restrictionData?.name));
    }
    // eslint-disable-next-line
  }, [pathUrl, restrictionData]);

  useEffect(() => {
    if (id || overrideId) {
      getRestrictionData();
      getAuditLogs();
    }

    // eslint-disable-next-line
  }, [overrideId, id]);

  const getRestrictionData = async () => {
    setLoading(true);
    const response = await getRestriction(overrideId ?? id);

    if (response.ok) {
      const data = await response.json();

      // Set Restriction
      dispatch(saveRestriction(data.value));
      // Set Water Right list
      setWaterRights(data.value.waterRightIds);
    }
    setLoading(false);
  };

  const navigateEdit = () => {
    dispatch(
      addBreadcrumb({
        type: `Edit`,
        url: `restriction/${id}/edit`,
      })
    );
    navigate(routes.restrictionEdit, { id: overrideId ?? id });
  };

  const navigateBack = () => {
    dispatch(removeBreadcrumb());
    navigate(-1);
  };

  const getAuditLogs = async () => {
    setLoading(true);
    const response = await getRestrictionAuditLogs(overrideId ?? (id as any));

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setAuditLogs(data.value);
      }
    }
    setLoading(false);
  };

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  const renderTabs = (restrictionData: any) => {
    let tabs: any[] = [
      {
        label: "Water Rights",
        key: "waterRights",
        children: <WaterRightTable refreshRestrictionListCalculations={refreshRestrictionListCalculations} showAddWaterRight={false} showHeader waterRightIds={waterRights ?? []} />,
      },
      {
        label: "Calculations",
        key: "calculations",
        children: (
          <RestrictionCalculations startDate={restrictionData?.startDate} endDate={restrictionData?.endDate} termQuantity={restrictionData?.termQuantity} id={restrictionData?.id} showIcon={false} />
        ),
      },
    ];

    if (canEdit)
      tabs.push({
        label: "Audit Log",
        key: "auditLog",
        children: <AuditLogTable entityId={restrictionData?.id} entityType={AuditLogType.Restriction} />,
      });

    return tabs;
  };

  const values = useMemo(() => {
    if (restrictionCalculation) {
      return {
        startDate: restrictionCalculation.startDate,
        endDate: restrictionCalculation.endDate,
        termQuantity: restrictionCalculation.termQuantity / 325851,
        irrigationAcres: restrictionCalculation.authorizedIrrigationAcres,
        years: restrictionCalculation.years,
        baseline: restrictionCalculation.baselineQuantity,
        remainingYears: restrictionCalculation.remainingYears,
        remaining: restrictionCalculation.remainingUsage,
        yearlyRemaining: restrictionCalculation.remainingYearlyAverage,
        currentUsage: restrictionCalculation.currentYearUsage,
        currentYearRemaining:
          restrictionCalculation.remainingUsage > restrictionCalculation.baselineQuantity - restrictionCalculation.currentYearUsage
            ? restrictionCalculation.baselineQuantity - restrictionCalculation.currentYearUsage
            : restrictionCalculation.remainingUsage,
        totalUsed: restrictionCalculation.used,
        futureRemainingYears: restrictionCalculation.futureRemainingYears,
        futureRemainingYearlyAverage: restrictionCalculation.futureRemainingYearlyAverage,
        previousYearQuantities: restrictionCalculation.previousYearQuantities,
        yearlyUsage: restrictionCalculation.yearlyUsage,
      };
    } else return undefined;
  }, [restrictionCalculation]);

  const renderContent = () => {
    return (
      <div>
        <Descriptions bordered size="small" column={3}>
          <Descriptions.Item label="Name">{restrictionData?.name}</Descriptions.Item>
          <Descriptions.Item label="Start Date">{restrictionData?.startDate ? dayjs(restrictionData?.startDate).format(constants.dateFormat) : "N/A"}</Descriptions.Item>
          <Descriptions.Item label="Term Quantity">{restrictionData?.termQuantity}</Descriptions.Item>

          <Descriptions.Item label="Type">
            <LookupLabel lookupType="restrictiontype" value={restrictionData?.type} />
          </Descriptions.Item>
          <Descriptions.Item label="End Date">{restrictionData?.endDate ? dayjs(restrictionData?.endDate).format(constants.dateFormat) : "N/A"}</Descriptions.Item>
          <Descriptions.Item label="Auto Renew">{<StatusTag status={restrictionData?.renew ? (restrictionData?.renew === true ? "Yes" : "No") : "No"} />}</Descriptions.Item>
        </Descriptions>
        <br />
        {values && (
          <div style={{ flex: 1, flexDirection: "row", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", marginBottom: 20 }}>
            <RestrictionRadialGauge
              myfa={restrictionData?.myfa}
              type={restrictionData?.type}
              remainingYears={values.remainingYears}
              companyId={restrictionData?.companyId}
              usedQuantity={values.totalUsed}
              termQuantity={restrictionCalculation?.termQuantity}
              style={{ paddingBottom: 0, marginLeft: 30, marginRight: 20, marginTop: 20 }}
            />
            <Card
              styles={{
                body: {
                  padding: 0,
                },
              }}
              style={{ flex: 1 }}
            >
              <RemainingYearlyAverageTable calculation={values} />
            </Card>
            <br />
          </div>
        )}
        <Tabs defaultActiveKey="1" type="card" size="small" style={{ marginBottom: 32 }} onChange={onChange} items={renderTabs(restrictionData)} />
      </div>
    );
  };

  const { canEdit } = useSelectedCompanyData(restrictionData?.companyId);

  return (
    <Card
      className="restrictionView"
      loading={loading}
      id="restrictionView"
      title={showHeader ? `Restriction - ${loading ? "loading..." : restrictionData?.name}` : undefined}
      extra={
        showHeader ? (
          <>
            <Button icon={<LeftOutlined />} style={{ marginRight: 5 }} onClick={navigateBack}>
              Back
            </Button>
            {canEdit && (
              <Button icon={<EditOutlined />} type="primary" onClick={navigateEdit}>
                Edit
              </Button>
            )}
          </>
        ) : undefined
      }
    >
      {showHeader ? <PrintPage content={renderContent()} /> : renderContent()}
    </Card>
  );
};

export default RestrictionView;
