import { EnvironmentFilled, MailFilled, PhoneFilled } from "@ant-design/icons";
import { Badge, Button, Card, Col, Image, List, Row, Typography } from "antd";
import { routes } from "@/configs";
import { FC } from "react";
import useCustomNavigate from "@/services/useCustomNavigate";
import "./PageFooter.scss";
import { showNavigationWarning } from "@/services/utils";
import { useSelector } from "react-redux";

const PageFooter: FC = () => {
  const { busyEditing } = useSelector((state: any) => state.company);
  const { navigate } = useCustomNavigate();

  return (
    <div
      style={{
        position: "relative",
        paddingTop: 70,
        paddingBottom: 30,
        backgroundImage: `url(images/VandWater-Footer.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundBlendMode: "overlay",
        backgroundColor: "rgba(0,0,0,0.46)",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        style={{
          width: "80%",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          borderWidth: 0,
        }}
        className="removeBoxShadow"
        bodyStyle={{ color: "white" }}
      >
        <Row gutter={[24, 24]}>
          <Col
            xs={24}
            sm={24}
            md={10}
            lg={10}
            xl={10}
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "flex-start",
              justifyContent: "center",
            }}
          >
            <div style={{ maxWidth: 300 }}>
              <Image
                style={{
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  alignContent: "center",
                  maxHeight: 510,
                  maxWidth: 510,
                  // borderRadius: 10,
                  objectFit: "contain",
                }}
                preview={false}
                src="images/logo_white.png"
              />
              <div
                style={{
                  paddingTop: 10,
                  lineHeight: "1.7em",
                  fontFamily: "Montserrat, Helvetica, Arial, Lucida, sans-serif",
                  wordWrap: "break-word",
                  fontWeight: 500,
                  fontStyle: "italic",
                  textAlign: "center",
                  color: "white",
                }}
              >
                Providing water management technology to preserve our planet’s most valuable resource™
              </div>
            </div>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "flex-start",
              justifyContent: "center",
            }}
          >
            <div>
              <Typography.Title level={4} style={{ color: "white", marginTop: 0 }}>
                More to see
              </Typography.Title>
              <List size="small">
                {[
                  { key: "772ecfc5", label: "Vision and Mission", route: routes.missionVision },
                  { key: "4b204217", label: "Ethics & Sustainability", route: routes.ethicsSustainability },
                  { key: "19db81d7", label: "Photo Gallery", route: routes.photoGallery },
                  { key: "e717ad80", label: "Privacy Policy", route: routes.privacy },
                  { key: "735c200f", label: "Terms of Use", route: routes.termsOfUse },
                  { key: "c1cab651", label: "Disclaimer", download: "pdfs/VandWater-Disclaimer-Statement.pdf" },
                ].map((item) => (
                  <List.Item style={{ padding: 0, margin: 0, height: 24 }} key={item.key}>
                    <Button
                      type="link"
                      style={{ padding: 0, margin: 0 }}
                      icon={<Badge dot color="white" style={{ marginRight: 10 }} />}
                      onClick={() => {
                        if (item.route) showNavigationWarning(busyEditing, () => navigate(item.route));
                        else if (item.download) {
                          const link = document.createElement("a");
                          link.download = item.download.split("/").pop() ?? "File";
                          link.href = item.download;
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }
                      }}
                    >
                      <span style={{ color: "white" }}>{item.label}</span>
                    </Button>
                  </List.Item>
                ))}
              </List>
            </div>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "flex-start",
              justifyContent: "center",
            }}
          >
            <div>
              <Typography.Title level={4} style={{ color: "white", marginTop: 0 }}>
                Contact Us
              </Typography.Title>
              <List size="small">
                {[
                  {
                    title: "Phone",
                    icon: <PhoneFilled />,
                    content: <>785.527.9041</>,
                  },
                  {
                    title: "Email",
                    icon: <MailFilled />,
                    content: <>info@vandwater.com</>,
                  },
                  {
                    title: "Location",
                    icon: <EnvironmentFilled />,
                    content: (
                      <>
                        1817 E Frontage Rd
                        <br />
                        Belleville KS 66935
                      </>
                    ),
                  },
                ].map((item) => (
                  <List.Item style={{ padding: 0, margin: 0, color: "white" }}>
                    {item.icon}{" "}
                    <span
                      style={{
                        paddingLeft: 8,
                        fontSize: 18,
                        fontWeight: "bold",
                      }}
                    >
                      {item.title}
                    </span>
                    <br />
                    <div style={{ paddingLeft: 25 }}>{item.content}</div>
                  </List.Item>
                ))}
              </List>
            </div>
          </Col>
        </Row>
      </Card>
      <div
        style={{
          background: `url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI1MnB4IiB2aWV3Qm94PSIwIDAgMTI4MCAxNDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTAgNTEuNzZjMzYuMjEtMi4yNSA3Ny41Ny0zLjU4IDEyNi40Mi0zLjU4IDMyMCAwIDMyMCA1NyA2NDAgNTcgMjcxLjE1IDAgMzEyLjU4LTQwLjkxIDUxMy41OC01My40VjBIMHoiIGZpbGwtb3BhY2l0eT0iLjMiLz48cGF0aCBkPSJNMCAyNC4zMWM0My40Ni01LjY5IDk0LjU2LTkuMjUgMTU4LjQyLTkuMjUgMzIwIDAgMzIwIDg5LjI0IDY0MCA4OS4yNCAyNTYuMTMgMCAzMDcuMjgtNTcuMTYgNDgxLjU4LTgwVjBIMHoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNMCAwdjMuNEMyOC4yIDEuNiA1OS40LjU5IDk0LjQyLjU5YzMyMCAwIDMyMCA4NC4zIDY0MCA4NC4zIDI4NSAwIDMxNi4xNy02Ni44NSA1NDUuNTgtODEuNDlWMHoiLz48L2c+PC9zdmc+')`,
          position: "absolute",
          top: 0,
          left: 0,
          height: 44,
          width: "100%",
          marginTop: -2,
        }}
      />
    </div>
  );
};

export default PageFooter;
