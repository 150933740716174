import { ChartConfig, ChartContainer, ChartStyle, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import useCompanyMonthlyUsageQuery from "@/queries/useCompanyMonthlyUsageQuery";
import { convertNumToMonth, convertUnits } from "@/services/utils";
import { Card } from "antd";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";

type TotalCompanyWaterUsageByMonthProps = {
  waterRightsAnalyticsOverview?: any[];
  permitType?: string;
};

const id = "bar-company-monthly-usage";

const TotalCompanyWaterUsageByMonth: FC<TotalCompanyWaterUsageByMonthProps> = ({ permitType }) => {
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const { companyMonthlyUsages } = useCompanyMonthlyUsageQuery(selectedCompanyId, permitType);

  const chartConfig = {
    usage: {
      label: "Used",
      color: "hsl(var(--chart-1))",
    },
    label: {
      color: "hsl(var(--background))",
    },
  } satisfies ChartConfig;

  const chartData: any[] = useMemo(() => {
    const data: any[] = companyMonthlyUsages?.map((usage: any, index: number) => {
      return {
        month: convertNumToMonth(usage?.month),
        usage: convertUnits(usage?.monthUsage, "gallons", "acrefeet"),
      };
    });

    return data;
  }, [companyMonthlyUsages]);

  return (
    <Card bordered={false} className="removeBoxShadow" styles={{ body: { paddingLeft: 20, paddingRight: 20, paddingBottom: 20 } }}>
      <div style={{ textAlign: "left", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", paddingBottom: 25 }}>
        <div style={{ flex: 1, fontWeight: "semibold", fontSize: 16 }}>Total Water Used By Month</div>
      </div>
      <ChartStyle id={id} config={chartConfig} />
      <ChartContainer id={id} config={chartConfig} className="mx-auto aspect-square w-full">
        <BarChart accessibilityLayer data={chartData} layout="vertical">
          <CartesianGrid vertical={true} />
          <XAxis type="number" dataKey="usage" tickLine={false} axisLine={false} tickMargin={5} />
          <YAxis type="category" dataKey="month" tickLine={false} tickMargin={5} axisLine={false} />
          <ChartTooltip cursor={false} content={<ChartTooltipContent postFix=" AF" />} />
          <Bar dataKey="usage" fill="var(--color-usage)" radius={2} />
        </BarChart>
      </ChartContainer>
    </Card>
  );
};

export default TotalCompanyWaterUsageByMonth;
