import { Button, Card, Form } from "antd";
import { generateDatesReport } from "@/apis/waterright.api";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { openReportFromGeneratedFile } from "@/services/utils";
import { useWatch } from "antd/es/form/Form";
import { CompanyReportSelector } from "@/components";

const ExportWaterRightDateInformationReport: FC = () => {
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [form] = Form.useForm();

  const [generatingDatesReport, setGeneratingDatesReport] = useState<boolean>(false);
  const [companies, setCompanies] = useState<any[]>([]);

  const companyIds: any[] = useWatch("companyIds", form);

  const downloadDatesReport = async () => {
    setGeneratingDatesReport(true);

    const request = {
      companyId: selectedCompanyId ?? companyIds,
      companyName: companies?.find((company: any) => company?.id === companyIds)?.name,
    };

    const response = await generateDatesReport(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        openReportFromGeneratedFile(data.value);
      }
    }

    setGeneratingDatesReport(false);
  };

  return (
    <Card title="Water Right Date Information Export" bodyStyle={{ padding: 12, margin: 0 }}>
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
        {!selectedCompanyId && (
          <CompanyReportSelector
            form={form}
            propertyToSet={"companyIds"}
            label={"Companies"}
            setCompanies={setCompanies}
            placeholder="Select Company"
            style={{ marginTop: 0, marginBottom: 0 }}
            singleSelect
          />
        )}
      </Form>
      <div
        style={{
          margin: "auto",
          width: "50%",
          textAlign: "center",
          marginTop: !selectedCompanyId ? 10 : undefined,
        }}
      >
        {(selectedCompanyId || companyIds?.length > 0) && (
          <Button loading={generatingDatesReport} disabled={generatingDatesReport} type="primary" onClick={(e) => downloadDatesReport()}>
            Download
          </Button>
        )}
      </div>
    </Card>
  );
};

export default ExportWaterRightDateInformationReport;
