import { Button, Card, Table } from "antd";
import { listSupportQuery } from "@/apis/communication.api";
import { constants, routes } from "@/configs";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";

const AdminSupportQueryList: FC = () => {
  const { navigate, updateQueryParams, queryParams } = useCustomNavigate();

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [supportQueries, setSupportQueries] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    refreshSupportQueryList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [supportQueries]);

  const handleViewSupportQuery = (record: any) => {
    // Add view breadcrumb
    dispatch(
      addBreadcrumb({
        type: `${record.supportQuery}`,
        url: `supportquery/${record.id}/view`,
      })
    );

    navigate(routes.adminSupportQueryView, { id: record.id });
  };

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Reference",
        key: "supportQuery",
        dataIndex: "supportQuery",
        width: 50,
        render: (val: any, record: any) => (
          <Button style={{ paddingLeft: 0 }} onClick={() => handleViewSupportQuery(record)} type="link">
            {val}
          </Button>
        ),
      },
      {
        title: "Date",
        key: "date",
        dataIndex: "date",
        width: 125,
        render: (val: any, record: any) => dayjs(val).format(constants.dateTimeFormat),
      },
      {
        title: "Contact Details",
        children: [
          {
            title: "Email",
            dataIndex: "userEmail",
            width: 100,
            render: (val: any, record: any) => val ?? "-",
          },
          {
            title: "Phone Number",
            dataIndex: "phoneNumber",
            width: 100,
            render: (val: any, record: any) => val ?? "-",
          },
        ],
      },
      {
        title: "Name",
        key: "name",
        width: 100,
        render: (val: any, record: any) => <>{record?.firstName && record?.lastName ? `${record?.firstName} ${record?.lastName}` : "N/A"}</>,
      },
      {
        title: "Body",
        key: "body",
        dataIndex: "body",
        width: 200,
        render: (val: any, record: any) => (val?.length > 50 ? `${val?.substring(0, 50)} ...` : val),
      },
      // {
      //     title: 'Status',
      //     key: 'status',
      //     dataIndex: 'status',
      //     width: 200,
      //     render: (val: any, record: any) => val?.length > 50 ? `${val?.substring(0, 50)} ...` : val
      // },
      {
        title: "Actions",
        width: 75,
        render: (val: any, record: any) => (
          <>
            <Button style={{ paddingLeft: 0 }} onClick={() => handleViewSupportQuery(record)} type="link">
              View
            </Button>
          </>
        ),
      },
    ];

    setColumns(tempColumns);
  };

  const refreshSupportQueryList = async () => {
    setLoading(true);

    const response = await listSupportQuery();

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setSupportQueries(data.value);
      }
    }

    setLoading(false);
  };

  return (
    <Card className="adminSupportQueryList" id="adminSupportQueryList" title="Support Queries">
      <Table rowKey={(row: any) => row.id} loading={loading} columns={columns} dataSource={supportQueries ?? []} size="small" />
    </Card>
  );
};

export default AdminSupportQueryList;
