import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import useStockLookupQuery from "@/queries/useStockLookupQuery";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { Button, Divider, Form, FormInstance, Select, Space, Tag } from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";

type StockSelectorProps = {
  placeholder?: string;
  form: FormInstance<any> | undefined;
  style?: React.CSSProperties;
  propertyToSet: string;
  label: string;
  setStocks: any;
};

const StockSelector: FC<StockSelectorProps> = ({ placeholder, form, style, propertyToSet, label, setStocks }) => {
  const [stockDownRef, stockDownEntry] = useIntersectionObserver();

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const { stockLookup, invalidateStockLookup, fetchNextPage, hasNextPage, isFetched, isFetching, isFetchingNextPage, isLoading, totalItemCount } = useStockLookupQuery({
    searchString: undefined,
  });

  const { companyConfigs } = useComapnyConfigLookup();

  useEffect(() => {
    if (stockDownEntry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line
  }, [stockDownEntry?.isIntersecting, hasNextPage]);

  useEffect(() => {
    if (stockLookup.length > 0) {
      setStocks(stockLookup);
    }
    // eslint-disable-next-line
  }, [stockLookup]);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  const handleReset = () => {
    form!.setFieldValue(propertyToSet, []);
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {stockLookup?.find((stock) => stock?.id === value)?.name}
      </Tag>
    );
  };

  return (
    <Form.Item label={label} name={propertyToSet} style={style}>
      <Select
        allowClear
        mode={"multiple"}
        loading={isLoading || isFetching}
        tagRender={tagRender}
        placeholder={placeholder}
        showSearch
        filterOption={(input, option) => {
          const searchVal = input?.toLowerCase();
          let list: any[] = [];
          stockLookup?.forEach((stock) => {
            let match: boolean = false;

            if (stock?.name?.toLowerCase()?.includes(searchVal)) match = true;

            if (match) list.push(stock.id);
          });
          return list.includes(option?.value) ?? [];
        }}
        optionFilterProp="value"
        maxTagCount={3}
        disabled={isLoading}
        options={stockLookup?.map((stock: any) => {
          return {
            value: stock.id,
            label: stock.name,
          };
        })}
        optionRender={(option, { index }) => {
          if (option.value === "loading") return null;
          const stock = stockLookup?.find((stock) => stock.id === option?.value);
          if (!stock) return null;

          return (
            <div key={stock.id}>
              {index === (stockLookup?.length ?? 0) - 1 ? <span ref={stockDownRef} /> : null}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  // paddingBottom: 5,
                }}
              >
                {stock?.name}
              </div>
              {!selectedCompanyId && (
                <div style={{ fontWeight: "normal", fontSize: 11, paddingTop: 0, paddingBottom: 5 }}>({companyConfigs?.find((company: any) => company?.id === stock?.companyId)?.name})</div>
              )}
            </div>
          );
        }}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <Space style={{ padding: "0 8px 4px" }}>
              {/* <Button
                onClick={() => {
                  form!.setFieldValue(
                    propertyToSet,
                    stockLookup?.filter((item: any) => item !== undefined).map((stock) => stock.id)
                  );
                }}
              >
                Select All
              </Button> */}
              <Button
                danger
                onClick={() => {
                  handleReset();
                }}
              >
                Clear List
              </Button>
            </Space>
          </>
        )}
      ></Select>
    </Form.Item>
  );
};

export default StockSelector;
