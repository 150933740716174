import { useEffect, useState } from "react";
import { generatePath, useLocation, useSearchParams } from "react-router-dom";
import { singletonHook } from "react-singleton-hook";
import router from "./router";

function handleNavigate(route: any, routeParams?: any, queryParams?: any, data?: any) {
  if (route.path) {
    let finalPath: string = routeParams ? generatePath(route.path, routeParams) : route.path;

    if (queryParams) {
      finalPath += "?" + new URLSearchParams(queryParams).toString();
    }

    router.navigate(finalPath, { state: { _component: route, data } });
  } else router.navigate(route);
}

const updateQueryParams = (queryParams: any) => {
  const currentPath = window.location.pathname;

  if (currentPath) {
    let finalPath: string = currentPath;
    let finalQueryParams: any = {};

    let existingQueryParams = window.location?.search;
    if (existingQueryParams) {
      const params = new URLSearchParams(existingQueryParams);
      params.forEach((value, key) => {
        finalQueryParams[key] = value;
      });
    }

    if (finalQueryParams) finalQueryParams = { ...finalQueryParams, ...queryParams };

    for (let param in finalQueryParams) {
      if (finalQueryParams[param] === undefined || finalQueryParams[param] === null || finalQueryParams[param] === "") {
        delete finalQueryParams[param];
      }
    }

    if (finalQueryParams) {
      finalPath += "?" + new URLSearchParams(finalQueryParams).toString();
    }

    router.navigate(finalPath, { replace: true });
  }

  return null;
};

type CustomNavigateHook = {
  navigate: (route: any, routeParams?: any, queryParams?: any, data?: any) => void;
  updateQueryParams: (queryParams: any) => void;
  queryParams?: any;
  searchParams?: URLSearchParams;
};

const initState: CustomNavigateHook = {
  navigate: handleNavigate,
  updateQueryParams: updateQueryParams,
};

//TODO: Update to use useSearchParams instead

const useCustomNavigate = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [queryParams, setQueryParams] = useState<any>();

  useEffect(() => {
    if (location?.search) {
      const params = new URLSearchParams(location.search);
      let finalQueryParams: any = {};
      params.forEach((value, key) => {
        finalQueryParams[key] = value;
      });
      setQueryParams(finalQueryParams);
    } else setQueryParams(undefined);
  }, [location]);

  return {
    navigate: handleNavigate,
    updateQueryParams: updateQueryParams,
    queryParams,
    searchParams,
  };
};

export default singletonHook(initState, useCustomNavigate);
