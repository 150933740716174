import { Popover, TransferProps } from "antd";
import Table from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";
import Transfer, { TransferDirection, TransferItem } from "antd/es/transfer";
import { getWaterRights } from "@/apis/waterright.api";
import { difference } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getFields } from "@/apis/field.api";
import { getWells } from "@/apis/well.api";
import JoinedItems from "../JoinedItems/JoinedItems";

interface TableTransferProps extends TransferProps<TransferItem> {
  loading?: boolean;
  leftHeader?: string;
  rightHeader?: string;
  onValuesChange?: (values: any[]) => void;
  companyId: string;
}

// Customize Table Transfer
const TableTransfer = ({ onValuesChange = undefined, onChange, ...restProps }: TableTransferProps) => {
  const { companyId } = restProps;
  // const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [columns, setColumns] = useState<any[]>([]);

  const [waterRights, setWaterRights] = useState<any[]>([]);
  const [loadingWaterRights, setLoadingWaterRights] = useState<boolean>(false);
  const [fields, setFields] = useState<any[]>([]);
  const [loadingFields, setLoadingFields] = useState<boolean>(true);
  const [wells, setWells] = useState<any[]>([]);
  const [loadingWells, setLoadingWells] = useState<boolean>(true);
  const [lastRefreshed, setLastRefreshed] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    if (companyId) refreshData();
  }, [companyId]);

  useEffect(() => {
    calculateColumns();
  }, [data]);

  useEffect(() => {
    const data = waterRights.map((waterRight: any) => {
      const wellsForWaterRight = wells.filter((well) => well.waterRightId === waterRight.id);
      const fieldsForWaterRight = wellsForWaterRight.map((well) => well.fieldId);
      const tempWells = wellsForWaterRight.map((well) => well.name);
      const tempFields = fields.filter((field) => fieldsForWaterRight.includes(field.id)).map((field) => field.name);

      return {
        ...waterRight,
        wells: tempWells?.length > 0 ? tempWells : ["-"],
        fields: tempFields.length > 0 ? tempFields : ["-"],
      };
    });
    setData(data);

    if (onValuesChange) onValuesChange(data.filter((x) => restProps.targetKeys?.includes(x.id)));
  }, [lastRefreshed]);

  const refreshData = async () => {
    await Promise.allSettled([refreshWaterRights(), refreshFields(), refreshWells()]);
    setLastRefreshed(Date.now());
  };

  const handleOnChange = (targetKeys: string[], direction: TransferDirection, moveKeys: string[]) => {
    if (onValuesChange) onValuesChange(data.filter((x) => targetKeys.includes(x.id)));
    if (onChange) onChange(targetKeys, direction, moveKeys);
  };

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: (
          <>
            Water Right /<br /> File Number
          </>
        ),
        key: "fileNumber",
        dataIndex: "fileNumber",
        fixed: "left",
        render: (val: any, record: any) => val,
      },
      {
        title: "Fields",
        key: "fields",
        dataIndex: "fields",
        fixed: "left",
        render: (val: any, record: any) => (loadingFields ? "Loading..." : <JoinedItems items={record?.fields} maxLength={30} title={"Fields for Water Right"} />),
      },
      {
        title: "Wells",
        key: "wells",
        dataIndex: "wells",
        fixed: "left",
        render: (val: any, record: any) => (loadingWells ? "Loading..." : <JoinedItems items={record?.wells} maxLength={30} title={"Wells for Water Right"} />),
      },
    ];

    setColumns(tempColumns);
  };

  const refreshWaterRights = async () => {
    setLoadingWaterRights(true);
    const request = {
      companyId: companyId,
    };
    const response = await getWaterRights(request);
    if (response.ok) {
      const data = await response.json();
      setWaterRights(data.value.map((item: any) => ({ ...item, key: item.id })));
    }
    setLoadingWaterRights(false);
  };

  const refreshFields = async () => {
    setLoadingFields(true);
    const request = { companyId: companyId };
    const response = await getFields(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setFields(data.value);
      }
    }
    setLoadingFields(false);
  };

  const refreshWells = async () => {
    setLoadingWells(true);
    const request = { companyId: companyId };
    const response = await getWells(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWells(data.value);
      }
    }
    setLoadingWells(false);
  };

  return (
    <Transfer {...restProps} onChange={handleOnChange} dataSource={data} titles={[restProps?.leftHeader ?? <>Available Water Rights</>, restProps?.rightHeader ?? <>Assigned Water Rights</>]}>
      {({ filteredItems, onItemSelectAll, onItemSelect, selectedKeys: listSelectedKeys, disabled: listDisabled }) => {
        const rowSelection: TableRowSelection<TransferItem> = {
          getCheckboxProps: (item) => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key);
            const diffKeys = selected ? difference(treeSelectedKeys, listSelectedKeys) : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys as string[], selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key as string, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        return (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            style={{ pointerEvents: listDisabled ? "none" : undefined }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return;
                onItemSelect(key as string, !listSelectedKeys.includes(key as string));
              },
            })}
            loading={loadingWaterRights || loadingFields || loadingWells || restProps.loading}
          />
        );
      }}
    </Transfer>
  );
};

export default TableTransfer;
