import { Col, Row, Image } from "antd";
import { PageContent, PageHeader } from "@/components";
import PageFooter from "@/components/PageComponents/PageFooter/PageFooter";
import { FC } from "react";

const Home: FC = () => {
  return (
    <div id="home">
      <PageHeader title="VandWater, Inc" backgroundImageUri="images/VandWater-Banner.png" description="Providing water management technology to preserve our planet’s most valuable resource" />
      <PageContent
        content={
          <div style={{ maxWidth: 1300 }}>
            <Row gutter={[30, 30]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div>
                  <h1
                    style={{
                      wordWrap: "break-word",
                      lineHeight: 1.5,
                      boxSizing: "border-box",
                      margin: 0,
                      paddingBottom: 10,
                      border: 0,
                      outline: 0,
                      verticalAlign: "baseline",
                      background: "transparent",
                      fontWeight: 700,
                      fontSize: "27px",
                      color: "#00007F",
                    }}
                  >
                    Providing Water Management Technology
                  </h1>
                  <p
                    style={{
                      color: "#666",
                      lineHeight: "1.7em",
                      fontWeight: 400,
                      wordWrap: "break-word",
                      textAlign: "justify",
                      boxSizing: "border-box",
                      margin: 0,
                      border: 0,
                      outline: 0,
                      fontSize: "20px",
                      verticalAlign: "baseline",
                      background: "transparent",
                      paddingBottom: "1em",
                    }}
                  >
                    Welcome to VandWater, Inc. – the simple and secure software that combines science and technology for tracking, managing, and reporting water activity through a proven and simple
                    software program and mobile app, all in one place. The VandWater app was created to better understand the array of challenges in tracking and monitoring water management to stay
                    within compliance regulations while also maximizing water efficiency.
                  </p>
                  <br />
                  <p
                    style={{
                      color: "#666",
                      lineHeight: "1.7em",
                      fontWeight: 400,
                      wordWrap: "break-word",
                      textAlign: "justify",
                      boxSizing: "border-box",
                      margin: 0,
                      border: 0,
                      outline: 0,
                      fontSize: "20px",
                      verticalAlign: "baseline",
                      background: "transparent",
                      paddingBottom: "1em",
                    }}
                  >
                    This program provides a sophisticated full use software platform that is highly flexible and scalable to ease monitoring and reporting, thereby assisting with compliance, as well
                    as saving operators time, money, and frustration with critical current well activity logs producing full water right history – all in the palm of your hand. This program was
                    created by farmers for farmers. The VandWater app will provide all the tools required for sustainable water management.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <Image
                    style={{
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      alignContent: "center",
                      maxHeight: 510,
                      maxWidth: 510,
                      borderRadius: 10,
                      objectFit: "contain",
                    }}
                    src="images/VandWater-Global-Leader-in-Water-Management-Technology.webp"
                    preview={false}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <p
                  style={{
                    fontSize: "35px",
                    display: "flex",
                    justifyContent: "center",
                    fontFamily: "Damion",
                    // fontStyle: 'italic',
                    letterSpacing: 1,
                    color: "#00007f",
                    boxSizing: "border-box",
                  }}
                >
                  Water Management Simplified
                </p>
              </Col>
            </Row>
          </div>
        }
      />
      <PageFooter />
    </div>
  );
};

export default Home;
