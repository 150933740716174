import { Breadcrumb } from "antd";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigationType } from "react-router-dom";
import { useAppDispatch } from "@/stores";
import { addSiderUrlStateBreadcrumb, addViewAddUrlStateBreadcrumb, clearUrlStateBreadcrumbs, removeStateBreadcrumbIndex, removeUrlStateBreadcrumb } from "@/stores/breadcrumbs.store";
import { breadCrumbViewEditBreadcrumbs, breadcrumbLabel, checkBreadCrumb } from "./BreadCrumbHelper";

interface BreadcrumbDto {
  type: string;
  url: string;
  label?: string;
}

const Breadcrumbs: FC = () => {
  const dispatch = useAppDispatch();

  const { selectedRole } = useSelector((state: any) => state.user);
  // const { urlHistory: urlStateHistory } = useSelector((state: any) => state.breadcrumb);
  const { urlStateHistory } = useSelector((state: any) => state.breadcrumb);

  const pathUrl = window.location.pathname;

  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType === "PUSH") {
      if (!pathUrl.includes("/dashboard")) {
        if (checkBreadCrumb(pathUrl)) {
          addUrlStateBreadCrumb(pathUrl, true);
        } else {
          addUrlStateBreadCrumb(pathUrl, false);
        }
      } else {
        dispatch(clearUrlStateBreadcrumbs());
      }
    } else if (navigationType === "POP") {
      if (urlStateHistory.length > 1) {
        removeUrlStateBreadcrumbIndex(pathUrl);
      }
    }
  }, [pathUrl, navigationType]);

  useEffect(() => {}, []);

  const removeUrlStateBreadcrumbIndex = (url?: string) => {
    dispatch(removeUrlStateBreadcrumb(url));
  };

  const addUrlStateBreadCrumb = (state: string, checkedBreadcrumb: boolean) => {
    if (checkedBreadcrumb) {
      // =======================
      // Sider bread crumbs
      // =======================
      if (state.includes("admin")) {
        const splitUrl = state.slice(1, state.length).split("/");
        const type = breadcrumbLabel(splitUrl[1]);
        const stateUrl = state.slice(1, state.length);

        dispatch(
          addSiderUrlStateBreadcrumb({
            type: type,
            url: stateUrl.slice(6, stateUrl.length),
          })
        );
      } else {
        const splitUrl = state.slice(1, state.length).split("/");
        const type = breadcrumbLabel(splitUrl[0]);
        const stateUrl = state.slice(1, state.length);

        dispatch(addSiderUrlStateBreadcrumb({ type: type, url: stateUrl }));
      }
    } else {
      if (state.includes("admin")) {
        const splitUrl = state.slice(1, state.length).split("/");
        const type = state.includes("view") ? "" : breadCrumbViewEditBreadcrumbs(splitUrl.slice(1, splitUrl.length));
        const stateUrl = state.slice(1, state.length);
        dispatch(
          addViewAddUrlStateBreadcrumb({
            type: type,
            url: stateUrl.slice(6, stateUrl.length),
          })
        );
      } else {
        const splitUrl = state.slice(1, state.length).split("/");
        const type = state.includes("view") ? "" : breadCrumbViewEditBreadcrumbs(splitUrl);
        const stateUrl = state.slice(1, state.length);
        dispatch(addViewAddUrlStateBreadcrumb({ type: type, url: stateUrl }));
      }
    }
  };

  const renderBreadCrumbItems = () =>
    urlStateHistory.map((url: BreadcrumbDto, index: number, list: BreadcrumbDto[]) => (
      // <Breadcrumb.Item key={`${url?.url}`} onClick={removeUrlStateBreadcrumbIndex}>
      <Breadcrumb.Item key={`${url?.url}`} onClick={() => dispatch(removeStateBreadcrumbIndex(url?.url))}>
        <Link style={index === list.length - 1 ? { pointerEvents: "none" } : undefined} to={selectedRole ? `/admin/${url?.url}` : `/${url?.url}`}>{`${url?.label ? url?.label : url?.type}`}</Link>
      </Breadcrumb.Item>
    ));

  return pathUrl.includes("/dashboard") ? null : <Breadcrumb style={{ padding: 16, paddingLeft: 8 }}>{renderBreadCrumbItems()}</Breadcrumb>;
};

export default Breadcrumbs;
