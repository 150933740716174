import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Card, Tabs, Tooltip } from "antd";
import ButtonGroup from "antd/es/button/button-group";
import { useState } from "react";

import RestrictionGaugesDashboard from "./RestrictionGaugesDashboard";
import WellGaugesDashboard from "./WellGaugesDashboard";

export default function GaugesDashboard() {
  // const { searchParams, updateQueryParams } = useCustomNavigate();
  const [activeKey, setActiveKey] = useState<string>("1");

  const tabs: {
    label: string;
    key: string;
    children: JSX.Element;
    icon: JSX.Element;
    hidden?: boolean;
    tooltip?: string;
    hiddenTooltip?: string;
    badgeCount?: number | (() => number);
  }[] = [
    {
      label: "Well",
      key: "1",
      children: <WellGaugesDashboard />,
      icon: <MenuOutlined />,
      tooltip: "Gauges related to well metrics",
    },
    {
      label: "Restriction",
      key: "2",
      children: <RestrictionGaugesDashboard />,
      icon: <CloseOutlined />,
      tooltip: "Gauges related to restriction metrics",
    },
  ];

  return (
    <>
      {activeKey && (
        <Tabs
          activeKey={activeKey}
          centered
          tabPosition={"top"}
          items={tabs}
          tabBarStyle={{ marginBottom: 5 }}
          renderTabBar={(props) => (
            <Card
              styles={{
                body: {
                  padding: 5,
                  marginRight: 8,
                },
              }}
            >
              <ButtonGroup>
                {tabs.map((tab, index) => (
                  <Tooltip key={tab.key} title={tab.hidden ? tab.hiddenTooltip : tab.tooltip}>
                    <Button key={tab.key} type={props.activeKey === tab.key ? "primary" : "default"} icon={tab.icon} onClick={() => setActiveKey(tab.key)} disabled={tab.hidden}>
                      {tab.label}
                    </Button>
                  </Tooltip>
                ))}
              </ButtonGroup>
            </Card>
          )}
        />
      )}
    </>
  );
}
