import React from "react";
import { Button, Layout } from "antd";
import "./Footer.scss";
import { routes } from "@/configs";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useSelector } from "react-redux";
import { showNavigationWarning } from "@/services/utils";

const Footer: React.FC = () => {
  const { busyEditing } = useSelector((state: any) => state.company);
  const { navigate } = useCustomNavigate();
  const checkVersion = () => {
    const version = localStorage.getItem("version");
    return version;
  };

  return (
    <Layout.Footer id="footer" style={{ padding: "8px 16px 8px 8px", clear: "both" }}>
      <div style={{ float: "left" }}>
        <Button type="text" style={{ pointerEvents: "none", color: "lightgray", marginLeft: -16 }}>
          Version: {checkVersion()}
        </Button>
      </div>
      <div style={{ float: "right" }}>
        <Button
          style={{ marginLeft: 10 }}
          type="link"
          onClick={() => {
            showNavigationWarning(busyEditing, () => navigate(routes.privacy));
          }}
        >
          Privacy
        </Button>
        <Button
          style={{ paddingLeft: 0 }}
          type="link"
          onClick={() => {
            showNavigationWarning(busyEditing, () => navigate(routes.termsOfUse));
          }}
        >
          Terms Of Use
        </Button>
        © VandWater, Inc. 2023
      </div>
    </Layout.Footer>
  );
};

export default Footer;
