import { listBillableUsers } from "@/apis/aggregator.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import BillingEventStatus from "@/types/BillingEventStatus";
import BillingEventType from "@/types/BillingEventType";
import CacheKeys from "@/types/CacheKeys";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

type BillingUsersProps = {
  searchString?: string;
  hasPendingBillableItems: boolean;
};

const useListBillingUsersInfiniteQuery = (props: BillingUsersProps) => {
  const { profile } = useSelector((state: any) => state.user);

  const queryKey = useMemo(
    () => [
      CacheKeys.billingUsers,
      CacheKeys.list,
      {
        profileId: profile?.id,
        hasPendingBillableItems: props.hasPendingBillableItems,
        searchString: props.searchString,
      },
    ],
    [profile?.id, props.hasPendingBillableItems, props.searchString]
  );

  const queryClient = useQueryClient();

  const { data, isFetching, isFetched, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading } = useInfiniteQuery({
    queryKey: queryKey,
    queryFn: async ({ pageParam }) => {
      const response = await listBillableUsers({
        searchString: props.searchString,
        hasPendingBillableItems: props.hasPendingBillableItems,
        pagination: {
          page: pageParam,
          pageSize: 25,
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch billable users");
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage?.pagination?.hasNextPage) return undefined;
      return lastPageParam + 1;
    },
  });

  const invalidateBillingUsers = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.billingUsers, CacheKeys.list],
    });
  }, [queryClient]);

  const optimisticBillableStatusUpdate = useCallback(
    (id: string, isBillable: boolean, userId?: string) => {
      const existingList: any = queryClient.getQueryData(queryKey);

      if (existingList) {
        const updatedList = existingList.pages.map((page: any) => {
          const updatedPage = page.list.map((item: any) => {
            if (item.id === (userId ?? id)) {
              const existingNotBillableEntities: any[] = item.notBillableEntities ?? [];
              if (isBillable) {
                return {
                  ...item,
                  notBillableEntities: existingNotBillableEntities.filter((notBillableEntity) => notBillableEntity !== id),
                };
              } else {
                return {
                  ...item,
                  notBillableEntities: [...existingNotBillableEntities, id],
                };
              }
            }
            return item;
          });

          return {
            ...page,
            list: updatedPage,
          };
        });

        queryClient.setQueryData(queryKey, {
          ...existingList,
          pages: updatedList,
        });
      }

      message.success("Billable status updated successfully");

      setTimeout(() => invalidateBillingUsers(), 5000);
    },
    [queryKey, invalidateBillingUsers, queryClient]
  );

  const optimisticClearItemsUpdate = useCallback(
    (userId?: string, billableItemIds?: string[]) => {
      const existingList: any = queryClient.getQueryData(queryKey);

      if (existingList) {
        const updatedList = existingList.pages.map((page: any) => {
          const updatedPage = page.list.map((item: any) => {
            if (item.id === userId) {
              const updatedBillableEntities = item.pendingBillableItems.filter((billableItem: any) => !billableItemIds?.includes(billableItem.id));

              const pendingWells = updatedBillableEntities
                .filter((x: any) => (x.Type === BillingEventType.WellAdded || x.Type === BillingEventType.AnnualBillableWells) && x.Status === BillingEventStatus.New && x.IsBillable)
                .reduce((acc: any, x: any) => acc + x.Quantity, 0);
              const pendingAssignedTokens = updatedBillableEntities
                .filter((x: any) => x.Type === BillingEventType.TokenAssigned && x.Status === BillingEventStatus.New)
                .reduce((acc: any, x: any) => acc + x.Quantity, 0);
              const pendingReplacedTokens = updatedBillableEntities
                .filter((x: any) => x.Type === BillingEventType.TokenReplaced && x.Status === BillingEventStatus.New)
                .reduce((acc: any, x: any) => acc + x.Quantity, 0);

              return {
                ...item,
                pendingBillableItems: updatedBillableEntities,
                pendingWells: pendingWells,
                pendingAssignedTokens: pendingAssignedTokens,
                pendingReplacedTokens: pendingReplacedTokens,
              };
            }
            return item;
          });

          return {
            ...page,
            list: updatedPage,
          };
        });

        queryClient.setQueryData(queryKey, {
          ...existingList,
          pages: updatedList,
        });

        message.success("Pending billable items cleared successfully");
      }

      setTimeout(() => invalidateBillingUsers(), 5000);
    },
    [queryKey, invalidateBillingUsers, queryClient]
  );

  const optimisticGenerateInvoiceUpdate = useCallback(
    (userId?: string, billableItemIds?: string[]) => {
      const existingList: any = queryClient.getQueryData(queryKey);

      if (existingList) {
        const updatedList = existingList.pages.map((page: any) => {
          const updatedPage = page.list.map((item: any) => {
            if (item.id === userId) {
              const updatedBillableEntities = item.pendingBillableItems.filter((billableItem: any) => !billableItemIds?.includes(billableItem.id));

              const pendingWells = updatedBillableEntities
                .filter((x: any) => (x.Type === BillingEventType.WellAdded || x.Type === BillingEventType.AnnualBillableWells) && x.Status === BillingEventStatus.New && x.IsBillable)
                .reduce((acc: any, x: any) => acc + x.Quantity, 0);
              const pendingAssignedTokens = updatedBillableEntities
                .filter((x: any) => x.Type === BillingEventType.TokenAssigned && x.Status === BillingEventStatus.New)
                .reduce((acc: any, x: any) => acc + x.Quantity, 0);
              const pendingReplacedTokens = updatedBillableEntities
                .filter((x: any) => x.Type === BillingEventType.TokenReplaced && x.Status === BillingEventStatus.New)
                .reduce((acc: any, x: any) => acc + x.Quantity, 0);

              return {
                ...item,
                pendingBillableItems: updatedBillableEntities,
                pendingWells: pendingWells,
                pendingAssignedTokens: pendingAssignedTokens,
                pendingReplacedTokens: pendingReplacedTokens,
              };
            }
            return item;
          });

          return {
            ...page,
            list: updatedPage,
          };
        });

        queryClient.setQueryData(queryKey, {
          ...existingList,
          pages: updatedList,
        });

        message.success("Invoice generated successfully");
      }

      setTimeout(() => invalidateBillingUsers(), 5000);
    },
    [queryKey, invalidateBillingUsers, queryClient]
  );

  const billingUsers = useMemo(() => data?.pages?.map((page) => page.list).flat() ?? [], [data]);
  const currentItemCount = billingUsers.length;
  const totalItemCount = useMemo(() => data?.pages?.[(data?.pages?.length ?? 0) - 1]?.pagination?.totalItemCount ?? 0, [data]);

  return {
    billingUsers,
    isLoading,
    currentItemCount,
    totalItemCount: totalItemCount,
    fetchNextPage,
    invalidateBillingUsers,
    optimisticBillableStatusUpdate,
    optimisticGenerateInvoiceUpdate,
    optimisticClearItemsUpdate,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
    isFetched,
  };
};

export default useListBillingUsersInfiniteQuery;
