import { FC } from "react";
import "./PageContent.scss";

interface Props {
  content?: any;
}

const PageContent: FC<Props> = (props: Props) => {
  const { content } = props;
  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 60,
      }}
    >
      {content && content}
    </div>
  );
};

export default PageContent;
