import React from "react";
import { useQuery } from "@tanstack/react-query";
import CacheKeys from "@/types/CacheKeys";
import { useSelector } from "react-redux";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import { getUnreadPushNotificationsCount } from "@/apis/communication.api";

const useUnreadNotificationCount = () => {
  const { profile } = useSelector((state: any) => state.user);

  const { data, isFetching, isFetched } = useQuery({
    queryKey: [
      CacheKeys.unreadNotificationCount,
      {
        profileId: profile?.id,
      },
    ],
    queryFn: async () => {
      const response = await getUnreadPushNotificationsCount();

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value.count;
      }

      throw new Error("Failed to fetch unread notifications count");
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    refetchInterval: 2 * 60 * 1000, //Every 2 mins
  });

  return {
    count: data,
    isFetched,
    isFetching,
  };
};

export default useUnreadNotificationCount;
