import { Col, Row, Image } from "antd";
import { PageContent, PageHeader } from "@/components";
import PageFooter from "@/components/PageComponents/PageFooter/PageFooter";
import { map } from "lodash";
import { FC } from "react";

const photos = [
  "images/PhotoGallery/Darland-Water-6.jpg",
  "images/PhotoGallery/Darland-Water-5.jpg",
  "images/PhotoGallery/Darland-Water-4.jpg",
  "images/PhotoGallery/Darland-Water-3.jpg",
  "images/PhotoGallery/Darland-Water-2.jpg",
  "images/PhotoGallery/Darland-Water-1.jpg",
  "images/PhotoGallery/Watermeter-and-Toke-0-2.jpg",
  "images/PhotoGallery/Watermeter-System-01.jpg",
];

const PhotoGallery: FC = () => {
  return (
    <div id="photo-gallery">
      <PageHeader title="Photo Gallery" backgroundImageUri="images/VandWater-Banner.png" />
      <PageContent
        content={
          <div style={{ maxWidth: 800, textAlign: "center" }}>
            <Image.PreviewGroup>
              {photos.map((photo) => (
                <Image
                  style={{
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    width: 180,
                    height: 180,
                    objectFit: "contain",
                    margin: 5,
                  }}
                  src={photo}
                />
              ))}
            </Image.PreviewGroup>
          </div>
        }
      />
      <PageFooter />
    </div>
  );
};

export default PhotoGallery;
