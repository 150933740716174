import { InvoicesModal } from "@/components";
import useListFinanceDocumentsQuery from "@/queries/useListFinanceDocumentsQuery";
import { ReloadOutlined } from "@ant-design/icons";
import { useIntersectionObserver, useWindowSize } from "@uidotdev/usehooks";
import { Button, Empty, Input, message, Select, Space, Table } from "antd";
import { FC, useEffect, useState } from "react";
import EditInvoiceButton from "./components/EditInvoiceButton";
import MarkAsPaidButton from "./components/MarkAsPaidButton";
import FinanceDocumentStatusComponent from "./components/FinanceDocumentStatusComponent";
import FinanceDocumentTypeComponent from "./components/FinanceDocumentTypeComponent";
import { markFinanceDocumentAsPaid, sendFinanceDocument } from "@/apis/aggregator.api";
import SendInvoiceButton from "./components/SendInvoiceButton";
import DownloadInvoiceButton from "./components/DownloadInvoiceButton";

export enum FinanceDocumentStatus {
  unknown = 0,
  draft = 1,
  sent = 2,
  updated = 3,
  paid = 4,
  cancelled = 5,
}

const FinanceDocumentStatusLookup: any = {
  Draft: FinanceDocumentStatus.draft,
  Sent: FinanceDocumentStatus.sent,
  Paid: FinanceDocumentStatus.paid,
  Cancelled: FinanceDocumentStatus.cancelled,
};

export enum FinanceDocumentType {
  unknown = 0,
  proformaInvoice = 1,
  invoice = 2,
}

const FinanceDocumentTypeLookup: any = {
  "Proforma Invoice": FinanceDocumentType.proformaInvoice,
  Invoice: FinanceDocumentType.invoice,
};

type AdminInvoicesProps = {
  userId?: string;
};

const AdminInvoices: FC<AdminInvoicesProps> = ({ userId }) => {
  const [financeDocumentsDownRef, financeDocumentsDownEntry] = useIntersectionObserver();

  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<FinanceDocumentStatus | undefined>(undefined);
  const [type, setType] = useState<FinanceDocumentType | undefined>(undefined);

  const [columns, setColumns] = useState<any[]>([]);

  const [editingInvoiceId, setEditingInvoiceId] = useState<string>();

  const { financeDocuments, invalidateFinanceDocuments, hasNextPage, isFetchingNextPage, totalItemCount, isLoading, fetchNextPage, optimisticMarkAsPaid, optimisticMarkAsSent } =
    useListFinanceDocumentsQuery({
      searchString,
      status,
      type,
      userId,
    });

  const handleRefresh = () => {
    invalidateFinanceDocuments();
  };

  const handleMarkAsPaid = async (id: string, onCompleted: () => void) => {
    try {
      const response = await markFinanceDocumentAsPaid({ id });
      if (response.ok) {
        optimisticMarkAsPaid(id);
      }
    } catch (error) {
      message.error("Failed to update billable status");
    }
    onCompleted();
  };

  const handleSendInvoice = async (id: string, onCompleted: () => void) => {
    try {
      const response = await sendFinanceDocument({ id });
      if (response.ok) {
        optimisticMarkAsSent(id);
        message.success("Successfully sent invoice");
      }
    } catch (error) {
      message.error("Failed to send invoice");
    }
    onCompleted();
  };

  useEffect(() => {
    if (financeDocumentsDownEntry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line
  }, [financeDocumentsDownEntry?.isIntersecting, hasNextPage]);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [financeDocuments]);

  const calculateColumns = () => {
    const tempColumns: any[] = [
      {
        title: "Invoice No.",
        key: "number",
        dataIndex: "number",
        width: 100,
        align: "center",
        render: (val: any, record: any, index: any) => (
          <>
            {val}
            {index === financeDocuments?.length - 1 ? <span ref={financeDocumentsDownRef} /> : null}
          </>
        ),
      },
      {
        title: "Invoice Date",
        key: "date",
        dataIndex: "date",
        width: 100,
        render: (val: any, record: any) => val,
      },

      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        width: 130,
        render: (val: FinanceDocumentType) => <FinanceDocumentTypeComponent type={val} />,
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        width: 80,
        align: "center",
        render: (val: FinanceDocumentStatus) => <FinanceDocumentStatusComponent status={val} />,
      },
      {
        title: "Billed To",
        key: "billTo",
        dataIndex: "billTo",
        render: (val: any, record: any) => {
          var lines = val?.split("\n");
          return lines?.[0] ?? "-";
        },
      },
      {
        title: "Billed To Emails",
        key: "billToEmail",
        dataIndex: "billToEmail",
        render: (val: any, record: any) => {
          var joined = val?.join(", ");

          if (joined.length > 50) {
            return joined.substring(0, 50) + "...";
          }
          return joined;
        },
      },
      {
        title: "Outstanding Amount",
        key: "totalOutstanding",
        dataIndex: "totalOutstanding",
        render: (val: any, record: any) => (val >= 0 ? "$ " + val.toFixed(2) : "-"),
      },
      {
        title: "Actions",
        width: 180,
        render: (val: any, record: any) => (
          <>
            <EditInvoiceButton id={record.id} status={record.status} onEdit={setEditingInvoiceId} />
            {" | "}
            <SendInvoiceButton id={record.id} invoiceNumber={record.number} displayName={record.billTo} emails={record.billToEmail.join(", ")} onSend={handleSendInvoice} />
            {" | "}
            <MarkAsPaidButton id={record.id} invoiceNumber={record.number} onMarkAsPaid={handleMarkAsPaid} disabled={record.status === FinanceDocumentStatus.paid} />
            {" | "}
            <DownloadInvoiceButton id={record.id} />
          </>
        ),
      },
    ];

    setColumns(tempColumns);
  };

  const windowSize = useWindowSize();

  const renderFinanceDocumentsTable = () => {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Space direction="horizontal">
            <Space style={{ paddingBottom: 10 }}>
              <Input.Search
                disabled={isLoading}
                placeholder="Search invoices"
                onSearch={setSearchString}
                onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                value={searchStringValue}
                style={{ width: 400 }}
                allowClear
              />
              <Select style={{ width: 200, fontWeight: "normal" }} onChange={setStatus} placeholder="Filter status" allowClear>
                {Object.keys(FinanceDocumentStatusLookup).map((key) => (
                  <Select.Option key={FinanceDocumentStatusLookup[key]} value={FinanceDocumentStatusLookup[key]}>
                    {key}
                  </Select.Option>
                ))}
              </Select>
              <Select style={{ width: 200, fontWeight: "normal" }} onChange={setType} placeholder="Filter type" allowClear>
                {Object.keys(FinanceDocumentTypeLookup).map((key) => (
                  <Select.Option key={FinanceDocumentTypeLookup[key]} value={FinanceDocumentTypeLookup[key]}>
                    {key}
                  </Select.Option>
                ))}
              </Select>
              <Button disabled={isLoading} icon={<ReloadOutlined />} onClick={handleRefresh}>
                Refresh
              </Button>
            </Space>
          </Space>
          <span style={{ fontSize: "16px", paddingRight: 25, fontWeight: 600 }}>Total: {totalItemCount ?? "-"}</span>
        </div>
        <Table
          virtual
          className="customSelectScrollBar"
          rowKey={(row: any) => row.id}
          loading={loading || isLoading}
          columns={columns}
          dataSource={financeDocuments ?? []}
          size="small"
          pagination={false}
          scroll={userId ? undefined : { y: windowSize?.height ? windowSize.height - 400 : 600 }}
          style={userId ? undefined : { height: 400, width: "99%" }}
          locale={{
            emptyText: <Empty description="No Finance Documents" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
          }}
        />
        {editingInvoiceId && (
          <InvoicesModal
            id={editingInvoiceId}
            onClose={(reload) => {
              setEditingInvoiceId(undefined);
              if (reload) {
                setLoading(true);
                setTimeout(() => {
                  invalidateFinanceDocuments();
                  setLoading(false);
                }, 5000);
              }
            }}
          />
        )}
      </>
    );
  };

  return renderFinanceDocumentsTable();
};

export default AdminInvoices;
