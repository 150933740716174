import { memo } from "react";
import { FinanceDocumentStatus } from "../AdminInvoices";

const FinanceDocumentStatusComponent = ({ status }: { status: FinanceDocumentStatus }) => {
  let description = "-";
  switch (status) {
    case FinanceDocumentStatus.unknown:
      description = "Unknown";
      break;
    case FinanceDocumentStatus.draft:
      description = "Draft";
      break;
    case FinanceDocumentStatus.sent:
      description = "Sent";
      break;
    case FinanceDocumentStatus.updated:
      description = "Updated";
      break;
    case FinanceDocumentStatus.paid:
      description = "Paid";
      break;
    case FinanceDocumentStatus.cancelled:
      description = "Cancelled";
      break;
  }

  return <>{description}</>;
};

export default memo(FinanceDocumentStatusComponent);
