import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Space, Table, Typography, message } from "antd";
import { listOccupancy } from "@/apis/stock.api";
import OccupancyAddEditModal from "@/components/OccupancyAddEditModal/OccupancyAddEditModal";
import dayjs from "dayjs";
import { UserRole } from "@/dtos/user.dto";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { convertNumToMonth, randomIntFromInterval } from "@/services/utils";

interface Props {
  stockId: any;
  canEdit: boolean;
}

const OccupancyTable: FC<Props> = (props) => {
  const { stockId, canEdit } = props;

  const { selectedCompany } = useSelector((state: any) => state.company);

  const [loading, setLoading] = useState<boolean>(true);

  const [date, setDate] = useState<any>(dayjs());
  const [occupancy, setOccupancy] = useState<any[]>([]);
  const [occupanyModalState, setOccupanyModalState] = useState<any>({
    open: false,
  });

  const [occupancyColumns, setOccupancyColumns] = useState<any[]>([]);

  useEffect(() => {
    refreshOccupanyData();
    // eslint-disable-next-line
  }, [stockId, date]);

  useEffect(() => {
    calculateOccupancyColumns();
    // eslint-disable-next-line
  }, [occupancy, date]);

  const refreshOccupanyData = async () => {
    setLoading(true);

    const request = {
      stockId,
      year: date?.year(),
    };

    const response = await listOccupancy(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        let occupancyData: any[] = data.value;

        for (let i = 0; i < 12; i++) {
          const element = occupancyData[i] ?? undefined;

          // Values are skipped between other values
          if (element !== undefined && element.month !== i + 1) {
            occupancyData.splice(i, 0, {
              id: randomIntFromInterval(1, 1000),
              headCount: null,
              stockId: stockId,
              month: i + 1,
              year: date?.year(),
            });
          } else if (element === undefined) {
            occupancyData.push({
              id: randomIntFromInterval(1, 1000),
              headCount: null,
              stockId: stockId,
              month: i + 1,
              year: date?.year(),
            });
          }
        }
        setOccupancy(occupancyData);
      }
    }
    setLoading(false);
  };

  const calculateOccupancyColumns = () => {
    let tempColumns: any[] = [
      {
        title: "Month",
        key: "month",
        dataIndex: "month",
        width: 180,
        render: (val: any, record: any) => convertNumToMonth(val),
      },
      {
        title: "Head Count",
        key: "headCount",
        dataIndex: "headCount",
        align: "right",
        width: 180,
        render: (val: any, record: any) => val ?? "-",
      },
    ];

    setOccupancyColumns(tempColumns);
  };

  const handleWellReadingClose = () => {
    setOccupanyModalState({
      open: false,
    });
  };

  const handleOccupancySuccess = () => {
    if (occupanyModalState.stockId) message.success("Updated head count successfully");
    else message.success("Added head count successfully");

    refreshOccupanyData();
    handleWellReadingClose();
  };

  return (
    <Card
      id="occupancyTable"
      title="Head Counts"
      extra={
        <>
          <Space direction="horizontal" style={{ width: "100%" }}>
            <Typography style={{ paddingBottom: 3, paddingRight: 3 }}>Year:</Typography>
            <DatePicker value={date} onChange={setDate} picker="year" allowClear={false} style={{ marginRight: 30 }} placeholder="All Occupancy" />
            <Button disabled={loading} icon={<ReloadOutlined />} loading={loading} onClick={refreshOccupanyData}>
              Refresh
            </Button>
            {canEdit && (
              <Button disabled={loading} icon={<PlusOutlined />} type="primary" onClick={() => setOccupanyModalState({ open: true })}>
                Update Head Count
              </Button>
            )}
          </Space>
        </>
      }
    >
      <Table
        style={{ maxWidth: 400 }}
        rowKey={(row: any) => row.id}
        loading={loading}
        columns={occupancyColumns}
        dataSource={occupancy}
        size="small"
        pagination={false}
        summary={() => {
          const averageHeadCount =
            occupancy.filter((x) => x.headCount !== null).length > 0 ? occupancy.reduce((acc, curr) => acc + (curr.headCount ?? 0), 0) / occupancy.filter((x) => x.headCount !== null).length : null;
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <b>Average</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                <b>{averageHeadCount ? averageHeadCount.toFixed(0) : "-"}</b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
      {occupanyModalState.open && <OccupancyAddEditModal stockId={stockId} open={occupanyModalState.open} onSuccess={handleOccupancySuccess} onCancel={handleWellReadingClose} />}
    </Card>
  );
};

export default OccupancyTable;
