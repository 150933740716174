import { Col, Row, Image } from "antd";
import { PageContent, PageHeader } from "@/components";
import PageFooter from "@/components/PageComponents/PageFooter/PageFooter";
import { FC } from "react";

const AboutUs: FC = () => {
  return (
    <div id="aboutUs">
      <PageHeader title="About VandWater" backgroundImageUri="images/VandWater-Banner.png" />
      <PageContent
        content={
          <div style={{ maxWidth: 1300 }}>
            <Row gutter={[30, 30]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div>
                  <h1
                    style={{
                      wordWrap: "break-word",
                      lineHeight: 1.5,
                      boxSizing: "border-box",
                      margin: 0,
                      paddingBottom: 10,
                      border: 0,
                      outline: 0,
                      verticalAlign: "baseline",
                      background: "transparent",
                      fontWeight: 700,
                      fontSize: "27px",
                      color: "#00007F",
                    }}
                  >
                    Water Management Simplified
                  </h1>
                  <p
                    style={{
                      color: "#666",
                      lineHeight: "1.7em",
                      fontWeight: "normal",
                      wordWrap: "break-word",
                      textAlign: "justify",
                      boxSizing: "border-box",
                      margin: 0,
                      border: 0,
                      outline: 0,
                      fontSize: "20px",
                      verticalAlign: "baseline",
                      background: "transparent",
                    }}
                  >
                    Welcome to VandWater, Inc. – the ultimate software for tracking, managing, and reporting water activity through a proven and simple software program and mobile app, all in one
                    place. The concept grew out of a need for our own irrigated farming operation to better manage all aspects of our water usage and permit compliance – tracking water consumption,
                    days left to pump, well and permit information, and much more – all in a single, easy-to-use format.
                  </p>
                  <br />
                  <p
                    style={{
                      color: "#666",
                      lineHeight: "1.7em",
                      fontWeight: "normal",
                      wordWrap: "break-word",
                      textAlign: "justify",
                      boxSizing: "border-box",
                      margin: 0,
                      border: 0,
                      outline: 0,
                      fontSize: "20px",
                      verticalAlign: "baseline",
                      background: "transparent",
                    }}
                  >
                    This iPhone (IOS) and Android device compatible program combines science and technology to provide a sophisticated, full-use software platform that is highly flexible and scalable
                    to manage all water related information, generating up-to-date information in an agricultural friendly environment. This program was created by farmers for farmers. With VandWater,
                    users will save time, money, and frustration, all while providing the ease and convenience of compliance in water reporting with an app that produces critical information for best
                    use in maximizing your allocated water.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <Image
                    style={{
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      alignContent: "center",
                      maxHeight: 600,
                      maxWidth: 600,
                      borderRadius: 10,
                      objectFit: "contain",
                    }}
                    src="images/Welcome-to-VandWater.webp"
                    preview={false}
                  />
                </div>
              </Col>
            </Row>
          </div>
        }
      />
      {/* <div style={{ marginLeft: '50px', marginRight: '50px', display: 'flex', justifyContent: 'center' }}>
            <div style={{ maxWidth: '650px' }}>
                <h1 style={{
                    wordWrap: 'break-word',
                    lineHeight: 1.5,
                    boxSizing: 'border-box',
                    margin: 0,
                    paddingBottom: 10,
                    border: 0,
                    outline: 0,
                    verticalAlign: 'baseline',
                    background: 'transparent',
                    fontWeight: 700,
                    fontSize: '27px',
                    color: '#00007F',
                }}
                >
                    Water Management Simplified
                </h1>
                <p
                    style={{
                        color: '#666',
                        lineHeight: '1.7em',
                        fontWeight: 400,
                        wordWrap: 'break-word',
                        textAlign: 'justify',
                        boxSizing: 'border-box',
                        margin: 0,
                        paddingRight: '20px',
                        border: 0,
                        outline: 0,
                        fontSize: '20px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        paddingBottom: '1em',
                    }}
                >
                    Welcome to VandWater, Inc. – the ultimate software for tracking, managing, and reporting water activity through a proven and simple software program and mobile app, all in one place. The concept grew out of a need for our own irrigated farming operation to better manage all aspects of our water usage and permit compliance – tracking water consumption, days left to pump, well and permit information, and much more – all in a single, easy-to-use format.
                </p>
                <br />
                <p
                    style={{
                        color: '#666',
                        lineHeight: '1.7em',
                        fontWeight: 400,
                        wordWrap: 'break-word',
                        textAlign: 'justify',
                        boxSizing: 'border-box',
                        margin: 0,
                        paddingRight: '20px',
                        border: 0,
                        outline: 0,
                        fontSize: '20px',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        paddingBottom: '1em'
                    }}
                >
                    This iPhone (IOS) and Android device compatible program combines science and technology to provide a sophisticated, full-use software platform that is highly flexible and scalable to manage all water related information, generating up-to-date information in an agricultural friendly environment. This program was created by farmers for farmers. With VandWater, users will save time, money, and frustration, all while providing the ease and convenience of compliance in water reporting with an app that produces critical information for best use in maximizing your allocated water.
                </p>
            </div>
            <img
                style={{ float: 'right' }}
                src='images/Welcome-to-VandWater.png'
            />
        </div> */}
      <PageFooter />
    </div>
  );
};

export default AboutUs;
