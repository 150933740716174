import { StockSelector } from "@/components";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Form, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import { FC, useEffect, useState } from "react";
import dayjs from "dayjs";

import GeneratedStockConfigurationReport from "./GeneratedStockConfigurationReport";
import { constants } from "@/configs";
import { useSelector } from "react-redux";

const StockConfigurationReport: FC = () => {
  const [form] = Form.useForm();
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [reportConfig, setReportConfig] = useState<any>(undefined);

  const stockIds: any[] = useWatch("stockIds", form);
  const [stocks, setStocks] = useState<any[]>([]);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    const tempStockIds: any[] = stockIds;

    setReportConfig({
      stockIds: tempStockIds ?? [],
      stock:
        stocks
          ?.filter((stock) => stockIds?.includes(stock?.id))
          ?.map((stock) => stock?.name)
          ?.join(", ") ?? [],
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <StockSelector form={form} propertyToSet={"stockIds"} label={"Stock"} placeholder="Leave blank to select all stocks" style={{ margin: 0, marginBottom: 10 }} setStocks={setStocks} />
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedStockConfigurationReport reportConfig={reportConfig} />}
    </>
  );
};

export default StockConfigurationReport;
