import { ClearOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Tooltip } from "antd";
import { FC, useState } from "react";

type ClearPendingBillableButtonProps = {
  name?: string;
  description?: string;
  userId: string;
  billableItemIds: string[];
  onClearPendingBillableItems: (id: string, billableItemIds: string[], onCompleted: () => void) => void;
};

const ClearPendingBillableButton: FC<ClearPendingBillableButtonProps> = ({ name, description, userId, billableItemIds, onClearPendingBillableItems }) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Popconfirm
      placement="topRight"
      title="Clear pending billable items"
      description={
        name ? (
          `Are you sure you want to clear the pending billable items for ${name}?`
        ) : (
          <div style={{ maxWidth: 450, paddingRight: 20 }}>
            Are you sure you want to clear the pending billable item?
            <br />
            <div style={{ paddingTop: 5, paddingBottom: 5 }}>{description}</div>
          </div>
        )
      }
      onConfirm={() => {
        setLoading(true);
        onClearPendingBillableItems(userId, billableItemIds, () => setLoading(false));
      }}
      okText="Yes"
      cancelText="No"
    >
      <Tooltip title={billableItemIds.length === 0 ? "No pending billable items" : billableItemIds.length === 1 ? "Clear pending billable item" : "Clear pending billable items"} placement="left">
        <Button loading={loading} style={{ paddingLeft: 0, paddingRight: 0 }} type="link" icon={<ClearOutlined />} disabled={billableItemIds.length === 0 || loading} />
      </Tooltip>
    </Popconfirm>
  );
};

export default ClearPendingBillableButton;
