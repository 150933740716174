import { archiveDocument, downloadDocument, getDate, getWaterRight, getWaterRightCalculation, getWaterRightDocuments } from "@/apis/waterright.api";
import { getWellsFromWaterRightId } from "@/apis/well.api";
import {
  AdjustmentTable,
  AuditLogTable,
  DatesAddEditModal,
  DocumentsAddModal,
  DownloadDocumentButton,
  FieldTable,
  LookupLabel,
  NotesTable,
  PlaceOfUseTable,
  RestrictionTable,
  StatusTag,
  WellTable,
} from "@/components";
import { AuditLogType } from "@/components/AuditLogTable/AuditLogTable";
import RadialGauge from "@/components/Gauges/RadialGauge";
import PrintPage from "@/components/PrintPage/PrintPage";
import RemainingDays from "@/components/RemainingDays/RemainingDays";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import WaterRightTagList from "@/components/WaterRightTagList/WaterRightTagList";
import { constants, routes } from "@/configs";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import useSelectedCompanyData from "@/queries/useSelectedCompanyData";
import useCustomNavigate from "@/services/useCustomNavigate";
import { getFileName, openFileFromGeneratedFile } from "@/services/utils";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb, removeBreadcrumb, replaceUrlStateBreadcrumbType } from "@/stores/breadcrumbs.store";
import { EditOutlined, LeftOutlined, PlusOutlined, ReloadOutlined, WarningOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Descriptions, Popconfirm, Row, Space, Switch, Table, Tabs, Typography, message } from "antd";
import dayjs from "dayjs";
import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "./WaterRightView.scss";

interface WaterRightViewProps {
  refreshWaterRightCalculations?: () => void;
  showHeader?: boolean;
  overrideId?: string;
}

const WaterRightView: FC<WaterRightViewProps> = (props) => {
  const { refreshWaterRightCalculations, showHeader = true, overrideId = undefined } = props;

  const dispatch = useAppDispatch();

  const { navigate } = useCustomNavigate();

  const { id } = useParams<{ id: string }>();

  const pathUrl = window.location.pathname;

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const [loadingDates, setLoadingDates] = useState<boolean>(false);
  const [calculation, setCalculation] = useState<any>(undefined);
  const [loadingCalculations, setLoadingCalculations] = useState<boolean>(false);
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [loadingPlaceOfUse, setLoadingPlaceOfUse] = useState<boolean>(false);

  const [documentUploadModalState, setDocumentUploadModalState] = useState<any>({
    open: false,
  });
  const [datesModalState, setDatesModalState] = useState<any>({
    open: false,
  });
  const [documentsColumns, setDocumentsColumns] = useState<any[]>([]);
  const [waterRightData, setWaterRightData] = useState<any>(undefined);
  const [fields, setFields] = useState<any[]>([]);
  const [dateData, setDateData] = useState<any>(undefined);
  const [documents, setDocuments] = useState<any[]>([]);

  const { companyConfig } = useComapnyConfigLookup(waterRightData?.companyId);

  const { canEdit } = useSelectedCompanyData(waterRightData?.companyId);

  const refreshWaterRightViewCalculations = useCallback(() => {
    handleRefresh();
  }, [id]);

  const refreshWaterRightPlaceOfUseData = useCallback(() => {
    getWaterRightPlaceOfUseData();
  }, [id]);

  useEffect(() => {
    if (pathUrl.includes("view") && waterRightData) {
      dispatch(replaceUrlStateBreadcrumbType(waterRightData?.fileNumber));
    }
  }, [pathUrl, waterRightData]);

  useEffect(() => {
    if (id || overrideId) {
      getWaterRightData();
      getWellsForWaterRight();
      refreshCalculation();
    }

    // eslint-disable-next-line
  }, [id, overrideId]);

  useEffect(() => {
    getDocumentsListData();
    // eslint-disable-next-line
  }, [showArchived, canEdit]);

  useEffect(() => {
    if (waterRightData?.id) {
      getDateData();
    }
    // eslint-disable-next-line
  }, [waterRightData]);

  useEffect(() => {
    calculateDocumentsColumns();
    // eslint-disable-next-line
  }, [documents]);

  const refreshCalculation = async () => {
    setLoadingCalculations(true);
    const response = await getWaterRightCalculation(overrideId ?? (id as string), dayjs().year());
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setCalculation(data.value?.[0]);
      }
    }
    setLoadingCalculations(false);
  };

  const determineValue = (id: string, type: string, postfix: any = undefined) => {
    if (calculation) {
      if (type === "remainingUsage" && calculation.quantityNotApplicable === true) return "N/A";
      else if (type === "remainingDays" && (calculation.quantityNotApplicable === true || calculation.rateNotApplicable === true)) return "N/A";
      return calculation[type];
    } else return undefined;
  };

  const getWellsForWaterRight = async () => {
    setLoading(true);
    const response = await getWellsFromWaterRightId(overrideId ?? id);
    if (response.ok) {
      const data = await response.json();

      const fieldIds: any = [];

      data.value.forEach((well: any) => {
        fieldIds.push(well.fieldId);
      });

      setFields(fieldIds);
    }
    setLoading(false);
  };

  const getDocumentsListData = async () => {
    if (canEdit) {
      setLoadingDocuments(true);

      const request = {
        waterRightId: overrideId ?? id,
        showArchived,
      };

      const response = await getWaterRightDocuments(request);
      if (response.ok) {
        const data = await response.json();
        setDocuments(data.value);
      }
      setLoadingDocuments(false);
    }
  };

  const getWaterRightData = async () => {
    setLoading(true);
    const response = await getWaterRight(overrideId ?? id);
    if (response.ok) {
      const data = await response.json();
      setWaterRightData(data.value);
    }
    setLoading(false);
  };

  const getWaterRightPlaceOfUseData = async () => {
    setLoadingPlaceOfUse(true);
    const response = await getWaterRight(overrideId ?? id);
    if (response.ok) {
      const data = await response.json();
      setWaterRightData(data.value);
    }
    setLoadingPlaceOfUse(false);
  };

  const navigateEdit = () => {
    dispatch(
      addBreadcrumb({
        type: `Edit`,
        url: `waterright/${id}/edit`,
      })
    );
    navigate(routes.waterRightEdit, { id: overrideId ?? id });
  };

  const navigateBack = () => {
    dispatch(removeBreadcrumb());
    navigate(-1);
  };

  const handleDateCancel = () => {
    setDatesModalState({
      ...datesModalState,
      open: false,
      dateId: undefined,
      waterRightId: undefined,
    });
  };

  const handleDateSuccess = () => {
    handleDateCancel();
    message.success(`Updated the dates successfully`);
    getDateData();
  };

  const handleViewRestriction = (record: any) => {
    navigate(routes.restrictionView, { id: record.id });
  };

  const handleDownloadDocument = async (record: any, onCompleted: () => void) => {
    try {
      if (!record.fileData) {
        const response = await downloadDocument(record.id);
        if (response.ok) {
          const data = await response.json();
          if (data.isSuccess) {
            openFileFromGeneratedFile(data.value);
          }
        } else {
          message.error("Failed to download document");
        }
      } else openFileFromGeneratedFile(record);
    } catch {
      message.error("Failed to download document");
    }
    onCompleted();
  };

  const getDateData = async () => {
    setLoadingDates(true);
    const response = await getDate(waterRightData?.id);
    if (response.ok) {
      const data = await response.json();
      setDateData(data.value);
    }
    setLoadingDates(false);
  };

  const handleArchiveDocument = async (id: any) => {
    const response = await archiveDocument(id);

    if (response.ok) {
      message.success("Validated reading successfully");
      getDocumentsListData();
    } else {
      message.error("Failed to validate reading");
    }
  };

  const handleRefresh = () => {
    // getWaterRightData();
    refreshCalculation();
  };

  const calculateDocumentsColumns = () => {
    let tempColumns = [
      {
        title: "Upload Date",
        key: "date",
        dataIndex: "date",
        width: 150,
        render: (val: any, record: any) => dayjs(record.date).format(constants.dateTimeFormat),
      },
      {
        title: "Uploaded By",
        key: "userName",
        dataIndex: "userName",
        width: 200,
      },
      {
        title: "File Name",
        key: "fileName",
        dataIndex: "fileName",
        render: (val: any, record: any) => <DownloadDocumentButton buttonText={getFileName(record)} record={record} handleDownloadDocument={handleDownloadDocument} />,
      },
      {
        title: "Notes",
        key: "note",
        dataIndex: "note",
        render: (val: any, record: any) => val,
      },
      {
        title: "Actions",
        key: "action",
        width: 200,
        render: (value: any, record: any) => (
          <>
            <Popconfirm
              title={record.archived === true ? "Restore the document" : "Archive the document"}
              description={record.archived === true ? "Are you sure you want to restore the document?" : "Are you sure you want to archive the document?"}
              // description="Are you sure you want to validate this well reading?"
              onConfirm={() => handleArchiveDocument(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button style={{ paddingLeft: 0, paddingRight: 10 }} type="link">
                {record.archived === true ? "Restore" : "Archive"}
              </Button>
            </Popconfirm>
            {` |  `}
            <DownloadDocumentButton buttonText="Download" record={record} handleDownloadDocument={handleDownloadDocument} />
          </>
        ),
      },
    ];

    if (showArchived)
      tempColumns.splice(4, 0, {
        title: "Archived",
        key: "archived",
        dataIndex: "archived",
        render: (val: any) => <StatusTag status={val ? "Archived" : "NotArchived"} />,
      });

    setDocumentsColumns(tempColumns);
  };

  const handleDocumentUploadCancel = () => {
    setDocumentUploadModalState({
      ...documentUploadModalState,
      open: false,
    });
  };

  const handleDocumentUploadSuccess = () => {
    handleDocumentUploadCancel();

    message.success("Uploaded document successfully");

    setLoading(true);
    getDocumentsListData();
    setLoading(false);
  };

  const renderTabs = () => {
    let tabs = [
      {
        label: "Details",
        key: "details",
        children: (
          <Descriptions bordered size="small">
            <Descriptions.Item label="Legal">
              State: {waterRightData?.state ? <LookupLabel lookupType="states" value={waterRightData?.state} /> : "-"} <br />
              Section: {waterRightData?.section ?? "-"} <br />
              Township: {waterRightData?.township ?? "-"} {waterRightData?.townshipDirection}
              <br />
              Range: {waterRightData?.range ?? "-"} {waterRightData?.rangeDirection}
            </Descriptions.Item>
            <Descriptions.Item label="Subject To MDS">
              <StatusTag status={waterRightData?.subjectToMds ? "Yes" : "No"} color={waterRightData?.subjectToMds ? "blue" : "green"} />
            </Descriptions.Item>
            <Descriptions.Item label="Permit Type">
              <LookupLabel lookupType="permitType" value={waterRightData?.permitType} />
            </Descriptions.Item>
            <Descriptions.Item label="Priority Type">
              <LookupLabel lookupType="priorityType" value={waterRightData?.priorityType} />
            </Descriptions.Item>
            <Descriptions.Item label="System Type">
              <LookupLabel lookupType="systemType" value={waterRightData?.systemType} />
            </Descriptions.Item>
            <Descriptions.Item label="Crop Type">
              <LookupLabel lookupType="cropType" value={waterRightData?.cropType} />
            </Descriptions.Item>
            <Descriptions.Item label="Energy Type">
              <LookupLabel lookupType="energyType" value={waterRightData?.energyType} />
            </Descriptions.Item>
            <Descriptions.Item label="Owner">
              <LookupLabel lookupType="owner" value={waterRightData?.owner} />
            </Descriptions.Item>
            <Descriptions.Item label="Water Source">
              <LookupLabel lookupType="watersource" value={waterRightData?.waterSource} />
            </Descriptions.Item>
            {waterRightData?.state === "kansas" && (
              <Descriptions.Item label="Ground Management District">
                <LookupLabel lookupType="gmd" value={waterRightData?.gmd} />
              </Descriptions.Item>
            )}
            {waterRightData?.state === "nebraska" && (
              <Descriptions.Item label="Natural Resources District">
                <LookupLabel lookupType="nrd" value={waterRightData?.nrd} />
              </Descriptions.Item>
            )}
            <Descriptions.Item label="End Guns">{waterRightData?.endGuns}</Descriptions.Item>
          </Descriptions>
        ),
      },
      {
        label: "Wells",
        key: "wells",
        children: (
          <WellTable
            refreshWaterRightCalculations={refreshWaterRightCalculations}
            refreshWaterRightViewCalculations={refreshWaterRightViewCalculations}
            addWell={false}
            header
            waterRightId={overrideId ?? id}
          />
        ),
      },
      {
        label: "Fields",
        key: "fields",
        children: <FieldTable showAddField={false} fieldIds={fields} />,
      },
      {
        label: "Restrictions",
        key: "restrictions",
        children: <RestrictionTable showAddRestriction={false} showHeader waterRightId={overrideId ?? id} />,
      },
      {
        label: "Places of Use",
        key: "placeOfUse",
        children: (
          <PlaceOfUseTable companyId={waterRightData?.companyId} places={waterRightData?.placesOfUse ?? []} waterRightId={overrideId ?? id} refreshWaterRightData={refreshWaterRightPlaceOfUseData} />
        ),
      },
      {
        label: "Adjustments",
        key: "adjustments",
        children: (
          <AdjustmentTable
            companyId={waterRightData?.companyId}
            // refreshWaterRightCalculations={refreshWaterRightCalculations}
            refreshWaterRightViewCalculations={refreshWaterRightViewCalculations}
            waterRightId={overrideId ?? id}
            canEdit={canEdit}
          />
        ),
      },
      {
        label: "Dates",
        key: "dates",
        children: (
          <Card
            title="Dates"
            loading={loadingDates}
            id="waterRightDates"
            extra={
              <>
                <Button icon={<ReloadOutlined />} onClick={getDateData}>
                  Refresh
                </Button>
                {canEdit && (
                  <Button
                    style={{ marginLeft: 5 }}
                    icon={<EditOutlined />}
                    type="primary"
                    onClick={() =>
                      setDatesModalState({
                        ...datesModalState,
                        open: true,
                        dateId: dateData?.id,
                      })
                    }
                  >
                    Edit
                  </Button>
                )}
              </>
            }
          >
            <Descriptions bordered size="small" layout="horizontal" style={{ width: 400 }} column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
              <Descriptions.Item label="Perfect By">{dateData?.perfectBy ? dayjs(dateData?.perfectBy).format(constants.dateFormat) : "N/A"}</Descriptions.Item>
              <Descriptions.Item label="Certificate Issued">{dateData?.certificateIssued ? dayjs(dateData?.certificateIssued).format(constants.dateFormat) : "N/A"}</Descriptions.Item>
              <Descriptions.Item label="Complete By">{dateData?.completeBy ? dayjs(dateData?.completeBy).format(constants.dateFormat) : "N/A"}</Descriptions.Item>
              <Descriptions.Item label="Completion Acknowledged">{dateData?.completionAck ? dayjs(dateData?.completionAck).format(constants.dateFormat) : "N/A"}</Descriptions.Item>
              <Descriptions.Item label="Base Acres">{dateData?.baseAcres ?? "N/A"}</Descriptions.Item>
            </Descriptions>

            {datesModalState.open && (
              <DatesAddEditModal
                waterRightId={waterRightData?.id}
                waterRightFileNumber={waterRightData?.fileNumber}
                onSuccess={handleDateSuccess}
                open={datesModalState.open}
                onCancel={handleDateCancel}
                dateId={dateData?.id}
              />
            )}
          </Card>
        ),
      },
    ];

    if (canEdit)
      tabs.push({
        label: "Documents",
        key: "documents",
        children: (
          <Card
            styles={{ body: { padding: 0 } }}
            title="Documents"
            loading={loadingDocuments}
            id="waterRightDocuments"
            extra={
              <>
                <Space direction="horizontal" style={{ width: "100%" }}>
                  <Typography style={{ paddingRight: 3 }}>Show Archived:</Typography>
                  <Switch checked={showArchived} onChange={(checked) => setShowArchived(checked)} checkedChildren="Yes" unCheckedChildren="No" style={{ marginRight: 30 }} />
                  <Button icon={<ReloadOutlined />} onClick={getDocumentsListData}>
                    Refresh
                  </Button>
                  <Button
                    style={{ marginLeft: 5 }}
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      setDocumentUploadModalState({
                        ...documentUploadModalState,
                        open: true,
                      })
                    }
                  >
                    Add Document
                  </Button>
                </Space>
              </>
            }
          >
            <Table loading={loading} columns={documentsColumns} dataSource={documents} size="small" />
            {documentUploadModalState.open && (
              <DocumentsAddModal waterRightId={waterRightData?.id} onSuccess={handleDocumentUploadSuccess} open={documentUploadModalState.open} onCancel={handleDocumentUploadCancel} />
            )}
          </Card>
        ),
      });
    tabs.push({
      label: "General Notes",
      key: "notes",
      children: <NotesTable waterRightId={waterRightData?.id} />,
    });

    if (canEdit)
      tabs.push({
        label: "Audit Log",
        key: "auditLog",
        children: <AuditLogTable entityId={waterRightData?.id} entityType={AuditLogType.WaterRight} />,
      });

    return tabs;
  };

  const renderContent = () => {
    return (
      <div>
        <Row>
          <Col>
            <Descriptions style={{ paddingLeft: 0, width: 600 }} bordered size="small" labelStyle={{ width: 120 }} column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
              <Descriptions.Item
                label={
                  <>
                    Water Right /<br />
                    File Number
                  </>
                }
              >
                {waterRightData?.fileNumber}{" "}
                {(waterRightData?.internalTags?.length > 0 || waterRightData?.externalTags?.length > 0) && (
                  <WaterRightTagList internalTags={waterRightData?.internalTags} externalTags={waterRightData?.externalTags} />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="PDIV">{waterRightData?.pdiv ? waterRightData?.pdiv : "-"}</Descriptions.Item>
              <Descriptions.Item label="CIN">{waterRightData?.cin ? waterRightData?.cin : "-"}</Descriptions.Item>
              <Descriptions.Item label="Status">
                <StatusTag status={waterRightData?.status} />
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col>
            <Descriptions style={{ paddingLeft: 0 }} bordered size="small" labelStyle={{ width: 150 }} column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
              <Descriptions.Item label="Authorized">
                Irrigation Acres:{" "}
                {waterRightData?.authorizedIrrigationNotApplicable === true ? "N/A" : waterRightData?.authorizedIrrigationAcres ? `${waterRightData?.authorizedIrrigationAcres} Acres` : "-"}
                <br />
                Quantity:{" "}
                {waterRightData?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <>
                    {waterRightData?.authorizedQuantity} <LookupLabel lookupType="meterUnits" value={waterRightData?.authorizedQuantityUnits} />
                  </>
                )}
                <br />
                Rate: {waterRightData?.authorizedRateNotApplicable === true ? "N/A" : waterRightData?.authorizedRate ? `${waterRightData?.authorizedRate} GPM` : "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Actual Usage">
                Irrigation Acres:{" "}
                {waterRightData?.authorizedIrrigationNotApplicable === true
                  ? "N/A"
                  : waterRightData?.usageIrrigationAcres
                    ? `${waterRightData?.usageIrrigationAcres} Acres`
                    : waterRightData?.authorizedIrrigationAcres
                      ? `${waterRightData?.authorizedIrrigationAcres} Acres`
                      : "-"}
                <br />
                Quantity:{" "}
                {waterRightData?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : waterRightData?.usageQuantity ? (
                  <>
                    {waterRightData?.usageQuantity} <LookupLabel lookupType="meterUnits" value={waterRightData?.usageQuantityUnits} />
                  </>
                ) : (
                  <>
                    {waterRightData?.authorizedQuantity} <LookupLabel lookupType="meterUnits" value={waterRightData?.authorizedQuantityUnits} />
                  </>
                )}
                <br />
                Rate:{" "}
                {waterRightData?.authorizedRateNotApplicable === true
                  ? "N/A"
                  : waterRightData?.usageRate
                    ? `${waterRightData?.usageRate} GPM`
                    : waterRightData?.authorizedRate
                      ? `${waterRightData?.authorizedRate} GPM`
                      : "-"}
              </Descriptions.Item>
              {determineValue(overrideId ?? (id as string), "penaltyQuantity") > 0 && (
                <Descriptions.Item label="Penalty">
                  <UnitsConverter
                    fromUnits="gallons"
                    toUnits={companyConfig?.settings?.metric ?? "acrefeet"}
                    value={determineValue(overrideId ?? (id as string), "penaltyQuantity")}
                    showUnitsLabel
                    placeholder="Loading..."
                  />
                </Descriptions.Item>
              )}
              {waterRightData?.subjectToMds && (
                <Descriptions.Item label="MDS">
                  Irrigation Acres:{" "}
                  {waterRightData?.authorizedIrrigationNotApplicable === true
                    ? "N/A"
                    : waterRightData?.usageIrrigationAcres
                      ? `${waterRightData?.usageIrrigationAcres} Acres`
                      : waterRightData?.authorizedIrrigationAcres
                        ? `${waterRightData?.authorizedIrrigationAcres} Acres`
                        : "-"}
                  <br />
                  Quantity:{" "}
                  {waterRightData?.authorizedQuantityNotApplicable === true ? (
                    "N/A"
                  ) : (
                    <>
                      <UnitsConverter
                        value={determineValue(overrideId ?? (id as string), "availableQuantity") + determineValue(overrideId ?? (id as string), "transferQuantity")}
                        fromUnits={"gallons"}
                        toUnits={companyConfig?.settings?.metric ?? "acrefeet"}
                        showUnitsLabel
                      />
                    </>
                  )}
                  <br />
                  Rate:{" "}
                  {waterRightData?.authorizedRateNotApplicable === true
                    ? "N/A"
                    : waterRightData?.usageRate
                      ? `${waterRightData?.usageRate} GPM`
                      : waterRightData?.authorizedRate
                        ? `${waterRightData?.authorizedRate} GPM`
                        : "-"}
                </Descriptions.Item>
              )}
              {calculation && (
                <>
                  <Descriptions.Item label="Transferred">
                    <UnitsConverter
                      fromUnits="gallons"
                      toUnits={companyConfig?.settings?.metric ?? "acrefeet"}
                      value={determineValue(overrideId ?? (id as string), "transferQuantity")}
                      showUnitsLabel
                      placeholder="Loading..."
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Used">
                    <UnitsConverter
                      fromUnits="gallons"
                      toUnits={companyConfig?.settings?.metric ?? "acrefeet"}
                      value={determineValue(overrideId ?? (id as string), "currentUsage")}
                      showUnitsLabel
                      placeholder="Loading..."
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Remaining Usage">
                    <UnitsConverter
                      fromUnits="gallons"
                      toUnits={companyConfig?.settings?.metric ?? "acrefeet"}
                      value={determineValue(overrideId ?? (id as string), "remainingUsage")}
                      showUnitsLabel
                      placeholder="Loading..."
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Remaining Days">
                    <RemainingDays remainingDays={determineValue(overrideId ?? (id as string), "remainingDays")} placeholder="Loading..." />
                  </Descriptions.Item>
                </>
              )}
            </Descriptions>
          </Col>
          {waterRightData?.disableWarnings && (
            <Col>
              <Descriptions style={{ paddingLeft: 0, width: 600 }} bordered size="small" labelStyle={{ width: 0 }} column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                <Descriptions.Item>
                  <Alert
                    type="error"
                    showIcon
                    message="Warnings Disabled"
                    icon={<WarningOutlined />}
                    description={
                      <>
                        This water right has been marked as having warnings disabled. This means that the system will not send any warning notifications for this water right. <br />
                        To re-enable warnings, click the 'Edit' button above and uncheck the 'Disable Warnings' checkbox. <br />
                        <br />
                        Acknowledged By: {waterRightData?.markedAsDisabledUserUserName} <br />
                        Date: {dayjs(waterRightData?.markedAsDisabledDate).format(constants.dateTimeFormat)}
                      </>
                    }
                  />
                </Descriptions.Item>
              </Descriptions>
            </Col>
          )}
          {calculation && (
            <Col>
              <RadialGauge
                quantityNotApplicable={calculation?.quantityNotApplicable}
                usedQuantity={determineValue(overrideId ?? (id as string), "currentUsage")}
                availableQuantity={determineValue(overrideId ?? (id as string), "availableQuantity")}
                remainingDays={determineValue(overrideId ?? (id as string), "remainingDays")}
                style={{ paddingLeft: 14, width: 320 }}
                companyId={waterRightData?.companyId}
              />
              {/* <QuantityGauge
              warningQuantity={10}
              warningQuantityUnits={'acrefeet'}
              availableQuantity={determineValue(overrideId ?? id as string, 'availableQuantity')}
              usedQuantity={determineValue(overrideId ?? id as string, 'currentUsage')}
            /> */}
            </Col>
          )}
        </Row>
        <br />
        <Tabs defaultActiveKey="1" type="card" size="small" style={{ marginBottom: 32 }} items={renderTabs()} />
      </div>
    );
  };

  return (
    <Card
      className="waterRightView"
      loading={loading}
      id="waterRightView"
      title={showHeader ? `Water Right - ${loading ? "loading..." : waterRightData?.fileNumber}` : undefined}
      extra={
        showHeader ? (
          <>
            <Button style={{ marginRight: 5 }} onClick={navigateBack} icon={<LeftOutlined />}>
              Back
            </Button>
            {canEdit && (
              <Button type="primary" onClick={navigateEdit} icon={<EditOutlined />}>
                Edit
              </Button>
            )}
          </>
        ) : undefined
      }
    >
      {showHeader ? <PrintPage content={renderContent()} /> : renderContent()}
    </Card>
  );
};

export default WaterRightView;
