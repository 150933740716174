import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Alert, Button, Card, DatePicker, Divider, Form, Select, Space, Switch } from "antd";
import { useWatch } from "antd/es/form/Form";
import { WaterRightReportSelector, WaterRightSelector } from "@/components";
import { constants } from "@/configs";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GeneratedDetailedEndOfYearWaterUsageReport from "./GeneratedDetailedEndOfYearWaterUsageReport";

const DetailedEndOfYearWaterUsageReport: FC = () => {
  const { lookups } = useSelector((state: any) => state.lookups);
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [form] = Form.useForm();

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [reportConfig, setReportConfig] = useState<any>(undefined);
  const [waterRights, setWaterRights] = useState<any[]>([]);

  const waterRightIds: any[] = useWatch("waterRightIds", form);
  const permitTypes: any[] = useWatch("permitTypes", form);
  const priorityTypes: any[] = useWatch("priorityTypes", form);
  const date: any = useWatch("date", form);
  const showNotes: boolean = useWatch("showNotes", form);
  const showGPM: boolean = useWatch("showGPM", form);
  const interpolate: boolean = useWatch("interpolate", form);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    const permitTypeLookups = lookups?.find((lookup: any) => lookup.map === "permittype");
    const priorityTypeLookups = lookups?.find((lookup: any) => lookup.map === "prioritytype");

    setReportConfig({
      permitTypes,
      priorityTypes,
      permitTypeNames: permitTypeLookups?.options
        ?.filter((option: any) => permitTypes?.includes(option.value))
        ?.sort((a: any, b: any) =>
          a?.name?.localeCompare(b?.name, "en", {
            numeric: true,
            sensitivity: "base",
          })
        )
        ?.map((option: any) => option.name)
        .join(", "),
      priorityTypeNames: priorityTypeLookups?.options
        ?.filter((option: any) => priorityTypes?.includes(option.value))
        ?.sort((a: any, b: any) =>
          a?.name?.localeCompare(b?.name, "en", {
            numeric: true,
            sensitivity: "base",
          })
        )
        ?.map((option: any) => option.name)
        .join(", "),
      waterRightIds,
      waterRights: waterRights
        .filter((waterRight: any) => waterRightIds?.includes(waterRight.id))
        ?.sort((a: any, b: any) =>
          a?.fileNumber?.localeCompare(b?.fileNumber, "en", {
            numeric: true,
            sensitivity: "base",
          })
        )
        ?.map((waterRight: any) => waterRight.fileNumber)
        .join(", "),
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
      year: date?.year(),
      showNotes: showNotes,
      showGPM: showGPM,
      interpolate,
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }} labelWrap={true}>
          <Form.Item label="Year" name="date" rules={[{ required: true, message: "Please enter a year" }]} style={{ margin: 0, marginBottom: 10 }} initialValue={dayjs()}>
            <DatePicker picker="year" style={{ marginRight: 30 }} placeholder="All Readings" allowClear={false} />
          </Form.Item>
          <WaterRightReportSelector
            form={form}
            propertyToSet={"waterRightIds"}
            label={"Water Rights"}
            setWaterRights={setWaterRights}
            placeholder="Leave blank to select all water rights"
            style={{ margin: 0, marginBottom: 0 }}
          />
          <Form.Item label="Permit Type" name="permitTypes" style={{ margin: 0, paddingTop: 10 }}>
            <Select
              allowClear
              mode="multiple"
              placeholder="Leave blank to select all permit types"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "permitTypes",
                          lookups.find((lookup: any) => lookup.map === "permittype").options.map((lookup: any) => lookup.value)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("permitTypes", [])}>
                      Clear List
                    </Button>
                  </Space>
                </>
              )}
            >
              {lookups
                ?.find((lookup: any) => lookup.map === "permittype")
                .options.map((lookup: any) => {
                  return (
                    <Select.Option value={lookup.value} key={lookup.id} label={lookup.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {lookup.name}
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item label="Priority Type" name="priorityTypes" style={{ margin: 0, paddingTop: 10 }}>
            <Select
              allowClear
              mode="multiple"
              placeholder="Leave blank to select all priority types"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "priorityTypes",
                          lookups.find((lookup: any) => lookup.map === "prioritytype").options.map((lookup: any) => lookup.value)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("priorityTypes", [])}>
                      Clear List
                    </Button>
                  </Space>
                </>
              )}
            >
              {lookups
                ?.find((lookup: any) => lookup.map === "prioritytype")
                .options.map((lookup: any) => {
                  return (
                    <Select.Option value={lookup.value} key={lookup.id} label={lookup.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {lookup.name}
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item name="showNotes" label="Show Notes" valuePropName="checked" style={{ margin: 0, paddingTop: 10 }}>
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
          <Form.Item name="showGPM" label="Show GPM calculations" valuePropName="checked" style={{ margin: 0, paddingTop: 10 }}>
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
          <Form.Item name="interpolate" label="Assume continuous usage for Stock Water calculations" valuePropName="checked" style={{ margin: 0, paddingTop: 10 }}>
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 14, offset: 6 }} style={{ paddingTop: 10 }}>
            <Alert
              message={interpolate ? "Continuous Usage" : "Allocated Usage"}
              description={
                <>
                  {interpolate && (
                    <>
                      The assumption will be made that water usage is continuous between readings.
                      <br />
                      Therefore, water usage will be spread equally across the time between reading dates to give a more accurate breakdown of water usage.
                      <br />
                      <br />
                      Example:
                      <br />
                      Reading 1: 31st of January 2022 at 10:00 AM with a meter reading of 0<br />
                      Reading 2: 1st of February 2022 at 11:00 AM with a meter reading of 100
                      <br />
                      <br />
                      Thus the time between readings is 25 hours.
                      <br />
                      100 units of water were used in 25 hours.
                      <br />
                      100 / 25 = 4 units of water per hour.
                      <br />
                      <br />
                      14 hours of water usage will be allocated to the month of January 2022 and 11 hours of water usage will be allocated to the month of February 2022.
                      <br />
                      January Usage: 14 * 4 = 56 units
                      <br />
                      February Usage: 11 * 4 = 44 units
                      <br />
                      <br />
                      This calculation will be accurate to the second.
                      <br />
                      <br />
                      Please note that the timezone setting for the company will be used to calculate the time between readings and allocate usage to the correct month.
                      <br />
                      <br />
                      ** This setting is only applicable to the Stock Water calculations
                    </>
                  )}
                  {!interpolate && (
                    <>
                      Water usage is allocated in the month it was recorded or allocated in.
                      <br />
                      <br />
                      Example:
                      <br />
                      Reading 1: 1st of January 2022 with a meter reading of 0
                      <br />
                      Reading 2: 1st of February 2022 with a meter reading of 100
                      <br />
                      <br />
                      Therefore 100 units of water will be allocated to the month of February 2022.
                      <br />
                      <br />
                      ** This setting is only applicable to the Stock Water calculations
                    </>
                  )}
                </>
              }
              type="info"
              showIcon
            />
          </Form.Item>
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedDetailedEndOfYearWaterUsageReport reportConfig={reportConfig} />}
    </>
  );
};

export default DetailedEndOfYearWaterUsageReport;
