import { Badge, Button, Popover, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";

const renderMenuItem = (collapsed: boolean, item: any, parent?: any, position?: string, count?: number, badgeColor?: string) => {
  return (
    <Popover
      placement={position === "bottom" ? "rightBottom" : "rightTop"}
      trigger="hover"
      content={
        <div style={{ minWidth: 140, minHeight: 40 }}>
          <Typography.Title level={5} style={{ margin: 0, paddingLeft: 10, paddingTop: 10 }}>
            {item.label}
          </Typography.Title>
          {item.children?.map((child: any) => renderMenuItem(collapsed, child, item, position))}
        </div>
      }
      overlayStyle={item.children?.length > 0 || collapsed ? undefined : { display: "none" }}
      showArrow={false}
      overlayInnerStyle={{ padding: 0, paddingBottom: 5 }}
      key={item.key}
    >
      {collapsed && !parent ? (
        <Badge
          dot
          count={count ?? 0}
          overflowCount={99}
          offset={[-15, 8]}
          style={{ backgroundColor: badgeColor }}
          styles={{
            indicator: {
              backgroundColor: badgeColor,
            },
            root: {
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 40,
            },
          }}
        >
          <Button type="text" icon={item.icon} onClick={item.onClick} />
        </Badge>
      ) : (
        <Button
          disabled={item.disabled ?? true}
          type="text"
          icon={item.icon}
          style={{
            textAlign: "left",
            height: 40,
            margin: "5px 5px 0px 5px",
            width: "calc(100% - 10px)",
            pointerEvents: item.type === "text" ? "none" : "auto",
          }}
          onClick={item.onClick}
        >
          {item.label}
          {item.children && !item.disabled && (
            <span style={{ float: "right" }}>
              <RightOutlined />
            </span>
          )}
          <span style={{ float: "right" }}>
            <Badge count={count ?? 0} offset={[5, 0]} overflowCount={99} style={{ backgroundColor: badgeColor }} />
          </span>
        </Button>
      )}
    </Popover>
  );
};

export default renderMenuItem;
