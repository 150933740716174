import { Button, Card, Divider, List, Typography } from "antd";
import React, { FC } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import LookupLabel from "../LookupLabel/LookupLabel";

type LookupHistory = {
  lookups: any[];
};

const MapPermitTypeLookupHistory: FC<LookupHistory> = ({ lookups }) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Card
      style={{ padding: 0, margin: 0 }}
      styles={{
        body: {
          padding: 0,
          margin: 0,
          width: 200,
          overflow: "hidden",
        },
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", cursor: "pointer", paddingLeft: 10, paddingTop: 5 }}
        onClick={() => setExpanded((current) => !current)}
      >
        <Typography.Title level={5}>Permit Types</Typography.Title>
        {lookups?.length > 1 && <Button type="link" icon={expanded ? <UpOutlined /> : <DownOutlined />} />}
      </div>
      <Divider style={{ margin: 0 }} />
      <List
        size="small"
        dataSource={expanded ? lookups : lookups.slice(0, 1)}
        renderItem={(item) => (
          <List.Item>
            <LookupLabel lookupType={"permittype"} value={item} />
          </List.Item>
        )}
        style={{ maxHeight: 400, overflow: "auto" }}
      />
    </Card>
  );
};

export default MapPermitTypeLookupHistory;
