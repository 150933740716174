import { Card, Checkbox, message, Modal, Table, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { acceptCompanyInvitesForUser, getCompanies, getUserOutstandingCompanyInvites } from "@/apis/company.api";
import { UserRole } from "@/dtos/user.dto";
import { FC, useEffect, useState } from "react";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { useAppDispatch } from "@/stores";
import { useSelector } from "react-redux";
import { saveCompanies, saveSelectedCompany, saveSelectedCompanyId, setReloadCompanies, setCanSwitch, setBusyEditing } from "@/stores/company.store";

interface OutstandingCompanyInvitesProps {
  onClose?: () => void;
}

const OutstandingCompanyInvites: FC<OutstandingCompanyInvitesProps> = (props: OutstandingCompanyInvitesProps) => {
  const { onClose } = props;

  const [open, setOpen] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [acceptLoading, setAcceptLoading] = useState<boolean>(false);
  const [outstandingInvites, setOutstandingInvites] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [checked, setChecked] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { companies, selectedCompany, selectedCompanyId, reload, canSwitch, busyEditing } = useSelector((state: any) => state.company);

  useEffect(() => {
    refreshCompanyInvites();
  }, []);

  useEffect(() => {
    calculateColumns();
    setSelectedRowKeys(outstandingInvites.map((item: any) => item.id));
    // eslint-disable-next-line
  }, [outstandingInvites]);

  const reloadCompanies = async () => {
    let result = await getCompanies(undefined, null);
    if (result.ok) {
      result.json().then((data) => {
        if (data.isSuccess) {
          if (data.value.length > 0) dispatch(saveCompanies(data.value));
          dispatch(setReloadCompanies(false));
        }
      });
    }
  };

  const refreshCompanyInvites = async () => {
    setLoading(true);
    const response = await getUserOutstandingCompanyInvites();
    if (response.ok) {
      const data = await response.json();

      if (data.isSuccess) {
        const newData: any = [];

        for (let index = 0; index < data.value.length; index++) {
          const element = data.value[index];
          element.key = element.companyId;
          newData.push(element);
        }

        setOutstandingInvites(data.value);
      }
    }
    setLoading(false);
  };

  const handleCompanyInvites = async () => {
    setAcceptLoading(true);
    const response = await acceptCompanyInvitesForUser(selectedRowKeys);
    if (response.ok) {
      const data = await response.json();

      if (data.isSuccess) {
        message.success("Successfully accepted company invites");
        reloadCompanies();
        handleClose();
      } else {
        message.error("Failed to accept company invites");
      }
    }
    setAcceptLoading(false);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onCheckChange = (e: CheckboxChangeEvent) => {
    setChecked(!checked);
  };

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Company Name",
        key: "companyName",
        dataIndex: "companyName",
        fixed: "left",
        render: (val: any, record: any) => val,
      },
      {
        title: "Role",
        key: "role",
        dataIndex: "role",
        render: (val: any, record: any) => UserRole[val]?.label,
      },
    ];

    setColumns(tempColumns);
  };

  const checkDisabled = () => {
    if (checked === false || hasSelected === false) return true;
    else return false;
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <Modal
      maskClosable={false}
      open={open}
      okText="Accept Invites"
      onOk={handleCompanyInvites}
      okButtonProps={{
        disabled: checkDisabled(),
        loading: acceptLoading,
        icon: <ThunderboltOutlined />,
      }}
      cancelText="Cancel"
      onCancel={handleClose}
      cancelButtonProps={{ icon: <CloseOutlined /> }}
      width={700}
      closable={false}
    >
      <Card loading={loading} title="Outstanding Company Invites" className="removeBoxShadow removeMargin">
        <Typography.Paragraph>Please select or deselect the company invites you would like to accept below</Typography.Paragraph>
        <Table
          id="outstandingCompanyInviteTable"
          style={{ padding: 0 }}
          pagination={false}
          loading={loading}
          columns={columns}
          dataSource={outstandingInvites || []}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          size="small"
          bordered={true}
          rowKey={(row: any) => row.id}
        />
        <br />
        <Checkbox style={{ marginLeft: 8 }} onChange={onCheckChange}>
          By accepting the company invite(s), you agree to share your Name, Email Address and Phone Number with the Company.
        </Checkbox>
      </Card>
    </Modal>
  );
};

export default OutstandingCompanyInvites;
