import { Col, Row, Image } from "antd";
import { PageContent, PageHeader } from "@/components";
import PageFooter from "@/components/PageComponents/PageFooter/PageFooter";
import { FC } from "react";

const EthicsSustainability: FC = () => {
  return (
    <div id="ethics-sustainability">
      <PageHeader title="Ethics & Sustainability" backgroundImageUri="images/VandWater-Banner.png" />
      <PageContent
        content={
          <div style={{ maxWidth: 1300 }}>
            <Row gutter={[30, 30]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div>
                  <h1
                    style={{
                      wordWrap: "break-word",
                      lineHeight: 1.5,
                      boxSizing: "border-box",
                      margin: 0,
                      paddingBottom: 10,
                      border: 0,
                      outline: 0,
                      verticalAlign: "baseline",
                      background: "transparent",
                      fontWeight: 700,
                      fontSize: "27px",
                      color: "#00007F",
                    }}
                  >
                    ETHICS STATEMENT
                  </h1>
                  <p
                    style={{
                      color: "#666",
                      lineHeight: "1.7em",
                      fontWeight: 400,
                      wordWrap: "break-word",
                      textAlign: "justify",
                      boxSizing: "border-box",
                      margin: 0,
                      border: 0,
                      outline: 0,
                      fontSize: "20px",
                      verticalAlign: "baseline",
                      background: "transparent",
                    }}
                  >
                    VandWater and its employees will always demonstrate the highest levels of integrity, truthfulness, honesty, and objectivity to uphold both personal and corporate reputations and to
                    inspire confidence and trust in their respective actions.
                  </p>
                  <p
                    style={{
                      color: "#666",
                      lineHeight: "1.7em",
                      fontWeight: 400,
                      wordWrap: "break-word",
                      textAlign: "justify",
                      boxSizing: "border-box",
                      margin: 0,
                      border: 0,
                      outline: 0,
                      fontSize: "20px",
                      verticalAlign: "baseline",
                      background: "transparent",
                    }}
                  >
                    Our company will conduct its business in a competent, fair, impartial, respectful, and efficient manner. We believe that how we conduct ourselves, both personally and in business,
                    is critical to VandWater success and is the foundation of our company’s structure.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div style={{ width: "100%", textAlign: "center", marginTop: 10 }}>
                  <Image
                    style={{
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      alignContent: "center",
                      maxHeight: 510,
                      maxWidth: 510,
                      borderRadius: 10,
                      objectFit: "contain",
                    }}
                    src="images/Ethics.webp"
                    preview={false}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div style={{ width: "100%", textAlign: "center", marginTop: 10 }}>
                  <Image
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      alignContent: "center",
                      maxHeight: 510,
                      maxWidth: "635px",
                      borderRadius: 10,
                      objectFit: "contain",
                    }}
                    src="images/Sustainability.webp"
                    preview={false}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div>
                  <h1
                    style={{
                      wordWrap: "break-word",
                      lineHeight: 1.5,
                      boxSizing: "border-box",
                      margin: 0,
                      paddingBottom: 10,
                      border: 0,
                      outline: 0,
                      verticalAlign: "baseline",
                      background: "transparent",
                      fontWeight: 700,
                      fontSize: "27px",
                      color: "#00007F",
                    }}
                  >
                    SUSTAINABILITY STATEMENT
                  </h1>
                  <p
                    style={{
                      color: "#666",
                      lineHeight: "1.7em",
                      fontWeight: 400,
                      wordWrap: "break-word",
                      textAlign: "justify",
                      boxSizing: "border-box",
                      margin: 0,
                      border: 0,
                      outline: 0,
                      fontSize: "20px",
                      verticalAlign: "baseline",
                      background: "transparent",
                      paddingBottom: "1em",
                    }}
                  >
                    VandWater is committed as a company, community member, and environmental steward to maximizing sustainability within all operations. Engagement on sustainability issues with
                    customers, employees, and suppliers allows VandWater to continuously strive towards increased awareness and positive improvement in pursuit of the best ecological practices from
                    local to global.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        }
      />
      <PageFooter />
    </div>
  );
};

export default EthicsSustainability;
