import { FC, ReactNode } from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import { SingletonHooksContainer } from "react-singleton-hook";

interface Props {
  children?: ReactNode;
}

const MobileLayout: FC<Props> = (props: any) => {
  return (
    <Layout id="baseLayout">
      <Layout>
        <SingletonHooksContainer />
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default MobileLayout;
