import { Form, Input, message, Modal, Switch, Upload } from "antd";
import { FC, useState } from "react";
import { ThunderboltOutlined, CloseOutlined, PlusOutlined, FileImageOutlined } from "@ant-design/icons";
import { constants } from "@/configs";
import { useAuth } from "react-oidc-context";
import { updateDocument } from "@/apis/waterright.api";
import { UserRole } from "@/dtos/user.dto";
import { useSelector } from "react-redux";
interface Props {
  waterRightId: string;
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}

const DocumentsAddModal: FC<Props> = (props) => {
  const { open, onCancel, onSuccess, waterRightId } = props;

  const [form] = Form.useForm();

  const user = useAuth();

  const [fileList, setFileList] = useState<any[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    const fileId = JSON.parse(fileList[0].xhr.response)?.value;
    form
      .validateFields()
      .then((values) => {
        const submitData = values;

        handleSubmitDocumentData(fileId, submitData);
      })
      .catch((errors) => {
        // TODO:
      });
  };

  const handleSubmitDocumentData = async (fileId: any, data: any) => {
    setLoading(true);
    const response = await updateDocument(fileId, data);

    if (response.ok) {
      onSuccess && onSuccess();
    } else {
      message.error("Failed to upload the document");
    }

    setLoading(false);
  };

  return (
    <Modal
      open={open}
      title="Add Document"
      okText="Save"
      cancelText="Cancel"
      onOk={handleSubmit}
      onCancel={onCancel}
      maskClosable={false}
      cancelButtonProps={{
        icon: <CloseOutlined />,
        disabled: uploading,
        loading: loading,
      }}
      okButtonProps={{
        icon: <ThunderboltOutlined />,
        disabled: uploading,
        loading: loading,
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal" initialValues={{ modifier: "public" }}>
        <Form.Item name="displayFileName" label="File Name" rules={[{ required: true, message: "Please enter the file name." }]}>
          <Input placeholder="Enter the file name" />
        </Form.Item>

        <Form.Item name="note" label="Notes">
          <Input.TextArea rows={4} placeholder="Enter notes (Optional)" />
        </Form.Item>

        <Form.Item label="Document To Upload">
          <Upload.Dragger
            maxCount={1}
            multiple={false}
            action={`${constants.baseApiUrl}/waterright/document/${waterRightId}/upload`}
            headers={{
              Authorization: `Bearer ${user.user?.access_token}`,
            }}
            listType="picture-card"
            fileList={fileList}
            onChange={(info: any) => {
              if (info.file.status === "uploading") {
                setUploading(true);
              }
              if (info.file.status === "done") {
                setUploading(false);
              }
              setFileList(info.fileList);
            }}
          >
            <p className="ant-upload-drag-icon">
              <FileImageOutlined />
            </p>
            <p className="ant-upload-text">Click or drag a file to this area to upload</p>
          </Upload.Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DocumentsAddModal;
