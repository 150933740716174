import { Form, Input, message, Modal } from "antd";
import { addWaterRightNote } from "@/apis/waterright.api";
import { addWellNote } from "@/apis/well.api";
import { FC, useState } from "react";
import { ThunderboltOutlined, CloseOutlined } from "@ant-design/icons";

interface Props {
  wellId?: string;
  waterRightId?: string;
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}

const WellNoteAddEditModal: FC<Props> = (props) => {
  const { open, onCancel, onSuccess, wellId, waterRightId } = props;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const submitData = {
          ...values,
          wellId,
          waterRightId,
        };
        handleSubmitNoteData(submitData);
      })
      .catch((e) => console.log(e));
  };

  const handleSubmitNoteData = async (data: any) => {
    setLoading(true);
    let response: Response | undefined = undefined;

    if (wellId) response = await addWellNote(data);
    if (waterRightId) response = await addWaterRightNote(data);

    if (response !== undefined && response.ok) {
      onSuccess && onSuccess();
    } else {
      message.error("Failed to add the note");
    }
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      title="Add a new note"
      okText="Save"
      cancelText="Cancel"
      onOk={handleSubmit}
      onCancel={onCancel}
      maskClosable={false}
      cancelButtonProps={{ icon: <CloseOutlined />, loading: loading }}
      okButtonProps={{ icon: <ThunderboltOutlined />, loading: loading }}
    >
      <Form form={form} layout="vertical" name="form_in_modal" initialValues={{ modifier: "public" }}>
        <Form.Item name="note" label="Note" rules={[{ required: true, message: "Please enter the note." }]}>
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default WellNoteAddEditModal;
