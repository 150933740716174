import { RestrictionSelector } from "@/components";
import { constants } from "@/configs";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Form, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import GeneratedRestrictionYearlyBreakdownReport from "./GeneratedRestrictionYearlyBreakdownReport";

const RestrictionYearlyBreakdownReport: FC = () => {
  const [form] = Form.useForm();
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [year, setYear] = useState<number | null>(null);
  const [restrictions, setRestrictions] = useState<any[]>([]);
  const [reportConfig, setReportConfig] = useState<any>(undefined);
  const [isActive, setIsActive] = useState<boolean | null>(true);

  const restrictionIds: any[] = useWatch("restrictionIds", form);
  const date: any = useWatch("date", form);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (date === null) setYear(null);
    else setYear(dayjs(date)?.year());
    // eslint-disable-next-line
  }, [date]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    setReportConfig({
      restrictionIds: restrictionIds ?? [],
      restrictions:
        restrictions
          ?.filter((restriction) => restrictionIds?.includes(restriction?.id))
          ?.map((restriction) => restriction?.name)
          ?.join(", ") ?? [],
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
      year,
      active: isActive,
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <Form.Item label="Year" name="date" style={{ margin: 0, marginBottom: 10 }} initialValue={null}>
            <DatePicker
              picker="year"
              style={{ marginRight: 30 }}
              placeholder="All Restrictions"
              allowClear={true}
              renderExtraFooter={() => (
                <div style={{ marginLeft: 5, paddingTop: 5, paddingBottom: 5 }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      form.setFieldValue("date", null);
                    }}
                  >
                    Clear
                  </Button>
                </div>
              )}
            />
          </Form.Item>
          <RestrictionSelector
            setActive={setIsActive}
            year={year !== null ? year : undefined}
            form={form}
            propertyToSet={"restrictionIds"}
            label={"Restrictions"}
            placeholder="Leave blank to select all restrictions"
            style={{ margin: 0, marginBottom: 10 }}
            setRestrictions={setRestrictions}
          />
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedRestrictionYearlyBreakdownReport reportConfig={reportConfig} />}
    </>
  );
};

export default RestrictionYearlyBreakdownReport;
