import { Card, Tabs } from "antd";
import { FC } from "react";
import AdminBillingUsers from "./AdminBillingUsers";
import useRequiredFeature from "@/services/useRequiredFeature";
import { RoleBasedFeature } from "@/services/useRoleBasedFeature";
import { EventsTable } from "@/components";
import AdminInvoices from "./AdminInvoices";

const AdminBilling: FC = () => {
  useRequiredFeature(RoleBasedFeature.Billing);

  const renderTabs = () => {
    const tabs: any[] = [
      {
        label: "Users",
        key: "users",
        children: <AdminBillingUsers styles={{ tableStyle: { height: 400 } }} />,
      },
      {
        label: "Invoices",
        key: "invoices",
        children: <AdminInvoices />,
      },
      {
        label: "Events",
        key: "events",
        children: <EventsTable eventTypes={["token_assigned", "token_replaced", "well_added", "well_updated"]} />,
      },
    ];

    return tabs;
  };

  return (
    <Card title="Billing" style={{ height: "calc(100vh - 210px)" }}>
      <Tabs tabPosition="left" items={renderTabs()} style={{ height: "calc(100vh - 310px)" }} />
    </Card>
  );
};

export default AdminBilling;
