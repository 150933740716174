import { useQueries } from "@tanstack/react-query";
import { min } from "lodash";
import { useSelector } from "react-redux";
import CacheKeys from "@/types/CacheKeys";
import { countWells } from "@/apis/well.api";
import { countCompanies, countCompanyUsers } from "@/apis/company.api";
import { countFields } from "@/apis/field.api";
import { countGroupings } from "@/apis/grouping.api";
import { countRestrictions } from "@/apis/restriction.api";
import { countStock } from "@/apis/stock.api";
import { countWaterRights } from "@/apis/waterright.api";

const queryDefinition = (profileId: string, key: string, title: string, api: (companyId?: string) => Promise<Response>, companyId?: string) => ({
  queryKey: [
    CacheKeys.dashboardCounts,
    key,
    {
      profileId,
      companyId,
    },
  ],
  queryFn: async () => {
    const response = await api(companyId);
    if (!response.ok) throw new Error("Failed to fetch " + key);
    const data = await response.json();
    return {
      label: title,
      children: `${data.value.count}`,
    };
  },
  placeholderData: (current: { label: string; children: string } | undefined) => current,
  enabled: !!profileId,
  staleTime: 60000,
});

export default function useDashboardCounts(companyId?: string) {
  const { profile } = useSelector((state: any) => state.user);

  const results = useQueries({
    queries: [
      queryDefinition(profile?.id, "companies", "Companies", countCompanies, companyId),
      queryDefinition(profile?.id, "fields", "Fields", countFields, companyId),
      queryDefinition(profile?.id, "waterRights", "Water Rights", countWaterRights, companyId),
      queryDefinition(profile?.id, "wells", "Wells", countWells, companyId),
      queryDefinition(profile?.id, "restrictions", "Restrictions", countRestrictions, companyId),
      queryDefinition(profile?.id, "groupings", "Groupings", countGroupings, companyId),
      queryDefinition(profile?.id, "stock", "Stock", countStock, companyId),
      queryDefinition(profile?.id, "companyUsers", "Users", countCompanyUsers, companyId),
    ],
  });

  const isPending = results.some((r) => r.isPending);
  const isLoading = results.some((r) => r.isFetching);
  const isError = results.some((r) => r.isError);

  return {
    lastUpdated: min(results.map((r) => r.dataUpdatedAt)),
    stats: isPending ? [] : results.map((r) => r.data),
    isPending,
    isLoading,
    isError,
  };
}
