import { constants, routes } from "@/configs";
import useWellDetails from "@/queries/useWellDetails";
import { Card, Divider, Tooltip } from "antd";
import dayjs from "dayjs";
import LookupLabel from "../LookupLabel/LookupLabel";
import FieldLabel from "../Lookups/FieldLabel";
import RemainingDays from "../RemainingDays/RemainingDays";
import WaterRightTagList from "../WaterRightTagList/WaterRightTagList";
import { FC, useMemo } from "react";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import useCustomNavigate from "@/services/useCustomNavigate";
import { MdOutlineLocationOff } from "react-icons/md";

type WellDetailsCardProps = {
  wellId: string;
};

const WellDetailsCard: FC<WellDetailsCardProps> = ({ wellId }) => {
  const dispatch = useAppDispatch();
  const { navigate } = useCustomNavigate();
  const { well, wellFetched } = useWellDetails({ wellId });

  const percentageAvailable = useMemo(() => {
    if (well?.remainingUsage === undefined || well?.availableQuantity === undefined || well?.availableQuantity === 0) return undefined;
    if (well.remainingUsage < 0) return "0.00 %";
    return ((well?.remainingUsage / well?.availableQuantity) * 100).toFixed(2) + " %";
  }, [well?.remainingUsage, well?.availableQuantity]);

  const handleOnClick = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record.name}`,
        url: `well/${record.id}/view`,
      })
    );
    navigate(routes.wellView, { id: record.id });
  };

  return well && wellFetched ? (
    <>
      <Card
        onClick={() => handleOnClick(well)}
        style={{ cursor: "pointer" }}
        styles={{
          header: {
            paddingLeft: 8,
            paddingRight: 8,
          },
          body: { paddingTop: 10, paddingBottom: 8, minWidth: 450, paddingLeft: 8, paddingRight: 8 },
        }}
        title={
          <div style={{ fontWeight: "normal", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", lineHeight: "1.3em" }}>
            <div style={{ fontWeight: "bold" }}>{well?.name}</div>
            <div style={{ fontSize: 12 }}>
              <FieldLabel fieldId={well?.fieldId} />
            </div>
            <div style={{ fontSize: 12 }}>
              <LookupLabel lookupType="permitType" value={well.permitType} />
            </div>
          </div>
        }
        extra={
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "flex-start", lineHeight: "1.3em" }}>
            <div style={{ fontSize: 12 }}>Last reading: {well?.lastReadingDate ? dayjs(well.lastReadingDate).format(constants.dateTimeFormatWithSeconds) : "No readings"}</div>
            <div style={{ fontWeight: "bold" }}>Est. Remaining</div>
            <RemainingDays remainingDays={well.remainingDays} />
            <div>{percentageAvailable}</div>
          </div>
        }
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", lineHeight: "1.3em" }}>
          <div style={{ fontSize: 12, paddingLeft: 5, display: "flex", flexDirection: "row" }}>
            <div style={{ width: 60 }}>WR / FN: </div>
            <div>{well?.waterRightFileNumber}</div>
          </div>
          <div style={{ fontSize: 12, paddingLeft: 5, display: "flex", flexDirection: "row" }}>
            <div style={{ width: 60 }}>PDIV: </div>
            <div>{well.pdiv ?? "-"}</div>
          </div>
          <div style={{ fontSize: 12, paddingLeft: 5, display: "flex", flexDirection: "row" }}>
            <div style={{ width: 60 }}>CIN: </div>
            <div>{well.cin ?? "-"}</div>
          </div>
        </div>
        {(well?.latitude === undefined || well?.latitude === null || well?.longitude === undefined || well?.longitude === null) && (
          <div style={{ position: "absolute", bottom: 10, right: 10 }}>
            <Tooltip title="No location available">
              <MdOutlineLocationOff size={20} color="red" />
            </Tooltip>
          </div>
        )}
        {(well.internalTags?.length > 0 || well.externalTags?.length > 0) && <Divider style={{ padding: 0, marginBottom: 4, marginTop: 4 }} />}
        <WaterRightTagList internalTags={well.internalTags} externalTags={well.externalTags} style={{ maxWidth: 400 }} />
      </Card>
    </>
  ) : (
    <></>
  );
};

export default WellDetailsCard;
