import { get, post, put } from "@/services/request";

export const getWells = (request: {
  companyId?: string;
  isActive?: boolean | null;
  searchString?: string;
  waterRightId?: string;
  fieldId?: string;
  reductionWellIds?: string[];
  wellIds?: string[];
}) => {
  return post(`well/list`, request);
};

export const getWell = (id: any) => {
  return get(`well/${id}`);
};

export const addWell = (request: any) => {
  return post("well", request);
};

export const updateWell = (id: any, request: any) => {
  return put(`well/update/${id}`, request);
};

export const getWellNotes = (request: any) => {
  return post(`well/note/list`, request);
};

export const addWellNote = (request: any) => {
  return post("well/note", request);
};

export const getWellsForField = (fieldId: any) => {
  return get(`well/field/${fieldId}/list`);
};

export const getWaterRightsFromField = (fieldId: any) => {
  return get(`well/waterright/list?fieldId=${fieldId}`);
};

export const countWells = (companyId?: string) => {
  const queryParams = companyId ? `?companyId=${companyId}` : "";

  return get(`well/count` + queryParams);
};

export const getWellsFromWaterRightId = (id: any) => {
  return get(`well/waterright/${id}/list`);
};

export const getRecentWellReading = (request: any) => {
  return post(`well/reading/recent`, request);
};

export const getWellAuditLogs = (id: string) => {
  return get(`well/auditlog/${id}/list`);
};

export const getWaterRightsForWell = (request: any) => {
  return post("well/well/waterright/list", request);
};

export const getWellUsageReport = (request: { priorityTypes?: string[]; permitTypes?: string[]; waterRightIds?: string[]; page: number; pageSize: number; companyId?: string }) => {
  return post("well/well/reports/usage", request);
};

export const addWellAdjustmentTransfer = (request: any, wellId: any) => {
  return post(`well/adjustmenttransfer/${wellId}/create`, request);
};

export const updateWellAdjustmentTransfer = (request: any, id: any) => {
  return put(`well/adjustmenttransfer/${id}/update`, request);
};

export const getWellAdjustmentTransfer = (id: any) => {
  return get(`well/adjustmenttransfer/${id}`);
};

export const getWellAdjustmentTransfers = (wellId: any) => {
  return get(`well/adjustmenttransfer/${wellId}/list`);
};

export const getWellConfig = (wellId: any) => {
  return get(`well/${wellId}/config`);
};

export const getWellReadingNotes = (request: any) => {
  return post(`well/reading/notes/list`, request);
};

export const getEndOfYearReadings = (request: any) => {
  return post(`well/reading/end/year`, request);
};

export const checkBillableRequired = (request: any) => {
  return post(`well/billable/required`, request);
};

export const getPagedWells = (request: any) => {
  return post(`well/paged/list`, request);
};

export const getWellsLookup = (request: { wellIds?: string[]; companyId?: string; isActive?: boolean }) => {
  return post(`well/lookup`, request);
};

export const getWellReportLookup = (request: { companyId: string; page: number; pageSize: number; isActive: boolean | null }) => {
  return post(`well/report/lookup`, request);
};
