import { StockSelector } from "@/components";
import { constants } from "@/configs";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Alert, Button, Card, DatePicker, Form, Space, Switch } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";

import GeneratedFeedLotReport from "./GeneratedFeedLotReport";
import { useSelector } from "react-redux";

const FeedLotReport: FC = () => {
  const [form] = Form.useForm();
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [reportConfig, setReportConfig] = useState<any>(undefined);

  const stockIds: any[] = useWatch("stockIds", form);
  const [stocks, setStocks] = useState<any[]>([]);
  const date: any = useWatch("date", form);
  const interpolate: boolean = useWatch("interpolate", form);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    setReportConfig({
      stockIds: stockIds ?? [],
      stockNames:
        stocks
          ?.filter((stock) => stockIds?.includes(stock?.id))
          ?.map((stock) => stock?.name)
          ?.join(", ") ?? [],
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
      year: date?.year(),
      interpolate,
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <Form.Item label="Year" name="date" rules={[{ required: true, message: "Please enter a year" }]} style={{ margin: 0, marginBottom: 10 }} initialValue={dayjs()}>
            <DatePicker picker="year" style={{ marginRight: 30 }} placeholder="All Readings" allowClear={false} />
          </Form.Item>
          <StockSelector form={form} propertyToSet={"stockIds"} label={"Stock"} placeholder="Leave blank to select all stocks" style={{ margin: 0, marginBottom: 0 }} setStocks={setStocks} />
          <Form.Item name="interpolate" label="Assume continuous usage" valuePropName="checked" style={{ margin: 0, paddingTop: 0 }}>
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 14, offset: 6 }} style={{ paddingTop: 10 }}>
            <Alert
              message={interpolate ? "Continuous Usage" : "Allocated Usage"}
              description={
                <>
                  {interpolate && (
                    <>
                      The assumption will be made that water usage is continuous between readings.
                      <br />
                      Therefore, water usage will be spread equally across the time between reading dates to give a more accurate breakdown of water usage.
                      <br />
                      <br />
                      Example:
                      <br />
                      Reading 1: 31st of January 2022 at 10:00 AM with a meter reading of 0<br />
                      Reading 2: 1st of February 2022 at 11:00 AM with a meter reading of 100
                      <br />
                      <br />
                      Thus the time between readings is 25 hours.
                      <br />
                      100 units of water were used in 25 hours.
                      <br />
                      100 / 25 = 4 units of water per hour.
                      <br />
                      <br />
                      14 hours of water usage will be allocated to the month of January 2022 and 11 hours of water usage will be allocated to the month of February 2022.
                      <br />
                      January Usage: 14 * 4 = 56 units
                      <br />
                      February Usage: 11 * 4 = 44 units
                      <br />
                      <br />
                      This calculation will be accurate to the second.
                      <br />
                      <br />
                      Please note that the timezone setting for the company will be used to calculate the time between readings and allocate usage to the correct month.
                    </>
                  )}
                  {!interpolate && (
                    <>
                      Water usage is allocated in the month it was recorded or allocated in.
                      <br />
                      <br />
                      Example:
                      <br />
                      Reading 1: 1st of January 2022 with a meter reading of 0
                      <br />
                      Reading 2: 1st of February 2022 with a meter reading of 100
                      <br />
                      <br />
                      Therefore 100 units of water will be allocated to the month of February 2022.
                    </>
                  )}
                </>
              }
              type="info"
              showIcon
            />
          </Form.Item>
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedFeedLotReport reportConfig={reportConfig} />}
    </>
  );
};

export default FeedLotReport;
