import LookupLabel from "@/components/LookupLabel/LookupLabel";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { convertUnits, formatNumber } from "@/services/utils";

export interface UnitsConverterProps {
  value: any;
  fromUnits: string;
  toUnits?: string;
  showUnitsLabel?: boolean;
  placeholder?: string;
  devideBy?: number;
  postFix?: string;
  removeStyling?: boolean;
  overrideDecimalPlaces?: number;
  breakLines?: boolean;
}

export default function UnitsConverter(props: UnitsConverterProps) {
  const {
    value,
    fromUnits,
    toUnits = undefined,
    showUnitsLabel = false,
    placeholder = "-",
    devideBy = undefined,
    postFix = undefined,
    removeStyling = false,
    overrideDecimalPlaces,
    breakLines = false,
  } = props;

  const { selectedCompany } = useSelector((state: any) => state.company);
  const [convertedValue, setConvertedValue] = useState<number>(0);

  useEffect(() => {
    if (value !== null && value !== undefined && value !== "N/A") {
      let tempValue: number = value;

      tempValue = convertUnits(value, fromUnits, toUnits ?? selectedCompany?.settings?.metric, devideBy);
      setConvertedValue(tempValue);
    }
  }, [value, fromUnits, toUnits, showUnitsLabel, devideBy]);

  if (value === undefined) return <>{placeholder}</>;
  if (value === "N/A") return <>N/A</>;

  return convertedValue >= 0 ? (
    <>
      {formatNumber(convertedValue.toFixed(overrideDecimalPlaces ?? (toUnits ?? selectedCompany?.settings?.metric ?? "acrefeet") !== "gallons" ? 2 : 0))}
      {breakLines ? <br /> : " "}
      {showUnitsLabel && <LookupLabel lookupType="meterUnits" value={toUnits ?? selectedCompany?.settings?.metric ?? "acrefeet"} />} {postFix ? <> {postFix}</> : <></>}
    </>
  ) : (
    <span style={removeStyling ? undefined : { color: "red" }}>
      {formatNumber(convertedValue.toFixed(overrideDecimalPlaces ?? (toUnits ?? selectedCompany?.settings?.metric ?? "acrefeet") !== "gallons" ? 2 : 0))}
      {breakLines ? <br /> : " "}
      {showUnitsLabel && <LookupLabel lookupType="meterUnits" value={toUnits ?? selectedCompany?.settings?.metric ?? "acrefeet"} />}
      {postFix ? <> {postFix}</> : <></>}
    </span>
  );
}
