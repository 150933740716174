export const retryIntervals = [2500, 5000, 10000, 30000];

const defaultQueryConfig = {
  retry: true,
  retryDelay: (failureCount: number) => {
    if (failureCount > retryIntervals.length) return retryIntervals[retryIntervals.length - 1];
    return retryIntervals[failureCount - 1];
  },
};

export default defaultQueryConfig;
