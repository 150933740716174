import { getCompany, getCompanyTags } from "@/apis/company.api";
import { AuditLogTable, LookupLabel, StatusTag } from "@/components";
import { AuditLogType } from "@/components/AuditLogTable/AuditLogTable";
import PrintPage from "@/components/PrintPage/PrintPage";
import { routes } from "@/configs";
import useSelectedCompanyData from "@/queries/useSelectedCompanyData";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb, removeBreadcrumb, replaceUrlStateBreadcrumbType } from "@/stores/breadcrumbs.store";
import { clearCompanyListDataState, saveCompany } from "@/stores/company.store";
import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Card, Descriptions, Tabs, Tag } from "antd";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";

import "./CompanyView.scss";
import { daysOfWeek, nth } from "@/services/utils";
import { zip } from "lodash";

const labelStyle = "original";
const timezones = {
  ...allTimezones,
};

const FieldView: FC = () => {
  const dispatch = useAppDispatch();

  const { companyData } = useSelector((state: any) => state.company);

  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });

  const { id } = useParams<{ id: string }>();

  const pathUrl = window.location.pathname;

  const { navigate } = useCustomNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [companyTags, setCompanyTags] = useState<any>(undefined);

  useEffect(() => {
    if (pathUrl.includes("view") && companyData) {
      dispatch(replaceUrlStateBreadcrumbType(companyData?.name));
    }
  }, [pathUrl, companyData]);

  useEffect(() => {
    refreshCompany();
    getTags();
    return () => {
      dispatch(clearCompanyListDataState());
    };
    // eslint-disable-next-line
  }, []);

  const refreshCompany = async () => {
    setLoading(true);
    const response = await getCompany(id);

    if (response.ok) {
      const data = await response.json();
      dispatch(saveCompany(data.value));
    }
    setLoading(false);
  };

  const getTags = async () => {
    const response = await getCompanyTags(id as any);

    if (response.ok) {
      const data = await response.json();
      setCompanyTags(data.value);
    }
  };

  const navigateEdit = () => {
    dispatch(
      addBreadcrumb({
        type: `Edit`,
        url: `company/${id}/edit`,
      })
    );
    navigate(routes.companyEdit, { id: id });
  };

  const navigateBack = () => {
    dispatch(removeBreadcrumb());
    navigate(-1);
  };

  const renderTabs = (companyData: any) => {
    let tabs: any[] = [
      {
        label: "Default Settings",
        key: "settings",
        children: (
          <Descriptions bordered size="small" column={1} labelStyle={{ width: 220 }}>
            <Descriptions.Item label="Timezone">{companyData?.settings?.timezone ? parseTimezone(companyData?.settings?.timezone)?.label : "-"}</Descriptions.Item>
            <Descriptions.Item label="Warning Quantity">
              Water Right: {companyData?.settings?.waterRightWarningQuantity ? companyData?.settings?.waterRightWarningQuantity : "-"}{" "}
              <LookupLabel lookupType="meterunits" value={companyData?.settings?.waterRightWarningQuantityUnits} /> <br />
              Restriction: {companyData?.settings?.restrictionWarningQuantity ? companyData?.settings?.restrictionWarningQuantity : "-"}{" "}
              <LookupLabel lookupType="meterunits" value={companyData?.settings?.restrictionWarningUnits} /> <br />
            </Descriptions.Item>
            <Descriptions.Item label="Display Units">
              {companyData?.settings?.metric === "acrefeet" ? "Acre Feet" : <LookupLabel lookupType={"meterunits"} value={companyData?.settings?.metric} />}
            </Descriptions.Item>
            <Descriptions.Item label="Meter Reading Frequency">
              {companyData?.settings?.meterReadingFrequency ? <LookupLabel lookupType="meterreadingfrequency" value={companyData?.settings?.meterReadingFrequency} /> : "-"}
              {companyData?.settings?.meterReadingFrequencyDayOfWeek !== null && <> - {daysOfWeek.find((x) => x.value === companyData?.settings?.meterReadingFrequencyDayOfWeek)?.day ?? ""}</>}
              {companyData?.settings?.meterReadingFrequencyDay !== null && (
                <>
                  {" "}
                  -{" "}
                  {companyData?.settings?.meterReadingFrequencyDay < 31
                    ? companyData?.settings?.meterReadingFrequencyDay + nth(companyData?.settings?.meterReadingFrequencyDay) + " day of the Month"
                    : "Last day of the Month"}
                </>
              )}
            </Descriptions.Item>
          </Descriptions>
        ),
      },
    ];

    if (canEdit)
      tabs.push({
        label: "Audit Log",
        key: "auditLog",
        children: <AuditLogTable entityId={companyData?.id} entityType={AuditLogType.Company} />,
      });

    return tabs;
  };

  const { canEdit } = useSelectedCompanyData(companyData?.id);

  return (
    <Card
      className="companyView"
      loading={loading}
      id="companyView"
      title={`Company - ${loading ? "loading..." : companyData?.name}`}
      extra={
        <>
          <Button icon={<LeftOutlined />} style={{ marginRight: 5 }} onClick={navigateBack}>
            Back
          </Button>
          {canEdit && (
            <Button icon={<EditOutlined />} type="primary" onClick={navigateEdit}>
              Edit
            </Button>
          )}
        </>
      }
    >
      <PrintPage
        content={
          <div>
            <Descriptions style={{ paddingLeft: 0, width: 500 }} bordered size="small" column={1} labelStyle={{ width: 220 }}>
              <Descriptions.Item label="Name">{companyData?.name}</Descriptions.Item>
              <Descriptions.Item label="Status">
                <StatusTag status={companyData?.active} />
              </Descriptions.Item>
              <Descriptions.Item label="Tags">{companyTags?.tags?.length > 0 ? companyTags?.tags.map((tag: any) => <Tag>{tag}</Tag>) : "-"}</Descriptions.Item>
              {/* <Descriptions.Item label="Logo">
                                {companyData?.logoId ? <Image.PreviewGroup>
                                    {<Image
                                        style={{
                                            textAlign: "center",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            alignContent: "center",
                                            paddingRight: 5,
                                            maxHeight: 50,
                                            maxWidth: 50
                                        }}
                                        src={`${constants.baseApiUrl}/company/image/${companyData?.logoId}/download?maxWidth=100&maxHeight=100`}
                                        preview={{
                                            src: `${constants.baseApiUrl}/company/image/${companyData?.logoId}/download`
                                        }}
                                    />}
                                </Image.PreviewGroup> : "-"}
                            </Descriptions.Item> */}
            </Descriptions>
            <br />
            <Tabs defaultActiveKey="1" type="card" size="small" style={{ marginBottom: 32 }} items={renderTabs(companyData)} />
          </div>
        }
      />
    </Card>
  );
};

export default FieldView;
