import { getCompanyConfigLookups } from "@/apis/company.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function useComapnyConfigLookup(companyId?: string) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();
  const {
    data: companyConfigs,
    isFetching,
    isRefetching,
    isFetched,
    isError,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: [
      CacheKeys.companyConfig,
      CacheKeys.lookup,
      {
        profileId: profile?.id,
      },
    ],
    queryFn: async () => {
      const response = await getCompanyConfigLookups();

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch company configs");
    },
    gcTime: Infinity,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    placeholderData: (currentData) => currentData || [],
  });

  const invalidateCompanyConfigs = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [CacheKeys.companyConfig, CacheKeys.lookup] });
  }, [queryClient]);

  const [companyConfig, setCompanyConfig] = useState<any>(null);

  useEffect(() => {
    if (isFetched && !isError && !isFetching && !isRefetching && isSuccess && companyId && companyConfigs) {
      const tempCompanyConfig = companyConfigs.find((c: any) => c.id === companyId);
      if (tempCompanyConfig) {
        if (!isEqual(companyConfig, tempCompanyConfig)) setCompanyConfig(tempCompanyConfig);
      } else {
        invalidateCompanyConfigs();
      }
    }
  }, [isError, isFetched, isFetching, isRefetching, companyId, companyConfigs, invalidateCompanyConfigs, isSuccess, companyConfig]);

  return { companyConfig, companyConfigs, isLoading, invalidateCompanyConfigs };
}
