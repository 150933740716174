import { getWellUsageReport } from "@/apis/well.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

type WellUsageReportProps = {
  permitTypes?: string[];
  priorityTypes?: string[];
  waterRightIds?: string[];
  generatedDate: string;
};

const useWellUsageReportQuery = ({ permitTypes, priorityTypes, waterRightIds, generatedDate }: WellUsageReportProps) => {
  const { profile } = useSelector((state: any) => state.user);

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const { data, isFetching, isFetched, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading } = useInfiniteQuery({
    queryKey: [
      CacheKeys.wells,
      CacheKeys.usage,
      {
        profileId: profile?.id,
        companyId: selectedCompanyId,
        permitTypes,
        priorityTypes,
        waterRightIds,
        generatedDate,
      },
    ],
    queryFn: async ({ pageParam }) => {
      const response = await getWellUsageReport({
        page: pageParam,
        pageSize: 10,
        permitTypes,
        priorityTypes,
        waterRightIds,
        companyId: selectedCompanyId,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch well usage report");
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    gcTime: Infinity,
    staleTime: Infinity,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage?.pagination?.hasNextPage) return undefined;
      return lastPageParam + 1;
    },
  });

  const invalidateWellUsageReport = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.wells, CacheKeys.usage],
    });
  }, [queryClient]);

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage && !isFetching) fetchNextPage();
  }, [hasNextPage, isFetchingNextPage, isFetching]);

  const wellUsageReport = useMemo(() => (hasNextPage ? [] : data?.pages?.map((page) => page.list).flat() ?? []), [data, hasNextPage]);
  const totalItemCount = useMemo(() => (hasNextPage ? 0 : data?.pages?.[(data?.pages?.length ?? 0) - 1]?.pagination?.totalItemCount ?? 0), [data, hasNextPage]);

  return {
    wellUsageReport,
    isLoading,
    fetchNextPage,
    invalidateWellUsageReport,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
    isFetched,
    totalItemCount,
  };
};

export default useWellUsageReportQuery;
