import { Tag } from "antd";
import { FC } from "react";

interface WaterRightTagProps {
  status: any;
  style?: any;
}

const style = { margin: 2 };

const WaterRightTag: FC<WaterRightTagProps> = (props) => {
  const { status, style: tagStyle = {} } = props;
  switch (status) {
    case "MDS":
      return (
        <Tag id="waterRightTag" style={{ ...style, ...tagStyle }} color="cyan">
          {status}
        </Tag>
      );
    case "LEMA":
      return (
        <Tag id="waterRightTag" style={{ ...style, ...tagStyle }} color="green">
          {status}
        </Tag>
      );
    case "5511":
      return (
        <Tag id="waterRightTag" style={{ ...style, ...tagStyle }} color="geekblue">
          {status}
        </Tag>
      );
    case "MYFA":
      return (
        <Tag id="waterRightTag" style={{ ...style, ...tagStyle }} color="magenta">
          {status}
        </Tag>
      );
    case "WCA":
      return (
        <Tag id="waterRightTag" style={{ ...style, ...tagStyle }} color="blue">
          {status}
        </Tag>
      );
    case "IGUCA":
      return (
        <Tag id="waterRightTag" style={{ ...style, ...tagStyle }} color="purple">
          {status}
        </Tag>
      );

    default:
      return (
        <Tag id="waterRightTag" style={{ ...style, ...tagStyle }}>
          {status}
        </Tag>
      );
  }
};

export default WaterRightTag;
