import React from "react";
import { Layout } from "antd";
import { Content } from "@/components";

interface Props {
  children?: React.ReactNode;
}

const BlankContentLayoutWithoutBackground: React.FC<Props> = (props: any) => {
  return (
    <Layout id="blankContentLayout" style={{ padding: 0 }}>
      <Layout style={{ padding: 0 }}>
        <Content useBackground={false} />
      </Layout>
    </Layout>
  );
};

export default BlankContentLayoutWithoutBackground;
