import { StockTable } from "@/components";
import { FC } from "react";

import "./StockList.scss";

const StockList: FC = () => {
  return <StockTable />;
};

export default StockList;
