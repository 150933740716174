import { getWaterRightTagsLookup } from "@/apis/waterright.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function useWaterRightTagsQuery(waterRightId?: string, companyId?: string, isActive?: boolean) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();
  const {
    data: waterRightTags,
    isFetching,
    isRefetching,
    isFetched,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: [
      CacheKeys.waterRightTags,
      CacheKeys.lookup,
      {
        waterRightId,
        profileId: profile?.id,
        companyId,
        isActive,
      },
    ],
    queryFn: async () => {
      const response = await getWaterRightTagsLookup({
        companyId,
        isActive,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch water right tags");
    },
    gcTime: Infinity,
    staleTime: Infinity,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
  });

  const invalidateWaterRightTags = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.waterRightTags, CacheKeys.lookup],
    });
  }, [queryClient]);

  const [waterRightTag, setWaterRightTag] = useState<any>(null);

  useEffect(() => {
    if (isFetched && !isError && !isFetching && !isRefetching && isSuccess && waterRightId && waterRightTags) {
      const tempWaterRightTag = waterRightTags.find((w: any) => w.id === waterRightId);
      if (tempWaterRightTag) {
        if (!isEqual(waterRightTag, tempWaterRightTag)) setWaterRightTag(tempWaterRightTag);
      } else {
        invalidateWaterRightTags();
      }
    }
  }, [isError, isFetched, isFetching, isRefetching, waterRightId, waterRightTags, invalidateWaterRightTags, isSuccess, waterRightTag]);

  const isLoading = isFetching || isRefetching;

  return { waterRightTag, waterRightTags, isFetched, isLoading, invalidateWaterRightTags };
}
