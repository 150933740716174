import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface IGroupingState {
  groupings: string[];
  groupingsTableState: any | undefined;
}

const initialState: IGroupingState = {
  groupings: [],
  groupingsTableState: undefined,
};

const groupingStore = createSlice({
  name: "grouping",
  initialState,
  reducers: {
    saveGroupings: (state: IGroupingState, action: PayloadAction<any>) => {
      state.groupings = action.payload;
    },
    saveGroupingsTableState: (state: IGroupingState, action: PayloadAction<any>) => {
      state.groupingsTableState = action.payload;
    },
    clearGroupingState: (state: IGroupingState) => {
      state.groupings = [];
      state.groupingsTableState = undefined;
    },
  },
});

export const { saveGroupings, saveGroupingsTableState, clearGroupingState } = groupingStore.actions;

export default groupingStore.reducer;
