import useFieldLookups from "@/queries/useFieldLookups";

type FieldLabelProps = {
  fieldId: string;
};

export default function FieldLabel(props: FieldLabelProps) {
  const { fieldId } = props;
  const { field } = useFieldLookups(fieldId);

  if (field) return <>{field.name}</>;
  else return <>Loading...</>;
}
