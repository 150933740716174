import { getFinanceDocument, listEvents } from "@/apis/aggregator.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

type FinanceDocumentProps = {
  id: string;
};

const useFinanceDocument = ({ id }: FinanceDocumentProps) => {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();

  const { data, isFetching, isFetched, isLoading } = useQuery({
    queryKey: [
      CacheKeys.financeDocuments,
      CacheKeys.view,
      {
        id,
      },
    ],
    queryFn: async () => {
      const response = await getFinanceDocument(id);

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch finance document");
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id && !!id,
  });

  const invalidateFinanceDocument = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.financeDocuments, CacheKeys.view],
    });
  }, [queryClient]);

  return {
    financeDocument: data,
    isFetching,
    isFetched,
    isLoading,
    invalidateFinanceDocument,
  };
};

export default useFinanceDocument;
