import { constants } from "@/configs";
import { getUser } from "./getUser";

const request = (url: string, removeAuth: boolean, options: RequestInit = {}) => {
  const user = getUser();
  const token = user?.access_token;

  const headers: any = { ...(options?.headers || {}) };
  if (token) headers.Authorization = `Bearer ${token}`;

  if (headers.Authorization && removeAuth) delete headers.Authorization;

  let tempUrl = url;
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    tempUrl = `${constants.baseApiUrl}/${url}`;
  }
  return fetch(tempUrl, {
    ...options,
    headers,
  });
};

export const post = (url: string, body: any | undefined, removeAuth: boolean = false) => {
  const user = getUser();
  const token = user?.access_token;

  return request(url, removeAuth, {
    method: "POST",
    body: body === undefined ? undefined : JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

// export const postWithNoBody = (url: string, removeAuth: boolean = false) => {
//   const user = getUser()
//   const token = user?.access_token

//   return request(url, removeAuth, {
//     method: 'POST',
//     headers: {
//       Authorization: `Bearer ${token}`,
//       'Content-Type': 'application/json',
//     },
//   })
// }

export const put = (url: string, body: any, removeAuth: boolean = false) => {
  const user = getUser();
  const token = user?.access_token;

  if (body)
    return request(url, removeAuth, {
      method: "PUT",
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  else
    return request(url, removeAuth, {
      method: "PUT",
    });
};

export const get = (url: string, removeAuth: boolean = false) => {
  return request(url, removeAuth, {
    method: "GET",
  });
};

export const del = (url: string, removeAuth: boolean = false) => {
  const user = getUser();
  const token = user?.access_token;

  return request(url, removeAuth, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
