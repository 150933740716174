import useListCompanyQuery from "@/queries/useListCompanyQuery";
import { ReloadOutlined } from "@ant-design/icons";
import { useIntersectionObserver, useWindowSize } from "@uidotdev/usehooks";
import { Button, Card, Empty, Input, Space, Table } from "antd";
import { FC, useEffect, useState } from "react";
import PlaceOfUseTable from "../PlaceOfUseTable/PlaceOfUseTable";
import PrintPage from "../PrintPage/PrintPage";

const CompanyPlaceOfUseTable: FC = () => {
  const [columns, setColumns] = useState<any[]>([]);
  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");

  const windowSize = useWindowSize();

  const [companiesDownRef, companiesDownEntry] = useIntersectionObserver();

  const {
    companyList: companies,
    fetchNextPage,
    hasNextPage,
    invalidateCompanies,
    isLoading,
    totalItemCount,
    isFetchingNextPage,
  } = useListCompanyQuery({
    isActive: "true",
    searchString: searchString,
  });

  useEffect(() => {
    if (companiesDownEntry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line
  }, [companiesDownEntry?.time, companiesDownEntry?.isIntersecting, hasNextPage]);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [companies]);

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Company Name",
        dataIndex: "name",
        fixed: "left",
        render: (val: any, record: any, index: any) => (
          <>
            {val}
            {index === companies?.length - 1 ? <span ref={companiesDownRef} /> : null}
          </>
        ),
      },
    ];

    setColumns(tempColumns);
  };

  const handleRefresh = () => {
    setSearchString(undefined);
    invalidateCompanies();
  };

  const renderExpandData = (record: any) => {
    return <PlaceOfUseTable companyId={record.id} />;
  };

  return (
    <Card
      styles={{
        body: {
          height: "calc(100vh - 320px)",
          padding: 0,
        },
      }}
      className="companyPlacesOfUseList"
      id="companyPlacesOfUseList"
      title={
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <span>Companies</span>
          <Space direction="horizontal">
            <Space>
              <Input.Search
                disabled={isLoading}
                placeholder="Search companies"
                onSearch={(val) => {
                  setSearchString(val);
                }}
                onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                value={searchStringValue}
                style={{ width: 400 }}
                allowClear
              />
              <Button icon={<ReloadOutlined />} disabled={isLoading} onClick={handleRefresh}>
                Refresh
              </Button>
            </Space>
          </Space>

          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingRight: 16 }}>Total: {totalItemCount ?? "-"}</span>
          </span>
        </div>
      }
    >
      <PrintPage
        content={
          <div>
            <Table
              className="customScrollBarStyle"
              rowKey={(row: any) => row.id}
              scroll={{ y: windowSize?.height ? windowSize.height - 360 : 600 }}
              style={{
                height: 400,
              }}
              expandable={{
                columnWidth: 55,
                expandedRowRender: (record: any, index: number, indent: number, expanded: boolean) => (expanded ? renderExpandData(record) : null),
              }}
              loading={isLoading || isFetchingNextPage}
              columns={columns}
              dataSource={companies ?? []}
              size="small"
              pagination={false}
              locale={{
                emptyText: <Empty description="No Companies" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
              }}
            />
          </div>
        }
      />
    </Card>
  );
};

export default CompanyPlaceOfUseTable;
