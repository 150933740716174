import { keepPreviousData, useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import { getWaterRightCalculation } from "@/apis/waterright.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import CacheKeys from "@/types/CacheKeys";

export default function useWaterRightCalculations(waterRightIds: string[], year: number) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();

  const results = useQueries({
    queries: waterRightIds.map((waterRightId) => ({
      queryKey: [
        CacheKeys.waterRights,
        CacheKeys.calculation,
        {
          id: waterRightId,
          year,
        },
      ],
      queryFn: async () => {
        const response = await getWaterRightCalculation(waterRightId, year);

        if (response.ok) {
          const data = await response.json();

          queryClient.setQueryData(
            [
              CacheKeys.waterRights,
              CacheKeys.calculation,
              {
                id: waterRightId,
                year,
              },
            ],
            data
          );

          if (data.isSuccess) return data.value?.[0];
        }

        throw new Error("Failed to fetch water right calculation");
      },
      ...defaultQueryConfig,
      enabled: !!profile?.id,
      placeholderData: keepPreviousData,
    })),
    combine: (results) => {
      return {
        waterRightCalculations: results.map((result) => result.data).filter((data) => data),
        isLoading: results.some((result) => result.isFetching),
      };
    },
  });

  const invalidateWaterRightCalculations = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.waterRights, CacheKeys.calculation],
    });
  }, [queryClient]);

  return {
    ...results,
    invalidateWaterRightCalculations,
  };
}
