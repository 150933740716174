import { useInfiniteQuery } from "@tanstack/react-query";
import CacheKeys from "@/types/CacheKeys";
import { useSelector } from "react-redux";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import { getPushNotifications } from "@/apis/communication.api";

const useNotifications = (searchString: string | undefined) => {
  const { profile } = useSelector((state: any) => state.user);

  const { data, isFetching, isFetched, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading } = useInfiniteQuery({
    queryKey: [
      CacheKeys.notifications,
      CacheKeys.list,
      {
        profileId: profile?.id,
        searchString,
      },
    ],
    queryFn: async ({ pageParam }) => {
      const response = await getPushNotifications(searchString, pageParam);

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch unread notifications count");
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    gcTime: 30 * 1000, //30 seconds
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage?.pagination?.hasNextPage) return undefined;
      return lastPageParam + 1;
    },
  });

  const notifications = data?.pages?.map((page) => page.list).flat() ?? [];

  return {
    notifications,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
    isFetched,
  };
};

export default useNotifications;
