import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Tooltip } from "antd";
import { FC, useState } from "react";

type MarkAsPaidButtonProps = {
  id: string;
  invoiceNumber: string;
  onMarkAsPaid: (id: string, onCompleted: () => void) => void;
  disabled?: boolean;
};

const MarkAsPaidButton: FC<MarkAsPaidButtonProps> = ({ id, invoiceNumber, onMarkAsPaid, disabled }) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Popconfirm
      placement="topRight"
      title="Mark as Paid"
      description={<pre style={{ fontFamily: "inherit" }}>Are you sure you want to mark the invoice {invoiceNumber} as PAID?</pre>}
      onConfirm={() => {
        setLoading(true);
        onMarkAsPaid(id, () => setLoading(false));
      }}
      okText={"Mark as Paid"}
      cancelText="No"
    >
      <Tooltip title={disabled ? "Already paid" : "Mark as paid"} placement="left">
        <Button loading={loading} disabled={disabled} style={{ paddingLeft: 0, paddingRight: 0 }} type="link" icon={<CheckCircleOutlined />} />
      </Tooltip>
    </Popconfirm>
  );
};

export default MarkAsPaidButton;
