import { Button, Result } from "antd";
import { routes } from "@/configs";
import { FC, useEffect, useReducer } from "react";
import { useRouteError } from "react-router-dom";
import useCustomNavigate from "@/services/useCustomNavigate";

let x: any = undefined;

const Error: FC = (props) => {
  const { navigate } = useCustomNavigate();
  const error: any = useRouteError();

  const [counter, countDown] = useReducer((state: any) => {
    const newState: any = state - 1;
    return newState;
  }, 10);

  useEffect(() => {
    if (error.status === 404) {
      x = setInterval(countDown, 1000);
    }
  }, [error]);

  useEffect(() => {
    if (counter !== undefined && counter < 0) {
      handleHomeClicked();
    }
  }, [counter]);

  const handleHomeClicked = () => {
    if (x) clearInterval(x);
    navigate(routes.home);
  };

  return error.status === 404 ? (
    <Result
      className="errorPage"
      status="404"
      title="404"
      subTitle={
        <>
          Sorry, the page you visited does not exist. <br />
          <p>Redirecting to home page in {counter}...</p>
        </>
      }
      extra={
        <Button type="primary" onClick={handleHomeClicked}>
          Navigate To Home
        </Button>
      }
    />
  ) : (
    <Result
      status="warning"
      title="There are some problems with your operation."
      extra={
        <Button type="primary" onClick={handleHomeClicked}>
          Navigate To Home
        </Button>
      }
    />
  );
};

export default Error;
