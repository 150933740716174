import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Popconfirm, Table, message } from "antd";
import { deleteFaq, getFaqs } from "@/apis/communication.api";
import { routes } from "@/configs";
import { FC, useEffect, useState } from "react";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";

const AdminFaqList: FC = () => {
  const { navigate, updateQueryParams, queryParams } = useCustomNavigate();

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [faqs, setFaqs] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);

  const [deletingFaq, setDeletingFaq] = useState<boolean>(false);

  useEffect(() => {
    refreshFaqList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [faqs]);

  const refreshFaqList = async () => {
    setLoading(true);

    const response = await getFaqs();
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setFaqs(data.value);
      }
    }

    setLoading(false);
  };

  const handleDeleteFaq = async (record: any) => {
    setDeletingFaq(true);

    const response = await deleteFaq({ id: record.id });
    if (response.ok) {
      message.success("Successfully deleted FAQ");
      refreshFaqList();
    } else {
      message.error("Failed to delete FAQ");
    }

    setDeletingFaq(false);
  };

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Order",
        key: "order",
        dataIndex: "order",
        width: 50,
        render: (val: any, record: any) => val ?? "N/A",
      },
      {
        title: "Title",
        key: "title",
        dataIndex: "title",
        width: 400,
        render: (val: any, record: any) => val,
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        render: (val: any, record: any) => <>{val.length > 50 ? `${val.substring(0, 50)}...` : val}</>,
      },
      {
        title: "Actions",
        render: (val: any, record: any) => (
          <>
            <Button style={{ paddingLeft: 0, paddingRight: 5 }} type="link" onClick={() => handleEditFaq(record)}>
              Edit
            </Button>
            {" | "}
            <Popconfirm title="Delete FAQ" description="Are you sure you want to delete this FAQ?" onConfirm={() => handleDeleteFaq(record)} okText="Yes" cancelText="No">
              <Button disabled={deletingFaq} loading={deletingFaq} style={{ paddingLeft: 5 }} type="link">
                Delete
              </Button>
            </Popconfirm>
          </>
        ),
      },
    ];

    setColumns(tempColumns);
  };

  const handleFaqAdd = () => {
    dispatch(
      addBreadcrumb({
        type: `FAQ / Add`,
        url: `faqs/add`,
      })
    );
    navigate(routes.adminFaqsAdd);
  };

  const handleEditFaq = (record: any) => {
    // Add view breadcrumb
    dispatch(
      addBreadcrumb({
        type: `${record.title}`,
        url: `faq/${record.id}/edit`,
      })
    );

    navigate(routes.adminFaqsEdit, { id: record.id });
  };

  return (
    <Card
      className="adminFaqList"
      id="adminFaqList"
      title="Frequently Asked Questions"
      extra={
        <>
          <Button onClick={handleFaqAdd} type="primary" icon={<PlusOutlined />}>
            Add FAQ
          </Button>
        </>
      }
    >
      <Table rowKey={(row: any) => row.id} loading={loading} columns={columns} dataSource={faqs ?? []} size="small" />
    </Card>
  );
};

export default AdminFaqList;
