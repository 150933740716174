import { removePlaceOfUse, removeWaterRightPlaceOfUse } from "@/apis/waterright.api";
import PlaceOfUseAddModal from "@/components/PlaceOfUseAddModal/PlaceOfUseAddModal";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import useListPlaceOfUseQuery from "@/queries/useListPlaceOfUseQuery";
import useSelectedCompanyData from "@/queries/useSelectedCompanyData";
import useCustomNavigate from "@/services/useCustomNavigate";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { useIntersectionObserver, useWindowSize } from "@uidotdev/usehooks";
import { Button, Card, Empty, Input, Popconfirm, Space, Table, message } from "antd";
import { FC, useEffect, useMemo, useState } from "react";

interface Props {
  places?: any[];
  refreshWaterRightData?: () => void;
  waterRightId?: string;
  companyId: string;
}

const PlaceOfUseTable: FC<Props> = (props) => {
  const { places, waterRightId = null, refreshWaterRightData, companyId } = props;

  const { navigate, updateQueryParams, queryParams } = useCustomNavigate();

  // const [placesOfUse, setPlacesOfUse] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [placeOfUseModalState, setPlaceOfUseModalState] = useState<any>({
    open: false,
  });
  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");

  const { companyConfig } = useComapnyConfigLookup(companyId);

  const [placesOfUseDownRef, placesOfUseDownEntry] = useIntersectionObserver();

  const windowSize = useWindowSize();

  const {
    placesOfUseList: tempPlacesOfUse,
    isLoading,
    invalidatePlacesOfUse,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    totalItemCount,
  } = useListPlaceOfUseQuery({
    companyId: companyId ?? null,
    searchString,
  });

  // const {
  //   placesOfUse: tempPlacesOfUse,
  //   isLoading: isPlacesOfUseLoading,
  //   invalidatePlacesOfUse,
  // } = usePlaceOfUsePagedList({
  //   pagination: {
  //     page: isChild ? queryParamState.page : queryParams?.page ?? "1",
  //     pageSize: isChild ? queryParamState.pageSize : queryParams?.pageSize ?? "10",
  //   },
  //   // searchString,
  //   companyId: companyId ?? null,
  // });

  const placesOfUse: any[] = useMemo(() => {
    const filteredPlacesOfUse: string[] = [];
    const placesOfUseToMap = places ? places : tempPlacesOfUse[0]?.placesOfUse;

    // Handle if table tempPlacesOfUse or just places
    if (searchString) {
      placesOfUseToMap?.map((placeOfUse: string) => {
        if (placeOfUse.toLowerCase().includes(searchString.toLowerCase())) filteredPlacesOfUse.push(placeOfUse);
      });
    } else placesOfUseToMap?.map((placeOfUse: string) => filteredPlacesOfUse.push(placeOfUse));

    // const placesOfUseToMap = places ? places : tempPlacesOfUse?.list[0]?.placesOfUse;
    const data = filteredPlacesOfUse
      ?.sort((a: string, b: string) => a.localeCompare(b, "en", { numeric: true, sensitivity: "base" }))
      ?.map((place: any) => {
        return { name: place };
      });

    return data;
  }, [tempPlacesOfUse, places, searchString]);

  useEffect(() => {
    if (placesOfUseDownEntry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line
  }, [placesOfUseDownEntry?.time, placesOfUseDownEntry?.isIntersecting, hasNextPage]);

  useEffect(() => {
    calculateColumns();
  }, [tempPlacesOfUse, places]);

  const handleRemovePlaceOfUse = async (placeOfUse: string) => {
    setLoading(true);

    const response = waterRightId ? await removeWaterRightPlaceOfUse({ waterRightId, placeOfUse }) : await removePlaceOfUse({ companyId: companyId, placeOfUse });
    if (response.ok) {
      if (!waterRightId) {
        handleRefresh();
      } else refreshWaterRightData && refreshWaterRightData();
      message.success("Successfully removed place of use");
    } else message.error("Failed to remove place of use");

    setLoading(false);
  };

  // const refreshPlacesOfUse = async (searchString: any = null) => {
  //   setLoading(true);

  //   if (places) {
  //     const filteredPlacesOfUse: string[] = [];

  //     if (searchString) {
  //       places?.map((placeOfUse: string) => {
  //         if (placeOfUse.toLowerCase().includes(searchString.toLowerCase())) filteredPlacesOfUse.push(placeOfUse);
  //       });
  //     } else places?.map((placeOfUse: string) => filteredPlacesOfUse.push(placeOfUse));

  //     // refreshWaterRightData && refreshWaterRightData();
  //     setPlacesOfUse(
  //       filteredPlacesOfUse
  //         ?.sort((a: string, b: string) => a.localeCompare(b, "en", { numeric: true, sensitivity: "base" }))
  //         ?.map((place) => {
  //           return { name: place };
  //         })
  //     );
  //   } else {
  //     const response = await getPlaceOfUse(selectedCompanyId);
  //     if (response.ok) {
  //       const data = await response.json();
  //       const filteredPlacesOfUse: string[] = [];

  //       if (searchString) {
  //         data.value?.placesOfUse?.map((placeOfUse: string) => {
  //           if (placeOfUse.toLowerCase().includes(searchString.toLowerCase())) filteredPlacesOfUse.push(placeOfUse);
  //         });
  //       } else data.value?.placesOfUse?.map((placeOfUse: string) => filteredPlacesOfUse.push(placeOfUse));

  //       setPlacesOfUse(
  //         filteredPlacesOfUse?.length > 0
  //           ? filteredPlacesOfUse
  //               .sort((a: string, b: string) =>
  //                 a.localeCompare(b, "en", {
  //                   numeric: true,
  //                   sensitivity: "base",
  //                 })
  //               )
  //               ?.map((placeOfUse: any) => {
  //                 return { name: placeOfUse };
  //               })
  //           : []
  //       );
  //     }
  //   }

  //   setLoading(false);
  // };

  const calculateColumns = () => {
    let tempColumns: any[] = [
      {
        title: "Place of Use",
        key: "name",
        dataIndex: "name",
        render: (val: any, record: any, index: any) => (
          <>
            {val}
            {index === placesOfUse?.length - 1 ? <span ref={placesOfUseDownRef} /> : null}
          </>
        ),
      },
    ];

    if (canEdit)
      tempColumns.push({
        title: "Actions",
        width: 80,
        render: (val: any, record: any) => (
          <Popconfirm
            title="Remove place of use"
            description={`Are you sure you want to remove this place of use${waterRightId ? " from this water right?" : "?"}`}
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleRemovePlaceOfUse(record.name)}
          >
            <Button style={{ paddingLeft: 0 }} type="link">
              Remove
            </Button>
          </Popconfirm>
        ),
      });

    setColumns(tempColumns);
  };

  // const handleEditPlaceOfUse = () => {
  //     dispatch(addBreadcrumb({
  //         type: `Place Of Use / Add`,
  //         url: `placeofuse/add`
  //     }))
  //     navigate(routes.placeOfUseAdd, { id: selectedCompanyId });
  // }

  const handlePlaceOfUseClose = () => {
    setPlaceOfUseModalState({ open: false });
  };

  const handlePlaceOfUseSuccess = () => {
    message.success("Successfully added place of use");
    handleRefresh();
    setPlaceOfUseModalState({ open: false });
  };

  const handleRefresh = () => {
    setSearchString("");
    setSearchStringValue("");
    invalidatePlacesOfUse();
  };

  const { canEdit } = useSelectedCompanyData(companyId);

  return (
    <Card
      styles={{
        body: {
          // height: places ? "calc(100vh - 720px)" : "calc(100vh - 320px)",
          padding: 0,
        },
      }}
      className="placeOfUseList"
      id="placeOfUseList"
      title={
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <span>Places of Use</span>
          <Space direction="horizontal">
            <Space>
              <Input.Search
                disabled={loading}
                placeholder="Search places of use"
                onSearch={(val) => {
                  setSearchString(val);
                }}
                onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                value={searchStringValue}
                style={{ width: 400 }}
                allowClear
              />
              <Button icon={<ReloadOutlined />} disabled={loading} onClick={handleRefresh}>
                Refresh
              </Button>
            </Space>
          </Space>

          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingRight: 16 }}>Total: {placesOfUse?.length ?? "-"}</span>
            {canEdit && (
              <Button onClick={() => setPlaceOfUseModalState({ open: true })} type="primary" icon={<PlusOutlined />}>
                Add Place of Use
              </Button>
            )}
          </span>
        </div>
      }
    >
      <Table
        className="customScrollBarStyle"
        rowKey={(row: any) => row.name}
        loading={isLoading || loading || isFetchingNextPage}
        columns={columns}
        dataSource={placesOfUse ?? []}
        scroll={{ y: places && places.length > 0 ? "calc(100vh - 600px)" : "calc(100vh - 385px)" }}
        // scroll={{ y: windowSize?.height ? windowSize.height - 450 : 600 }}
        // style={{
        //   height: 400,
        // }}
        size="small"
        pagination={false}
        locale={{
          emptyText: <Empty description="No Places of Use" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
        }}
      />
      {placeOfUseModalState.open && (
        <PlaceOfUseAddModal
          companyId={companyId}
          open={placeOfUseModalState.open}
          master={waterRightId ? false : true}
          // masterPlacesOfUse={!waterRightId ? (places ? places?.map((placeOfUse: any) => placeOfUse?.name) : tempPlacesOfUse?.list?.map((placeOfUse: any) => placeOfUse?.name)) : null}
          masterPlacesOfUse={!waterRightId ? placesOfUse?.map((placeOfUse: any) => placeOfUse?.name) : undefined}
          waterRightPlacesOfUse={waterRightId ? places : undefined}
          waterRightId={waterRightId ?? null}
          onCancel={handlePlaceOfUseClose}
          onSuccess={handlePlaceOfUseSuccess}
          refreshWaterRightData={refreshWaterRightData}
        />
      )}
    </Card>
  );
};

export default PlaceOfUseTable;
