import { Button, Dropdown, Space } from "antd";
import { IdentityRole } from "@/dtos/user.dto";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@/stores";
import { setSelectedRole } from "@/stores/user.store";

interface RoleSelectorProps {
  loading: boolean;
}

function RoleSelector(props: RoleSelectorProps) {
  const dispatch = useAppDispatch();

  const { loading } = props;
  const { profile, selectedRole } = useSelector((state: any) => state.user);

  const haveMasterAdminAccess = profile?.roles?.includes(IdentityRole.MasterAdministrator.role);

  return haveMasterAdminAccess ? (
    <Space.Compact block style={{ marginRight: 20 }}>
      <Button style={{ pointerEvents: "none" }}>Active Role</Button>
      <Dropdown
        menu={{
          items:
            profile?.roles?.map((role: any) => ({
              label: role === selectedRole ? (IdentityRole[role]?.label ?? "Role not supported") + " (Active)" : IdentityRole[role]?.label ?? "Role not supported",
              key: role,
              disabled: role === selectedRole,
            })) || [],
          onClick: (e) => {
            dispatch(setSelectedRole(e.key));
          },
          style: { width: 250 },
        }}
        placement="bottomRight"
        trigger={["click"]}
        disabled={!loading && !selectedRole && profile?.roles?.length === 0}
      >
        <Button loading={loading} disabled={!loading && !selectedRole && profile?.roles?.length === 0}>
          {loading ? "Loading..." : IdentityRole[selectedRole]?.label ?? "Role not supported"}
        </Button>
      </Dropdown>
    </Space.Compact>
  ) : (
    <></>
  );
}

export default RoleSelector;
