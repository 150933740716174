import { getPagedCompanyUsers } from "@/apis/company.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

type CompanyUsersList = {
  searchString?: string;
  isActive?: boolean;
  companyId?: string;
};

const useCompanyUsersQuery = (props: CompanyUsersList) => {
  const { profile } = useSelector((state: any) => state.user);

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const { data, isFetching, isFetched, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading } = useInfiniteQuery({
    queryKey: [
      CacheKeys.companyUsers,
      CacheKeys.list,
      {
        profileId: profile?.id,
        searchString: props.searchString,
        companyId: props.companyId ?? selectedCompanyId,
        isActive: props.isActive,
      },
    ],
    queryFn: async ({ pageParam }) => {
      const response = await getPagedCompanyUsers({
        ...props,
        companyId: props.companyId ?? selectedCompanyId,
        page: pageParam,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch company users infinite query");
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    gcTime: 30 * 1000, //30 seconds
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage?.pagination?.hasNextPage) return undefined;
      return lastPageParam + 1;
    },
  });

  const invalidateUsers = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.companyUsers, CacheKeys.list],
    });
  }, [queryClient]);

  const companyUsers = useMemo(() => data?.pages?.map((page) => page.list).flat() ?? [], [data]);
  const currentItemCount = companyUsers.length;
  const totalItemCounts = data?.pages?.map((page) => page.pagination?.totalItemCount).flat() ?? [];

  return {
    companyUsers,
    isLoading,
    currentItemCount,
    totalItemCount: totalItemCounts[totalItemCounts.length - 1],
    fetchNextPage,
    invalidateUsers,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
    isFetched,
  };
};

export default useCompanyUsersQuery;
