import React, { useEffect } from "react";
import useCustomNavigate from "./useCustomNavigate";
import useRoleBasedFeature, { RoleBasedFeature } from "./useRoleBasedFeature";

const useRequiredFeature = (feature: RoleBasedFeature) => {
  const [hasRole, setHasRole] = React.useState<boolean>();
  const { navigate } = useCustomNavigate();

  const hasAccessToFeature = useRoleBasedFeature();

  useEffect(() => {
    if (hasRole !== undefined && hasRole !== null && !hasRole) {
      navigate("/admin/dashboard");
    }
  }, [hasRole, navigate]);

  useEffect(() => {
    if (hasAccessToFeature) setHasRole(!!hasAccessToFeature?.[feature]);
  }, [hasAccessToFeature, feature]);
};

export default useRequiredFeature;
