import { get, post, put } from "@/services/request";

export const getWellReadings = (request: any) => {
  return post(`well/reading/list`, request);
};

export const addWellReading = (request: any) => {
  return post("well/reading", request);
};

export const updateWellReading = (id: string, request: any) => {
  return put(`well/reading/update/${id}`, request);
};

export const getWellReading = (id: string) => {
  return get(`well/reading/${id}`);
};

export const validateWellReading = (id: any) => {
  return put(`well/reading/${id}/validate`, undefined);
};

export const updateWellReadingImages = (request: any) => {
  return post(`well/image/update`, request);
};

export const archiveWellReading = (request: any) => {
  return put(`well/reading/archive`, request);
};
