export const emailValidator = (_: any, emails: any) => {
  let isEmailInvalid = false;

  if (emails !== undefined)
    emails.forEach((email: string) => {
      if (!isValidEmail(email)) {
        isEmailInvalid = true;
      }
    });

  if (isEmailInvalid) return Promise.reject("An invalid email was entered.");
  else return Promise.resolve();
};

const isValidEmail = (emailAddress: string, noneIsValid = false) => {
  if (noneIsValid && (!emailAddress || emailAddress.trim() === "")) return true;
  const sQtext = "[^\\x0d\\x22\\x5c\\x80-\\xff]";
  const sDtext = "[^\\x0d\\x5b-\\x5d\\x80-\\xff]";
  const sAtom = "[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+";
  const sQuotedPair = "\\x5c[\\x00-\\x7f]";
  const sDomainLiteral = `\\x5b(${sDtext}|${sQuotedPair})*\\x5d`;
  const sQuotedString = `\\x22(${sQtext}|${sQuotedPair})*\\x22`;
  const sDomainRef = sAtom;
  const sSubDomain = `(${sDomainRef}|${sDomainLiteral})`;
  const sWord = `(${sAtom}|${sQuotedString})`;
  const sDomain = `${sSubDomain}(\\x2e${sSubDomain})*`;
  const sLocalPart = `${sWord}(\\x2e${sWord})*`;
  const sAddrSpec = `${sLocalPart}\\x40${sDomain}`; // complete RFC822 email address spec
  const sValidEmail = `^${sAddrSpec}$`; // as whole string
  const reValidEmail = new RegExp(sValidEmail);
  return reValidEmail.test(emailAddress);
};

export default isValidEmail;
