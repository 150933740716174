import { Button, Space, notification } from "antd";
import RegistrationModal from "@/components/RegistrationModal/RegistrationModal";
import { FC, useState } from "react";
import useWindowSize from "@/services/useWindowSize";
interface Props {
  title: string;
  description?: string;
  backgroundImageUri: string;
}

const PageHeader: FC<Props> = (props) => {
  const size = useWindowSize();

  const [registrationModalState, setRegistrationModalState] = useState<any>({
    data: undefined,
    open: false,
  });

  const [api, contextHolder] = notification.useNotification();

  const handleRegistrationCancel = () => {
    setRegistrationModalState({
      ...registrationModalState,
      open: false,
      data: undefined,
    });
  };

  const openRegistrationCompleteNotification = () => {
    handleRegistrationCancel();

    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button type="default" onClick={() => api.destroy(key)}>
          Close
        </Button>
      </Space>
    );
    api.success({
      message: "Signup success",
      description: "A confirmation email has been sent to your inbox, please follow the instructions in the email to confirm your account.",
      btn,
      key,
      duration: 0,
      placement: "top",
    });
  };

  const { title, description, backgroundImageUri } = props;
  return (
    <div
      style={{
        position: "relative",
        paddingTop: 180,
        paddingBottom: 180,
        backgroundImage: `url(${backgroundImageUri})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundBlendMode: "overlay",
        backgroundColor: "rgba(0,0,0,0.46)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {contextHolder}
      <h2
        style={{
          width: "80%",
          textAlign: "center",
          wordWrap: "break-word",
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
          border: 0,
          outline: 0,
          verticalAlign: "baseline",
          background: "transparent",
          paddingBottom: 10,
          lineHeight: "1em",
          fontFamily: "Raleway, Helvetica, Arial, Lucida, sans-ser,f",
          color: "#fff",
          fontWeight: 300,
          marginTop: 0,
          fontSize: size.width > 700 ? 72 : 56,
          textShadow: "0.08em 0.08em 0.08em rgba(0, 0, 0, 0.4)",
        }}
      >
        {title}
      </h2>
      {description && (
        <p
          style={{
            width: "100%",
            maxWidth: 450,

            lineHeight: "1.7em",
            fontFamily: "Montserrat, Helvetica, Arial, Lucida, sans-serif",
            textAlign: "center",
            wordWrap: "break-word",
            textShadow: "0 1px 3px rgba(0, 0, 0, .3)",
            fontWeight: 400,
            color: "#fff",
            boxSizing: "border-box",
            margin: 0,
            padding: 0,
            border: 0,
            outline: 0,
            verticalAlign: "baseline",
            background: "transparent",
            paddingBottom: 0,
            fontSize: 18,
          }}
        >
          {description}
        </p>
      )}
      {size.width <= 1280 && (
        <div style={{ position: "absolute", top: 20 }}>
          <Button
            id="navRegisterBtn"
            type="primary"
            onClick={() =>
              setRegistrationModalState({
                ...registrationModalState,
                open: true,
              })
            }
            style={{ width: 150, fontSize: 18, fontWeight: 500, height: 42 }}
          >
            Register
          </Button>
        </div>
      )}
      <div
        style={{
          background:
            "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI0NHB4IiB2aWV3Qm94PSIwIDAgMTI4MCAxNDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI0ZGRkZGRiI+PHBhdGggZD0iTTEyODAgMy40QzEwNTAuNTkgMTggMTAxOS40IDg0Ljg5IDczNC40MiA4NC44OWMtMzIwIDAtMzIwLTg0LjMtNjQwLTg0LjNDNTkuNC41OSAyOC4yIDEuNiAwIDMuNFYxNDBoMTI4MHoiIGZpbGwtb3BhY2l0eT0iLjMiLz48cGF0aCBkPSJNMCAyNC4zMWM0My40Ni01LjY5IDk0LjU2LTkuMjUgMTU4LjQyLTkuMjUgMzIwIDAgMzIwIDg5LjI0IDY0MCA4OS4yNCAyNTYuMTMgMCAzMDcuMjgtNTcuMTYgNDgxLjU4LTgwVjE0MEgweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0xMjgwIDUxLjc2Yy0yMDEgMTIuNDktMjQyLjQzIDUzLjQtNTEzLjU4IDUzLjQtMzIwIDAtMzIwLTU3LTY0MC01Ny00OC44NS4wMS05MC4yMSAxLjM1LTEyNi40MiAzLjZWMTQwaDEyODB6Ii8+PC9nPjwvc3ZnPg==')",
          position: "absolute",
          bottom: 0,
          left: 0,
          height: 44,
          width: "100%",
          marginBottom: -2,
        }}
      />
      {size.width <= 1280 && registrationModalState.open && (
        <RegistrationModal onSuccess={openRegistrationCompleteNotification} open={registrationModalState.open} onCancel={handleRegistrationCancel} />
      )}
    </div>
  );
};

export default PageHeader;
