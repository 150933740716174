import { deleteWaterRightAdjustmentTransfer, getWaterRightAdjustmentTransfers, getWaterRights } from "@/apis/waterright.api";
import { getWellAdjustmentTransfers, getWells } from "@/apis/well.api";
import AdjustmentAddModal from "@/components/AdjustmentAddModal/AdjustmentAddModal";
import AuditLogTable, { AuditLogType } from "@/components/AuditLogTable/AuditLogTable";
import LookupLabel from "@/components/LookupLabel/LookupLabel";
import StatusTag from "@/components/StatusTag/StatusTag";
import { constants, routes } from "@/configs";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Popconfirm, Space, Table, message } from "antd";
import dayjs from "dayjs";
import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import "./AdjustmentTable.scss";

interface Props {
  refreshWaterRightCalculations?: () => void;
  refreshWaterRightViewCalculations?: () => void;
  wellId?: string;
  waterRightId?: string;
  canEdit: boolean;
  companyId: string;
}

const AdjustmentTable: FC<Props> = (props) => {
  const { refreshWaterRightCalculations, refreshWaterRightViewCalculations, wellId, waterRightId, canEdit, companyId } = props;

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const [tempAdjustments, setAdjustments] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [wells, setWells] = useState<any[]>([]);
  const [waterRights, setWaterRights] = useState<any[]>([]);
  const [adjustmentModalState, setAdjustmentModalState] = useState<any>({
    open: false,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [refreshAuditLogs, setRefreshAuditLogs] = useState<boolean>(false);
  const [waterRightsLoading, setWaterRightsLoading] = useState<boolean>(false);
  const [wellsLoading, setWellsLoading] = useState<boolean>(false);
  const [deleteAdjustmentTransfer, setDeleteAdjustmentTransfer] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { navigate } = useCustomNavigate();

  const adjustments: any[] = useMemo(() => {
    const data = tempAdjustments.map((adjustment: any) => {
      const well = wells.find((well) => well.id === adjustment.wellId)?.name;
      const toWell = wells.find((well) => well.id === adjustment.toWellId)?.name;
      const waterRight = waterRights.find((waterRight) => waterRight.id === adjustment.waterRightId)?.fileNumber;
      const toWaterRight = waterRights.find((waterRight) => waterRight.id === adjustment.toWaterRightId)?.fileNumber;

      return {
        ...adjustment,
        well: well ? well : null,
        toWell: toWell ? toWell : null,
        waterRight: waterRight ? waterRight : null,
        toWaterRight: toWaterRight ? toWaterRight : null,
      };
    });

    return data;
  }, [tempAdjustments, wells, waterRights, wellId, waterRightId]);

  useEffect(() => {
    refreshWaterRights();
    refreshWells();
  }, []);

  useEffect(() => {
    refreshAdjustments();
  }, [wellId, waterRightId]);

  useEffect(() => {
    calculateAdjustmentsColumns();
  }, [adjustments, tempAdjustments]);

  const refreshAdjustments = async () => {
    setLoading(true);

    if (refreshWaterRightCalculations) refreshWaterRightCalculations();
    if (refreshWaterRightViewCalculations) refreshWaterRightViewCalculations();

    if (wellId) {
      const response = await getWellAdjustmentTransfers(wellId);
      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) setAdjustments(data.value);
      }
    } else if (waterRightId) {
      const response = await getWaterRightAdjustmentTransfers(waterRightId);
      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) setAdjustments(data.value);
      }
    }

    setLoading(false);
  };

  const refreshWaterRights = async () => {
    setWaterRightsLoading(true);
    const request = { companyId: selectedCompanyId };
    const response = await getWaterRights(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) setWaterRights(data.value);
    }
    setWaterRightsLoading(false);
  };

  const refreshWells = async () => {
    setWellsLoading(true);
    const request = { companyId: selectedCompanyId };
    const response = await getWells(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWells(data.value);
      }
    }
    setWellsLoading(false);
  };

  const calculateAdjustmentsColumns = () => {
    let tempColumns: any[] = [
      {
        title: "Date",
        key: "date",
        dataIndex: "date",
        width: 150,
        render: (val: any, record: any) => dayjs(val).format(constants.dateTimeFormat),
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        width: 150,
        render: (val: any, record: any) => (val === "transfer" ? "Transfer" : val === "adjustment" ? "Adjustment" : "Penalty"),
      },
      {
        title: "Details",
        render: (val: any, record: any) => {
          if (record?.type === "transfer") {
            return (
              <>
                Transfered {record?.quantity} <LookupLabel lookupType={"meterUnits"} value={record?.quantityUnits} /> from {wellId ? "Well" : "Water Right"}{" "}
                {record?.wellId ? record?.well : record?.waterRight} to {wellId ? "Well" : "Water Right"}
                <Button
                  style={{ paddingRight: 5, paddingLeft: 5 }}
                  type="link"
                  onClick={() => {
                    if (record?.toWaterRightId) {
                      dispatch(
                        addBreadcrumb({
                          type: `${record.toWaterRight}`,
                          url: `waterright/${record.toWaterRightId}/view`,
                        })
                      );
                      navigate(routes.waterRightView, {
                        id: record.toWaterRightId,
                      });
                    } else if (record?.toWellId) {
                      dispatch(
                        addBreadcrumb({
                          type: `${record.toWell}`,
                          url: `well/${record.toWellId}/view`,
                        })
                      );
                      navigate(routes.wellView, { id: record.toWellId });
                    }
                  }}
                >
                  {record?.toWellId ? record?.toWell : record?.toWaterRight}
                </Button>
                for the year {dayjs(record?.date).format("YYYY")}
              </>
            );
          } else if (record?.type === "adjustment") {
            return (
              <>
                {record?.adjustmentType?.toLowerCase() === "subtraction" ? "Increased Total Used by" : "Decreased Total Used by"} {record?.quantity}{" "}
                <LookupLabel lookupType={"meterUnits"} value={record?.quantityUnits} /> for the year {dayjs(record?.date).format("YYYY")}
              </>
            );
          } else {
            return (
              <>
                Penalty of {record?.quantity} <LookupLabel lookupType={"meterUnits"} value={record?.quantityUnits} /> for the year {dayjs(record?.date).format("YYYY")}
              </>
            );
          }
        },
      },
      {
        title: "Notes",
        key: "notes",
        dataIndex: "notes",
        render: (val: any, record: any) => val,
      },
      {
        title: "Completed",
        key: "completed",
        dataIndex: "completed",
        align: "center",
        render: (val: any, record: any) => <StatusTag status={val ? "Yes" : "No"} color={val ? "green" : "red"} />,
      },
    ];

    if (canEdit)
      tempColumns.push({
        title: "Actions",
        width: 150,
        render: (val: any, record: any) => (
          <>
            <Button style={{ padding: 0, paddingRight: 10 }} type="link" onClick={() => handleEditAdjustment(record)}>
              Edit
            </Button>
            {canEdit && ` | `}
            {canEdit && (
              <Popconfirm
                title="Delete adjustment"
                description="Are you sure you want to delete this adjustment?"
                onConfirm={() => handleDeleteAdjustmentTransfer(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button loading={deleteAdjustmentTransfer} disabled={deleteAdjustmentTransfer} style={{ paddingLeft: 5 }} type="link">
                  Delete
                </Button>
              </Popconfirm>
            )}
          </>
        ),
      });

    setColumns(tempColumns.filter((x) => x));
  };

  const handleEditAdjustment = (record: any) => {
    setAdjustmentModalState({ open: true, adjustmentId: record.id });
  };

  const handleDeleteAdjustmentTransfer = async (record: any) => {
    setDeleteAdjustmentTransfer(true);

    const response = await deleteWaterRightAdjustmentTransfer({
      id: record.id,
    });
    if (response.ok) {
      message.success("Successfully deleted adjustment");
    } else message.error("Failed to delete adjustment");

    setDeleteAdjustmentTransfer(false);
    refreshAdjustments();
  };

  const handleAdjustmentClose = () => {
    setAdjustmentModalState({
      open: false,
    });

    setRefreshAuditLogs(true);
    refreshAdjustments();
    setRefreshAuditLogs(false);
  };

  const handleAdjustmentSuccess = () => {
    if (adjustmentModalState?.adjustmentId) message.success("Updated adjustment reading successfully");
    else message.success("Added adjustment reading successfully");

    handleAdjustmentClose();
  };

  const renderExpandData = (record: any) => {
    return (
      <>
        {canEdit && wellId && <AuditLogTable entityId={record.id} entityType={AuditLogType.WellAdjustmentTransfer} refresh={loading} />}
        {canEdit && waterRightId && <AuditLogTable entityId={record.id} entityType={AuditLogType.WaterRightAdjustmentTransfer} refresh={loading} />}
      </>
    );
  };

  return (
    <Card
      styles={{ body: { padding: 0 } }}
      title={`Adjustments`}
      extra={
        <Space>
          <Button icon={<ReloadOutlined />} disabled={loading} onClick={refreshAdjustments}>
            Refresh
          </Button>
          {canEdit && (
            <Button type="primary" icon={<PlusOutlined />} disabled={loading} onClick={() => setAdjustmentModalState({ open: true })}>
              Add
            </Button>
          )}
        </Space>
      }
    >
      <Table
        rowKey={(row: any) => row.id}
        loading={loading}
        columns={columns}
        dataSource={adjustments || []}
        size="small"
        rowClassName={(record: any) => (!record.completed ? "incomplete" : "")}
        expandable={{
          onExpand(expanded, record) {},
          expandedRowRender: (record: any) => renderExpandData(record),
        }}
      />
      {adjustmentModalState.open && (
        <AdjustmentAddModal
          companyId={companyId}
          id={adjustmentModalState.adjustmentId}
          waterRightId={waterRightId}
          wellId={wellId}
          open={adjustmentModalState.open}
          onCancel={handleAdjustmentClose}
          onSuccess={handleAdjustmentSuccess}
        />
      )}
    </Card>
  );
};

export default AdjustmentTable;
