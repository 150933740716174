import { Anchor, Col, Row, Typography } from "antd";
import Title from "antd/es/typography/Title";
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./PrivacyPolicy.scss";
import useCustomNavigate from "@/services/useCustomNavigate";
import useWindowSize from "@/services/useWindowSize";

const links = [
  {
    key: "privacyPolicySubTitle1",
    href: "#privacyPolicySubTitle1",
    title: "1. Our commitment to your privacy",
  },
  {
    key: "privacyPolicySubTitle2",
    href: "#privacyPolicySubTitle2",
    title: "2. The kinds of information that we collect and process",
  },
  {
    key: "privacyPolicySubTitle3",
    href: "#privacyPolicySubTitle3",
    title: "3. How we collect Personal Data",
  },
  {
    key: "privacyPolicySubTitle4",
    href: "#privacyPolicySubTitle4",
    title: "4. Cookies",
  },
  {
    key: "privacyPolicySubTitle5",
    href: "#privacyPolicySubTitle5",
    title: "5. How we use Personal Data",
  },
  {
    key: "privacyPolicySubTitle6",
    href: "#privacyPolicySubTitle6",
    title: "6. Legal basis for Processing Personal Data",
  },
  {
    key: "privacyPolicySubTitle7",
    href: "#privacyPolicySubTitle7",
    title: "7. How we use credit information",
  },
  {
    key: "privacyPolicySubTitle8",
    href: "#privacyPolicySubTitle8",
    title: "8. The circumstances in which we may disclose Personal Data",
  },
  {
    key: "privacyPolicySubTitle9",
    href: "#privacyPolicySubTitle9",
    title: "9. International Transfer of Personal Data",
  },
  {
    key: "privacyPolicySubTitle10",
    href: "#privacyPolicySubTitle10",
    title: "10. Security of your Personal Data",
  },
  {
    key: "privacyPolicySubTitle11",
    href: "#privacyPolicySubTitle11",
    title: "11. Third parties",
  },
  {
    key: "privacyPolicySubTitle12",
    href: "#privacyPolicySubTitle12",
    title: "12. Access to and correction of your information",
  },
  {
    key: "privacyPolicySubTitle13",
    href: "#privacyPolicySubTitle13",
    title: "13. How You Can Manage Your Privacy",
  },
  {
    key: "privacyPolicySubTitle14",
    href: "#privacyPolicySubTitle14",
    title: "14. Privacy of Children",
  },
  {
    key: "privacyPolicySubTitle15",
    href: "#privacyPolicySubTitle15",
    title: "15. Retention of Information",
  },
  {
    key: "privacyPolicySubTitle16",
    href: "#privacyPolicySubTitle16",
    title: "16. Marketing",
  },
  {
    key: "privacyPolicySubTitle17",
    href: "#privacyPolicySubTitle17",
    title: "17. Privacy Policy Updates",
  },
  {
    key: "privacyPolicySubTitle18",
    href: "#privacyPolicySubTitle18",
    title: "18. Specific Mobile Application Terms",
  },
  {
    key: "privacyPolicySubTitle19",
    href: "#privacyPolicySubTitle19",
    title: "19. California Resident Users",
  },
  {
    key: "privacyPolicySubTitle20",
    href: "#privacyPolicySubTitle20",
    title: "20. Connecticut Users",
  },
  {
    key: "privacyPolicySubTitle21",
    href: "#privacyPolicySubTitle21",
    title: "21. Contact Us",
  },
];

const PrivacyPolicy: FC = () => {
  const { navigate } = useCustomNavigate();

  const location = useLocation();
  const size = useWindowSize();

  const [isMobile, setIsMobile] = useState<boolean>(true);

  useEffect(() => {
    if (!location?.pathname?.includes("/mobile/")) setIsMobile(false);
    if (size.width < 700) setIsMobile(true);
  }, [location, size]);

  return (
    <Row id="privacyPolicy">
      <Col id="data" span={isMobile ? 24 : 18}>
        <Title id="privacyPolicyTitle">Privacy Policy</Title>
        <Title id="privacyPolicySubTitle1" className="title" level={2}>
          1. Our commitment to your privacy
        </Title>
        <Typography>
          VandWater, Inc. ("VandWater," “we,” “our,” or “us”) recognize/s that your privacy is very important to you, and we are committed to protecting your (“you,’' "your") Personal Data.
          <div className="break" />
          “Personal Data” means any information relating to an identified or identifiable natural person; an identifiable natural person is one who can be identified, directly or indirectly, in
          particular by reference to an identifier such as a name, an identification number, location data, an online identifier, or to one or more factors specific to the physical, physiological,
          genetic, mental, economic, cultural, or social identity of that natural person.
          <div className="break" />
          This privacy policy (“Privacy Policy”) describes the Personal Data that VandWater or its service providers collect, how it is processed, as well as the purposes for such processing.
          <div className="break" />
          VandWater offers a range of services (“Services”) consisting of the services offered by VandWater or its subsidiaries, now or in the future, and any content including, without limitation,
          water product service offerings, water management offerings, irrigation product service offerings, water data, water permit information, price information, connectivity, contract
          confirmation, and messaging.
          <div className="break" />
          The Services may be made available to you through any of the existing and future electronic systems, APIs, SMS platforms, and other platforms (collectively, the “Systems”).
          <div className="break" />
          This privacy policy (the “Privacy Policy”) applies to Personal Data we receive and handle about participants in connection with:
          <ul>
            <li>Your use of this website and mobile applications (collectively, our “Site”)</li>
            <li>Communication with VandWater</li>
            <li>Marketing messages</li>
            <li>Your use of VandWater products or Services</li>
            <li>Participation in binding contract offers and acceptances, market/water data, price information and pricing contracts, messaging</li>
            <li>Ability to connect with VandWater representatives or advisors</li>
            <li>Use of the APIs, SMS, and other platforms through which VandWater makes the Services available</li>
          </ul>
          By providing Personal Data, using our Site, or accessing Systems and/or Services, you agree to be bound by the terms of this Privacy Policy.
        </Typography>

        <Title id="privacyPolicySubTitle2" className="title" level={2}>
          2. The kinds of information that we collect and process
        </Title>
        <Typography>
          The kinds of Personal Data we collect and process, by or on behalf of us, include:
          <div className="break" />
          <b>Contact Information</b>, such as: your name, address, telephone number, email address.
          <div className="break" />
          <b>Personal Details Information</b>, such as: your name, profession, and job title.
          <div className="break" />
          <b>Technical Information</b>, such as: technical data collected about your interaction with our websites, mobile applications, and email communications including IP address, log files,
          unique device identifiers, pages viewed, date and time of access, content of request, username, password, usage data, device type, browser type and settings, language settings, aggregate
          statistical information, transferred volume of data, and website requesting access.
          <div className="break" />
          <b>Marketing Data</b>, such as: cookie data, browsing history, advertising interests, responses to direct marketing, and opt-outs from direct marketing.
          <div className="break" />
          <b>Grower/User/Consumer Data</b>, such as: Well data, Water Permit/File information, Irrigation data, Agronomic data, Land/Field/Acre data, and GPS/Satellite imagery data.
          <div className="break" />
          <b>Financial Information</b>, such as: payment information, credit card details, purchase interests, and purchase history.
          <div className="break" />
          <b>Credit Information</b>: If applicable, as based upon your participation in VandWater, we may collect credit information. By applying for our services, you agree that we may obtain this
          credit information from you or from a credit reporting body. This information may include:
          <div className="break" />
          <ul>
            <li>Default information</li>
            <li>Credit infringement information</li>
            <li>Personal solvency information (e.g., bankruptcy information)</li>
            <li>Court proceedings information (e.g., any default judgments entered against you)</li>
            <li>Repayment history information</li>
          </ul>
          You can choose not to provide your Personal Data to us, but generally the information we request from you is required for us to provide you the goods and Services we offer.
        </Typography>

        <Title id="privacyPolicySubTitle3" className="title" level={2}>
          3. How we collect Personal Data
        </Title>
        <Typography>
          Generally, we collect Personal Data from:
          <div className="break" />
          <b>Direct Interactions</b>: Such as when you contact us, inquire about our products or Services, participate in surveys or questionnaires.
          <div className="break" />
          <b>Mobile Applications</b>: Such as when you download or use one of our apps. Some apps may have specific privacy statements that include more specific information that you should review.
          Section 18 contains additional app specific terms.
          <div className="break" />
          <b>Cookies and Automated Technologies</b>: Such as when you visit or interact with our Site or click on links on emails or text messages.
          <div className="break" />
          <b>Public Sources</b>: Such as when we need to collect Personal Data from publicly available sources, including land registries and government agencies.
          <div className="break" />
          <b>Social Media</b>: Such as Personal Data that you make available by linking to social media platforms using social media plug-ins, or your social media profile(s).
          <div className="break" />
          <b>Grower/User/Consumer Technologies</b>: Such as when you use or receive any service offerings that include any well or surface water Information, water permit/file information, data
          recording, land/field information, mapping or GPS/satellite technologies, and State of Federal Government farm or water divisions/agencies, or private and/or quasi-government water boards or
          associations.
          <div className="break" />
          <b>Products and Services</b>: Such as when you provide us with information in connection with our products and Services, as well as information gathered through our Services.
          <div className="break" />
          <b>Private Third-Party Sources</b>: Such as when third parties provide information to us.
        </Typography>

        <Title id="privacyPolicySubTitle4" className="title" level={2}>
          4. Cookies
        </Title>
        <Typography>
          Regarding Site use, components of our Site may use “cookies” (these are files that are implanted in your hard drive by some websites to store information about your web use patterns) to
          enhance or personalize your use of our Site. Cookies allow for the collection of information such as:
          <div className="break" />
          <ul>
            <li>Browser type</li>
            <li>Time spent on our Site</li>
            <li>Pages visited</li>
            <li>Language preferences</li>
          </ul>
          These cookies may also be used to collect and store information about your usage of certain components of our Site. We or our service providers may in any case collect anonymous information
          that allows us to review information about the date, time, and duration of visits to our components of our Site without identifying you.
          <div className="break" />
          Pixel tags, web beacons, clear GIFs, or other similar technologies may be used in connection with our Site to track the way you use our Site and to compile statistics about site usage and
          response rates. This information is generally automatically provided to us by your web browser.
          <div className="break" />
          You may choose to refuse cookies by turning them off in the browser; however, if you do not accept them, you may experience some difficulty in your use of certain components of our Site.
          <div className="break" />
          We may use cookies to:
          <ul>
            <li>Improve the functionality of our Site</li>
            <li>Process your purchase orders</li>
            <li>Display information more effectively</li>
            <li>Personalize your experience while using our Site</li>
            <li>Gather information of how our Site is used and compile anonymous and aggregate statistics</li>
          </ul>
        </Typography>

        <Title id="privacyPolicySubTitle5" className="title" level={2}>
          5. How we use Personal Data
        </Title>
        <Typography>
          The primary purpose(s) we collect your Personal Data is to:
          <div className="break" />
          <ul>
            <li>Verify your identity</li>
            <li>Respond to your queries</li>
            <li>Maintain our relationship with you</li>
            <li>Provide our products and Services to you, including without limitation, pricing products</li>
            <li>Send you statements and invoices</li>
            <li>Collect payments from you</li>
            <li>Provide you with information about our products and Services</li>
            <li>Update you on changes to our Services terms, conditions, policies, and other administrative information</li>
            <li>Conduct research for our own internal purposes</li>
            <li>Request credit information from a credit reporting body</li>
            <li>Enable the creation of bids, offers, and acceptance to the extent applicable</li>
            <li>Send you communications and contact you about our goods and Services</li>
            <li>Programs, events, campaigns, functions, or news updates that may be relevant or of interest to you</li>
            <li>Administering, maintaining, and monitoring Site performance and usage</li>
          </ul>
          You agree that we may use your Personal Data for any of these purposes.
        </Typography>

        <Title id="privacyPolicySubTitle6" className="title" level={2}>
          6. Legal basis for Processing Personal Data
        </Title>
        <Typography>
          The legal basis for processing your Personal Data in connection with the purposes may, depending on the circumstances, rely on any of the following legal bases:
          <div className="break" />
          <ul>
            <li>The processing is necessary in connection with a contract entered into with you, or for your benefit</li>
            <li>We or a partner has obtained your prior written consent to the processing</li>
            <li>The processing is required by laws, statutes, or regulations applicable to us</li>
            <li>
              We have a legitimate interest in carrying out the processing (including our legitimate interest in providing goods and Services to you, maintaining or improving our products or Services,
              operating our business, alerting you to other products or services that may be of interest to you) and there is no undue risk to your right, freedoms, or interests
            </li>{" "}
            <li></li>The processing is necessary to protect the vital interests of any individual
            <li>Any other lawful basis</li>
          </ul>
        </Typography>

        <Title id="privacyPolicySubTitle7" className="title" level={2}>
          7. How we use credit information
        </Title>
        <Typography>
          To the extent applicable for your participation in VandWater, we use the credit information that we obtain from you or a credit reporting body and information we derive from such information
          strictly for the purpose of assessing your application for credit. If you do not authorize us to obtain credit information about you, we may be unable to assess your creditworthiness and
          therefore your suitability for any prepayment or participation in certain products or programs. In such circumstances, your request for credit may be refused.
          <div className="break" />
          To the extent applicable for your participation in VandWater, we may disclose your Personal Data to credit reporting bodies where we are permitted to do so by law, for example, in
          circumstances where you fail to meet your payment obligations in relation to customer credit. These credit reporting bodies may include any such information in reports provided to other
          credit providers to assist them to assess your credit worthiness.
        </Typography>

        <Title id="privacyPolicySubTitle8" className="title" level={2}>
          8. The circumstances in which we may disclose Personal Data
        </Title>
        <Typography>
          We may disclose Personal Data to related entities, and you may receive communications directly from these entities in order that you may receive information about products, services, and/or
          event(s) that may be of interest to you. These communications may include information and marketing communications about their products and services. We use reasonable endeavors to ensure
          that these companies do not breach any U.S. laws, however, we disclaim all liability for any unlawful use of your Personal Data by an affiliated or associated company.
          <div className="break" />
          We may disclose your Personal Data to the extent that we are required to do so by law, including in connection with any legal proceedings, anticipated legal proceedings or regulatory
          administrative matters, or in order to comply with any legal obligation, or to establish, exercise or defend our legal rights or in response to a request from law enforcement or governmental
          agencies. We may sell, transfer, or otherwise disclose our database of Personal Data to an actual or potential successor entity, purchaser, or investor in connection with a corporate merger,
          consolidation, sale of our assets, or a substantial part of our assets, share sale, investment transaction, or other corporate rearrangement.
          <div className="break" />
          We may disclose your Personal Data to a prospective seller or buyer for the purpose of selling, merging, or transferring all or a portion of our business or assets in order to enable such
          transfer.
          <div className="break" />
          If you make a payment via our Site or one of our apps or directly to us or one of our dealers, your Personal Data may need to be provided to third parties for the purpose of processing such
          payments.
          <div className="break" />
          You agree that we may disclose your Personal Data in any of these circumstances. We disclaim all liability for any privacy breaches by third parties to whom we have disclosed your Personal
          Data in accordance with this policy.
          <div className="break" />
          VandWater will assure that any third-party service provider that VandWater shares or discloses information with will legally be required to provide the same or equal protection of user data
          as provided by VandWater.
          <div className="break" />
          You may withdraw your consent to use or disclose your Personal Data at any time. To withdraw this consent, please contact us at the details below. Please note that withdrawing your consent
          may mean that we are unable to provide you with any services.
        </Typography>

        <Title id="privacyPolicySubTitle9" className="title" level={2}>
          9. International Transfer of Personal Data
        </Title>
        <Typography>
          Personal Data that we collect may be transferred and stored in jurisdictions other than the jurisdiction in which your Personal Data was originally collected, including overseas, and
          transferred between any of the countries in which we, our related entities or our service providers or international distributors operate in connection with the operation of our Site or in
          connection with any of the processing purposes set forth in this Privacy Policy.
          <div className="break" />
          Your Personal Data may be transferred overseas if we sell, transfer, or disclose our database of Personal Data to an actual or potential successor entity, purchaser, or investor who is
          located or has offices overseas in order to enable such actual or potential transfer. It is not practicable to specify the likely countries in which recipients of information may be located
          in this regard.
          <div className="break" />
          These transfers of Personal Data are necessary in order to provide our products and services to you in an effective and efficient manner. The Personal Data will be transferred for the
          operation of our Site or in connection with any of the processing purposes set forth in this Privacy Policy.
        </Typography>

        <Title id="privacyPolicySubTitle10" className="title" level={2}>
          10. Security of your Personal Data
        </Title>
        <Typography>
          Once in our possession, we take all reasonable precautions to protect the personal and credit information we hold about you from misuse, interference, and loss, and unauthorized access,
          modification, or disclosure.
          <div className="break" />
          While we endeavor to provide a secure online environment, there are inherent risks associated with the transmission of information via the internet and no data transmission over the internet
          can be guaranteed to be completely secure. We therefore cannot warrant the security of any information you provide to us over the internet, and you do so at your own risk.
          <div className="break" />
          We encourage you to play an important role in keeping your Personal Data secure by maintaining the confidentiality of any passwords and account details used on our Site. It is your sole
          responsibility to maintain such confidentiality and we will not be liable for any damage, loss, or expense suffered due to such disclosure.
          <div className="break" />
          VandWater processes Personal Data in a manner that ensures appropriate security of the Personal Data, including protection against unauthorized or unlawful processing and against accidental
          loss, destruction, or damage, using appropriate technical or organizational measures. Additionally:
          <ul>
            <li>VandWater has imposed disciplinary measures for violations of the comprehensive information security program rules.</li>
            <li>
              VandWater performs regular monitoring to determine if the information security program is operating in a manner to prevent unauthorized access to or unauthorized use of Personal Data.
            </li>
          </ul>
        </Typography>

        <Title id="privacyPolicySubTitle11" className="title" level={2}>
          11. Third parties
        </Title>
        <Typography>
          With regard to your use of certain components of the Site, certain components of our Site may contain links to third-party websites and social media features that are hosted by a
          third-party. Links to other websites do not constitute sponsorship, endorsement, or approval of the information found on those websites. You should evaluate the accuracy, relevance, and
          suitability for your purposes of any such information. We are not responsible for the privacy policies or practices of third-party websites, and your interactions with any social media
          features are governed by the privacy policies and practices of the hosting entities.
        </Typography>

        <Title id="privacyPolicySubTitle12" className="title" level={2}>
          12. Access to and correction of your information
        </Title>
        <Typography>
          We aim to ensure that your personal and credit information is accurate, complete, and up to date. To assist us, please contact us via the details below if any of your details provided have
          changed or if you believe that the information we hold is inaccurate.
          <div className="break" />
          You may request us to provide you with access to the personal or credit information we hold about you at any time. We will respond to your access request as soon as possible, however, prior
          to disclosing any such information it may be necessary for you to satisfactorily verify your identity.
          <div className="break" />
          There are exceptional circumstances where access to or correction of your personal or credit information may be refused by us, such as where access would be unlawful. We will advise you of
          such circumstances if they arise.
        </Typography>

        <Title id="privacyPolicySubTitle13" className="title" level={2}>
          13. How You Can Manage Your Privacy
        </Title>
        <Typography>
          You may limit the collection of certain website information by deleting or disabling cookies. Most computers' internet browsers enable you to erase stored cookies, block all cookies, or
          receive a warning before a cookie is stored. Disabling cookies may prevent you from using specific features on our Site or other websites, such as ordering products or Services and
          maintaining an online account.
          <div className="break" />
          Please note that many opt outs use browser cookies or device controls and are specific to the device or browser you are using. If you buy a new computer, change web browsers or devices, or
          delete the cookies on your computer, you may need to opt out again.
        </Typography>

        <Title id="privacyPolicySubTitle14" className="title" level={2}>
          14. Privacy of Children
        </Title>
        <Typography>
          We do not knowingly intend to collect information from or direct VandWater to children age 16 and under. If VandWater learns that information has been received from a child under 16, then
          VandWater will delete such information.
        </Typography>

        <Title id="privacyPolicySubTitle15" className="title" level={2}>
          15. Retention of Information
        </Title>
        <Typography>
          VandWater retains your Personal Data only for the period necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or allowed by law or
          to otherwise fulfill a legal obligation. For the purpose of business continuity, VandWater backs up your data within the VandWater infrastructure. As soon as these retention periods are
          complete, the data will be deleted or properly anonymized in order that you may no longer be identified from it.
        </Typography>

        <Title id="privacyPolicySubTitle16" className="title" level={2}>
          16. Marketing
        </Title>
        <Typography>
          VandWater may contact you by phone, email, text, or via other electronic communication to provide you with marketing information and materials about VandWater products or services that may
          be of interest to you. If you do not wish to receive such communications, you may contact VandWater or, as applicable, follow the unsubscribe options provided in the electronic marketing
          communications.
        </Typography>

        <Title id="privacyPolicySubTitle17" className="title" level={2}>
          17. Privacy Policy Updates
        </Title>
        <Typography>
          From time to time, we may change and post revisions to this Privacy Policy and such changes to this Privacy Policy will become effective upon the posting of the revised Privacy Policy on our
          Site. Your use of our Site following these changes means that you accept the revised Privacy Policy.
        </Typography>

        <Title id="privacyPolicySubTitle18" className="title" level={2}>
          18. Specific Mobile Application Terms
        </Title>
        <Typography>
          The following terms are specific and controlling with regard to the types of mobile applications referenced below:
          <div className="break" />
          iOS Mobile Applications (the “iOS Apps”)
          <div className="break" />
          VandWater collects usage data and technical information to conduct mobile analytics for internal use in order to increase the usability of the iOS Apps. This data will not be shared with
          third parties or utilized to track users across other third-party apps unless the iOS Apps first request and obtain your permission before collecting analytics and providing you with the
          right to opt-out at any time for any tracking or sharing purpose. VandWater does not share your location data with third parties except if required by law. The iOS Apps are intended only for
          users in the United States; do not use these applications outside of the United States.
          <div className="break" />
          Android Mobile Applications (the “Android Apps”)
          <div className="break" />
          VandWater collects usage data and technical information to conduct mobile analytics for internal use in order to increase the usability of the Android Apps. This data will not be shared with
          third parties or utilized to track users across other third-party apps unless the Android Apps first provide notice to you of such use for tracking or sharing purposes. VandWater does not
          share your location data with third parties except if required by law. The Android Apps are intended only for users in the United States; do not use this application outside of the United
          States.
        </Typography>

        <Title id="privacyPolicySubTitle19" className="title" level={2}>
          19. California Resident Users
        </Title>
        <Typography>
          If VandWater performs business in the State of California, California residents have rights under the California Consumer Privacy Act and may: request (a) information pertaining to the
          Personal Data about you that VandWater collects, uses, and discloses and (b) deletion of your Personal Data collected by VandWater by contacting VandWater as set forth in the contact section
          or by contacting via phone at 1.785.527.9222. Your exercise of these rights will not result in discriminatory treatment or a lower quality of service to you. VandWater has not sold consumer
          personal data of California residents in the last twelve months.
        </Typography>

        <Title className="title" level={2} id="privacyPolicySubTitle20">
          20. Connecticut Users
        </Title>
        <Typography>
          If VandWater performs business in the state of Connecticut:
          <ul>
            <li>VandWater shall not print your Social Security number on any card required for you to access products or Services</li>
            <li>VandWater shall not transmit your Social Security number over the Internet, unless the connection is secure, or the Social Security number is encrypted</li>
            <li>
              VandWater shall not require you to use your Social Security number to access an Internet website, unless a password or unique personal identification number or another authentication
              device is also required to access the Internet website
            </li>
          </ul>
        </Typography>

        <Title className="title" level={2} id="privacyPolicySubTitle21">
          21. Contact Us
        </Title>
        <Typography>
          If you have any questions or complaints about this Privacy Policy or our treatment of your personal or credit information, or if you would like to access or amend your Personal Data, please
          contact us:
          <div className="break" />
          Privacy Officer, VandWater, Inc.
          <div className="break" />
          1817 E Frontage Road
          <div className="break" />
          Belleville, KS 66935
          <div className="break" />
          <a href="mailto:info@vandwater.com">info@vandwater.com</a>
          <div className="break" />
          This policy will be reviewed from time to time to take account of new laws and/or changes to our operations. Any information we hold about you will be governed by our most current policy. We
          recommend that you periodically review this policy for any changes.
          <div className="break" />
          Last Updated: <i>02.08.2023 - 9:45AM</i>
        </Typography>
      </Col>
      {!isMobile && (
        <Col id="nav" span={6}>
          <Anchor
            offsetTop={50}
            getContainer={() => document.getElementById("contentContainer") || window}
            items={links}
            onClick={(e: any, link: any) => {
              e.preventDefault();
              navigate(link.href);
            }}
          />
        </Col>
      )}
    </Row>
  );
};

export default PrivacyPolicy;
