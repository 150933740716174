import { Button, Card, Divider, List, Typography } from "antd";
import React, { FC } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

type RecentWellsProps = {
  recentWells: any[];
  onClick: (well: any) => void;
};

const RecentWells: FC<RecentWellsProps> = ({ recentWells, onClick }) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Card
      style={{ padding: 0, margin: 0 }}
      styles={{
        body: {
          padding: 0,
          margin: 0,
          width: 200,
          overflow: "hidden",
        },
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", cursor: recentWells?.length > 1 ? "pointer" : "auto", paddingLeft: 10, paddingTop: 5 }}
        onClick={() => setExpanded((current) => !current)}
      >
        <Typography.Title level={5}>Recent Wells</Typography.Title>
        {recentWells?.length > 1 && <Button type="link" icon={expanded ? <UpOutlined /> : <DownOutlined />} />}
      </div>
      <Divider style={{ margin: 0 }} />
      <List
        size="small"
        dataSource={expanded ? recentWells : recentWells.slice(0, 1)}
        renderItem={(item) => (
          <List.Item onClick={() => onClick(item)} style={{ cursor: "pointer" }}>
            {item.label}
          </List.Item>
        )}
        style={{ maxHeight: 400, overflow: "auto" }}
      />
    </Card>
  );
};

export default RecentWells;
