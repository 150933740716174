import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface IWellState {
  wells: string[];
  wellData: any | undefined;
  wellNotes: string[];
}

const initialState: IWellState = {
  wells: [],
  wellData: undefined,
  wellNotes: [],
};

const wellStore = createSlice({
  name: "well",
  initialState,
  reducers: {
    saveWells: (state: IWellState, action: PayloadAction<any>) => {
      state.wells = action.payload;
    },
    saveWell: (state: IWellState, action: PayloadAction<any>) => {
      state.wellData = action.payload;
    },
    clearWellState: (state: IWellState) => {
      state.wellData = undefined;
      state.wells = [];
    },
    saveWellNotes: (state: IWellState, action: PayloadAction<any>) => {
      state.wellNotes = action.payload;
    },
  },
});

export const { saveWells, saveWell, clearWellState, saveWellNotes } = wellStore.actions;

export default wellStore.reducer;
