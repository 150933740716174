import { CSSProperties, FC } from "react";
import GaugeComponent from "react-gauge-component";
import CompanyLabel from "../Lookups/CompanyLabel";
import { Card } from "antd";
import { convertUnits, formatNumber } from "@/services/utils";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import UnitsConverter from "../UnitsConverter/UnitsConverter";
import LookupLabel from "../LookupLabel/LookupLabel";

interface RestrictionRadialGaugeProps {
  header?: string;
  companyId?: string;
  termQuantity: number;
  usedQuantity: number;
  remainingYears: number;
  type: string;
  myfa: boolean;
  borderless?: boolean;
  style?: CSSProperties;
  removeTitles?: boolean;
}

const remainingBlockStyle = {
  height: 70,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  padding: 0,
};

const RestrictionRadialGauge: FC<RestrictionRadialGaugeProps> = (props) => {
  const { header, companyId, termQuantity, usedQuantity, borderless, style, remainingYears, type, myfa, removeTitles = false } = props;

  let remainingQuantity = termQuantity - usedQuantity;
  let remainingPercentage = termQuantity === null || termQuantity === undefined || termQuantity === 0 ? 0 : (remainingQuantity / termQuantity) * 100;

  if (remainingQuantity < 0) remainingQuantity = 0;
  if (remainingPercentage < 0) remainingPercentage = 0;

  const { companyConfig } = useComapnyConfigLookup(companyId);

  const displayUnits = companyConfig?.settings?.metric ?? "acrefeet";

  const availableQuantityInAF = convertUnits(termQuantity, "gallons", displayUnits);

  const formatTextValue = (value: number) => {
    const calculatedValue = convertUnits((termQuantity * value) / 100, "gallons", displayUnits);
    return formatNumber(calculatedValue?.toFixed(displayUnits !== "gallons" ? 2 : 1));
  };

  const scaleTickFont = (value: number, metric: string) => {
    const length = value?.toFixed(0)?.length;
    switch (true) {
      case length > 8:
        return "0.5em";
      case length > 7:
        return "0.6em";
      case length > 6:
        return "0.7em";
      case length > 5:
        return "0.8em";
      case length > 4:
        return "0.9em";
      default:
        return "0.95em";
    }
  };

  return (
    <Card
      styles={{
        body: {
          textAlign: "center",
          padding: 0,
          paddingBottom: 20,
          width: 300,
          ...(style ?? {}),
        },
      }}
      bordered={!borderless}
      className={borderless ? "removeBoxShadow removeMargin" : undefined}
    >
      {header && (
        <div style={{ textAlign: "left", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", paddingBottom: 0 }}>
          <div style={{ flex: 1, fontWeight: "semibold", fontSize: 16 }}>{header}</div>
          {companyId && <div style={{ paddingRight: 10, fontSize: 11 }}>({<CompanyLabel companyId={companyId} />})</div>}
        </div>
      )}
      {header && (
        <div style={{ margin: 0, fontSize: "small" }}>
          <LookupLabel lookupType={"restrictionType"} value={type} /> {myfa && " - MYFA"}
        </div>
      )}
      {
        <GaugeComponent
          marginInPercent={{ bottom: 0, top: 0.1, left: 0.12, right: 0.12 }}
          style={{ width: 285 }}
          type="semicircle"
          arc={{
            cornerRadius: 1,
            width: 0.3,
            subArcs: [
              {
                limit: 10,
                color: "#f32423",
                showTick: true,
              },
              {
                limit: 25,
                color: "#f36823",
                showTick: true,
              },
              {
                limit: 50,
                color: "#fed000",
                showTick: true,
              },
              {
                limit: 75,
                color: "#33c635",
                showTick: true,
              },
              {
                limit: 100,
                color: "#33c635",
                showTick: true,
              },
            ],
          }}
          pointer={{
            color: "black",
            length: 0.9,
            animationDelay: 0,
            animationDuration: 2000,
          }}
          labels={{
            valueLabel: {
              hide: true,
            },
            tickLabels: {
              type: "outer",
              defaultTickValueConfig: {
                formatTextValue: formatTextValue,
                style: {
                  fontSize: scaleTickFont(availableQuantityInAF, displayUnits),
                  fill: "black",
                  textShadow: "none",
                },
              },
              defaultTickLineConfig: {
                char: "_",
                style: {
                  fill: "black",
                  textShadow: "none",
                  fontWeight: "bold",
                },
              },
              ticks: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 75 }],
            },
          }}
          value={remainingPercentage}
          minValue={0}
          maxValue={100}
        />
      }
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", flex: 1, paddingRight: 15, paddingTop: 10 }}>
        {remainingPercentage !== null && remainingPercentage !== undefined && (
          <div className="ant-card ant-card-grid removeShadow" style={{ ...remainingBlockStyle }}>
            {remainingPercentage?.toFixed(2)}
            <br />%
          </div>
        )}
        {remainingYears !== null && remainingYears !== undefined && (
          <div className="ant-card ant-card-grid removeShadow" style={{ ...remainingBlockStyle }}>
            {remainingYears} {remainingYears !== 1 ? "Years" : "Year"} Remaining
          </div>
        )}
        {termQuantity !== null && termQuantity !== undefined && (
          <div className="ant-card ant-card-grid removeShadow" style={{ ...remainingBlockStyle }}>
            <UnitsConverter fromUnits="gallons" toUnits={"acrefeet"} value={remainingQuantity} showUnitsLabel placeholder="Loading..." breakLines />
          </div>
        )}
      </div>
      <br />
    </Card>
  );
};

export default RestrictionRadialGauge;
