import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { IdentityRole } from "@/dtos/user.dto";

export enum RoleBasedFeature {
  All,

  Dashboard,

  Communications,

  Billing,
  BillingUsers,
  BillingEvents,
  BillingReports,
  BillingInvoices,
  BillingElevatedRoles,

  Events,

  Users,
  UserAdd,

  Reports,

  FAQ,

  ContactMessages,

  SupportQueries,

  Lookups,

  SeedEvents,
}

const useRoleBasedFeature = () => {
  const { profile, selectedRole } = useSelector((state: any) => state.user);
  const [accessibleFeatures, setAccesibleFeatures] = React.useState<any>();

  useEffect(() => {
    const uniqueFeatures: any = {};
    if (profile?.roles?.includes(IdentityRole.MasterAdministrator.role)) {
      const features = IdentityRole[selectedRole].features;

      if (features.includes(RoleBasedFeature.All)) {
        Object.keys(RoleBasedFeature).forEach((feature) => {
          uniqueFeatures[feature] = feature;
        });
      } else {
        features.forEach((feature) => {
          uniqueFeatures[feature] = feature;
        });
      }
    } else {
      const features = Object.values(IdentityRole)
        .filter((x) => profile?.roles?.includes(x.role))
        .map((role) => role.features)
        .flat();
      features.forEach((feature) => {
        uniqueFeatures[feature] = feature;
      });
    }

    setAccesibleFeatures(uniqueFeatures);
  }, [profile, selectedRole]);

  return accessibleFeatures;
};

export default useRoleBasedFeature;
