import { WaterRightReportSelector } from "@/components";
import { constants } from "@/configs";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Form, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";

import GeneratedPlacesOfUseReport from "./GeneratedPlacesOfUseReport";
import { useSelector } from "react-redux";

const PlacesOfUseReport: FC = () => {
  const [form] = Form.useForm();
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [reportConfig, setReportConfig] = useState<any>(undefined);
  const [waterRights, setWaterRights] = useState<any[]>([]);

  const waterRightIds: any[] = useWatch("waterRightIds", form);
  const date: any = useWatch("date", form);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    setReportConfig({
      waterRightIds: waterRightIds ?? [],
      waterRights:
        waterRights
          .filter((waterRight: any) => waterRightIds?.includes(waterRight.id))
          ?.sort((a: any, b: any) =>
            a?.fileNumber?.localeCompare(b?.fileNumber, "en", {
              numeric: true,
              sensitivity: "base",
            })
          )
          ?.map((waterRight: any) => waterRight.fileNumber)
          .join(", ") ?? [],
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
      year: date?.year(),
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }} labelWrap={true}>
          <Form.Item label="Year" name="date" rules={[{ required: true, message: "Please enter a year" }]} style={{ margin: 0, marginBottom: 10 }} initialValue={dayjs()}>
            <DatePicker picker="year" style={{ marginRight: 30 }} placeholder="All Readings" allowClear={false} />
          </Form.Item>
          <WaterRightReportSelector
            form={form}
            propertyToSet={"waterRightIds"}
            label={"Water Rights"}
            setWaterRights={setWaterRights}
            placeholder="Leave blank to select all water rights"
            style={{ margin: 0, marginBottom: 0 }}
          />
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedPlacesOfUseReport reportConfig={reportConfig} />}
    </>
  );
};

export default PlacesOfUseReport;
