import { Button, Dropdown, Space } from "antd";
import { concat } from "lodash";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@/stores";
import { saveSelectedCompany, saveSelectedCompanyId } from "@/stores/company.store";
import { clearFieldState } from "@/stores/field.store";
import { clearGroupingState } from "@/stores/grouping.store";
import { clearWellState } from "@/stores/well.store";

interface CompanySelectorProps {
  loading: boolean;
}

function CompanySelector(props: CompanySelectorProps) {
  const dispatch = useAppDispatch();
  const { companies, selectedCompany, canSwitch } = useSelector((state: any) => state.company);

  const { loading } = props;

  const clearCompanyRelatedStates = () => {
    dispatch(clearGroupingState());
    dispatch(clearWellState());
    dispatch(clearFieldState());
  };

  const renderCompanyName = (loading: boolean, selectedCompany: any) => {
    if (loading) {
      return "Loading...";
    } else if (selectedCompany) {
      return selectedCompany._companyId === "all" ? "- None -" : `${selectedCompany?.companyName} - ${selectedCompany?.userRole}`;
    } else return "No Companies Available";
  };

  return (
    <Space.Compact block style={{ marginRight: 20 }}>
      <Button style={{ pointerEvents: "none" }}>Active Company</Button>
      <Dropdown
        menu={{
          items:
            companies?.length === 1
              ? companies?.map((company: any) => ({
                  label: company.id === selectedCompany?.companyId ? company.name + " (Active)" : company.name,
                  key: company.id,
                  disabled: company.id === selectedCompany?.companyId,
                }))
              : concat(
                  [
                    {
                      label: "- None -",
                      key: "all",
                      disabled: selectedCompany?._companyId === "all",
                    },
                  ],
                  companies?.map((company: any) => ({
                    label: company.id === selectedCompany?.companyId ? company.name + " (Active)" : company.name,
                    key: company.id,
                    disabled: company.id === selectedCompany?.companyId,
                  })) || []
                ),
          onClick: (e) => {
            if (e.key === "all" && selectedCompany?._companyId === "all") return;
            else if (e.key === "all") dispatch(saveSelectedCompany({ _companyId: "all" }));
            else dispatch(saveSelectedCompanyId(e.key));
            clearCompanyRelatedStates();
          },
          style: { width: 200 },
        }}
        placement="bottomRight"
        trigger={["click"]}
        disabled={(!loading && !selectedCompany && companies?.length === 0) || !canSwitch}
      >
        <Button
          loading={loading}
          // disabled={true}
          disabled={!loading && !selectedCompany && companies?.length === 0}
        >
          {renderCompanyName(loading, selectedCompany)}
        </Button>
      </Dropdown>
    </Space.Compact>
  );
}

export default CompanySelector;
