import { getField } from "@/apis/field.api";
import { getWaterRightsFromField } from "@/apis/well.api";
import { AuditLogTable, StatusTag, WaterRightTable, WellTable } from "@/components";
import { AuditLogType } from "@/components/AuditLogTable/AuditLogTable";
import PrintPage from "@/components/PrintPage/PrintPage";
import { constants, routes } from "@/configs";
import useSelectedCompanyData from "@/queries/useSelectedCompanyData";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb, removeBreadcrumb, replaceUrlStateBreadcrumbType } from "@/stores/breadcrumbs.store";
import { clearFieldState, saveField } from "@/stores/field.store";
import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Descriptions, Image, Row, Tabs } from "antd";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./FieldView.scss";

interface FieldViewProps {
  showHeader?: boolean;
  overrideId?: string;
}

const FieldView: FC<FieldViewProps> = ({ showHeader = true, overrideId = undefined }) => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  const { fieldData } = useSelector((state: any) => state.field);

  const pathUrl = window.location.pathname;

  const { navigate } = useCustomNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [waterRights, setWaterRights] = useState<any[] | null>(null);
  const [activeKey, setActiveKey] = useState("wells");

  useEffect(() => {
    if (pathUrl.includes("view") && fieldData) {
      dispatch(replaceUrlStateBreadcrumbType(fieldData?.name));
    }
    // eslint-disable-next-line
  }, [pathUrl, fieldData]);

  useEffect(() => {
    return () => {
      dispatch(clearFieldState());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getFieldData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (overrideId ?? fieldData?.id) {
      getWaterRightIdsList();
    }
  }, [overrideId, fieldData]);

  const getFieldData = async () => {
    setLoading(true);
    const response = await getField(overrideId ?? id);

    if (response.ok) {
      const data = await response.json();

      dispatch(saveField(data.value));
    }
    setLoading(false);
  };

  const getWaterRightIdsList = async () => {
    setLoading(true);
    const response = await getWaterRightsFromField(overrideId ?? fieldData?.id);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWaterRights(data.value);
      }
    }
    setLoading(false);
  };

  const navigateEdit = () => {
    dispatch(
      addBreadcrumb({
        type: `Edit`,
        url: `field/${id}/edit`,
      })
    );
    navigate(routes.fieldEdit, { id: overrideId ?? id });
  };

  const navigateBack = () => {
    dispatch(removeBreadcrumb());
    navigate(-1);
  };

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  const renderTabs = (fieldData: any) => {
    let tabs: any[] = [
      {
        label: "Details / Directions",
        key: "directions",
        children: (
          <Card id="directions" title="Details / Directions">
            <Row gutter={[16, 16]}>
              <Col>
                <Descriptions bordered size="small" column={1} labelStyle={{ width: 165 }} style={{ width: "100%", height: "100%" }}>
                  <Descriptions.Item style={{ whiteSpace: "pre-line" }} label="Direction Notes">
                    {fieldData?.notes ? fieldData?.notes : "-"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Direction Images">
                    {fieldData?.imageIds?.length > 0 ? (
                      <Image.PreviewGroup>
                        {fieldData?.imageIds.map((imageId: any) => (
                          <Image
                            style={{
                              textAlign: "center",
                              marginLeft: "auto",
                              marginRight: "auto",
                              alignContent: "center",
                              paddingRight: 5,
                              width: 200,
                            }}
                            src={`${constants.baseApiUrl}/field/image/${imageId}/download?maxWidth=200`}
                            preview={{
                              src: `${constants.baseApiUrl}/field/image/${imageId}/download`,
                            }}
                          />
                        ))}
                      </Image.PreviewGroup>
                    ) : (
                      "-"
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </Card>
        ),
      },
      {
        label: "Wells",
        key: "wells",
        children: activeKey === "wells" && <WellTable header addWell={false} fieldId={overrideId ?? id} />,
      },
      {
        label: "Water Right",
        key: "waterRight",
        children: activeKey === "waterRight" && <WaterRightTable showAddWaterRight={false} showHeader waterRightIds={waterRights ? waterRights : null} />,
      },
    ];

    if (canEdit)
      tabs.push({
        label: "Audit Log",
        key: "auditLog",
        children: <AuditLogTable entityId={overrideId ?? fieldData?.id} entityType={AuditLogType.Field} />,
      });

    return tabs;
  };

  const renderContent = () => {
    return (
      <div>
        <Descriptions
          // asd
          bordered
          size="small"
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          labelStyle={{ width: 200 }}
          style={{ width: 415, height: "100%" }}
          title={
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                padding: 5,
              }}
            >
              Summary
            </div>
          }
          className="removeDescriptionHeaderPadding"
        >
          <Descriptions.Item label="Field Name">{fieldData?.name}</Descriptions.Item>
          <Descriptions.Item label="Status">
            <StatusTag status={fieldData?.status} />
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Tabs defaultActiveKey="1" type="card" size="small" style={{ marginBottom: 32 }} onChange={onChange} items={renderTabs(fieldData)} />
      </div>
    );
  };

  const { canEdit } = useSelectedCompanyData(fieldData?.companyId);

  return (
    <Card
      className="fieldView"
      loading={loading}
      id="fieldView"
      title={showHeader ? `Field - ${loading ? "loading..." : fieldData?.name}` : undefined}
      extra={
        showHeader ? (
          <>
            <Button style={{ marginRight: 5 }} onClick={navigateBack} icon={<LeftOutlined />}>
              Back
            </Button>
            {canEdit && (
              <Button type="primary" onClick={navigateEdit} icon={<EditOutlined />}>
                Edit
              </Button>
            )}
          </>
        ) : undefined
      }
    >
      {showHeader ? <PrintPage content={renderContent()} /> : renderContent()}
    </Card>
  );
};

export default FieldView;
