import { get, post, put } from "@/services/request";

// export const getRestrictions = (searchString: any, companyId: string) => {
//     return get(`restriction/list?companyId=${companyId}${searchString ? '&?searchString=' + searchString : ''}`)
// }

export const getRestrictions = (request: { companyId?: string; searchString?: string; waterRightId?: string; year?: number; isActive?: boolean; restrictionIds?: string[] }) => {
  return post(`restriction/list`, request);
};

export const getRestriction = (id: any) => {
  return get(`restriction/${id}`);
};

export const addRestriction = (request: any) => {
  return post("restriction", request);
};

export const updateRestriction = (id: any, request: any) => {
  return put(`restriction/update/${id}`, request);
};

export const getRestrictionAuditLogs = (id: string) => {
  return get(`restriction/auditlog/${id}/list`);
};

export const getRestrictionsForWaterRight = (id: any) => {
  return get(`restriction/waterright/${id}/restrictions`);
};

export const syncRestrictionCalculations = (data: any) => {
  return post(`restriction/calculations/sync`, data);
};

export const getRestrictionCalculation = (id: any) => {
  return get(`restriction/calculations/${id}`);
};

export const countRestrictions = (companyId?: string) => {
  const queryParams = companyId ? `?companyId=${companyId}` : "";

  return get(`restriction/count` + queryParams);
};

export const manualRenewRestriction = (id: any) => {
  return post(`restriction/renew/${id}`, undefined);
};

export const getPagedRestrictions = (request: any) => {
  return post(`restriction/paged/list`, request);
};

export const getRestrictionsLookup = (request: { restrictionIds?: string[]; companyId?: string; isActive?: boolean | null }) => {
  return post(`restriction/lookup`, request);
};

export const getRestrictionReportLookup = (request: { companyId: string; page: number; pageSize: number; isActive: boolean | null }) => {
  return post(`restriction/report/lookup`, request);
};
