import { constants } from "@/configs";
import { CloseOutlined, PlusOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Divider, Form, Input, InputNumber, message, Modal, Popconfirm, Row, Select, Table, TableProps } from "antd";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import useFinanceDocument from "@/queries/useFinanceDocument";
import FinanceDocumentTypeComponent from "@/pages/Admin/AdminBilling/components/FinanceDocumentTypeComponent";
import FinanceDocumentStatusComponent from "@/pages/Admin/AdminBilling/components/FinanceDocumentStatusComponent";
import { v4 as uuidv4 } from "uuid";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import "./InvoicesModal.scss";
import { isEqual } from "lodash";
import { emailValidator } from "@/utils/isValidEmail";
import { updateFinanceDocument } from "@/apis/aggregator.api";
import { render } from "react-dom";

const { TextArea } = Input;

interface InvoicesModalProps {
  id: string;
  onClose: (reload?: boolean) => void;
}

interface LineItem {
  id: string;
  description: string;
  quantity: number;
  rate: number;
  amount: number;
  additionalDescription?: string;
}

type Payment = {
  id: string;
  date?: Date;
  reference: string;
  description: string;
  source: string;
  amount: number;
};

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  editable: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "textArea" | "date";
  record: LineItem;
  index: number;
  required: boolean;
  requiredText?: string;
  prefix?: string;
}

const EditableCell: FC<PropsWithChildren<EditableCellProps>> = ({ editing, editable, dataIndex, title, inputType, record, index, children, required, requiredText, prefix, ...restProps }) => {
  return (
    <td {...restProps}>
      {editable && editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: required,
              message: requiredText,
            },
          ]}
        >
          {inputType === "date" && <DatePicker style={{ width: "100%" }} format={constants.dateFormat} />}
          {inputType === "number" && <InputNumber style={{ width: "100%" }} decimalSeparator="." prefix={prefix} step={1.0} />}
          {inputType === "text" && <Input />}
          {inputType === "textArea" && (
            <TextArea
              autoSize
              styles={{
                textarea: {
                  maxHeight: "6em",
                },
              }}
            />
          )}
        </Form.Item>
      ) : (
        <>
          {prefix ? prefix + " " : ""}
          {children}
        </>
      )}
    </td>
  );
};

const InvoicesModal: FC<InvoicesModalProps> = ({ id, onClose }) => {
  const { financeDocument, isFetched, isLoading } = useFinanceDocument({ id });

  const [form] = Form.useForm();
  const values = useWatch((x) => x, form);

  const [lineItemForm] = Form.useForm();
  const rate = useWatch("rate", lineItemForm);
  const quantity = useWatch("quantity", lineItemForm);
  const amount = (rate ?? 0) * (quantity ?? 0);

  const [paymentsForm] = Form.useForm();
  const paymentAmount = useWatch("amount", paymentsForm);

  const [lineItems, setLineItems] = useState<LineItem[]>([]);
  const [payments, setPayments] = useState<Payment[]>([]);

  const [editingKey, setEditingKey] = useState<string>();
  const [adding, setAdding] = useState<boolean>(false);

  const [editingPaymentKey, setEditingPaymentKey] = useState<string>();
  const [addingPayment, setAddingPayment] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  const isEditing = (record: LineItem) => record.id === editingKey;
  const isEditingPayment = (record: Payment) => record.id === editingPaymentKey;

  const edit = (record: LineItem) => {
    setEditingKey(record.id);
    lineItemForm.setFieldsValue({ ...record });
  };

  const editPayment = (record: Payment) => {
    setEditingPaymentKey(record.id);
    paymentsForm.setFieldsValue({ ...record });
  };

  const cancel = () => {
    setEditingKey(undefined);
    setAdding(false);
  };

  const cancelPayment = () => {
    setEditingPaymentKey(undefined);
    setAddingPayment(false);
  };

  const save = (id: string) => {
    lineItemForm
      .validateFields()
      .then((values) => {
        setEditingKey(undefined);
        const newData = [...lineItems];
        const index = newData.findIndex((item) => id === item.id);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...values, amount: values.rate * values.quantity });
          setLineItems(newData);
        }
        cancel();
      })
      .catch(() => message.error("Please fill out all required fields."));
  };

  const savePayment = (id: string) => {
    paymentsForm
      .validateFields()
      .then((values) => {
        setEditingPaymentKey(undefined);
        const newData = [...payments];
        const index = newData.findIndex((item) => id === item.id);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...values });
          setPayments(newData);
        }
        cancelPayment();
      })
      .catch(() => message.error("Please fill out all required fields."));
  };

  const handleAdd = () => {
    setAdding(true);
    const newItem: LineItem = {
      id: uuidv4(),
      description: "",
      additionalDescription: "",
      quantity: 1,
      amount: 0,
      rate: 0,
    };
    setLineItems((existingItems) => [...existingItems, newItem]);
    edit(newItem);
  };

  const handleRemove = (id: string) => {
    setLineItems((existingItems) => existingItems.filter((x) => x.id !== id));
  };

  const handleAddPayment = () => {
    setAddingPayment(true);
    const newItem: Payment = {
      id: uuidv4(),
      reference: "",
      description: "",
      source: "",
      amount: 0,
    };
    setPayments((existingItems) => [...existingItems, newItem]);
    editPayment(newItem);
  };

  const handleRemovePayment = (id: string) => {
    setPayments((existingItems) => existingItems.filter((x) => x.id !== id));
  };

  const handleSubmit = () => {
    if (!!editingKey) {
      message.error("Changes cannot be saved while editing or adding a line item");
      return;
    }

    if (!!editingPaymentKey) {
      message.error("Changes cannot be saved while editing or adding a received payment");
      return;
    }

    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        const submitData = {
          ...financeDocument,
          ...values,
          date: dayjs(values.date).format("MM/DD/YYYY"),
          lineItems: lineItems,
          payments: payments,
          totalAmount: lineItems.reduce((acc, item) => acc + item.amount, 0),
        };

        var response = await updateFinanceDocument({ id: financeDocument.id, financeDocument: submitData });

        if (response.ok) {
          message.success("Invoice updated successfully");
          onClose(true);
        } else {
          message.error("Failed to update invoice");
        }

        setLoading(false);
      })
      .catch(() => message.error("Please fill out all required fields."));
  };

  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      editable: true,
      inputType: "text",
      required: true,
      requiredText: "Please input a description!",
    },
    {
      title: "Additional Description",
      dataIndex: "additionalDescription",
      editable: true,
      inputType: "textArea",
      required: false,
      render: (val: string) => (val && val.length > 50 ? val.substring(0, 50) + "..." : val),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      editable: true,
      inputType: "number",
      required: true,
      requiredText: "Please input a quantity!",
      width: 150,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      editable: true,
      inputType: "number",
      required: true,
      requiredText: "Please input a rate!",
      width: 150,
      prefix: "$",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      editable: false,
      width: 150,
      prefix: "$",
      render: (_: any, record: LineItem) => (isEditing(record) ? amount.toFixed(2) : (record.rate * record.quantity).toFixed(2)),
    },
    {
      title: "Actions",
      dataIndex: "operation",
      width: 130,
      render: (_: any, record: LineItem) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Button type="link" style={{ padding: 0, paddingRight: 5 }} onClick={() => save(record.id)}>
              Done
            </Button>
            {" | "}
            {adding ? (
              <Popconfirm
                title="Are you sure you want to remove this line item?"
                onConfirm={() => {
                  cancel();
                  handleRemove(record.id);
                }}
                placement="topLeft"
              >
                <Button type="link" style={{ padding: 0, paddingLeft: 5 }}>
                  Cancel
                </Button>
              </Popconfirm>
            ) : (
              <Button type="link" style={{ padding: 0, paddingLeft: 5 }} onClick={() => cancel()}>
                Cancel
              </Button>
            )}
          </>
        ) : (
          <>
            <Button type="link" style={{ padding: 0, paddingRight: 5 }} disabled={!!editingKey} onClick={() => edit(record)}>
              Edit
            </Button>
            {" | "}
            <Popconfirm title="Are you sure you want to remove this line item?" onConfirm={() => handleRemove(record.id)} placement="topLeft">
              <Button type="link" style={{ padding: 0, paddingLeft: 5 }} disabled={!!editingKey}>
                Remove
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const paymentColumns = [
    {
      title: "Date",
      dataIndex: "date",
      editable: true,
      inputType: "date",
      required: true,
      requiredText: "Please select a date!",
      render: (val: Date) => (val ? dayjs(val).format(constants.dateFormat) : "-"),
    },
    {
      title: "Reference",
      dataIndex: "reference",
      editable: true,
      inputType: "text",
      required: true,
      requiredText: "Please input a reference!",
    },
    {
      title: "Description",
      dataIndex: "description",
      editable: true,
      inputType: "text",
    },
    {
      title: "Source",
      dataIndex: "source",
      editable: true,
      inputType: "text",
      required: true,
      requiredText: "Please input a source!",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      editable: true,
      inputType: "number",
      required: true,
      requiredText: "Please input an amount!",
      width: 150,
      prefix: "$",
      render: (val: any) => val.toFixed(2),
    },
    {
      title: "Actions",
      dataIndex: "operation",
      width: 130,
      render: (_: any, record: Payment) => {
        const editable = isEditingPayment(record);
        return editable ? (
          <>
            <Button type="link" style={{ padding: 0, paddingRight: 5 }} onClick={() => savePayment(record.id)}>
              Done
            </Button>
            {" | "}
            {addingPayment ? (
              <Popconfirm
                title="Are you sure you want to remove this line item?"
                onConfirm={() => {
                  cancelPayment();
                  handleRemovePayment(record.id);
                }}
                placement="topLeft"
              >
                <Button type="link" style={{ padding: 0, paddingLeft: 5 }}>
                  Cancel
                </Button>
              </Popconfirm>
            ) : (
              <Button type="link" style={{ padding: 0, paddingLeft: 5 }} onClick={() => cancelPayment()}>
                Cancel
              </Button>
            )}
          </>
        ) : (
          <>
            <Button type="link" style={{ padding: 0, paddingRight: 5 }} disabled={!!editingPaymentKey} onClick={() => editPayment(record)}>
              Edit
            </Button>
            {" | "}
            <Popconfirm title="Are you sure you want to remove this line item?" onConfirm={() => handleRemovePayment(record.id)} placement="topLeft">
              <Button type="link" style={{ padding: 0, paddingLeft: 5 }} disabled={!!editingPaymentKey}>
                Remove
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const mergedColumns: TableProps<LineItem>["columns"] = columns.map((col) => {
    return {
      ...col,
      onCell: (record: LineItem) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        required: col.required,
        requiredText: col.requiredText,
        prefix: col.prefix,
        editable: col.editable,
      }),
    };
  });

  const mergedPaymentColumns: TableProps<Payment>["columns"] = paymentColumns.map((col) => {
    return {
      ...col,
      onCell: (record: Payment) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditingPayment(record),
        required: col.required,
        requiredText: col.requiredText,
        prefix: col.prefix,
        editable: col.editable,
      }),
    };
  });

  useEffect(() => {
    if (financeDocument) {
      form.setFieldsValue({
        number: financeDocument.number,
        date: dayjs(financeDocument.date, "MM-DD-YYYY"),
        billToEmail: financeDocument.billToEmail,
        billTo: financeDocument.billTo,
        terms: financeDocument.terms,
        annual: financeDocument.annual,
      });
      setLineItems(financeDocument.lineItems);
      setPayments(financeDocument.payments.map((x: any) => ({ ...x, date: typeof x.date === "string" ? dayjs(x.date) : x.date })));
    }
  }, [financeDocument, form]);

  useEffect(() => {
    if (financeDocument && values) {
      const original = {
        number: financeDocument.number,
        date: financeDocument.date,
        billToEmail: financeDocument.billToEmail,
        billTo: financeDocument.billTo,
        lineItems: financeDocument.lineItems,
        terms: financeDocument.terms,
        annual: financeDocument.annual,
      };
      const updated = {
        number: values.number,
        date: dayjs(values.date).format("MM-DD-YYYY"),
        billToEmail: values.billToEmail,
        billTo: values.billTo,
        lineItems: lineItems,
        terms: values.terms,
        annual: values.annual,
      };
      if (isEqual(original, updated)) setChanged(false);
      else setChanged(true);
    }
  }, [financeDocument, lineItems, values]);

  const total = lineItems.filter((x) => x.id !== editingKey).reduce((acc, item) => acc + item.amount, 0) + amount;
  const totalPayments = payments.filter((x) => x.id !== editingPaymentKey).reduce((acc, item) => acc + item.amount, 0) + (paymentAmount ?? 0);

  return (
    <Modal
      open
      width={"95%"}
      title={
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", fontSize: 20, fontWeight: "bold" }}>
          <div>
            <FinanceDocumentTypeComponent type={financeDocument?.type} />
          </div>
          <div>
            <FinanceDocumentStatusComponent status={financeDocument?.status} />
          </div>
        </div>
      }
      okText="Save Changes"
      cancelText="Cancel"
      onOk={handleSubmit}
      onCancel={() => {
        onClose(false);
      }}
      closable={false}
      maskClosable={false}
      cancelButtonProps={{
        icon: <CloseOutlined />,
        disabled: loading,
        loading: loading,
      }}
      okButtonProps={{
        icon: <ThunderboltOutlined />,
        disabled: !changed || loading || !!editingKey || !!editingPaymentKey,
        loading: loading,
      }}
      styles={{
        body: {
          padding: 0,
          paddingBottom: 20,
          maxHeight: "calc(100vh - 300px)",
          overflowY: "auto",
        },
      }}
    >
      {isFetched && (
        <Form form={form} labelWrap={true} layout="horizontal" name="invoiceForm">
          <Divider orientation="left">Invoice Details</Divider>
          <Card className="removeBoxShadow removeMargin">
            <Row>
              <Col span={12}>
                <Form.Item
                  labelCol={{ span: 6 }}
                  label="Invoice Number"
                  name="number"
                  rules={[
                    {
                      required: true,
                      message: "Please input an invoice number",
                    },
                  ]}
                  style={{ marginBottom: "5px" }}
                >
                  <InputNumber style={{ width: "100%" }} placeholder="Invoice number" min={0} formatter={(value) => `${value}`.replace(",", "").replace(".", "")} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 6 }}
                  label="Date"
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: "Please input a date for the invoice",
                    },
                  ]}
                  style={{ marginBottom: "5px" }}
                >
                  <DatePicker style={{ width: "100%" }} placeholder="Select the date of the adjustment" format={constants.dateFormat} allowClear />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 6 }}
                  label="Terms"
                  name="terms"
                  rules={[
                    {
                      required: true,
                      message: "Please input the invoice terms",
                    },
                  ]}
                  style={{ marginBottom: "5px" }}
                >
                  <Input placeholder="Terms" />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 6 }}
                  label="Annual Terms"
                  name="annual"
                  rules={[
                    {
                      required: true,
                      message: "Please input the invoice annual terms",
                    },
                  ]}
                >
                  <Input placeholder="Annual terms" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  labelCol={{ span: 6 }}
                  label="Bill To Email"
                  name="billToEmail"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please input atleast one email address.",
                    },
                    {
                      message: "An invalid email was entered.",
                      validator: emailValidator,
                    },
                  ]}
                  style={{ marginBottom: "5px" }}
                >
                  <Select mode="tags" maxTagCount={5} style={{ width: "100%" }} placeholder="Billing Emails" />
                </Form.Item>
                <Form.Item labelCol={{ span: 6 }} label="Bill To" name="billTo" style={{ marginBottom: "5px" }}>
                  <TextArea placeholder="Bill to" autoSize autoFocus={false} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      )}
      <Form form={lineItemForm} labelWrap={true} layout="horizontal" name="lineItemForm">
        <Divider orientation="left">Line Items</Divider>
        <Card className="removeBoxShadow removeMargin">
          <Table
            loading={isLoading}
            size="small"
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={lineItems}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
            footer={() => (
              <Button type="dashed" icon={<PlusOutlined />} disabled={!!editingKey} onClick={handleAdd} style={{ padding: 0, width: "100%", borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
                Add Line Item
              </Button>
            )}
            style={{ marginTop: 20 }}
            summary={() => (
              <>
                <Table.Summary.Row style={{ fontWeight: "bold" }}>
                  <Table.Summary.Cell colSpan={6} index={0}>
                    {" "}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row style={{ fontWeight: "bold" }}>
                  <Table.Summary.Cell colSpan={4} index={0}>
                    Total
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={2} index={1}>
                    $ {(total > 0 ? total : 0).toFixed(2)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )}
          />
        </Card>
      </Form>

      <Form form={paymentsForm} labelWrap={true} layout="horizontal" name="lineItemForm">
        <Divider orientation="left">Payments Received</Divider>
        <Card className="removeBoxShadow removeMargin">
          <Table
            loading={isLoading}
            size="small"
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={payments}
            columns={mergedPaymentColumns}
            rowClassName="editable-row"
            pagination={false}
            footer={() => (
              <Button
                type="dashed"
                icon={<PlusOutlined />}
                disabled={!!editingPaymentKey}
                onClick={handleAddPayment}
                style={{ padding: 0, width: "100%", borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
              >
                Add Payment Received
              </Button>
            )}
            style={{ marginTop: 20 }}
            summary={() => (
              <>
                <Table.Summary.Row style={{ fontWeight: "bold" }}>
                  <Table.Summary.Cell colSpan={6} index={0}>
                    {" "}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row style={{ fontWeight: "bold" }}>
                  <Table.Summary.Cell colSpan={4} index={0}>
                    Total
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={2} index={1}>
                    $ {(total > 0 ? total : 0).toFixed(2)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row style={{ fontWeight: "bold" }}>
                  <Table.Summary.Cell colSpan={4} index={0}>
                    Total Payments Received
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={2} index={1}>
                    $ ({totalPayments.toFixed(2)})
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row style={{ fontWeight: "bold" }}>
                  <Table.Summary.Cell colSpan={4} index={0}>
                    Total Outstanding
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={2} index={1}>
                    $ {((total > 0 ? total : 0) - totalPayments).toFixed(2)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )}
          />
        </Card>
      </Form>
    </Modal>
  );
};

export default InvoicesModal;
