import { RetweetOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Tooltip } from "antd";
import { FC, memo, useState } from "react";

type GenerateAnnualSubscriptionButtonProps = {
  userIds: string[];
  name: string;
  email: string;
  onGenerateAnnualSubscription: (userIds: string[], year: number, onCompleted: () => void) => void;
};

const GenerateAnnualSubscriptionButton: FC<GenerateAnnualSubscriptionButtonProps> = (props) => {
  const { userIds, email, name, onGenerateAnnualSubscription } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const year = new Date().getFullYear();

  return (
    <Popconfirm
      placement="topRight"
      title={"Generate Annual Subscription"}
      description={
        <pre style={{ fontFamily: "inherit" }}>
          Are you sure you want to generate an annual subscription for the year {year}?
          <br />
          <br />
          Name: {name}
          <br />
          Email: {email?.toLowerCase()}
        </pre>
      }
      onConfirm={() => {
        setLoading(true);
        onGenerateAnnualSubscription(userIds, year, () => setLoading(false));
      }}
      okText={"Generate annual subscription"}
      cancelText="No"
    >
      <Tooltip title={"Generate annual subscription " + year} placement="left" style={{ width: 400 }} overlayInnerStyle={{ width: 200, textAlign: "center" }}>
        <Button loading={loading} style={{ paddingLeft: 0, paddingRight: 0 }} type="link" icon={<RetweetOutlined />} />
      </Tooltip>
    </Popconfirm>
  );
};

export default memo(GenerateAnnualSubscriptionButton);
