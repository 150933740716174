import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { DatePicker, Form, Input, Modal, message } from "antd";
import { listOccupancy, upsertOccupancy } from "@/apis/stock.api";
import dayjs from "dayjs";
import { FC, useEffect, useRef, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
interface Props {
  stockId: any;
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}

const OccupancyAddEditModal: FC<Props> = (props) => {
  const { stockId, open, onSuccess, onCancel } = props;

  const [form] = Form.useForm();

  const user = useAuth();

  const { selectedCompany } = useSelector((state: any) => state.company);

  const [loading, setLoading] = useState<boolean>(true);
  const [occupancyData, setOccupancyData] = useState<any[]>([]);
  const [date, setDate] = useState<any>(dayjs());

  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (stockId) refreshOccupanyData();
    else setLoading(false);
  }, [stockId, date]);

  useEffect(() => {
    if (occupancyData.length > 0) {
      form.setFieldsValue({
        jan: occupancyData?.find((occupany) => occupany?.month === 1)?.headCount ?? undefined,
        feb: occupancyData?.find((occupany) => occupany?.month === 2)?.headCount ?? undefined,
        mar: occupancyData?.find((occupany) => occupany?.month === 3)?.headCount ?? undefined,
        apr: occupancyData?.find((occupany) => occupany?.month === 4)?.headCount ?? undefined,
        may: occupancyData?.find((occupany) => occupany?.month === 5)?.headCount ?? undefined,
        jun: occupancyData?.find((occupany) => occupany?.month === 6)?.headCount ?? undefined,
        jul: occupancyData?.find((occupany) => occupany?.month === 7)?.headCount ?? undefined,
        aug: occupancyData?.find((occupany) => occupany?.month === 8)?.headCount ?? undefined,
        sep: occupancyData?.find((occupany) => occupany?.month === 9)?.headCount ?? undefined,
        oct: occupancyData?.find((occupany) => occupany?.month === 10)?.headCount ?? undefined,
        nov: occupancyData?.find((occupany) => occupany?.month === 11)?.headCount ?? undefined,
        dec: occupancyData?.find((occupany) => occupany?.month === 12)?.headCount ?? undefined,
      });
    } else {
      form.resetFields();
    }
  }, [occupancyData]);

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const refreshOccupanyData = async () => {
    setLoading(true);

    const request = {
      stockId,
      year: date?.year(),
    };

    const response = await listOccupancy(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setOccupancyData(data.value);
      }
    }

    setLoading(false);
  };

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        const submitData = {
          year: date.year(),
          stockId,
          occupancy: [
            {
              id: occupancyData?.find((occupancy) => occupancy?.month === 1)?.id ?? null,
              month: 1,
              headCount: values.jan !== "" ? values.jan ?? null : null,
            },
            {
              id: occupancyData?.find((occupancy) => occupancy?.month === 2)?.id ?? null,
              month: 2,
              headCount: values.feb !== "" ? values.feb ?? null : null,
            },
            {
              id: occupancyData?.find((occupancy) => occupancy?.month === 3)?.id ?? null,
              month: 3,
              headCount: values.mar !== "" ? values.mar ?? null : null,
            },
            {
              id: occupancyData?.find((occupancy) => occupancy?.month === 4)?.id ?? null,
              month: 4,
              headCount: values.apr !== "" ? values.apr ?? null : null,
            },
            {
              id: occupancyData?.find((occupancy) => occupancy?.month === 5)?.id ?? null,
              month: 5,
              headCount: values.may !== "" ? values.may ?? null : null,
            },
            {
              id: occupancyData?.find((occupancy) => occupancy?.month === 6)?.id ?? null,
              month: 6,
              headCount: values.jun !== "" ? values.jun ?? null : null,
            },
            {
              id: occupancyData?.find((occupancy) => occupancy?.month === 7)?.id ?? null,
              month: 7,
              headCount: values.jul !== "" ? values.jul ?? null : null,
            },
            {
              id: occupancyData?.find((occupancy) => occupancy?.month === 8)?.id ?? null,
              month: 8,
              headCount: values.aug !== "" ? values.aug ?? null : null,
            },
            {
              id: occupancyData?.find((occupancy) => occupancy?.month === 9)?.id ?? null,
              month: 9,
              headCount: values.sep !== "" ? values.sep ?? null : null,
            },
            {
              id: occupancyData?.find((occupancy) => occupancy?.month === 10)?.id ?? null,
              month: 10,
              headCount: values.oct !== "" ? values.oct ?? null : null,
            },
            {
              id: occupancyData?.find((occupancy) => occupancy?.month === 11)?.id ?? null,
              month: 11,
              headCount: values.nov !== "" ? values.nov ?? null : null,
            },
            {
              id: occupancyData?.find((occupancy) => occupancy?.month === 12)?.id ?? null,
              month: 12,
              headCount: values.dec !== "" ? values.dec ?? null : null,
            },
          ],
        };

        handleSubmitOccupancyData(submitData);
      })
      .catch((e) => {
        message.error("An error occured during validation");
        setLoading(false);
      });
  };

  const handleSubmitOccupancyData = async (data: any) => {
    const response = await upsertOccupancy(data);
    if (response.ok) {
      onSuccess && onSuccess();
    } else {
      message.error(`Failed to update head count for the year ${date.year()}`);
    }

    setLoading(false);
  };

  return (
    <Modal
      width={"600px"}
      open={open}
      title={
        <div
          style={{ width: "100%", cursor: "move" }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {`Update Head Counts - ${date.year()}`}
        </div>
      }
      okText="Save"
      cancelText="Cancel"
      onOk={handleSubmit}
      onCancel={onCancel}
      maskClosable={false}
      cancelButtonProps={{
        icon: <CloseOutlined />,
        disabled: loading,
        loading: loading,
      }}
      okButtonProps={{
        icon: <ThunderboltOutlined />,
        disabled: loading,
        loading: loading,
      }}
      modalRender={(modal) => (
        <Draggable disabled={disabled} bounds={bounds} nodeRef={draggleRef} onStart={(event, uiData) => onStart(event, uiData)}>
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <Form name="basic" labelCol={{ span: 4 }} form={form} layout="horizontal" initialValues={{ date: date }}>
        <Form.Item
          label="Year"
          name="date"
          rules={[
            {
              required: true,
              message: "Please select the date reading was taken",
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} allowClear={false} picker="year" onChange={setDate} placeholder="Select year for head count" />
        </Form.Item>
        <Form.Item style={{ marginBottom: "5px" }} label="January" name="jan">
          <Input type="number" min={0} allowClear disabled={loading} style={{ width: "100%" }} placeholder="Enter January head count (Leave blank to use year average)" />
          {/* <InputNumber disabled={loading} style={{ width: '100%' }} placeholder="Enter January head count (Leave blank to use year average)" /> */}
        </Form.Item>
        <Form.Item style={{ marginTop: "5px", marginBottom: "5px" }} label="February" name="feb">
          <Input type="number" min={0} allowClear disabled={loading} style={{ width: "100%" }} placeholder="Enter February head count (Leave blank to use year average)" />
          {/* <InputNumber disabled={loading} style={{ width: '100%' }} placeholder="Enter February headcount (Leave blank to use year average)" /> */}
        </Form.Item>
        <Form.Item style={{ marginTop: "5px", marginBottom: "5px" }} label="March" name="mar">
          <Input type="number" min={0} allowClear disabled={loading} style={{ width: "100%" }} placeholder="Enter March head count (Leave blank to use year average)" />
          {/* <InputNumber disabled={loading} style={{ width: '100%' }} placeholder="Enter March headcount (Leave blank to use year average)" /> */}
        </Form.Item>
        <Form.Item style={{ marginTop: "5px", marginBottom: "5px" }} label="April" name="apr">
          <Input type="number" min={0} allowClear disabled={loading} style={{ width: "100%" }} placeholder="Enter April head count (Leave blank to use year average)" />
          {/* <InputNumber disabled={loading} style={{ width: '100%' }} placeholder="Enter April headcount (Leave blank to use year average)" /> */}
        </Form.Item>
        <Form.Item style={{ marginTop: "5px", marginBottom: "5px" }} label="May" name="may">
          <Input type="number" min={0} allowClear disabled={loading} style={{ width: "100%" }} placeholder="Enter May head count (Leave blank to use year average)" />
          {/* <InputNumber disabled={loading} style={{ width: '100%' }} placeholder="Enter May headcount (Leave blank to use year average)" /> */}
        </Form.Item>
        <Form.Item style={{ marginTop: "5px", marginBottom: "5px" }} label="June" name="jun">
          <Input type="number" min={0} allowClear disabled={loading} style={{ width: "100%" }} placeholder="Enter June head count (Leave blank to use year average)" />
          {/* <InputNumber disabled={loading} style={{ width: '100%' }} placeholder="Enter June headcount (Leave blank to use year average)" /> */}
        </Form.Item>
        <Form.Item style={{ marginTop: "5px", marginBottom: "5px" }} label="July" name="jul">
          <Input type="number" min={0} allowClear disabled={loading} style={{ width: "100%" }} placeholder="Enter July head count (Leave blank to use year average)" />
          {/* <InputNumber disabled={loading} style={{ width: '100%' }} placeholder="Enter July headcount (Leave blank to use year average)" /> */}
        </Form.Item>
        <Form.Item style={{ marginTop: "5px", marginBottom: "5px" }} label="August" name="aug">
          <Input type="number" min={0} allowClear disabled={loading} style={{ width: "100%" }} placeholder="Enter August head count (Leave blank to use year average)" />
          {/* <InputNumber disabled={loading} style={{ width: '100%' }} placeholder="Enter August headcount (Leave blank to use year average)" /> */}
        </Form.Item>
        <Form.Item style={{ marginTop: "5px", marginBottom: "5px" }} label="September" name="sep">
          <Input type="number" min={0} allowClear disabled={loading} style={{ width: "100%" }} placeholder="Enter September head count (Leave blank to use year average)" />
          {/* <InputNumber disabled={loading} style={{ width: '100%' }} placeholder="Enter September headcount (Leave blank to use year average)" /> */}
        </Form.Item>
        <Form.Item style={{ marginTop: "5px", marginBottom: "5px" }} label="October" name="oct">
          <Input type="number" min={0} allowClear disabled={loading} style={{ width: "100%" }} placeholder="Enter October head count (Leave blank to use year average)" />
          {/* <InputNumber disabled={loading} style={{ width: '100%' }} placeholder="Enter October headcount (Leave blank to use year average)" /> */}
        </Form.Item>
        <Form.Item style={{ marginTop: "5px", marginBottom: "5px" }} label="November" name="nov">
          <Input type="number" min={0} allowClear disabled={loading} style={{ width: "100%" }} placeholder="Enter November head count (Leave blank to use year average)" />
          {/* <InputNumber disabled={loading} style={{ width: '100%' }} placeholder="Enter November headcount (Leave blank to use year average)" /> */}
        </Form.Item>
        <Form.Item style={{ marginTop: "5px" }} label="December" name="dec">
          <Input type="number" min={0} allowClear disabled={loading} style={{ width: "100%" }} placeholder="Enter December head count (Leave blank to use year average)" />
          {/* <InputNumber disabled={loading} style={{ width: '100%' }} placeholder="Enter December headcount (Leave blank to use year average)" /> */}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OccupancyAddEditModal;
