import { get, post, put } from "@/services/request";

export const getCompanies = (searchString: any, isActive: any) => {
  return get(
    `company/list${searchString ? "?searchString=" + searchString : ""}${isActive === "true" && searchString !== null ? "&isActive=" + isActive : ""}${isActive === "true" && searchString === null ? "?isActive=" + isActive : ""}`
  );
};

export const getPagedCompanies = (request: any) => {
  return post(`company/paged/list`, request);
};

export const getCompaniesLookup = (request: { companyIds?: string[]; isActive?: boolean; canEdit?: boolean }) => {
  return post(`company/lookup`, request);
};

export const getCompany = (id: any) => {
  return get(`company/${id}`);
};

export const addCompany = (request: any) => {
  return post("company", request);
};

export const updateCompany = (id: any, request: any) => {
  return put(`company/update/${id}`, request);
};

export const getSelectedCompany = (companyId: string) => {
  return get(`company/${companyId}/role`);
};

export const getUsersForCompany = (request: any) => {
  return post("company/users", request);
};

export const getAllUsers = () => {
  return get("company/users/all");
};

export const inviteUserToCompany = (payload: any) => {
  return post(`company/invite`, payload);
};

export const updateCompanyUser = (id: string, data: any) => {
  return post(`company/users/${id}/update`, data);
};

export const resendUserInvite = (inviteId: string) => {
  return post(`company/invite/${inviteId}/resend`, undefined);
};

export const getCompanyHealth = (removeAuth: boolean) => {
  return get(`company/health`, removeAuth);
};

export const getCompanyTags = (companyId: string) => {
  return get(`company/${companyId}/tags`);
};

export const getCompanyTagsList = () => {
  return get(`company/tags`);
};

export const upsertCompanyTags = (companyId: string, tags: string[]) => {
  return post(`company/${companyId}/tags`, { companyId, tags });
};

export const getUserOutstandingCompanyInvites = () => {
  return get(`company/invites/outstanding`);
};

export const getCompanyInvite = (inviteId: string) => {
  return get(`company/invites/${inviteId}`);
};

export const getCompanyUser = (companyId: string, id: string) => {
  return get(`company/${companyId}/users/${id}`);
};

export const acceptCompanyInviteForUser = (inviteId: string) => {
  return post(`company/invite/${inviteId}/accept`, undefined);
};

export const acceptCompanyInvitesForUser = (inviteIds: string[]) => {
  return post(`company/invite/accept`, { inviteIds });
};

export const countCompanies = (companyId?: string) => {
  return get(`company/count`);
};

export const countCompanyUsers = (companyId?: string) => {
  const queryParams = companyId ? `?companyId=${companyId}` : "";

  return get(`company/users/count` + queryParams);
};

export const getCompanyAuditLogs = (id: string) => {
  return get(`company/auditlog/${id}/list`);
};

export const deleteCompanyInvitedUser = (id: string) => {
  return put(`company/invite/${id}/delete`, undefined);
};

export const getCompanyConfigLookups = () => {
  return get(`company/config/lookup`);
};

export const getPagedCompanyUsers = (request: { companyId?: string; searchString?: string; page?: number; isActive?: boolean }) => {
  return post(`company/users/list`, request);
};
