export default function RemainingDays({
  remainingDays,
  placeholder = "-",
  daysLeftOverride = undefined,
  breakLines = false,
}: {
  remainingDays: any | undefined;
  placeholder?: string;
  daysLeftOverride?: number;
  breakLines?: boolean;
}) {
  var currentDate = new Date();
  var date1 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0);
  var date2 = new Date(date1.getFullYear() + 1, 0, 0, 0, 0, 0, 0);
  var diff = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  var daysLeft = daysLeftOverride ?? Math.floor(diff / (1000 * 3600 * 24));

  if (remainingDays === "N/A") return <>N/A</>;
  if (remainingDays === undefined || remainingDays === null) return <>{placeholder}</>;
  if (remainingDays === 0) return <span style={{ color: "red" }}>0.0{breakLines ? <br /> : " "}Days</span>;
  if (remainingDays > 1 && remainingDays > daysLeft) return <>+365{breakLines ? <br /> : " "}Days</>;
  if (remainingDays >= 1)
    return (
      <>
        {remainingDays.toFixed(1)}
        {breakLines ? <br /> : " "}Days
      </>
    );
  if (remainingDays < 1 && remainingDays >= 1 / 24)
    return (
      <>
        {(remainingDays * 24.0).toFixed(1)}
        {breakLines ? <br /> : " "}Hours
      </>
    );
  if (remainingDays < 1 / 24 && remainingDays >= 0)
    return (
      <>
        {(remainingDays * 24.0 * 60.0).toFixed(1)}
        {breakLines ? <br /> : " "}Minutes
      </>
    );
  return (
    <span style={{ color: "red" }}>
      {remainingDays.toFixed(1)}
      {breakLines ? <br /> : " "}Days
    </span>
  );
}
