import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export function getDaysInMonthForYear(year: number, timezone: string) {
  const now = dayjs().tz(timezone);
  const currentYear = now.year();
  const currentMonth = now.month() + 1; // dayjs months are 0-indexed

  let daysInMonthForYear: number[] = [];

  for (let month = 1; month <= 12; month++) {
    let daysInMonth = 0;
    if (year === currentYear && month === currentMonth) {
      daysInMonth = now.date();
    } else if (year < currentYear || (year === currentYear && month <= currentMonth)) {
      daysInMonth = dayjs.tz(`${year}-${month}-01`, timezone).daysInMonth();
    }
    daysInMonthForYear.push(daysInMonth);
  }

  return daysInMonthForYear;
}
