import { Card, Table, Tabs, Skeleton, Tooltip } from "antd";
import { getLookups } from "@/apis/lookups.api";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@/stores";
import { saveLookups } from "@/stores/lookups.store";
import { InfoCircleOutlined } from "@ant-design/icons";
import _ from "lodash";

import "./Lookups.scss";

const Lookups: FC = () => {
  const dispatch = useAppDispatch();

  const { lookups } = useSelector((state: any) => state.lookups);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    refreshLookupsList();
    // eslint-disable-next-line
  }, []);

  const refreshLookupsList = async () => {
    setLoading(true);
    const response = await getLookups();
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        const tempLookups = data.value;
        const sortedList = tempLookups.sort((a: any, b: any) => a.name.localeCompare(b.name));
        dispatch(saveLookups(sortedList));
      }
    }
    setLoading(false);
  };

  const calculateColumns = (lookup: any) => {
    let tempColumns: any[] = [
      {
        title: "Description",
        dataIndex: "name",
        editable: true,
        sorter: (a: any, b: any) => a?.code?.localeCompare(b?.code),
        // sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        defaultSortOrder: "ascend",
        sortDirections: ["ascend", "descend", "ascend"],
        // render: (val: any, record: any) => `${record?.code ? record?.code : ''} -  ${val}`
        render: (val: any, record: any) =>
          record.description ? (
            <text>
              {val}{" "}
              <Tooltip placement="top" title={record?.description}>
                <InfoCircleOutlined />
              </Tooltip>
            </text>
          ) : (
            val
          ),
      },
    ];

    if (lookup.showCode) {
      tempColumns?.unshift({
        title: "Code",
        dataIndex: "code",
        editable: true,
        width: 100,
        sorter: (a: any, b: any) => {
          if (a?.code?.match(/^-?\d+$/) || b?.code?.match(/^-?\d+$/)) return Number(a.code) > Number(b.code) ? 1 : -1;
          return a?.code?.localeCompare(b?.code!);
        },
        // sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        defaultSortOrder: "ascend",
        sortDirections: ["ascend", "descend", "ascend"],
        render: (val: any, record: any) => val,
      });
    }
    return tempColumns;
  };

  return (
    <>
      <Card id="lookupsList" title="Lookups" loading={loading}>
        {/* <Skeleton loading={loading} active /> */}
        <Tabs
          type="card"
          size="small"
          items={_.sortBy(
            lookups?.map((lookup: any) => {
              return {
                label: lookup.name,
                key: lookup.id,
                children: <Table rowClassName={() => "editable-row"} bordered dataSource={lookup.options} columns={calculateColumns(lookup)} pagination={false} size="small" />,
              };
            }) ?? [],
            ["label"]
          )}
        />
      </Card>
    </>
  );
};

export default Lookups;
