import { useQuery, useQueryClient } from "@tanstack/react-query";
import CacheKeys from "@/types/CacheKeys";
import { useSelector } from "react-redux";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import { getUserOutstandingCompanyInvites } from "@/apis/company.api";
import { useCallback } from "react";

const useOutstandingInvites = () => {
  const queryClient = useQueryClient();
  const { profile } = useSelector((state: any) => state.user);

  const { data, isFetching, isFetched } = useQuery({
    queryKey: [
      CacheKeys.oustandingInvites,
      {
        profileId: profile?.id,
      },
    ],
    queryFn: async () => {
      const response = await getUserOutstandingCompanyInvites();

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch outstanding company invites");
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    refetchInterval: 5 * 60 * 1000, //Every 5 mins
    initialData: [],
  });

  const invalidateCompanyInvites = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.oustandingInvites],
    });
  }, [queryClient]);

  return {
    oustandingInvites: data,
    isFetched,
    isFetching,
    invalidateCompanyInvites,
  };
};

export default useOutstandingInvites;
