import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BreadcrumbDto {
  type: string;
  url: string;
  label?: string;
}

interface IBreadcrumbState {
  urlHistory: BreadcrumbDto[];
  urlStateHistory: BreadcrumbDto[];
}

const initialState: IBreadcrumbState = {
  urlHistory: [
    {
      type: "Dashboard",
      url: "dashboard",
      label: undefined,
    },
  ],
  urlStateHistory: [
    {
      type: "Dashboard",
      url: "dashboard",
      label: undefined,
    },
  ],
};

const breadCrumbStore = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    addBreadcrumb: (state: IBreadcrumbState, action: PayloadAction<BreadcrumbDto>) => {
      state.urlHistory.push(action.payload);
    },
    addSiderUrlStateBreadcrumb: (state: IBreadcrumbState, action: PayloadAction<any>) => {
      state.urlStateHistory = [
        {
          type: "Dashboard",
          url: "dashboard",
          label: undefined,
        },
        {
          type: action.payload.type,
          url: action.payload.url,
          label: undefined,
        },
      ];
    },
    addViewAddUrlStateBreadcrumb: (state: IBreadcrumbState, action: PayloadAction<any>) => {
      if (action.payload.url !== state.urlStateHistory[state.urlStateHistory.length - 1].url)
        state.urlStateHistory.push({
          type: action.payload.type,
          url: action.payload.url,
          label: undefined,
        });
    },
    replaceUrlStateBreadcrumbType: (state: IBreadcrumbState, action: PayloadAction<any>) => {
      var copyState = {
        ...state.urlStateHistory[state.urlStateHistory.length - 1],
      };
      copyState.type = action.payload;
      state.urlStateHistory.pop();
      state.urlStateHistory.push(copyState);
    },
    clearBreadcrumbs: (state: IBreadcrumbState) => {
      state.urlHistory = [
        {
          type: "Dashboard",
          url: "dashboard",
        },
      ];
    },
    clearUrlStateBreadcrumbs: (state: IBreadcrumbState) => {
      state.urlStateHistory = [
        {
          type: "Dashboard",
          url: "dashboard",
        },
      ];
    },
    removeBreadcrumb: (state: IBreadcrumbState) => {
      state.urlHistory.pop();
    },
    removeUrlStateBreadcrumb: (state: IBreadcrumbState, action: PayloadAction<string | undefined>) => {
      let url = action?.payload;
      if (url?.startsWith("/")) url = url.slice(1);

      let pop = true;

      if (url && state.urlStateHistory?.[state.urlStateHistory?.length - 1]?.url === url) {
        pop = false;
      }

      pop && state.urlStateHistory.pop();
    },
    removeViewEditBreadcrumbs: (state: IBreadcrumbState) => {
      state.urlHistory.pop();
      state.urlHistory.pop();
    },
    removeBreadcrumbIndex: (state: IBreadcrumbState, action: PayloadAction<string>) => {
      const foundBreadcrumb = state.urlHistory.find((item) => item.url === action.payload);

      if (foundBreadcrumb !== undefined) {
        const index = state.urlHistory.indexOf(foundBreadcrumb);

        const copyUrlHistory = [...state.urlHistory];
        state.urlHistory = copyUrlHistory.slice(0, index + 1);
      }
    },
    removeStateBreadcrumbIndex: (state: IBreadcrumbState, action: PayloadAction<string>) => {
      const foundBreadcrumb = state.urlStateHistory.find((item) => item.url === action.payload);

      if (foundBreadcrumb !== undefined) {
        const index = state.urlStateHistory.indexOf(foundBreadcrumb);

        const copyUrlHistory = [...state.urlStateHistory];
        state.urlStateHistory = copyUrlHistory.slice(0, index + 1);
      }
    },
  },
});

export const {
  addBreadcrumb,
  addSiderUrlStateBreadcrumb,
  addViewAddUrlStateBreadcrumb,
  replaceUrlStateBreadcrumbType,
  clearBreadcrumbs,
  clearUrlStateBreadcrumbs,
  removeBreadcrumb,
  removeUrlStateBreadcrumb,
  removeViewEditBreadcrumbs,
  removeBreadcrumbIndex,
  removeStateBreadcrumbIndex,
} = breadCrumbStore.actions;

export default breadCrumbStore.reducer;
