import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Tooltip } from "antd";
import { FC, memo, useState } from "react";

type NotBillableButtonProps = {
  isBillable: boolean;
  name: string;
  onUpdateBillableStatus: (id: string, billable: boolean, entityType: string, entityName: string, companyName: string | undefined, onCompleted: () => void) => void;
  id: string;
  type: string;
  companyName?: string;
};

const NotBillableButton: FC<NotBillableButtonProps> = ({ isBillable, name, id, onUpdateBillableStatus, type, companyName }) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Popconfirm
      placement="topRight"
      title={isBillable ? "Mark as NOT billable" : "Mark as billable"}
      description={isBillable ? `Are you sure you want to mark ${name} as NOT billable?` : `Are you sure you want to mark ${name} as billable?`}
      onConfirm={() => {
        setLoading(true);
        onUpdateBillableStatus(id, !isBillable, type, name, companyName, () => setLoading(false));
      }}
      okText="Yes"
      cancelText="No"
    >
      <Tooltip title={isBillable ? "Mark as NOT billable" : "Mark as billable"} placement="left">
        <Button
          disabled={loading}
          loading={loading}
          style={{ paddingLeft: 0, paddingRight: 0 }}
          type="link"
          icon={isBillable ? <MinusCircleOutlined style={{ color: "#f5222d" }} /> : <PlusCircleOutlined style={{ color: "#52c41a" }} />}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default NotBillableButton;
