import { Button, Tooltip } from "antd";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { WifiOutlined } from "@ant-design/icons";
import { RootState, useAppDispatch } from "@/stores";
import { saveBackendServiceStatus } from "@/stores/serverStatus.store";
import { get } from "@/services/request";
import { constants } from "@/configs";

let interval: any = undefined;

const ServerStatus: FC<any> = (props: any) => {
  const dispatch = useAppDispatch();
  const { title, color, backendServiceStatus } = useSelector((state: RootState) => state.serverStatus);
  const { label } = props;

  useEffect(() => {
    checkServerStatus(true);
    interval = setInterval(checkServerStatus, 240000);
    return () => {
      clearInterval(interval);
    };
  }, [title]);

  const checkServerStatus = async (initial: boolean = false) => {
    Object.keys(backendServiceStatus).forEach((key: string) => {
      if (backendServiceStatus[key] === "Disconnected" || (!initial && backendServiceStatus[key] === "Connected")) {
        if (initial) dispatch(saveBackendServiceStatus({ key: key, status: "Connecting" }));

        const data = get(`${key === "idsv" ? constants.oidcConfig.authority : key}/health`, true)
          .then((res) => (res.ok ? res : Promise.reject(res)))
          .then(async (res) => {
            return await res.json();
          });

        data.then((res) => {
          if (res.status === "Healthy") {
            dispatch(saveBackendServiceStatus({ key: key, status: "Connected" }));
          } else {
            dispatch(saveBackendServiceStatus({ key: key, status: "Disconnected" }));
          }
        });
      }
    });
  };

  return (
    <Tooltip title={title}>
      <Button type="text" icon={<WifiOutlined style={{ color: color, borderColor: "#00000073" }} />}>
        {label}
      </Button>
    </Tooltip>
  );
};

export default ServerStatus;
