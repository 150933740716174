import { Card, Table } from "antd";
import { ColumnProps } from "antd/es/table";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import dayjs from "dayjs";
import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

interface Props {
  calculation: any;
}

const RemainingYearlyAverageTable: FC<Props> = (props) => {
  const { calculation } = props;

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const currentYear = dayjs().year();

  const config: any = useMemo(() => {
    const startYear = dayjs(calculation.startDate).year();
    const endYear = dayjs(calculation.endDate).year();

    const rows: any[] = [{ title: "Used" }, { title: "Remaining Yearly Average" }, { title: "Future Remaining Yearly Average" }];

    const tempColumns: ColumnProps<any>[] = [
      {
        title: "Remaining Yearly Average Breakdown",
        key: "title",
        dataIndex: "title",
        fixed: "left",
      },
    ];

    const yearlyUsageTotals: any = {
      ...(calculation.previousYearQuantities ?? {}),
    };

    Object.keys(yearlyUsageTotals).forEach((key: string) => (yearlyUsageTotals[key] = yearlyUsageTotals[key] * 325851 ?? 0));

    calculation.yearlyUsage?.forEach((yearlyUsage: any) => {
      if (yearlyUsage.year) {
        if (!yearlyUsageTotals[yearlyUsage.year.toString()]) yearlyUsageTotals[yearlyUsage.year.toString()] = 0;
        yearlyUsageTotals[yearlyUsage.year.toString()] += yearlyUsage.usage;
      }
    });

    for (let index = 0; endYear >= startYear + index; index++) {
      rows[0][index.toString()] = yearlyUsageTotals[(startYear + index).toString()] ?? 0;
      rows[1][index.toString()] = startYear + index >= currentYear ? calculation.yearlyRemaining ?? calculation.remainingYearlyAverage : 0;
      rows[2][index.toString()] = startYear + index > currentYear ? calculation.futureRemainingYearlyAverage : 0;

      tempColumns.push({
        title: `${startYear + index}`,
        key: `${index}`,
        dataIndex: `${index}`,
        align: "right",
        render: (val: any) => (val ? <UnitsConverter value={val} toUnits={selectedCompanyId ? selectedCompany?.settings?.metric : "acrefeet"} fromUnits={"gallons"} showUnitsLabel /> : "-"),
        onCell: (_, rowIndex) => ({
          className: (startYear + index === currentYear && rowIndex === 0) || (startYear + index >= currentYear && rowIndex === 1) ? "table-row-dark" : "table-row-light",
        }),
        onHeaderCell: (_, columnIndex) => ({
          style: {
            fontWeight: startYear + index === currentYear ? "bold" : "normal",
          },
        }),
      });
    }

    // Calculate totals
    rows[0].totals = calculation.totalUsed ?? calculation.used;
    rows[1].totals = calculation.remaining ?? calculation.remainingUsage;
    rows[2].totals = calculation.futureRemainingYears > 0 ? calculation.remaining ?? calculation.remainingUsage : undefined;

    tempColumns.push({
      title: "Totals",
      key: "totals",
      dataIndex: "totals",
      align: "right",
      render: (val: any) => <UnitsConverter value={val} fromUnits={"gallons"} toUnits={selectedCompanyId ? selectedCompany?.settings?.metric : "acrefeet"} showUnitsLabel />,
      onCell: (_, rowIndex) => ({
        className: rowIndex === 1 ? "table-row-dark" : "table-row-light",
      }),
    });

    return { columns: tempColumns, rows };
  }, [calculation]);

  return <Table style={{ marginTop: 0, marginBottom: 0 }} rowKey={(row: any) => row.title} pagination={false} columns={config.columns} dataSource={config.rows} size="small" />;
};

export default RemainingYearlyAverageTable;
