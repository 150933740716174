import { getFields } from "@/apis/field.api";
import { getWaterRights } from "@/apis/waterright.api";
import { getWells } from "@/apis/well.api";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import useFieldLookups from "@/queries/useFieldLookups";
import { Card, ConfigProvider, Descriptions, Image, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import "./GeneratedSummaryReport.scss";

interface Props {
  reportConfig: {
    fieldIds?: string[];
    fields: string;
    generatedDate: string;
    active: boolean | null;
  };
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

export default function GeneratedFieldConfigurationReport(props: Props) {
  const { reportConfig } = props;

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [loadingWaterRights, setLoadingWaterRights] = useState<boolean>(true);
  const [loadingWells, setLoadingWells] = useState<boolean>(true);
  const [loadingFields, setLoadingFields] = useState<boolean>(true);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [waterRights, setWaterRights] = useState<any[]>([]);
  const [wells, setWells] = useState<any[]>([]);
  const [tempFields, setFields] = useState<any[]>([]);

  const [fieldColumns, setFieldColumns] = useState<any[]>([]);
  const [fieldIds, setFieldIds] = useState<string[]>([]);

  const { fields: fLookup } = useFieldLookups(undefined, selectedCompanyId, reportConfig.active ? reportConfig.active : undefined);

  const fields: any[] = useMemo(() => {
    const data = tempFields.map((field: any) => {
      const waterRightIdsForField = wells.filter((well) => well.fieldId === field.id).map((well) => well.waterRightId);
      const waterRightsForField = waterRights.filter((waterRight) => waterRightIdsForField.includes(waterRight.id)).map((waterRight) => waterRight.fileNumber);
      const wellsForField = wells.filter((well) => well.fieldId === field.id).map((well) => well.name);

      return {
        ...field,
        waterRights: waterRightsForField?.length > 0 ? waterRightsForField : ["-"],
        wells: wellsForField?.length > 0 ? wellsForField : ["-"],
      };
    });

    return data;
  }, [waterRights, wells, tempFields]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  useEffect(() => {
    if (fieldIds.length > 0) {
      refreshWaterRightsList();
      refreshWells();
      refreshFields();
    }
  }, [fieldIds]);

  useEffect(() => {
    if (reportConfig.fieldIds !== undefined && reportConfig.fieldIds.length > 0 && fLookup.length > 0) {
      setFieldIds(reportConfig.fieldIds);
    } else {
      setFieldIds(fLookup?.map((field: any) => field?.id));
    }
    // eslint-disable-next-line
  }, [reportConfig]);

  useEffect(() => {
    calculateFieldColumns();
    // eslint-disable-next-line
  }, [fields, waterRights, wells]);

  const refreshWaterRightsList = async () => {
    setLoadingWaterRights(true);

    const request = {
      companyId: selectedCompanyId,
      isActive: undefined,
    };

    const response = await getWaterRights(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWaterRights(data.value);
      }
    }
    setLoadingWaterRights(false);
  };

  const refreshWells = async () => {
    setLoadingWells(true);
    const request = { companyId: selectedCompanyId };
    const response = await getWells(request);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWells(data.value);
      }
    }
    setLoadingWells(false);
  };

  const refreshFields = async () => {
    setLoadingFields(true);

    const response = await getFields({ companyId: selectedCompanyId, fieldIds: fieldIds });

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setFields(data.value);
      }
    }

    setLoadingFields(false);
  };

  const calculateFieldColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Field Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        width: 300,
        render: (val: any, record: any) => (
          <>
            <div>{val}</div>
            {!selectedCompanyId && (
              <div style={{ fontSize: 11 }}>
                (<CompanyLabel companyId={record?.companyId} />)
              </div>
            )}
          </>
        ),
      },
      {
        title: "Water Rights",
        key: "waterRights",
        dataIndex: "waterRights",
        render: (val: any) => val && <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{val?.map((x: any) => x).join(", ")}</div>,
      },
      {
        title: "Wells / Meters",
        key: "wells",
        dataIndex: "wells",
        render: (val: any) => val && <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{val?.map((x: any) => x).join(", ")}</div>,
      },
    ];

    setFieldColumns(tempColumns);
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedFieldSummaryReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Field Configuration Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
          // title={`Summary Report on ${reportConfig.generatedDate}`}
          loading={loadingWells && loadingWaterRights && loadingFields}
        >
          {
            <Card title="Fields">
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                  rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  rowKey={(row: any) => row.id}
                  loading={loadingFields}
                  columns={fieldColumns}
                  dataSource={fields ?? []}
                  size="small"
                  pagination={false}
                />
              </ConfigProvider>
            </Card>
          }
          <div className="page-break" />
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Fields">{reportConfig.fields === "" ? "-" : reportConfig.fields}</Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
}
