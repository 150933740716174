import { WaterRightReportSelector, WellSelector } from "@/components";
import { constants } from "@/configs";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Form, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import GeneratedNotesReport from "./GeneratedNotesReport";

const NotesReport: FC = () => {
  const [form] = Form.useForm();
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [reportConfig, setReportConfig] = useState<any>(undefined);
  const [waterRights, setWaterRights] = useState<any[]>([]);
  const [wells, setWells] = useState<any[]>([]);

  const waterRightIds: any[] = useWatch("waterRightIds", form);
  const wellIds: any[] = useWatch("wellIds", form);

  const [wellIsActive, setWellIsActive] = useState<boolean | null>(true);
  const [waterRightIsActive, setWaterRightSetIsActive] = useState<boolean | null>(true);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    const wellData: any[] = [];

    wellIds?.forEach((wellId) => {
      const wellName = wells.find((well) => well.id === wellId)?.name;
      wellData.push({ wellId, name: wellName });
    });

    const wellsReport = {
      wells: wellData,
    };

    const waterRightData: any[] = [];

    waterRightIds?.forEach((waterRightId) => {
      const waterRightFileNumber = waterRights.find((waterRight) => waterRight.id === waterRightId)?.fileNumber;
      waterRightData.push({ waterRightId, fileNumber: waterRightFileNumber });
    });

    const waterRightsReport = {
      waterRights: waterRightData,
    };

    setReportConfig({
      waterRights: waterRightsReport,
      wells: wellsReport,
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
      wellActive: wellIsActive,
      waterRightActive: waterRightIsActive,
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <WellSelector
            setActive={setWellIsActive}
            form={form}
            propertyToSet={"wellIds"}
            label={"Wells"}
            placeholder="Leave blank to select all wells"
            style={{ margin: 0, marginBottom: 10 }}
            setWells={setWells}
          />
          <WaterRightReportSelector
            setActive={setWaterRightSetIsActive}
            form={form}
            propertyToSet={"waterRightIds"}
            label={"Water Rights"}
            setWaterRights={setWaterRights}
            placeholder="Leave blank to select all water rights"
            style={{ margin: 0, marginBottom: 0 }}
          />
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedNotesReport reportConfig={reportConfig} />}
    </>
  );
};

export default NotesReport;
