import { CompanyPlaceOfUseTable, PlaceOfUseTable } from "@/components";
import { RootState } from "@/stores";
import { FC } from "react";
import { useSelector } from "react-redux";

const PlaceOfUseList: FC = () => {
  const { selectedCompanyId } = useSelector((state: RootState) => state.company);

  return selectedCompanyId ? <PlaceOfUseTable companyId={selectedCompanyId} /> : <CompanyPlaceOfUseTable />;
};

export default PlaceOfUseList;
