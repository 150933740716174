import { CheckOutlined } from "@ant-design/icons";
import { Empty, List, Select } from "antd";
import { getPlaceOfUse } from "@/apis/waterright.api";
import { FC, useEffect, useState } from "react";

interface Props {
  selectedCompanyId: any;
  waterRightId: any;
  master: boolean;
  reading: boolean;
  waterRightPlacesOfUse: any[] | undefined;
  masterPlacesOfUse?: string[];
  readingPlacesOfUse?: string[];
  updateSelectedValues?: (selected: string[]) => void;
}

type PlaceOfUseAddModalState = {
  selected: string[];
  adding: string[];
  waterRightList: string[];
  masterList: string[];
  all: Set<string>;
  searchValue: string;
};

const PlacesOfUseSelector: FC<Props> = (props) => {
  const { selectedCompanyId, waterRightId, master, reading, waterRightPlacesOfUse, masterPlacesOfUse, readingPlacesOfUse, updateSelectedValues } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [placesOfUse, setPlacesOfUse] = useState<any>(undefined);
  const [state, setState] = useState<PlaceOfUseAddModalState>({
    selected: [],
    adding: [],
    waterRightList: [],
    masterList: [],
    all: new Set<string>(),
    searchValue: "",
  });

  useEffect(() => {
    if (selectedCompanyId) refreshPlacesOfUse();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    let tempMaster: string[] = [];
    let tempWaterRight: string[] = [];
    let tempSelected: string[] = [];

    if (reading && readingPlacesOfUse) tempSelected = readingPlacesOfUse;

    if (waterRightPlacesOfUse) tempWaterRight = waterRightPlacesOfUse;

    if (master && masterPlacesOfUse) tempMaster = masterPlacesOfUse;

    if (!masterPlacesOfUse && placesOfUse) {
      tempMaster = placesOfUse?.placesOfUse?.map((x: any) => x);
    }

    const filteredWaterRightPlacesOfUse = tempWaterRight?.filter((x: string) => !tempSelected.includes(x));
    const filteredExistingPlacesOfUse = tempMaster?.filter((x: string) => !tempWaterRight.includes(x) && !tempSelected.includes(x));

    const uniqueList = new Set([...tempMaster.map((x: string) => x.toLowerCase()), ...tempWaterRight.map((x: string) => x.toLowerCase())]);

    setState({
      selected: tempSelected,
      adding: tempSelected,
      waterRightList: filteredWaterRightPlacesOfUse,
      masterList: filteredExistingPlacesOfUse,
      all: uniqueList,
      searchValue: "",
    });
    // eslint-disable-next-line
  }, [master, masterPlacesOfUse, waterRightPlacesOfUse, placesOfUse]);

  const refreshPlacesOfUse = async () => {
    setLoading(true);

    const response = await getPlaceOfUse(selectedCompanyId);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setPlacesOfUse(data.value);
      }
    }

    setLoading(false);
  };

  const waterRightSelectDisabled = reading ? false : waterRightId ? true : false;
  const masterSelectDisabled = master ? true : false;

  const handleAddItem = (selectedValue: string) => {
    const val = selectedValue.toLowerCase();

    if (val !== "") {
      setState((currentState) => {
        let itemToAdd = selectedValue;

        const tempAddingList = [...currentState.adding];
        const addingIndex = tempAddingList.findIndex((x: string) => x.toLowerCase() === val);
        if (addingIndex > -1) {
          itemToAdd = tempAddingList[addingIndex];
          tempAddingList.splice(addingIndex, 1);
        }

        const tempWaterRightList = [...currentState.waterRightList];
        const waterRightIndex = tempWaterRightList.findIndex((x: string) => x.toLowerCase() === val);
        if (waterRightIndex > -1) {
          if (waterRightSelectDisabled) return currentState;
          itemToAdd = tempWaterRightList[waterRightIndex];
          tempWaterRightList.splice(waterRightIndex, 1);
        }

        const tempMasterList = [...currentState.masterList];
        const masterIndex = tempMasterList.findIndex((x: string) => x.toLowerCase() === val);

        if (masterIndex > -1) {
          if (masterSelectDisabled) return currentState;
          itemToAdd = tempMasterList[masterIndex];
          tempMasterList.splice(masterIndex, 1);
        }

        tempAddingList.push(itemToAdd);

        const tempSelected = [...currentState.selected];
        const selectedIndex = tempSelected.findIndex((x: string) => x.toLowerCase() === val);
        if (selectedIndex === -1) {
          tempSelected.push(itemToAdd);
        }

        currentState.all.add(val);

        updateSelectedValues?.(tempSelected);

        return {
          ...currentState,
          selected: tempSelected,
          adding: tempAddingList.sort((a: string, b: string) => a?.localeCompare(b, "en", { numeric: true, sensitivity: "base" })),
          waterRightList: tempWaterRightList.sort((a: string, b: string) => a?.localeCompare(b, "en", { numeric: true, sensitivity: "base" })),
          masterList: tempMasterList.sort((a: string, b: string) => a?.localeCompare(b, "en", { numeric: true, sensitivity: "base" })),
          searchValue: "",
        };
      });
    }
  };

  const handleRemoveItem = (val: string) => {
    setState((currentState) => {
      const tempSelected = [...currentState.selected];
      const selectedIndex = tempSelected.findIndex((placeOfUse: string) => placeOfUse.toLowerCase() === val.toLowerCase());
      if (selectedIndex > -1) tempSelected.splice(selectedIndex, 1);

      updateSelectedValues?.(tempSelected);

      return {
        ...currentState,
        selected: tempSelected,
      };
    });
  };

  const renderList = (list: string[], disabled: boolean, title?: string) => {
    return list.length > 0 ? (
      <List header={<div style={{ paddingLeft: 5, fontWeight: "bold", fontSize: "1.05em" }}>{title}</div>} size="small">
        {list.map((placeOfUse: any) => {
          const selected = state.selected.findIndex((x) => x === placeOfUse) > -1;

          return (
            <List.Item
              key={placeOfUse}
              onClick={disabled ? undefined : (e: any) => (selected ? handleRemoveItem(e.target.innerText) : handleAddItem(e.target.innerText))}
              style={{ margin: 0, padding: 1, paddingLeft: 10 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flex: 1,
                  margin: 0,
                  cursor: disabled ? "not-allowed" : "pointer",
                  fontWeight: selected ? "bold" : "normal",
                  backgroundColor: selected ? "#e5f4ff" : "transparent",
                  padding: 5,
                  borderRadius: 5,
                  opacity: disabled ? 0.6 : 1,
                }}
              >
                <div>{placeOfUse}</div>
                <div>{selected && <CheckOutlined style={{ color: "green" }} />}</div>
              </div>
            </List.Item>
          );
        })}
      </List>
    ) : undefined;
  };

  return (
    <Select
      style={{ width: "100%" }}
      value={state.selected}
      mode="multiple"
      placeholder={waterRightId ? "Enter or select places of use" : "Enter places of use"}
      loading={loading}
      maxTagCount={3}
      disabled={loading}
      dropdownRender={(menu) => {
        const { adding, waterRightList, masterList, all } = state;

        return (
          <div style={{ maxHeight: 500, overflowY: "auto" }}>
            {state.searchValue !== "" && !all.has(state.searchValue.toLowerCase()) && (
              <List size="small">
                <List.Item key={state.searchValue} onClick={(e: any) => handleAddItem(state.searchValue)} style={{ margin: 0, padding: 1, paddingLeft: 10 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flex: 1,
                      margin: 0,
                      cursor: "pointer",
                      fontWeight: "normal",
                      backgroundColor: "transparent",
                      padding: 5,
                      borderRadius: 5,
                      opacity: 1,
                    }}
                  >
                    <div>{state.searchValue}</div>
                    <div>{false && <CheckOutlined style={{ color: "green" }} />}</div>
                  </div>
                </List.Item>
              </List>
            )}
            {renderList(
              adding.filter((x) => state.searchValue === "" || x.toLowerCase().startsWith(state.searchValue.toLowerCase())),
              false,
              "Adding Places of Use"
            )}
            {renderList(
              waterRightList.filter((x) => state.searchValue === "" || x.toLowerCase().startsWith(state.searchValue.toLowerCase())),
              waterRightSelectDisabled,
              "Water Right Places of Use"
            )}
            {renderList(
              masterList.filter((x) => state.searchValue === "" || x.toLowerCase().startsWith(state.searchValue.toLowerCase())),
              masterSelectDisabled,
              "Existing Places of Use"
            )}
            {adding.length === 0 && waterRightList.length === 0 && masterList.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          </div>
        );
      }}
      onDeselect={(val) => {
        handleRemoveItem(val);
      }}
      onSearch={(val) => {
        setState((currentState: any) => {
          return {
            ...currentState,
            searchValue: val,
          };
        });
      }}
      onInputKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          handleAddItem(state.searchValue);
        }
      }}
      searchValue={state.searchValue}
    />
  );
};

export default PlacesOfUseSelector;
