import { addField, getField, updateField } from "@/apis/field.api";
import { constants, routes } from "@/configs";
import scrollToTop from "@/services/scrollToTop";
import useCustomNavigate from "@/services/useCustomNavigate";
import { getBase64, resizeImage } from "@/services/utils";
import { useAppDispatch } from "@/stores";
import { removeBreadcrumb, removeViewEditBreadcrumbs } from "@/stores/breadcrumbs.store";
import { clearFieldState, saveField } from "@/stores/field.store";
import CacheKeys from "@/types/CacheKeys";
import { CloseOutlined, PlusOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Card, Form, FormInstance, Image, Input, message, Space, Steps, Switch, Upload, UploadFile } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { RcFile } from "antd/es/upload";
import { FC, useEffect, useRef, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import "./FieldAddEdit.scss";

const FieldAddEdit: FC = () => {
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();

  const { fieldData } = useSelector((state: any) => state.field);
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const { id } = useParams<{ id: string }>();

  const formRef = useRef<FormInstance>(null);

  const { navigate } = useCustomNavigate();

  const user = useAuth();

  const [loading, setLoading] = useState<boolean>(true);
  const [addAnother, setAddAnother] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  useEffect(() => {
    if (fieldData?.imageIds) {
      setFileList(
        fieldData.imageIds.map((imageId: any, index: number) => {
          return {
            uid: imageId,
            name: "image.png",
            status: "done",
            url: `${constants.baseApiUrl}/field/image/${imageId}/download`,
            existingFile: true,
          };
        })
      );
    }
  }, [fieldData]);

  useEffect(() => {
    return () => {
      dispatch(clearFieldState());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(true);

    if (id) getFieldData();
    else {
      resetDefaults();
    }

    setLoading(false);
    // eslint-disable-next-line
  }, [id]);

  const resetDefaults = () => {
    formRef.current!.setFieldValue("status", true);
    scrollToTop();
  };

  useEffect(() => {
    if (fieldData) {
      formRef.current!.setFieldValue("name", fieldData?.name);
      formRef.current!.setFieldValue("notes", fieldData?.notes);
      formRef.current!.setFieldValue("status", fieldData?.status);
    }
    // eslint-disable-next-line
  }, [fieldData]);

  const getFieldData = async () => {
    const response = await getField(id);

    if (response.ok) {
      const data = await response.json();
      dispatch(saveField(data.value));
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const data = {
      name: values.name,
      status: values.status,
      notes: values.notes,
      companyId: id ? fieldData?.companyId : selectedCompanyId,
    };

    if (id) {
      const oldImageIds = fileList.filter((item: any) => item.existingFile).map((file: any) => file.uid);
      const newImageIds = fileList.filter((item: any) => !item.existingFile).map((file: any) => JSON.parse(file.xhr.response)?.value);

      const response = await updateField(id, {
        ...data,
        imageIds: [...oldImageIds, ...newImageIds],
      });

      if (response.ok) {
        queryClient.invalidateQueries({ queryKey: [CacheKeys.fields] });

        dispatch(saveField(undefined));
        message.success("Successfully updated the field");
        navigate(routes.fieldList);
      } else {
        message.error("Failed to update the field");
      }
    } else {
      const response = await addField({
        ...data,
        imageIds: fileList.map((file: any) => JSON.parse(file.xhr.response)?.value),
      });

      if (response.ok) {
        queryClient.invalidateQueries({ queryKey: [CacheKeys.fields] });

        message.success("Successfully added the field");
        if (addAnother) {
          dispatch(clearFieldState());
          formRef.current?.resetFields();
          resetDefaults();
          navigate(routes.fieldAdd);
        } else navigate(routes.fieldList);
      } else {
        message.error("Failed to add the field");
      }
    }

    if (id) {
      dispatch(removeViewEditBreadcrumbs());
    } else if (!addAnother) dispatch(removeBreadcrumb());
    // else dispatch(removeBreadcrumb());

    setLoading(false);
    setAddAnother(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleAddAnother = () => {
    formRef.current
      ?.validateFields()
      .then((values) => {
        setAddAnother(true);
        setFileList([]);
        formRef.current?.submit();
      })
      .catch((errors) => {
        scrollToTop();
        message.error(`Please fill in the required fields`, 5);
      });
  };

  const handleSubmit = () => {
    formRef.current
      ?.validateFields()
      .then((values) => {
        onFinish(values);
      })
      .catch((errors) => {
        scrollToTop();
        message.error(`Please fill in the required fields`, 5);
      });
  };

  const renderActionButtons = (placement: TooltipPlacement) => (
    <Space>
      {!id && (
        <>
          <Button disabled={uploading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button disabled={uploading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={handleAddAnother}>
            Save + Add Another
          </Button>
        </>
      )}
      {id && (
        <Button disabled={uploading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
          Save
        </Button>
      )}
      <Button
        disabled={uploading}
        loading={loading}
        icon={<CloseOutlined />}
        onClick={() => {
          if (id) {
            dispatch(removeBreadcrumb());
            navigate(-1);
          } else {
            dispatch(removeBreadcrumb());
            navigate(routes.fieldList);
          }
        }}
      >
        Cancel
      </Button>
    </Space>
  );

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  return (
    <Card className="fieldAddEdit" id="fieldAddEdit" title={id ? "Edit Field" : "Add Field"} extra={renderActionButtons("bottomRight")} actions={[renderActionButtons("top")]}>
      <Form ref={formRef} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
        <Steps
          direction="vertical"
          items={[
            {
              title: "Details",
              status: "process",
              description: (
                <Card>
                  <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please enter a name" }]}>
                    <Input placeholder="Enter a name" />
                  </Form.Item>
                  <Form.Item label="Status" name="status" valuePropName="checked">
                    <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
                  </Form.Item>
                </Card>
              ),
            },
            {
              title: "Directions",
              status: "process",
              description: (
                <Card>
                  <Form.Item label="Notes" name="notes">
                    <Input.TextArea rows={4} placeholder="Enter field direction notes" />
                  </Form.Item>

                  <Form.Item label="Images" name="map">
                    <Upload
                      multiple={true}
                      action={`${constants.baseApiUrl}/field/image/upload`}
                      headers={{
                        Authorization: `Bearer ${user.user?.access_token}`,
                      }}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={(info: any) => {
                        if (info.file.status === "uploading") {
                          setUploading(true);
                        }
                        if (info.file.status === "done") {
                          setUploading(false);
                        }
                        setFileList(info.fileList);
                      }}
                      beforeUpload={resizeImage}
                      onPreview={handlePreview}
                    >
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    </Upload>
                  </Form.Item>
                </Card>
              ),
            },
          ]}
        />
      </Form>
      <Image
        style={{ display: "none" }}
        preview={{
          visible: previewOpen,
          scaleStep: 0.5,
          src: previewImage,
          onVisibleChange: (value) => {
            setPreviewOpen(value);
          },
        }}
      />
    </Card>
  );
};

export default FieldAddEdit;
