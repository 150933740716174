import React from "react";
import { Layout } from "antd";
import { Content } from "@/components";

interface Props {
  children?: React.ReactNode;
}

const BlankContentLayout: React.FC<Props> = (props: any) => {
  return (
    <Layout id="blankContentLayout" style={{ padding: 0 }}>
      <Layout style={{ padding: 0, height: "calc(100vh - 94px)" }}>
        <Content />
      </Layout>
    </Layout>
  );
};

export default BlankContentLayout;
