import { get, post, put } from "@/services/request";

export const getWaterRights = (request: { companyId?: string; searchString?: string; isActive?: boolean; waterRightIds?: string[]; permitTypes?: string[] }) => {
  return post(`waterright/list`, request);
};

export const getWaterRight = (id: any) => {
  return get(`waterright/${id}`);
};

export const addWaterRight = (request: any) => {
  return post("waterright", request);
};

export const updateWaterRight = (id: any, request: any) => {
  return put(`waterright/update/${id}`, request);
};

export const getWaterRightNotes = (request: any) => {
  return post(`waterright/note/list`, request);
};

export const addWaterRightNote = (request: any) => {
  return post("waterright/note", request);
};

export const getWaterRightDocuments = (request: any) => {
  return post(`waterright/document/list`, request);
};

export const downloadDocument = (id: any) => {
  return get(`waterright/document/${id}/download`);
};

export const getWaterRightsFromIds = (request: any) => {
  return post(`waterright/fromids/list`, request);
};

export const updateDocument = (id: any, request: any) => {
  return put(`waterright/document/${id}/update`, request);
};

export const getDate = (waterRightId: any) => {
  return get(`waterright/dates/${waterRightId}`);
};

export const createDate = (request: any) => {
  return post(`waterright/dates/create`, request);
};

export const updateDate = (id: any, request: any) => {
  return put(`waterright/dates/${id}/update`, request);
};

export const countWaterRights = (companyId?: string) => {
  const queryParams = companyId ? `?companyId=${companyId}` : "";

  return get(`waterright/count` + queryParams);
};

export const getWaterRightFileNumber = (id: any) => {
  return get(`waterright/${id}/filenumber`);
};

export const getWaterRightAuditLogs = (id: string) => {
  return get(`waterright/auditlog/${id}/list`);
};

export const syncWaterRightCalculation = (data: any) => {
  return post(`waterright/calculation/sync`, data);
};

export const getWaterRightTags = (request: any) => {
  return post(`waterright/tags/get`, request);
};

export const getWaterRightCalculation = (id: string, year: number) => {
  return post(`waterright/${id}/calculation` + (year ? `?year=${year}` : ""), {});
};

export const archiveDocument = (id: any) => {
  return put(`waterright/document/${id}/archive`, undefined);
};

export const addWaterRightAdjustmentTransfer = (request: any, waterRightId: any) => {
  return post(`waterright/adjustmenttransfer/${waterRightId}/create`, request);
};

export const updateWaterRightAdjustmentTransfer = (request: any, id: any) => {
  return put(`waterright/adjustmenttransfer/${id}/update`, request);
};

export const deleteWaterRightAdjustmentTransfer = (request: any) => {
  return post(`waterright/adjustmenttransfer/delete`, request);
};

export const getWaterRightAdjustmentTransfer = (id: any) => {
  return get(`waterright/adjustmenttransfer/${id}`);
};

export const getWaterRightAdjustmentTransfers = (waterRightId: any) => {
  return get(`waterright/adjustmenttransfer/${waterRightId}/list`);
};

export const listWaterRightSummaryReport = (request: any) => {
  return post(`waterright/report/summary`, request);
};

export const listWaterRightDatesReport = (request: any) => {
  return post(`waterright/report/document`, request);
};

export const generateSummaryReport = (request: any) => {
  return post(`waterright/report/generate/summary`, request);
};

export const generateDatesReport = (request: any) => {
  return post(`waterright/report/generate/dates`, request);
};

export const listWaterRightTags = (request: any) => {
  return post(`waterright/tags/list`, request);
};

export const getWaterRightSnapShot = (request: any) => {
  return post(`waterright/snapshot/retrieve`, request);
};

export const updateWaterRightSnapShot = (id: any, request: any) => {
  return put(`waterright/snapshot/${id}/update`, request);
};

export const getWaterRightSnapsotList = (request: any) => {
  return post(`waterright/snapshot/list`, request);
};

export const getWaterRightUsagePerMonth = (request: { waterRightIds?: string[]; year: number; page: number; pageSize: number }) => {
  return post(`waterright/usage`, request);
};

export const getBillableEntities = (request: any) => {
  return post("waterright/billable/list", request);
};

export const upsertPlaceOfUse = (request: any) => {
  return put("waterright/placeofuse", request);
};

export const getPlaceOfUse = (companyId: any) => {
  return get(`waterright/placeofuse/${companyId}`);
};

export const getPagedPlaceOfUse = (request: any) => {
  return post(`waterright/placeofuse/paged`, request);
};

export const removePlaceOfUse = (request: any) => {
  return put("waterright/placeofuse/remove", request);
};

export const upsertWaterRightPlaceOfUse = (request: any) => {
  return put("waterright/placeofuse/waterright", request);
};

export const removeWaterRightPlaceOfUse = (request: any) => {
  return put("waterright/placeofuse/waterright/remove", request);
};

export const getPagedWaterRights = (request: any) => {
  return post(`waterright/paged/list`, request);
};

export const getWaterRightsLookup = (request: { waterRightIds?: string[]; isActive?: boolean; companyId?: string }) => {
  return post(`waterright/lookup`, request);
};

export const getWaterRightCalculations = (request: any) => {
  return post(`waterright/calculations`, request);
};

export const getWaterRightTagsLookup = (request: { companyId?: string; isActive?: boolean }) => {
  return post(`waterright/tags/lookup`, request);
};

export const getWaterRightReportLookup = (request: { companyId: string; page: number; pageSize: number; isActive: boolean | null }) => {
  return post(`waterright/report/lookup`, request);
};
