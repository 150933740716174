import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Space, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { TooltipPlacement } from "antd/es/tooltip";
import { addFaq, getFaq, updateFaq } from "@/apis/communication.api";
import { routes } from "@/configs";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import scrollToTop from "@/services/scrollToTop";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { removeBreadcrumb } from "@/stores/breadcrumbs.store";

const AdminFaqAddEdit: FC = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  const { navigate } = useCustomNavigate();

  const [form] = useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [faq, setFaq] = useState<any>(undefined);

  useEffect(() => {
    if (id) {
      getFaqData();
    }
  }, [id]);

  useEffect(() => {
    if (faq) form.setFieldsValue(faq);
  }, [faq]);

  const getFaqData = async () => {
    setLoading(true);

    const response = await getFaq(id);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setFaq(data.value);
      }
    }

    setLoading(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onFinish(values);
      })
      .catch((errors) => {
        scrollToTop();
        message.error(`Please fill in the required fields`, 5);
      });
  };

  const renderActionButtons = (placement: TooltipPlacement) => (
    <Space>
      <Button loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
        Save
      </Button>
      <Button
        loading={loading}
        icon={<CloseOutlined />}
        onClick={() => {
          dispatch(removeBreadcrumb());
          navigate(-1);
        }}
      >
        Cancel
      </Button>
    </Space>
  );

  const onFinish = async (values: any) => {
    setLoading(true);

    const data = { ...values };

    if (id) {
      const response = await updateFaq(id, data);
      if (response.ok) {
        message.success("Successfully updated the FAQ");
        navigate(routes.adminFaqsList);
        dispatch(removeBreadcrumb());
      } else {
        message.error("Failed to update the FAQ");
      }
    } else {
      const response = await addFaq({ ...data });
      if (response.ok) {
        navigate(routes.adminFaqsList);
        message.success("Successfully added the FAQ");
        dispatch(removeBreadcrumb());
      } else {
        message.error("Failed to add the FAQ");
      }
    }

    setLoading(false);
  };

  return (
    <Card className="adminFaqAddEdit" id="adminFaqAddEdit" title={id ? "Edit FAQ" : "Add FAQ"} extra={renderActionButtons("bottomRight")} actions={[renderActionButtons("top")]}>
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} onFinish={onFinish} autoComplete="off">
        <Form.Item label="Title" name="title" rules={[{ required: true, message: "Please enter a title for the FAQ" }]}>
          <Input placeholder="Title" />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Please enter a description for the FAQ",
            },
          ]}
        >
          <Input.TextArea rows={6} placeholder="Title" />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AdminFaqAddEdit;
