import { WellTable } from "@/components";
import { FC } from "react";

import "./WellList.scss";

const WellList: FC = () => {
  // const { selectedCompanyId } = useSelector((state: any) => state.company);

  return <WellTable header addWell trackTableState />;
};

export default WellList;
