import { RoleBasedFeature } from "@/services/useRoleBasedFeature";

export enum UserInviteStatus {
  Invited = 0,
  Accepted = 1,
}

export const UserRole: any = {
  admin: {
    label: "Administrator",
    description: "Can view, edit and update all company data and settings",
    value: "admin",
  },
  waterreader: {
    label: "Water Reader",
    description: "Can only view assigned water rights and record water meter readings for the water rights",
    value: "waterreader",
  },
  viewer: {
    label: "View Only",
    description: "Can view only assigned water rights",
    value: "viewer",
  },
};

export const IdentityRole: {
  [key: string]: {
    role: string;
    label: string;
    features: RoleBasedFeature[];
  };
} = {
  MasterAdministrator: {
    role: "MasterAdministrator",
    label: "Master Administrator",
    features: [RoleBasedFeature.All],
  },
  AdministratorManager: {
    role: "AdministratorManager",
    label: "Administrator Manager",
    features: [
      RoleBasedFeature.Dashboard,
      RoleBasedFeature.Communications,

      RoleBasedFeature.Billing,
      RoleBasedFeature.BillingUsers,
      RoleBasedFeature.BillingEvents,
      RoleBasedFeature.BillingReports,
      RoleBasedFeature.BillingInvoices,

      RoleBasedFeature.Events,
      RoleBasedFeature.Users,
      RoleBasedFeature.UserAdd,
      RoleBasedFeature.Reports,
      RoleBasedFeature.BillingReports,
      RoleBasedFeature.FAQ,
      RoleBasedFeature.ContactMessages,
      RoleBasedFeature.SupportQueries,
      RoleBasedFeature.Lookups,
    ],
  },
  Administrator: {
    role: "Administrator",
    label: "Administrator",
    features: [
      RoleBasedFeature.Dashboard,
      RoleBasedFeature.Communications,

      RoleBasedFeature.Billing,
      RoleBasedFeature.BillingUsers,
      RoleBasedFeature.BillingEvents,
      RoleBasedFeature.BillingReports,
      RoleBasedFeature.BillingInvoices,

      RoleBasedFeature.Events,
      RoleBasedFeature.Users,
      RoleBasedFeature.Reports,
      RoleBasedFeature.FAQ,
      RoleBasedFeature.ContactMessages,
      RoleBasedFeature.SupportQueries,
      RoleBasedFeature.Lookups,
    ],
  },
  FinanceManager: {
    role: "FinanceManager",
    label: "Finance Manager",
    features: [
      RoleBasedFeature.Billing,
      RoleBasedFeature.BillingUsers,
      RoleBasedFeature.BillingEvents,
      RoleBasedFeature.BillingReports,
      RoleBasedFeature.BillingInvoices,
      RoleBasedFeature.BillingElevatedRoles,
    ],
  },
  Impersonator: {
    role: "Impersonator",
    label: "Impersonator",
    features: [RoleBasedFeature.Users],
  },
};

//Testing tagging
