import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Input, Popconfirm, Space, Switch, Table, Tag, message } from "antd";
import { archiveBulkNotification, cloneBulkNotification, listBulkNotification, sendBulkNotification } from "@/apis/communication.api";
import { StatusTag } from "@/components";
import { constants, routes } from "@/configs";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";

const AdminCommunications: FC = () => {
  const dispatch = useAppDispatch();

  const { navigate, updateQueryParams, queryParams } = useCustomNavigate();

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const [loading, setLoading] = useState<boolean>(false);
  const [clone, setCloning] = useState<boolean>(false);
  const [archiving, setArchiving] = useState<boolean>(false);
  const [sendingNotification, setSendingNotification] = useState<boolean>(false);

  const [communicationList, setCommunicationList] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const [isArchived, setIsArchived] = useState<boolean | null>(null);

  useEffect(() => {
    refreshCommunicationList();
    // eslint-disable-next-line
  }, [isArchived]);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [communicationList]);

  const handleRefresh = () => {
    setSearchString(undefined);
    refreshCommunicationList();
  };

  const refreshCommunicationList = async (searchString: any = null) => {
    setLoading(true);

    const request = { searchString: searchString, isArchived: isArchived };

    const response = await listBulkNotification(request);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setCommunicationList(data.value);
      }
    }

    setLoading(false);
  };

  const onSearchChange = (searchString: any) => {
    setSearchString(searchString);
  };

  const onSearch = (searchString: any) => {
    setSearchString(searchString);
    refreshCommunicationList(searchString);
  };

  const handleAddCommunication = () => {
    dispatch(
      addBreadcrumb({
        type: `Communication / Add`,
        url: `communication/add`,
      })
    );
    navigate(routes.adminCommunicationAdd);
  };

  const handleArchiveBulkNotification = async (record: any) => {
    setArchiving(true);

    const response = await archiveBulkNotification(record?.id, {
      id: record?.id,
    });
    if (response.ok) {
      message.success("Successfully archived communication");
    }

    refreshCommunicationList();

    setArchiving(false);
  };

  const handleSendingNotification = async (record: any) => {
    setSendingNotification(true);

    const response = await sendBulkNotification(record.id, { id: record.id });

    if (response.ok) {
      message.success("Successfully sent bulk communication");
    } else message.error("Failed to send bulk communication");

    setSendingNotification(false);
    refreshCommunicationList();
  };

  const handleCloneCommunication = async (record: any) => {
    navigate(routes.adminCommunicationAdd, null, null, record);
  };

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Date",
        key: "date",
        dataIndex: "date",
        width: 150,
        render: (val: any, record: any) => (
          <>
            {dayjs(val).format(constants.dateTimeFormat)}
            {/* <Button style={{ paddingLeft: 0 }} type='link' onClick={() => handleViewCommunication(record)}>{val}</Button> */}
          </>
        ),
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        width: 100,
        render: (val: any, record: any) => {
          return val === "email" ? "Email" : "Push";
        },
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        width: 100,
        render: (val: any, record: any) => <StatusTag status={val} />,
      },
      isArchived !== null &&
        isArchived === false && {
          title: "Archived",
          key: "archived",
          width: 100,
          align: "center",
          render: (val: any, record: any) => {
            return record.archived ? <Tag color="green">YES</Tag> : <Tag color="blue">NO</Tag>;
          },
        },
      {
        title: "Subject / Title",
        key: "subject",
        dataIndex: "subject",
        width: 150,
        render: (val: any, record: any) => {
          if (val !== null) {
            if (val?.length > 32) {
              return `${val.substring(0, 32)}...`;
            } else if (val?.length < 32) return val;
          } else return record?.title;
        },
      },
      {
        title: "Body",
        key: "body",
        dataIndex: "body",
        width: 150,
        render: (val: any, record: any) => {
          if (val.length > 32) {
            return `${val.substring(0, 32)}...`;
          } else if (val.length < 32) return val;
        },
      },
      // {
      //     title: 'Footer',
      //     key: 'footer',
      //     dataIndex: 'footer',
      //     width: 150,
      //     render: (val: any, record: any) => {
      //         if (val && val.length > 32) {
      //             return <Popover style={{ maxWidth: '400px' }} content={val} title="Body">
      //                 <InfoCircleOutlined /> {`${val.substring(0, 32)}`}...
      //             </Popover>
      //         } else if (val && val.length < 50) return val
      //         else return val
      //     }
      // },
      {
        title: "Actions",
        key: "action",
        width: 170,
        render: (value: any, record: any) => (
          <>
            {record?.status === "Created" && (
              <Popconfirm title="Send communication" description="Are you sure you want to send this communication?" onConfirm={() => handleSendingNotification(record)} okText="Yes" cancelText="No">
                <Button type="link" style={{ paddingLeft: 0, paddingRight: 0 }}>
                  Send
                </Button>
              </Popconfirm>
            )}
            {record?.status === "Created" && ` | `}
            <Button type="link" onClick={() => handleViewCommunication(record)} style={{ paddingLeft: 0, paddingRight: 0 }}>
              View
            </Button>
            {" | "}
            <Popconfirm title="Clone communication" description="Are you sure you want to clone this communication?" onConfirm={() => handleCloneCommunication(record)} okText="Yes" cancelText="No">
              <Button type="link" style={{ paddingLeft: 0, paddingRight: 0 }}>
                Clone
              </Button>
            </Popconfirm>
            {record?.archived === null && ` | `}
            {record?.archived ? undefined : (
              <Popconfirm
                title="Archive communication"
                description="Are you sure you want to archive this communication?"
                onConfirm={() => handleArchiveBulkNotification(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" style={{ paddingLeft: 0, paddingRight: 0 }}>
                  Archive
                </Button>
              </Popconfirm>
            )}
          </>
        ),
      },
    ];

    setColumns(tempColumns.filter((x) => x));
  };

  const handleViewCommunication = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record?.subject ? record?.subject : record?.title}`,
        url: `communication/${record.id}/view`,
      })
    );
    navigate(routes.adminCommunicationView, { id: record.id });
  };

  const handleOnChange = (value: boolean) => {
    if (!value) setIsArchived(false);
    else setIsArchived(null);
  };

  return (
    <Card
      className="adminCommunication"
      id="adminCommunication"
      title="Communication"
      bordered={false}
      extra={
        <Button onClick={handleAddCommunication} icon={<PlusOutlined />} type="primary">
          Add Communication
        </Button>
      }
    >
      <div>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Space style={{ paddingBottom: 10 }}>
            <Input.Search
              disabled={loading}
              placeholder="Search communication"
              onSearch={(searchString) => {
                onSearch(searchString);
              }}
              onChange={(val) => onSearchChange(val.currentTarget.value)}
              value={searchString}
              style={{ width: 400 }}
              allowClear
            />
            <Button disabled={loading} onClick={handleRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
            <Switch defaultChecked onChange={handleOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
          </Space>
          <Table rowKey={(row: any) => row.id} loading={loading} columns={columns} dataSource={communicationList ?? []} size="small" />
        </Space>
      </div>
    </Card>
  );
};

export default AdminCommunications;
