import { LeftOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Descriptions, List, Space, Table, Tabs } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { downloadBulkNotificationAttachment, getSupportQuery, listBulkNotificationAttachment } from "@/apis/communication.api";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useCustomNavigate from "@/services/useCustomNavigate";
import { openAttachmentFromGeneratedFile } from "@/services/utils";
import { useAppDispatch } from "@/stores";
import { removeBreadcrumb, replaceUrlStateBreadcrumbType } from "@/stores/breadcrumbs.store";

import "./AdminSupportQueryView.scss";

const AdminSupportQueryView: FC = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  const { navigate } = useCustomNavigate();

  const pathUrl = window.location.pathname;

  const [loading, setLoading] = useState<boolean>(false);
  const [donwloadingAttachment, setDonwloadingAttachment] = useState<boolean>(false);
  const [loadingAttachments, setLoadingAttachments] = useState<boolean>(false);

  const [attachmentColumns, setAttachmentColumns] = useState<any[]>([]);

  const [attachments, setAttachments] = useState<any[]>([]);
  const [supportQuery, setSupportQuery] = useState<any>(undefined);
  const [activeKey, setActiveKey] = useState("attachments");

  useEffect(() => {
    if (pathUrl.includes("view") && supportQuery) {
      dispatch(replaceUrlStateBreadcrumbType(supportQuery?.supportQuery));
    }
  }, [pathUrl, supportQuery]);

  useEffect(() => {
    if (id) getSupportQueryData();
  }, [id]);

  useEffect(() => {
    calculateAttachmentColumns();
  }, [attachments, supportQuery]);

  useEffect(() => {
    if (supportQuery?.attachments?.length > 0) {
      getNotificationAttachments(supportQuery.attachments);
    }
  }, [supportQuery]);

  const getSupportQueryData = async () => {
    setLoading(true);

    const response = await getSupportQuery(id);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setSupportQuery(data.value);
      }
    }

    setLoading(false);
  };

  const getNotificationAttachments = async (attachments: any[]) => {
    setLoadingAttachments(true);

    const request = { attachmentIds: attachments };

    const response = await listBulkNotificationAttachment(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setAttachments(data.value);
      }
    }

    setLoadingAttachments(false);
  };

  const downloadAttachment = async (id: string) => {
    setDonwloadingAttachment(true);

    const response = await downloadBulkNotificationAttachment(id);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        openAttachmentFromGeneratedFile(data.value);
      }
    }

    setDonwloadingAttachment(false);
  };

  const calculateAttachmentColumns = () => {
    let tempColumns = [
      {
        title: "File Name",
        key: "fileName",
        dataIndex: "fileName",
        width: 150,
        render: (val: any, record: any) => val,
      },
      {
        title: "Download Attachment",
        width: 150,
        render: (val: any, record: any) => (
          <Button loading={donwloadingAttachment} disabled={donwloadingAttachment} style={{ paddingLeft: 0 }} type="link" onClick={() => downloadAttachment(record.id)}>
            Download
          </Button>
        ),
      },
    ];

    setAttachmentColumns(tempColumns.filter((x) => x));
  };

  const renderTabs = (supportQuery: any) => {
    let tabs: any[] = [
      {
        label: "Attahcments",
        key: "attachments",
        children: (
          <Card id="attachments" title="Attachments">
            <Table rowKey={(row: any) => row.id} loading={loadingAttachments} columns={attachmentColumns} dataSource={attachments ?? []} size="small" />
          </Card>
        ),
      },
    ];

    return tabs;
  };

  const data = [
    {
      title: "Support Request",
    },
  ];

  const renderContent = () => {
    return (
      <div>
        <Descriptions
          bordered
          size="small"
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          labelStyle={{ width: 200 }}
          style={{ width: 500, height: "100%" }}
          className="removeDescriptionHeaderPadding"
        >
          <Descriptions.Item label="Email">{supportQuery?.userEmail}</Descriptions.Item>
          <Descriptions.Item label="Phone Number">{supportQuery?.phoneNumber ?? "-"}</Descriptions.Item>
          <Descriptions.Item label="First Name">{supportQuery?.firstName ?? "-"}</Descriptions.Item>
          <Descriptions.Item label="Last Name">{supportQuery?.lastName ?? "-"}</Descriptions.Item>
        </Descriptions>
        <br />
        <Card title="Communication History">
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item style={{ whiteSpace: "pre-line" }}>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: "orange",
                        verticalAlign: "middle",
                      }}
                      size="large"
                    >
                      C
                    </Avatar>
                  }
                  title={item.title}
                  description={
                    <>
                      {`${supportQuery?.body}`}
                      {supportQuery?.attachments && supportQuery?.attachments.length > 0 && (
                        <>
                          <br />
                          Attachments:
                          <br />
                          {attachments.map((attachment) => (
                            <a onClick={() => downloadAttachment(attachment.id)}>{attachment.fileName}</a>
                          ))}
                        </>
                      )}
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </Card>
      </div>
    );
  };

  const renderActionButtons = (placement: TooltipPlacement) => (
    <Space>
      <Button
        loading={loading}
        icon={<LeftOutlined />}
        onClick={() => {
          dispatch(removeBreadcrumb());
          navigate(-1);
        }}
      >
        Back
      </Button>
    </Space>
  );

  return (
    <Card
      className="adminSupportQueryView"
      id="adminSupportQueryView"
      title={`${loading ? "loading..." : supportQuery?.supportQuery}`}
      extra={renderActionButtons("bottomRight")}
      // actions={[renderActionButtons('top')]}
    >
      {renderContent()}
    </Card>
  );
};

export default AdminSupportQueryView;
