import { memo } from "react";
import { FinanceDocumentType } from "../AdminInvoices";

const FinanceDocumentTypeComponent = ({ type }: { type: FinanceDocumentType }) => {
  let description = "-";
  switch (type) {
    case FinanceDocumentType.unknown:
      description = "Unknown";
      break;
    case FinanceDocumentType.proformaInvoice:
      description = "Proforma Invoice";
      break;
    case FinanceDocumentType.invoice:
      description = "Invoice";
      break;
  }

  return <>{description}</>;
};

export default memo(FinanceDocumentTypeComponent);
