import { FC, ReactNode } from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import { Header, SupportQueryFloatingButton } from "@/components";
import { SingletonHooksContainer } from "react-singleton-hook";
import VersionChecker from "@/components/VersionChecker/VersionChecker";

interface Props {
  children?: ReactNode;
}

const BaseLayout: FC<Props> = (props: any) => {
  return (
    <Layout id="baseLayout">
      <Header />
      <Layout>
        <SupportQueryFloatingButton />
        <VersionChecker />
        <SingletonHooksContainer />
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default BaseLayout;
