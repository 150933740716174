import { CloseOutlined, LeftCircleFilled, PlusOutlined, RightCircleFilled, RotateLeftOutlined, RotateRightOutlined, ThunderboltOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { Alert, Col, DatePicker, Divider, Empty, Form, Image, Input, InputNumber, Modal, Row, Select, Space, Switch, Tabs, Upload, UploadFile, message } from "antd";
import { useWatch } from "antd/es/form/Form";
import { RcFile } from "antd/es/upload";
import { getWaterRight } from "@/apis/waterright.api";
import { getWell } from "@/apis/well.api";
import { addWellReading, getWellReading, updateWellReading, updateWellReadingImages } from "@/apis/wellreading.api";
import { PlacesOfUseSelector } from "@/components";
import LookupSelector from "@/components/LookupSelector/LookupSelector";
import { constants } from "@/configs";
import dayjs from "dayjs";
import { UserRole } from "@/dtos/user.dto";
import { FC, useEffect, useRef, useState } from "react";
import type { DraggableData, DraggableEvent } from "react-draggable";
import Draggable from "react-draggable";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { formatReadingValue, getBase64, isValidGUID, resizeImage } from "@/services/utils";
import leftPad from "@/utils/leftPad";
import useSelectedCompanyData from "@/queries/useSelectedCompanyData";

interface Props {
  readingId: string;
  wellId: string;
  waterRightId: string;
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  refreshWellViewCalculations?: () => void;
  canEdit: boolean;
}

const ReadingsAddEditModal: FC<Props> = (props) => {
  const { open, onCancel, onSuccess, wellId, waterRightId, readingId, refreshWellViewCalculations, canEdit } = props;

  const [form] = Form.useForm();

  const user = useAuth();

  const { selectedCompany, selectedCompanyId } = useSelector((state: any) => state.company);

  const [fileList, setFileList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [readingData, setReadingData] = useState<any>(undefined);
  const [loadingWellData, setLoadingWellData] = useState<boolean>(true);
  const [uploading, setUploading] = useState<boolean | undefined>(false);
  const [wellData, setWellData] = useState<any>(undefined);
  const [imageIndex, setImageIndex] = useState<number | undefined>(undefined);
  const [imageToView, setImageToView] = useState("");
  const [selected, setSelected] = useState<string[]>([]);

  const [waterRightData, setWaterRightData] = useState<any>(undefined);
  const [loadingWaterRight, setLoadingWaterRight] = useState<boolean>(false);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const [degrees, setDegrees] = useState<number>(0);
  const [angles, setAngles] = useState<any[]>([]);

  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef<HTMLDivElement>(null);

  const newMeter: boolean | undefined = useWatch("newMeter", form);

  const digital = useWatch("digital", form);
  const meterMaxValue: string = useWatch("meterMaxValue", form);
  const decimals: number = useWatch("decimals", form);
  const reading: number = useWatch("reading", form);
  const meterUnits: string = useWatch("meterUnits", form);

  useEffect(() => {
    if (selectedCompanyId) getWaterRightData();
  }, [selectedCompanyId]);

  const getWaterRightData = async () => {
    setLoadingWaterRight(true);
    const response = await getWaterRight(waterRightId);
    if (response.ok) {
      const data = await response.json();
      setWaterRightData(data.value);
    }
    setLoadingWaterRight(false);
  };

  useEffect(() => {
    setDegrees(0);
  }, [imageToView]);

  useEffect(() => {
    if (fileList.length > 0 && imageIndex === undefined) {
      if (fileList[0].url) {
        // Pre-existing images
        setImageIndex(0);
        setImageToView(fileList[0].url);
      } else {
        // Uploaded image
        if (fileList[0].status === "done") {
          setImageIndex(0);
          setImageToView(`${constants.baseApiUrl}/well/image/${fileList[0].response.value}/download`);
        }
      }
    }
  }, [fileList]);

  useEffect(() => {
    if (angles.length === 0) {
      const angleList = fileList.map((file) => {
        const id = isValidGUID(file?.uid) ? file.uid : file?.response?.value;
        return { id: id, angle: 0 };
      });
      setAngles(angleList);
    } else {
      let newList: any[] = [];
      fileList.map((file) => {
        let match = false;
        const id = isValidGUID(file?.uid) ? file.uid : file?.response?.value;
        const found = angles?.find((angleDto) => angleDto?.id === id);
        if (found) newList.push(found);
        else newList.push({ id: id, angle: 0 });
      });

      setAngles(newList);
    }
  }, [fileList]);

  useEffect(() => {
    if (readingId) {
      getReadingData();
    } else setLoading(false);
  }, [readingId]);

  useEffect(() => {
    if (wellId) {
      getWellData();
    }
  }, [wellId]);

  useEffect(() => {
    if (readingId) {
      if (readingData?.meterSerialNumber) form.setFieldValue("meterSerialNumber", readingData.meterSerialNumber);
      if (readingData?.meterMaxValue) form.setFieldValue("meterMaxValue", readingData.meterMaxValue.toString());
      if (readingData?.digital) form.setFieldValue("digital", readingData.digital);
      if (readingData?.decimals) form.setFieldValue("decimals", readingData.decimals);
    } else {
      if (wellData?.meterSerialNumber) form.setFieldValue("meterSerialNumber", wellData.meterSerialNumber);
      if (wellData?.meterMaxValue) form.setFieldValue("meterMaxValue", wellData.meterMaxValue.toString());
      if (wellData?.digital) form.setFieldValue("digital", wellData.digital);
      if (wellData?.decimals) form.setFieldValue("decimals", wellData.decimals);
    }
  }, [wellData, readingData, readingId]);

  const getWellData = async () => {
    setLoadingWellData(true);
    const response = await getWell(wellId);

    if (response.ok) {
      const data = await response.json();
      setWellData(data.value);
    }
    setLoadingWellData(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        let submitData: any = {
          ...values,
          wellId,
          companyId: wellData?.companyId,
          waterRightId: wellData?.waterRightId,
          placesOfUse: selected,
        };

        // if (readingId) submitData.type = "Manual";
        if (readingId && values.type === "Scanned") submitData.type = "Scanned - Manual";
        else if (readingId && values.type === "Scanned - Manual") submitData.type = "Scanned - Manual";
        else submitData.type = "Manual";

        // if (newMeter !== undefined || !newMeter) {
        //     submitData.meterSerialNumber = wellData?.meterSerialNumber;
        //     submitData.meterUnits = wellData?.meterUnits;
        //     submitData.meterMaxValue = wellData?.meterMaxValue;
        //     submitData.meterMultiplier = wellData?.meterMultiplier;
        // }

        if (fileList) {
          const oldImageIds = fileList.filter((item: any) => item.existingFile).map((file: any) => file.uid);
          const newImageIds = fileList.filter((item: any) => !item.existingFile).map((file: any) => JSON.parse(file.xhr.response)?.value);
          submitData.imageIds = [...oldImageIds, ...newImageIds];
        }

        handleSubmitReadingData(submitData);
      })
      .catch((errors) => {
        setLoading(false);
      });
  };

  const handleSubmitReadingData = async (data: any) => {
    // Update images
    let images: any[] = [];
    let newImageData: any[] = [];
    let newImageIds: any[] = [];
    angles.map((angleDto) => {
      if (angleDto.angle !== 0) images.push({ imageId: angleDto.id, angle: angleDto.angle });
    });

    if (images.length > 0) {
      const imageResponse = await updateWellReadingImages({ images: images });
      if (imageResponse.ok) {
        const data = await imageResponse.json();
        newImageData = data.value;
      }
    }

    data.imageIds.map((imageId: any) => {
      const foundImage = newImageData.find((image: any) => image.oldId === imageId);
      if (foundImage) newImageIds.push(foundImage.newId);
      else newImageIds.push(imageId);
    });

    if (readingId) {
      // Update reading
      const response = await updateWellReading(readingId, {
        ...data,
        imageIds: newImageIds,
        decimals: !digital ? null : decimals,
      });
      if (response.ok) {
        onSuccess && onSuccess();
        refreshWellViewCalculations && refreshWellViewCalculations();
      } else {
        message.error("Failed to update the meter reading");
      }
    } else {
      // Add reading
      const response = await addWellReading({
        ...data,
        imageIds: newImageIds,
        decimals: !digital ? null : decimals,
      });
      if (response.ok) {
        onSuccess && onSuccess();
        refreshWellViewCalculations && refreshWellViewCalculations();
      } else {
        message.error("Failed to add the meter reading");
      }
    }
    setLoading(false);
  };

  const getReadingData = async () => {
    setLoading(true);

    const response = await getWellReading(readingId);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        form.setFieldsValue({
          ...data.value,
          date: dayjs(data.value.date),
          type: data.value.type || "Manual",
        });
        setFileList(
          data.value.imageIds.map((imageId: any, index: number) => {
            return {
              uid: imageId,
              name: "image.png",
              status: "done",
              url: `${constants.baseApiUrl}/well/image/${imageId}/download`,
              existingFile: true,
            };
          })
        );
        setReadingData(data.value);
      }
    }

    setLoading(false);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleCancel = () => setPreviewOpen(false);

  const handleLeftClick = () => {
    if (imageIndex && imageIndex === 0) return;
    else if (imageIndex !== undefined && imageIndex > 0) {
      setImageIndex(imageIndex - 1);
      if (fileList[imageIndex - 1].url) setImageToView(fileList[imageIndex - 1].url);
      else setImageToView(`${constants.baseApiUrl}/well/image/${fileList[imageIndex - 1].response.value}/download`);
    }
  };

  const handleRightClick = () => {
    if (imageIndex && imageIndex === fileList.length - 1) return;
    else if (imageIndex !== undefined && imageIndex < fileList.length - 1) {
      setImageIndex(imageIndex + 1);
      if (fileList[imageIndex + 1].url) setImageToView(fileList[imageIndex + 1].url);
      else setImageToView(`${constants.baseApiUrl}/well/image/${fileList[imageIndex + 1].response.value}/download`);
    }
  };

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const renderForm = () => {
    return (
      <Row>
        <Col style={{ maxHeight: 750, overflowY: "auto", paddingRight: 10 }} span={12}>
          <Form form={form} labelCol={{ span: 10 }} name="basic" layout="horizontal" initialValues={{ type: "Manual" }}>
            <Form.Item
              label="Reading Date"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please select the date reading was taken",
                },
              ]}
              style={{ marginBottom: "5px" }}
            >
              <DatePicker style={{ width: "100%" }} showTime placeholder="Select date when reading was taken" format={constants.dateTimeFormat} allowClear />
            </Form.Item>

            <Form.Item
              name="reading"
              label="Reading"
              rules={[
                {
                  message: (
                    <>
                      Readings may not contain decimal values, please provide all the numbers as seen on the meter.
                      <br />
                      For example "123456,111" should be entered as "123456111".
                      <br />
                      Please also ensure the meter multiplier and meter max value are correct if the meter displays decimal values.
                    </>
                  ),
                  validator: (_, reading: string) => {
                    let decimalContained = false;

                    if (reading.indexOf(",") > -1 || reading.indexOf(".") > -1) decimalContained = true;

                    if (decimalContained) return Promise.reject("An invalid reading was entered.");
                    else return Promise.resolve();
                  },
                },
                {
                  required: true,
                  message: "Please enter the reading value",
                },
              ]}
              style={{ marginBottom: "5px" }}
            >
              <Input disabled={loading} maxLength={meterMaxValue?.toString()?.length} placeholder="Enter the reading value" />
            </Form.Item>

            {reading && digital && meterMaxValue && decimals > 0 && (
              <Form.Item wrapperCol={{ span: 14, offset: 10 }} style={{ marginBottom: "5px" }}>
                <Alert
                  type="info"
                  showIcon
                  description={
                    <>
                      Please confirm the reading value below is the same as displayed on the meter: <br />
                      <br />
                      Reading: {leftPad(formatReadingValue({ reading, decimals }), meterMaxValue?.toString()?.length, "0")}
                    </>
                  }
                />
              </Form.Item>
            )}

            <Form.Item name="type" label="Type" style={{ marginBottom: "5px" }}>
              <Select disabled placeholder="Select the type of reading" allowClear>
                <Select.Option value="Manual" key="Manual">
                  Manual
                </Select.Option>
                <Select.Option value="Scanned" key="Scanned">
                  Scanned
                </Select.Option>
              </Select>
            </Form.Item>

            {canEdit && (
              <Form.Item style={{ marginBottom: "5px" }} name="newMeter" label="New Meter" valuePropName="checked">
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
            )}

            <Form.Item style={{ marginBottom: "5px" }} name="meterSerialNumber" label="Meter Serial Number">
              <Input
                defaultValue={readingData?.meterSerialNumber ? readingData.meterSerialNumber : wellData?.meterSerialNumber}
                disabled={loading || !canEdit}
                placeholder="Enter the meter serial number"
              />
            </Form.Item>

            <Form.Item style={{ marginBottom: "5px" }} name="meterUnits" label="Meter Units">
              <LookupSelector
                locked={!canEdit}
                propertyToSet="meterUnits"
                form={form}
                placeholder="Select the meter units"
                lookupType="meterunits"
                constantValue={readingData?.meterUnits ? readingData?.meterUnits : wellData?.meterUnits}
              />
            </Form.Item>

            <Form.Item style={{ marginBottom: "5px" }} name="meterMultiplier" label="Meter Multiplier">
              <LookupSelector
                locked={!canEdit}
                propertyToSet="meterMultiplier"
                form={form}
                placeholder="Select the meter multiplier"
                lookupType="metermultiplier"
                constantValue={readingData?.meterMultiplier ? readingData?.meterMultiplier : wellData?.meterMultiplier}
              />
            </Form.Item>

            <Form.Item style={{ marginBottom: "5px" }} label="Meter Max Value" name="meterMaxValue">
              <LookupSelector
                locked={!canEdit}
                propertyToSet="meterMaxValue"
                form={form}
                placeholder="Select the meter max value"
                lookupType="metermaxvalue"
                constantValue={(readingData?.meterMaxValue ? readingData?.meterMaxValue : wellData?.meterMaxValue)?.toString()}
              />
            </Form.Item>

            <Form.Item style={{ marginBottom: "5px" }} label="Is Digital Meter?" name="digital" valuePropName="checked">
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>

            {digital && (
              <Form.Item style={{ marginBottom: "5px" }} label="Number of Decimal Places" name="decimals">
                <InputNumber style={{ minWidth: 230, width: "100%" }} min={0} max={meterMaxValue?.length - 1} defaultValue={0} placeholder="Enter the number of decimal places" />
              </Form.Item>
            )}

            <Form.Item style={{ marginBottom: "5px" }} label="Photos" required>
              <Upload
                hasControlInside={false}
                style={{ marginBottom: "5px" }}
                multiple={true}
                action={`${constants.baseApiUrl}/well/image/upload`}
                headers={{
                  Authorization: `Bearer ${user.user?.access_token}`,
                }}
                listType="picture-card"
                fileList={fileList}
                onChange={(info: any) => {
                  if (info.file.status === "uploading") {
                    setUploading(true);
                  }
                  if (info.file.status === "done") {
                    setUploading(false);
                  }
                  setFileList(info.fileList);
                }}
                showUploadList={{
                  showRemoveIcon: readingId ? false : true,
                }}
                beforeUpload={resizeImage}
                onPreview={(file) => {
                  // Existing file
                  if (isValidGUID(file.uid)) {
                    const index = fileList?.findIndex((fileItem) => fileItem?.uid === file?.uid);
                    setImageIndex(index);
                    setImageToView(fileList[index]?.url);
                  } else {
                    const index = fileList?.findIndex((fileItem) => fileItem?.response?.value === file?.response?.value);
                    setImageIndex(index);
                    setImageToView(`${constants.baseApiUrl}/well/image/${fileList[index]?.response?.value}/download`);
                  }

                  return undefined;
                }}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </Upload>
            </Form.Item>

            <Form.Item label="Places of Use" style={{ marginBottom: "5px" }}>
              <PlacesOfUseSelector
                readingPlacesOfUse={readingData?.placesOfUse}
                reading={true}
                selectedCompanyId={selectedCompanyId}
                waterRightId={waterRightId}
                master={false}
                waterRightPlacesOfUse={waterRightData?.placesOfUse}
                updateSelectedValues={setSelected}
              />
            </Form.Item>

            <Form.Item style={{ marginBottom: "5px" }} name="notes" label="Notes">
              <Input.TextArea rows={4} placeholder="Enter notes for this reading (Optional)" />
            </Form.Item>
            {readingId && (
              <Form.Item style={{ marginBottom: "5px" }} name="archived" label="Archived" valuePropName="checked">
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
            )}
          </Form>
          <Image
            style={{ display: "none" }}
            preview={{
              maskStyle: {
                backgroundColor: "transparent",
                position: "absolute",
              },
              bodyStyle: {
                width: "30%",
                height: "50%",
                float: "right",
              },
              // wrapProps: undefined,
              mask: undefined,
              maskClosable: false,
              visible: previewOpen,
              scaleStep: 0.5,
              src: previewImage,
              onVisibleChange: (value) => {
                setPreviewOpen(value);
              },
            }}
          />
        </Col>
        <Col style={{ paddingLeft: 20 }} span={12}>
          {
            imageToView !== "" && (
              // <div style={{ paddingRight: 20 }}>
              <TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0}>
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <>
                    <TransformComponent contentStyle={{ height: 580, width: 460 }}>
                      <img
                        src={imageToView}
                        alt="noimage"
                        style={{
                          objectFit: "contain",
                          maxHeight: 580,
                          maxWidth: 460,
                          transform: `rotate(${angles.find((angleDto) => angleDto.id === imageToView.split("/")[imageToView.split("/").length - 2])?.angle}deg)`,
                        }}
                      />
                    </TransformComponent>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Space style={{ marginLeft: -10 }}>
                        <LeftCircleFilled
                          onClick={() => {
                            handleLeftClick();
                            resetTransform();
                          }}
                          style={{ fontSize: 30 }}
                        />
                        <ZoomOutOutlined onClick={() => zoomOut()} style={{ fontSize: 30 }} />
                        <RotateLeftOutlined
                          onClick={() => {
                            // setDegrees((oldVal) => oldVal - 90)
                            setAngles((oldValues: any[]) => {
                              let newValues = [...oldValues];
                              const id = imageToView.split("/")[imageToView.split("/").length - 2];
                              const index = oldValues?.findIndex((x) => x?.id === id);

                              newValues[index].angle = (newValues[index].angle - 90) % 360 === 0 ? 0 : newValues[index].angle - 90;
                              return newValues;
                            });
                          }}
                          style={{ fontSize: 30 }}
                        />
                        <RotateRightOutlined
                          onClick={() => {
                            // setDegrees((oldVal) => oldVal + 90)
                            setAngles((oldValues: any[]) => {
                              let newValues = [...oldValues];
                              const id = imageToView.split("/")[imageToView.split("/").length - 2];
                              const index = oldValues?.findIndex((x) => x?.id === id);

                              newValues[index].angle = (newValues[index].angle + 90) % 360 === 0 ? 0 : newValues[index].angle + 90;
                              return newValues;
                            });
                          }}
                          style={{ fontSize: 30 }}
                        />
                        <ZoomInOutlined onClick={() => zoomIn()} style={{ fontSize: 30 }} />
                        <RightCircleFilled
                          onClick={() => {
                            handleRightClick();
                            resetTransform();
                          }}
                          style={{ fontSize: 30 }}
                        />
                      </Space>
                    </div>
                  </>
                )}
              </TransformWrapper>
            )
            // </div>
          }
        </Col>
      </Row>
    );
  };

  const renderTabs = () => {
    const tabs: any[] = [
      {
        label: "Details",
        key: "details",
        children: renderForm(),
      },
      {
        label: "Additional Notes",
        key: "additionalNotes",
        children: renderAdditionalNotesList(readingData?.additionalNotes),
      },
    ];

    return tabs;
  };

  const renderAdditionalNotesList = (additionalNotes: any[]) =>
    additionalNotes?.length > 0 ? (
      additionalNotes?.map((note: any, index: number) => (
        <pre
          style={{
            width: "100%",
            margin: 0,
            marginTop: 5,
            fontFamily: "inherit",
            display: "block",
            wordBreak: "break-word",
            overflowWrap: "anywhere",
          }}
        >
          <div style={{ display: "flex", paddingBottom: 5, paddingTop: 5 }}>
            <div style={{ width: 120 }}>
              {note.displayName}
              <br />
              <span style={{ fontSize: 10 }}>{dayjs(note.createdAt).format(constants.dateTimeFormat)}</span>
            </div>
            <div style={{ flex: 1 }}>{note.note}</div>
          </div>
          <Divider style={{ padding: 0, margin: 0, marginTop: 5 }} />
        </pre>
      ))
    ) : (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    );

  return (
    <Modal
      styles={{ body: { maxHeight: 750 } }}
      width={readingId ? 1200 : 1000}
      open={open}
      title={
        <div
          style={{ width: "100%", cursor: "move" }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {readingId ? "Update Meter Reading" : "Add Meter Reading"}
        </div>
      }
      okText="Save"
      cancelText="Cancel"
      onOk={handleSubmit}
      onCancel={onCancel}
      maskClosable={false}
      cancelButtonProps={{
        icon: <CloseOutlined />,
        disabled: uploading || loading,
        loading: loading || loadingWellData || uploading,
      }}
      okButtonProps={{
        icon: <ThunderboltOutlined />,
        disabled: uploading || loading,
        loading: loading || loadingWellData || uploading,
      }}
      modalRender={(modal) => (
        <Draggable disabled={disabled} bounds={bounds} nodeRef={draggleRef} onStart={(event, uiData) => onStart(event, uiData)}>
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      {readingId ? <Tabs tabPosition="left" defaultActiveKey="details" size="small" items={renderTabs()} style={{ marginBottom: 32 }} /> : renderForm()}
    </Modal>
  );
};

export default ReadingsAddEditModal;
