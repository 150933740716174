import { getWellsForDashboardMap } from "@/apis/aggregator.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

type WellsForDashboardMap = {
  permitTypeFilters?: string[];
};

const useListWellsForDashboardMapQuery = (props: WellsForDashboardMap) => {
  const { profile } = useSelector((state: any) => state.user);

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const { data, isFetching, isFetched, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [
      CacheKeys.wellsForDashboardMap,
      CacheKeys.list,
      {
        profileId: profile?.id,
        companyId: selectedCompanyId,
        permitTypeFilters: props.permitTypeFilters,
      },
    ],
    queryFn: async ({ pageParam }) => {
      const response = await getWellsForDashboardMap({
        ...props,
        page: pageParam,
        pageSize: 100,
        companyId: selectedCompanyId,
        isActive: true, //Hard coded to not return inactive wells
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess)
          return (
            data.value?.list
              ?.filter((item: any) => {
                return item.latitude !== undefined && item.latitude !== null && item.longitude !== undefined && item.longitude !== null;
              })
              .map((item: any) => ({
                type: "Feature",
                properties: { cluster: false, ...item },
                geometry: {
                  type: "Point",
                  coordinates: [parseFloat(item.longitude), parseFloat(item.latitude)],
                },
              })) ?? []
          );
      }

      throw new Error("Failed to fetch wells for map dashboard");
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    gcTime: 30 * 1000, //30 seconds
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage?.pagination?.hasNextPage) return undefined;
      return lastPageParam + 1;
    },
  });

  useEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const invalidateWellsForDashboardMap = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.wellDashboard, CacheKeys.list],
    });
  }, [queryClient]);

  const wellsForDashboardMap = useMemo(() => data?.pages?.flat() ?? [], [data]);
  const currentItemCount = wellsForDashboardMap.length;
  const totalItemCounts = data?.pages?.map((page) => page.pagination?.totalItemCount).flat() ?? [];

  return {
    wellsForDashboardMap,
    currentItemCount,
    totalItemCount: totalItemCounts[totalItemCounts.length - 1],
    fetchNextPage,
    invalidateWellsForDashboardMap,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
    isFetched,
  };
};

export default useListWellsForDashboardMapQuery;
