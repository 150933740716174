import { upsertPlaceOfUse, upsertWaterRightPlaceOfUse } from "@/apis/waterright.api";
import PlacesOfUseSelector from "@/components/PlacesOfUseSelector/PlacesOfUseSelector";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Modal, message } from "antd";
import { FC, useState } from "react";

interface Props {
  open: boolean;
  companyId: string;
  master?: boolean;
  masterPlacesOfUse?: any[];
  waterRightPlacesOfUse?: any[] | undefined;
  waterRightId?: any;
  onCancel: () => void;
  onSuccess: () => void;
  refreshWaterRightData?: () => void;
}

const PlaceOfUseAddModal: FC<Props> = (props) => {
  const { open, companyId, master = false, masterPlacesOfUse, waterRightPlacesOfUse, waterRightId, onCancel, onSuccess, refreshWaterRightData } = props;

  // const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);

  const handleSubmit = () => {
    const submitData = {
      placesOfuse: selected,
      companyId: companyId,
      waterRightId: waterRightId,
    };

    if (submitData.placesOfuse.length > 0) handleSubmitPlaceOfUseData(submitData);
    else message.error("Enter one or more places of use", 5);
  };

  const handleSubmitPlaceOfUseData = async (data: any) => {
    setLoading(true);

    const response = waterRightId ? await upsertWaterRightPlaceOfUse(data) : await upsertPlaceOfUse(data);
    if (response.ok) {
      onSuccess && onSuccess();
      refreshWaterRightData && refreshWaterRightData();
    } else message.error("Failed to add place of use");

    setLoading(false);
  };

  return (
    <Modal
      width={800}
      open={open}
      title={"Add Place of Use"}
      okText="Save"
      cancelText="Cancel"
      onOk={handleSubmit}
      onCancel={onCancel}
      maskClosable={false}
      cancelButtonProps={{
        icon: <CloseOutlined />,
        disabled: loading,
        loading: loading,
      }}
      okButtonProps={{
        icon: <ThunderboltOutlined />,
        disabled: loading,
        loading: loading,
      }}
    >
      <PlacesOfUseSelector reading={false} selectedCompanyId={companyId} waterRightId={waterRightId} master={master} waterRightPlacesOfUse={waterRightPlacesOfUse} updateSelectedValues={setSelected} />
    </Modal>
  );
};

export default PlaceOfUseAddModal;
