import { FieldSelector } from "@/components";
import { constants } from "@/configs";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Form, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import GeneratedFieldConfigurationReport from "./GeneratedFieldConfigurationReport";

const FieldConfigurationReport: FC = () => {
  const [form] = Form.useForm();
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [reportConfig, setReportConfig] = useState<any>(undefined);

  const fieldIds: any[] = useWatch("fieldIds", form);
  const [fields, setFields] = useState<any[]>([]);
  const [isActive, setIsActive] = useState<boolean | null>(true);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    const tempFieldIds: any[] = fieldIds;

    setReportConfig({
      fieldIds: tempFieldIds ?? [],
      fields:
        fields
          ?.filter((field) => fieldIds?.includes(field?.id))
          ?.map((field) => field?.name)
          ?.join(", ") ?? [],
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
      active: isActive,
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <FieldSelector
            setActive={setIsActive}
            form={form}
            propertyToSet={"fieldIds"}
            label={"Fields"}
            placeholder="Leave blank to select all fields"
            style={{ margin: 0, marginBottom: 10 }}
            setFields={setFields}
          />
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedFieldConfigurationReport reportConfig={reportConfig} />}
    </>
  );
};

export default FieldConfigurationReport;
