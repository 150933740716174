import { Anchor, Col, Row, Typography } from "antd";
import Title from "antd/es/typography/Title";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./TermsConditions.scss";
import useCustomNavigate from "@/services/useCustomNavigate";
import useWindowSize from "@/services/useWindowSize";

const links = [
  {
    key: "termsConditionsSubTitle1",
    href: "#termsConditionsSubTitle1",
    title: "1. Copyright and Trademark Notice",
  },
  {
    key: "termsConditionsSubTitle2",
    href: "#termsConditionsSubTitle2",
    title: "2. Personal Information and Privacy",
  },
  {
    key: "termsConditionsSubTitle3",
    href: "#termsConditionsSubTitle3",
    title: "3. Submitted Information",
  },
  {
    key: "termsConditionsSubTitle4",
    href: "#termsConditionsSubTitle4",
    title: "4. No Unlawful Use",
  },
  {
    key: "termsConditionsSubTitle5",
    href: "#termsConditionsSubTitle5",
    title: "5. Limitation of Liability and Responsibility",
  },
  {
    key: "termsConditionsSubTitle6",
    href: "#termsConditionsSubTitle6",
    title: "6. Disclaimer of Warranties",
  },
  {
    key: "termsConditionsSubTitle7",
    href: "#termsConditionsSubTitle7",
    title: "7. External Links and References",
  },
  {
    key: "termsConditionsSubTitle8",
    href: "#termsConditionsSubTitle8",
    title: "8. Indemnification",
  },
  {
    key: "termsConditionsSubTitle9",
    href: "#termsConditionsSubTitle9",
    title: "9. Updates",
  },
  {
    key: "termsConditionsSubTitle10",
    href: "#termsConditionsSubTitle10",
    title: "10. Choice of Law",
  },
  {
    key: "termsConditionsSubTitle11",
    href: "#termsConditionsSubTitle11",
    title: "11. Entire Agreement",
  },
];

const TermsConditions: FC = () => {
  const { navigate } = useCustomNavigate();

  const location = useLocation();
  const size = useWindowSize();

  const [isMobile, setIsMobile] = useState<boolean>(true);

  useEffect(() => {
    if (!location?.pathname?.includes("/mobile/")) setIsMobile(false);
    if (size.width < 700) setIsMobile(true);
  }, [location, size]);

  return (
    <Row id="termsConditions">
      <Col id="data" span={isMobile ? 24 : 18}>
        <Title id="termsConditionsTitle">Terms Of Use</Title>
        <Typography>
          This Internet site (“Site”), including all the content it contains, is provided to individuals, as well as representatives of business entities (“You”, “Your”) by VandWater, Inc., including
          through its applicable subsidiaries and affiliates, (“VandWater”) under these terms of use, including any changes or amendments (collectively, the “Terms of Use”). You may use this Site only
          for lawful purposes and in accordance with the Terms of Use. If You are not willing to accept these Terms of Use, please do not access or use this Site. Your use of the Site constitutes
          acceptance of the Terms of Use.
        </Typography>
        <Title id="termsConditionsSubTitle1" className="title" level={2}>
          1. Copyright and Trademark Notice
        </Title>
        <Typography>
          This Site is owned or controlled by VandWater and is protected by worldwide copyright laws. Content may not be copied or used in any way, except that it may be downloaded only for your
          non-commercial, personal use provided that no modification or further reproduction of the content is permitted. Trademarks, trade names, service marks, and products in this Site are
          protected both in the United States as well as internationally. These may not be used without the prior, written authorization of VandWater, except for the purpose of identifying the
          products or services of VandWater.
        </Typography>
        <Title id="termsConditionsSubTitle2" className="title" level={2}>
          2. Personal Information and Privacy
        </Title>
        <Typography>
          Use of this Site is also subject to this Site's Privacy Policy. Personally identifiable information provided in electronic communication to this Site is governed by the Privacy Policy.
        </Typography>
        <Title id="termsConditionsSubTitle3" className="title" level={2}>
          3. Submitted Information
        </Title>
        <Typography>
          Any information or material submitted by you to this Site will be considered non-confidential information. By providing VandWater such information and material, you grant VandWater an
          unrestricted, worldwide, perpetual, irrevocable right and license to use it (encompassing any ideas, inventions, concepts, techniques, or know-how disclosed therein) for any purpose,
          including, without limitation, disclosure to third parties, developing, marketing goods or services, the right to copy, distribute, display, reproduce, perform, and transmit such information
          and material. By submitting information or material to VandWater through this Site, you represent that you have the right to submit such information and material. You are responsible for the
          content and information of any communications you send to this Site, including its truthfulness and accuracy.
        </Typography>
        <Title id="termsConditionsSubTitle4" className="title" level={2}>
          4. No Unlawful Use
        </Title>
        <Typography>
          You may not use this Site: (a) for any purpose that is unlawful or prohibited by these Terms of Use; (b) in order to damage, disable, overburden, or impair any VandWater server; (c)
          interfere with another party’s use of the Site; (d) to spam or scrape; or (e) to attempt to gain unauthorized access to any information, materials, or services.
        </Typography>
        <Title id="termsConditionsSubTitle5" className="title" level={2}>
          5. Limitation of Liability and Responsibility
        </Title>
        <Typography>
          YOU ACKNOWLEDGE, BY YOUR USE OF THIS SITE: (A) THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK; (B) THAT YOU ASSUME FULL RESPONSIBILITY FOR ALL COSTS ASSOCIATED WITH ALL NECESSARY REPAIRS OF
          ANY EQUIPMENT THAT YOU USE IN CONNECTION WITH YOUR USE OF THIS SITE; AND (C) THAT VANDWATER WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND RELATED TO YOUR USE OF OR INABILITY TO USE THIS
          SITE, INCLUDING, WITHOUT LIMITATION, FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, COMPUTER VIRUS, OR NETWORK OR INTERNET FAILURE, OR INCLUDING, WITHOUT LIMITATION, DIRECT,
          INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, COMPENSATORY OR CONSEQUENTIAL DAMAGES, LOST PROFITS AND/OR LOSS OF OR DAMAGE TO DATA OR PROPERTY WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT,
          TORT, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER BASIS, EVEN IF VANDWATER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IF YOUR USE OF THIS SITE RESULTS IN THE NEED FOR SERVICING,
          REPAIR, OR CORRECTION OF EQUIPMENT OR DATA, YOU ASSUME ANY COSTS THEREOF. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION
          OR EXCLUSION MAY NOT APPLY TO YOU.
        </Typography>
        <Title id="termsConditionsSubTitle6" className="title" level={2}>
          6. Disclaimer of Warranties
        </Title>
        <Typography>
          THE CONTENT ON THIS SITE IS PROVIDED ON AN “AS IS” BASIS, WITHOUT REPRESENTATIONS, WARRANTIES, OR GUARANTEES OF ANY KIND AND TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW,
          VANDWATER EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, LEGAL OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE AND NON-INFRINGEMENT. VANDWATER DOES NOT MAKE ANY WARRANTY THAT THE SITE WILL MEET YOUR REQUIREMENTS, OR THAT ACCESS TO THE SITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE,
          OR THAT DEFECTS, IF ANY, WILL BE CORRECTED. VANDWATER MAKES NO WARRANTIES AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SITE OR AS TO THE ACCURACY, QUALITY, OR RELIABILITY OF
          ANY INFORMATION OBTAINED THROUGH THE SITE.
        </Typography>
        <Title id="termsConditionsSubTitle7" className="title" level={2}>
          7. External Links and References
        </Title>
        <Typography>
          In the event that this Site provides links or references to other sites, VandWater will have no responsibility for the content of such other sites and will have no liability for any damages
          or injury arising from that content. Such links to other sites are simply provided for the benefit of the users of the Site and are not an endorsement of any such content or information,
          including without limitation, third party products, services, and/or information.
        </Typography>
        <Title id="termsConditionsSubTitle8" className="title" level={2}>
          8. Indemnification
        </Title>
        <Typography>
          In the event that you violate these Terms of Use, you agree, at your expense, to defend, indemnify, and hold harmless VandWater, including its affiliates, employees, directors, and agents
          from any and all claims and expenses arising out of such violations.
        </Typography>
        <Title id="termsConditionsSubTitle9" className="title" level={2}>
          9. Updates
        </Title>
        <Typography>
          VandWater may make changes to the Terms of Use from time to time in VandWater’s sole discretion, by updating the Terms of Use on this Site, and specifying the effective date of the new
          version of the Terms of Use. The “Last Updated” date at the footer of these Terms of Use will indicate when the latest changes were made. Your continued use of the Site following the posting
          of a new version of the Terms of Use constitutes your acceptance of any such changes. Accordingly, whenever you visit this Site, you should check to see if a new version of the Terms of Use
          has been posted.
        </Typography>
        <Title id="termsConditionsSubTitle10" className="title" level={2}>
          10. Choice of Law
        </Title>
        <Typography>
          The information on the Site is intended only for use by residents of the United States. Other countries may have laws that differ from those in the United States. The Terms of Use and the
          resolution of any dispute related to the Terms of Use, the Site, and any non-contractual obligations arising out of or in connection with these Terms of Use, shall be governed by and
          construed in accordance with the laws of the State of Kansas giving effect to any principles of conflicts of law. Any legal action or proceeding between VandWater and you related to the
          Terms of Use will be brought exclusively in a court of competent jurisdiction sitting in the State of Kansas, and you agree to submit to the personal and exclusive jurisdiction of such
          courts.
        </Typography>
        <Title id="termsConditionsSubTitle11" className="title" level={2}>
          11. Entire Agreement
        </Title>
        <Typography>
          These Terms of Use constitute the entire agreement between you and VandWater related to your use of this Site and supersede any prior agreements or understandings. By continuing your use of
          this Site You represent that you are not under the age of 16. These Terms of Use may not be modified either expressly or implicitly except via written authorization by VandWater.
        </Typography>
        <br />
        Last Updated: <i>01.26.2023 – 10:00AM</i>
      </Col>
      {!isMobile && (
        <Col id="nav" span={6}>
          <Anchor
            offsetTop={50}
            getContainer={() => document.getElementById("contentContainer") || window}
            items={links}
            onClick={(e: any, link: any) => {
              e.preventDefault();
              navigate(link.href);
            }}
          />
        </Col>
      )}
    </Row>
  );
};

export default TermsConditions;
