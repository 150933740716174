import { addWaterRightAdjustmentTransfer, getWaterRightAdjustmentTransfer, updateWaterRightAdjustmentTransfer } from "@/apis/waterright.api";
import { addWellAdjustmentTransfer, getWellAdjustmentTransfer, getWells, updateWellAdjustmentTransfer } from "@/apis/well.api";
import LookupSelector from "@/components/LookupSelector/LookupSelector";
import StatusTag from "@/components/StatusTag/StatusTag";
import WaterRightSelector from "@/components/WaterRightSelector/WaterRightSelector";
import { constants } from "@/configs";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Alert, Checkbox, DatePicker, Form, Input, InputNumber, Modal, Radio, Select, Switch, message } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";

interface Props {
  id?: string;
  waterRightId?: string;
  wellId?: string;
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  companyId: string;
}

const AdjustmentAddModal: FC<Props> = (props) => {
  const { open, onCancel, onSuccess, waterRightId, wellId, id, companyId } = props;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingWells, setLoadingWells] = useState<boolean>(false);
  const [loadingWaterRights, setLoadingWaterRights] = useState<boolean>(false);
  const [adjustmentData, setAdjustmentData] = useState<any>(undefined);

  const [wells, setWells] = useState<any[]>([]);

  const type: string = useWatch("type", form);
  const adjustmentType: string = useWatch("adjustmentType", form);

  const addSecondaryIncompleteAdjustment: string = useWatch("addSecondaryIncompleteAdjustment", form);

  const [disabled, setDisabled] = useState(true);

  const { companyConfig } = useComapnyConfigLookup(companyId);

  useEffect(() => {
    if (companyConfig) form.setFieldValue("quantityUnits", companyConfig?.settings?.metric);
    // eslint-disable-next-line
  }, [companyConfig]);

  useEffect(() => {
    refreshWells();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      refreshAdjustmentData();
      setDisabled(false);
    }
    // eslint-disable-next-line
  }, [id]);

  const refreshAdjustmentData = async () => {
    var response = undefined;
    if (waterRightId) response = await getWaterRightAdjustmentTransfer(id);
    else if (wellId) response = await getWellAdjustmentTransfer(id);

    if (response !== undefined && response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        form.setFieldsValue({ ...data.value, date: dayjs(data.value.date) });
        setAdjustmentData(data.value);
      }
    }
  };

  const refreshWells = async () => {
    setLoadingWells(true);
    const request = { companyId: companyId };
    const response = await getWells(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWells(data.value.filter((well: any) => well.id !== wellId));
      }
    }
    setLoadingWells(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const submitData = {
          ...values,
          wellId: wellId ?? null,
          waterRightId: waterRightId ?? null,
          adjustmentType: values.adjustmentType ?? "subtraction",
        };
        handleSubmitAdjustmentData(submitData);
      })
      .catch((errors) => {
        // TODO:
      });
  };

  const handleSubmitAdjustmentData = async (data: any) => {
    setLoading(true);

    if (id) {
      const submitValues = {
        ...data,
        wellId: wellId ?? null,
        waterRightId: waterRightId ?? null,
      };
      const response = wellId ? await updateWellAdjustmentTransfer(submitValues, id) : await updateWaterRightAdjustmentTransfer(submitValues, id);
      if (response.ok) {
        onSuccess && onSuccess();
      } else message.error("Failed to add adjustment.");
    } else {
      const response = wellId ? await addWellAdjustmentTransfer(data, wellId) : await addWaterRightAdjustmentTransfer(data, waterRightId);
      if (response.ok) {
        onSuccess && onSuccess();
      } else message.error("Failed to add adjustment.");
    }

    setLoading(false);
  };

  return (
    <Modal
      width={900}
      open={open}
      title={id ? "Edit Adjustment" : "Add Adjustment"}
      okText="Save"
      cancelText="Cancel"
      onOk={handleSubmit}
      onCancel={onCancel}
      maskClosable={false}
      cancelButtonProps={{
        icon: <CloseOutlined />,
        disabled: loading,
        loading: loading,
      }}
      okButtonProps={{
        icon: <ThunderboltOutlined />,
        disabled: disabled || loading,
        loading: loading,
      }}
    >
      <Form form={form} labelCol={{ span: 8 }} labelWrap={true} layout="horizontal" name="form_in_modal" initialValues={{ modifier: "public" }}>
        <Form.Item
          label="Adjustment Date"
          name="date"
          rules={[
            {
              required: true,
              message: "Please select the date of the adjustment",
            },
          ]}
          style={{ marginBottom: "5px" }}
        >
          <DatePicker style={{ width: "100%" }} showTime placeholder="Select the date of the adjustment" format={constants.dateTimeFormat} allowClear />
        </Form.Item>

        <Form.Item label="Type" name="type" rules={[{ required: true, message: "Please select the type" }]} initialValue={wellId ? "adjustment" : undefined} style={{ marginBottom: "5px" }}>
          <Select placeholder="Select the type of adjustment" allowClear>
            <Select.Option value="adjustment" key="Manual">
              Adjustment
            </Select.Option>
            {waterRightId ? (
              <Select.Option value="transfer" key="Scanned">
                Transfer
              </Select.Option>
            ) : undefined}
            <Select.Option value="penalty" key="Scanned">
              Penalty
            </Select.Option>
          </Select>
        </Form.Item>

        {type === "adjustment" && (
          <>
            <Form.Item label="Adjustment Type" name="adjustmentType" style={{ marginBottom: "5px" }}>
              <Radio.Group defaultValue={"subtraction"} buttonStyle="solid">
                <Radio.Button value="subtraction">Increase Total Used</Radio.Button>
                <Radio.Button value="addition">Decrease Total Used</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8 }} style={{ marginBottom: "5px" }}>
              <Alert
                type="info"
                showIcon
                message="Important Information"
                description={
                  <>
                    <p>
                      The quantity entered below will {adjustmentType === "addition" ? "add to" : "subtract from"} the available quantity by{" "}
                      {adjustmentType === "addition" ? "decreasing" : "increasing"} the total amount used on this water right for the year.
                    </p>
                  </>
                }
              />
            </Form.Item>
          </>
        )}

        {type === "transfer" && wellId && (
          <>
            <Form.Item label="Transferred to Well" name="toWellId" style={{ marginBottom: "5px" }}>
              <Select placeholder="Select the well to transfer the quantity to" loading={loadingWells} disabled={loadingWells} showSearch optionFilterProp="label">
                {wells
                  .filter((item: any) => item !== undefined)
                  .map((well: any) => {
                    return (
                      <Select.Option value={well.id} key={well.id} label={well.name}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          {well.name} <StatusTag status={well.status} />
                        </div>
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </>
        )}

        {type === "transfer" && waterRightId && (
          <>
            <WaterRightSelector
              style={{ margin: 0, marginBottom: "5px" }}
              form={form}
              loading={loadingWaterRights}
              label={"Transferred to Water Right"}
              placeholder={"Select the water right to transfer the quantity to"}
              propertyToSet={"toWaterRightId"}
              required
              singleSelect
            />
            <Form.Item wrapperCol={{ offset: 8 }} style={{ marginBottom: "5px" }}>
              <Alert
                type="info"
                showIcon
                message="Important Information"
                description={
                  <>
                    <p>The quantity entered below will reduce the available quantity by subtracting from the authorized / usage quantity for the year.</p>
                    <p>If MDS is applicable for the year, the transfer quantity will be deducted AFTER the MDS calculations are applied.</p>
                  </>
                }
              />
            </Form.Item>
          </>
        )}

        {type === "penalty" && (
          <>
            <Form.Item wrapperCol={{ offset: 8 }} style={{ marginBottom: "5px" }}>
              <Alert
                type="info"
                showIcon
                message="Important Information"
                description={
                  <>
                    <p>The quantity entered below will reduce the available quantity by subtracting from the authorized / usage quantity for the year.</p>
                    <p>If MDS is applicable for the year, the penalty will be deducted before any MDS calculations are applied.</p>
                  </>
                }
              />
            </Form.Item>
          </>
        )}

        {type && (
          <Form.Item label={`${type === "transfer" ? "Transfer" : type === "adjustment" ? "Adjustment" : "Penalty"} Quantity`} required style={{ marginBottom: "5px" }}>
            <Input.Group compact>
              <Form.Item name="quantity" noStyle rules={[{ required: true, message: "Please enter the quantity" }]} style={{ marginBottom: "5px" }}>
                <InputNumber min={0} style={{ minWidth: 200 }} placeholder="Enter the quantity" />
              </Form.Item>
              <Form.Item name="quantityUnits" noStyle rules={[{ required: true, message: "Please select the units" }]} style={{ marginBottom: "5px" }}>
                <LookupSelector
                  form={form}
                  propertyToSet="quantityUnits"
                  placeholder="Select Units"
                  lookupType="meterUnits"
                  style={{ minWidth: 120 }}
                  // setDefault
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        )}

        <Form.Item
          name="notes"
          label="Notes"
          rules={[
            {
              required: true,
              message: "Please enter a description for the adjustment",
            },
          ]}
          style={{ marginBottom: "5px" }}
        >
          <Input.TextArea rows={4} placeholder="Enter notes" />
        </Form.Item>

        <Form.Item label="Completed" name="completed" valuePropName="checked" style={{ marginBottom: "5px" }}>
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </Form.Item>

        {!id && type === "transfer" && (
          <>
            <Form.Item label="Does the transferred quantity need to be pumped through this water right?" name="addSecondaryIncompleteAdjustment" valuePropName="checked" style={{ marginBottom: 15 }}>
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
            {addSecondaryIncompleteAdjustment && (
              <Alert
                style={{ marginBottom: "5px" }}
                type="info"
                showIcon
                message="Important Information"
                description={
                  <>
                    <p>
                      A secondary adjustment will be generated automatically in the incompleted state. This adjustment will need to be completed once the water has been pumped through this water
                      right.
                    </p>
                    <p>This will ensure your current water right is not over pumped.</p>
                    <p>Please remember to complete the adjustment to ensure your full water right allocation can be used.</p>
                  </>
                }
              />
            )}
          </>
        )}

        {type && (
          <Alert
            style={{ marginBottom: 10 }}
            type="info"
            showIcon
            message="Important Information"
            description={
              <>
                <p>The values entered on this adjustment will not be taken into account unless the adjustment is marked as completed. </p>
                {type === "transfer" && (
                  <p>
                    This transfer will not automatically adjust the usage quantity on the water right it was transferred to. Please ensure you manually adjust the usage values according to how you
                    need to manage that water right.
                  </p>
                )}
                {!id && (
                  <Form.Item
                    name="acknowledged"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        message: "Please confirm you have read this section by clicking on the check box",
                      },
                    ]}
                    getValueFromEvent={(e) => {
                      setDisabled(!e.target.checked);
                      return e.target.checked;
                    }}
                    style={{ marginBottom: "5px" }}
                  >
                    <Checkbox style={{ marginTop: 10 }}>By clicking on the checkbox you acknowledge that you have read this section.</Checkbox>
                  </Form.Item>
                )}
              </>
            }
          />
        )}
      </Form>
    </Modal>
  );
};

export default AdjustmentAddModal;
