import { Button } from "antd";
import { FC, useState } from "react";

type DownloadDocumentButtonProps = {
  record: any;
  buttonText: string;
  handleDownloadDocument: (record: any, onCompleted: () => void) => void;
};

const DownloadDocumentButton: FC<DownloadDocumentButtonProps> = (props) => {
  const { record, buttonText, handleDownloadDocument } = props;

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Button
      loading={loading}
      style={{ paddingLeft: 10 }}
      type="link"
      onClick={() => {
        setLoading(true);
        handleDownloadDocument(record, () => setLoading(false));
      }}
    >
      {buttonText}
    </Button>
  );
};

export default DownloadDocumentButton;
