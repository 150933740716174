import { Modal } from "antd";
import { useEffect, useState } from "react";
import * as serviceWorker from "../../serviceWorkerRegistration";

export default function VersionChecker() {
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
  const [activeWorker, setActiveWorker] = useState<ServiceWorker | null>(null);

  const onSuccess = (registration: ServiceWorkerRegistration) => {
    setActiveWorker(registration.active);
  };

  const onUpdate = (registration: ServiceWorkerRegistration) => {
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onSuccess, onUpdate });
  }, []);

  useEffect(() => {
    checkVersion();
  }, [activeWorker, waitingWorker]);

  const onOk = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    window.location.reload();
  };

  const onCancel = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
  };

  const checkVersion = () => {
    fetch("/version.txt")
      .then((response) => response.text())
      .then((version) => {
        const currentVersion = localStorage.getItem("version");

        if (!currentVersion || currentVersion !== version) {
          localStorage.setItem("version", version);

          if (currentVersion && currentVersion !== version) {
            Modal.confirm({
              icon: null,
              title: <>Version {version} Available Now</>,

              content: (
                <div>
                  <p>Please refresh your browser to update to the latest version.</p>
                  <p>Would you like to refresh your browser now?</p>
                </div>
              ),
              okText: "Refresh Now",
              onOk,
              onCancel,
            });
          }
        }
      });
  };

  return <></>;
}
