import { HtmlEditor } from "@/components/HtmlEditor";
import { CloseOutlined, FileImageOutlined, SendOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Input, Popconfirm, Select, Space, Upload, message } from "antd";
import { useWatch } from "antd/es/form/Form";
import { TooltipPlacement } from "antd/es/tooltip";
import { addBulkNotification, getBulkNotification, saveAndSendBulkNotification, updateBulkNotification } from "@/apis/communication.api";
import { listProfile } from "@/apis/identity.api";
import { constants, routes } from "@/configs";
import { UserRole } from "@/dtos/user.dto";
import { FC, useEffect, useRef, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import scrollToTop from "@/services/scrollToTop";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { removeBreadcrumb, removeViewEditBreadcrumbs } from "@/stores/breadcrumbs.store";
import { HtmlEditorRef } from "@/components/HtmlEditor/HtmlEditor";

const AdminCommunicationAddEdit: FC = () => {
  const dispatch = useAppDispatch();

  const { navigate } = useCustomNavigate();

  const { state } = useLocation();

  const user = useAuth();

  const { id } = useParams<{ id: string }>();

  const { selectedRole } = useSelector((state: any) => state.user);

  // selectedRole === IdentityRole.MasterAdministrator.role

  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [loadingNotification, setLoadingNotification] = useState<boolean>(false);

  const [role, setRole] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [notification, setNotification] = useState<any>(undefined);

  const type: string = useWatch("type", form);

  useEffect(() => {
    if (state.data) {
      form!.setFieldsValue(state.data);
      if (state.data?.userTypes !== null) form!.setFieldValue("userTypes", state.data?.userTypes);
      else form!.setFieldValue("userTypes", undefined);
      if (state.data?.userIds !== null) form!.setFieldValue("userIds", state.data?.userIds);
      else form!.setFieldValue("userIds", undefined);
    }
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (notification) {
      form!.setFieldsValue(notification);
      if (notification?.userTypes !== null) form!.setFieldValue("userTypes", notification?.userTypes);
      else form!.setFieldValue("userTypes", undefined);
      if (notification?.userIds !== null) form!.setFieldValue("userIds", notification?.userIds);
      else form!.setFieldValue("userIds", undefined);
    }
    // eslint-disable-next-line
  }, [notification]);

  useEffect(() => {
    if (notification?.attachments) {
      setFileList(
        notification.attachments.map((imageId: any, index: number) => {
          return {
            uid: imageId,
            name: "image.png",
            status: "done",
            url: `${constants.baseApiUrl}/communication/bulknotification/attachment/${imageId}/download`,
            existingFile: true,
          };
        })
      );
    }
  }, [notification]);

  useEffect(() => {
    if (id) getBulkNotifcation();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    refreshUsers();
    // eslint-disable-next-line
  }, []);

  const getBulkNotifcation = async () => {
    setLoadingNotification(true);

    const response = await getBulkNotification(id);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setNotification(data.value);
      }
    }

    setLoadingNotification(false);
  };

  const refreshUsers = async () => {
    setLoadingUsers(true);

    const response = await listProfile({ searchString: null });

    if (response.ok) {
      const data = await response.json();
      setUsers(data);
    }

    setLoadingUsers(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onFinish(values);
      })
      .catch((errors) => {
        scrollToTop();
        message.error(`Please fill in the required fields`, 5);
      });
  };

  const renderActionButtons = (placement: TooltipPlacement) => (
    <Space>
      {id ? (
        <Button disabled={loading || notification?.status === "Processed" ? true : false} loading={loading} type="primary" icon={<SendOutlined />} onClick={() => form.submit()}>
          Send
        </Button>
      ) : (
        <Button disabled={loading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
          Save
        </Button>
      )}
      {!id ? (
        <Popconfirm title="Save and Send Communcation" description="Are you sure you want send this bulk communication?" onConfirm={() => handleSaveAndSend()} okText="Yes" cancelText="No">
          <Button disabled={loading || notification?.status === "Processed" ? true : false} loading={loading} type="primary" icon={<SendOutlined />}>
            Save and Send
          </Button>
        </Popconfirm>
      ) : undefined}
      <Button
        disabled={loading}
        loading={loading}
        icon={<CloseOutlined />}
        onClick={() => {
          dispatch(removeBreadcrumb());
          navigate(-1);
        }}
      >
        Cancel
      </Button>
    </Space>
  );

  const handleSaveAndSend = async () => {
    setLoading(true);

    form.validateFields().then((values) => {
      const data = { ...values };

      let request = undefined;
      if (data.type === "email") {
        const attachments = fileList.map((file: any) => JSON.parse(file.xhr.response)?.value);
        request = { ...data, attachments: attachments };
      } else request = data;

      saveAndSendBulkNotification(request).then((response) => {
        if (response.ok) {
          message.success("Successfully saved and sent bulk notification");
          navigate(routes.adminCommunication);
          dispatch(removeBreadcrumb());
        } else message.error("Failed to save and send bulk notification");
      });
    });

    setLoading(false);
  };

  const onFinish = async (values: any) => {
    setLoading(true);

    const data = { ...values };

    if (id) {
      let request = undefined;
      if (data.type === "email") {
        const oldAttachmentIds = fileList.filter((item: any) => item.existingFile).map((file: any) => file.uid);
        const newAttachmentIds = fileList.filter((item: any) => !item.existingFile).map((file: any) => JSON.parse(file.xhr.response)?.value);
        request = {
          ...data,
          attachments: [...oldAttachmentIds, ...newAttachmentIds],
        };
      } else
        request = {
          ...data,
          userIds: data?.userIds?.length > 0 ? data.userIds : users?.map((user) => user?.id),
        };

      const response = await updateBulkNotification(id, request);

      if (response.ok) {
        message.success("Successfully sent bulk notification");
        navigate(routes.adminCommunication);
      } else message.error("Failed to send bulk notification");
    } else {
      let request = undefined;
      if (data.type === "email") {
        const attachments = fileList.map((file: any) => JSON.parse(file.xhr.response)?.value);
        request = { ...data, attachments: attachments };
      } else request = { ...data };

      const response = await addBulkNotification(request);

      if (response.ok) {
        message.success("Successfully added bulk notification");
        navigate(routes.adminCommunication);
      } else message.error("Failed to add bulk notification");
    }

    if (id) {
      dispatch(removeViewEditBreadcrumbs());
    } else dispatch(removeBreadcrumb());

    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const htmlEditorRef = useRef<HtmlEditorRef>(null);

  return (
    <Card
      className="communicationAddEdit"
      id="communicationAddEdit"
      title={id ? "Edit Communication" : "Add Communication"}
      extra={renderActionButtons("bottomRight")}
      actions={[renderActionButtons("top")]}
    >
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
        <Form.Item label="Type" name="type" rules={[{ required: true, message: "Please select the type" }]} initialValue={"email"}>
          <Select placeholder="Select the type of communication" allowClear>
            <Select.Option value="email" key="email">
              Email
            </Select.Option>
            <Select.Option value="push" key="push">
              Push
            </Select.Option>
          </Select>
        </Form.Item>

        {type && (
          <>
            <Form.Item
              label="User Roles"
              name="userTypes"
              getValueFromEvent={(val: any) => {
                setRole(val);
                return val;
              }}
            >
              <Select mode="multiple" placeholder="Select roles (Optional)">
                {Object.keys(UserRole)
                  .filter((x) => x !== null)
                  .map((key: any) => (
                    <Select.Option value={key}>{UserRole[key].label}</Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item label="Users" name="userIds">
              <Select
                mode="multiple"
                maxTagCount={3}
                placeholder="Select specific users or leave blank to send to EVERYONE"
                optionFilterProp="value"
                filterOption={(input, option) => {
                  const searchVal = input?.toLowerCase();

                  let list: any[] = [];

                  users?.forEach((user) => {
                    let match: boolean = false;

                    if (user?.email?.toLowerCase()?.includes(searchVal)) match = true;

                    if (match) list.push(user.id);
                  });

                  return list.includes(option?.value) ?? [];
                }}
                options={users
                  .filter((x) => x !== null)
                  ?.sort((a, b) =>
                    a?.email?.localeCompare(b?.email, "en", {
                      numeric: true,
                      sensitivity: "base",
                    })
                  )
                  ?.map((user) => {
                    return {
                      value: user.id,
                      label: user?.email,
                    };
                  })}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <Space style={{ padding: "0 8px 4px" }}>
                      <Button
                        onClick={() =>
                          form!.setFieldValue(
                            "userIds",
                            users?.filter((item: any) => item !== null).map((user) => user.id)
                          )
                        }
                      >
                        Select All
                      </Button>
                      <Button danger onClick={() => form!.setFieldValue("userIds", [])}>
                        Clear List
                      </Button>
                    </Space>
                  </>
                )}
              />
            </Form.Item>
          </>
        )}

        {type === "push" && (
          <>
            <Form.Item label="Title" name="title" rules={[{ required: true, message: "Please enter a title" }]}>
              <Input placeholder="Enter a title" />
            </Form.Item>
            <Form.Item label="Body" name="body" rules={[{ required: true, message: "Please enter a body" }]}>
              <Input.TextArea rows={6} placeholder="Enter a body" />
            </Form.Item>
          </>
        )}

        {type === "email" && (
          <>
            <Form.Item label="Subject" name="subject" rules={[{ required: true, message: "Please enter a subject" }]}>
              <Input placeholder="Enter a subject" />
            </Form.Item>
            <Form.Item label="Body" name="body" rules={[{ required: true, message: "Please enter a body" }]}>
              <Input.TextArea rows={6} placeholder="Enter a body" />
              {/* <div>
                <HtmlEditor ref={htmlEditorRef} />
              </div> */}
            </Form.Item>
            <Form.Item label="Attachments">
              <Upload.Dragger
                multiple={true}
                action={`${constants.baseApiUrl}/communication/bulknotification/attachment/upload`}
                headers={{
                  Authorization: `Bearer ${user.user?.access_token}`,
                }}
                listType="picture-card"
                fileList={fileList}
                onChange={(info: any) => {
                  if (info.file.status === "uploading") {
                    setUploading(true);
                  }
                  if (info.file.status === "done") {
                    setUploading(false);
                  }
                  if (info.file.status === "error") {
                    setUploading(false);
                  }
                  setFileList(info.fileList);
                }}
              >
                <p className="ant-upload-drag-icon">
                  <FileImageOutlined />
                </p>
                <p className="ant-upload-text">Click or drag a file to this area to upload</p>
              </Upload.Dragger>
            </Form.Item>
          </>
        )}
      </Form>
    </Card>
  );
};

export default AdminCommunicationAddEdit;
