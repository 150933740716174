import LookupLabel from "@/components/LookupLabel/LookupLabel";
import useWaterRightLookups from "@/queries/useWaterRightLookups";

type WaterRightPermitTypeLabelProps = {
  waterRightId: string;
};

export default function WaterRightPermitTypeLabel(props: WaterRightPermitTypeLabelProps) {
  const { waterRightId } = props;
  const { waterRight } = useWaterRightLookups(waterRightId);

  if (waterRight) {
    return <LookupLabel dontShowCode lookupType={"permittype"} value={waterRight.permitType} />;
  } else return <>Loading...</>;
}
