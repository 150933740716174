import { get, post, put } from "@/services/request";

export const getPushNotifications = (searchString: string | undefined, pageParam: number = 1) => {
  return get(`communication/push/paged/list?searchString=${searchString ?? ""}&page=${pageParam}`);
};

export const getUnreadPushNotificationsCount = () => {
  return get(`communication/push/unread/count`);
};

export const putMarkPushAsRead = (request: any) => {
  return put(`communication/push/read`, request);
};

export const putMarkPushAsUnRead = (request: any) => {
  return put(`communication/push/unread`, request);
};

export const addFaq = (request: any) => {
  return post("communication/faq/add", request);
};

export const deleteFaq = (request: any) => {
  return post("communication/faq/delete", request);
};

export const updateFaq = (id: any, request: any) => {
  return put(`communication/faq/${id}/edit`, request);
};

export const getFaq = (id: any) => {
  return get(`communication/faq/${id}/retrieve`);
};

export const getFaqs = () => {
  return get(`communication/faq/list`);
};

export const addContactUs = (request: any) => {
  return post(`communication/contact/message`, request);
};

export const getContactUs = (id: any) => {
  return get(`communication/contact/${id}/retrieve`);
};

export const listContactUs = () => {
  return get(`communication/contact/list`);
};

export const addBulkNotification = (request: any) => {
  return post(`communication/bulknotification/add`, request);
};

export const cloneBulkNotification = (request: any) => {
  return post(`communication/bulknotification/clone`, request);
};

export const saveAndSendBulkNotification = (request: any) => {
  return post(`communication/bulknotification/send`, request);
};

export const sendBulkNotification = (id: any, request: any) => {
  return put(`communication/bulknotification/${id}/send`, request);
};

export const updateBulkNotification = (id: any, request: any) => {
  return put(`communication/bulknotification/${id}/update`, request);
};

export const archiveBulkNotification = (id: any, request: any) => {
  return put(`communication/bulknotification/${id}/archive`, request);
};

export const getBulkNotification = (id: any) => {
  return get(`communication/bulknotification/${id}/retrieve`);
};

export const listBulkNotification = (request: any) => {
  return post(`communication/bulknotification/list`, request);
};

export const listBulkNotificationAttachment = (request: any) => {
  return post(`communication/bulknotification/attachments/list`, request);
};

export const downloadBulkNotificationAttachment = (id: any) => {
  return get(`communication/bulknotification/attachment/${id}/download`);
};

export const getSupportQuery = (id: any) => {
  return get(`communication/support/${id}/retrieve`);
};

export const listSupportQuery = () => {
  return get(`communication/support/list`);
};

export const sendSupportEmail = (request: any) => {
  return post(`communication/email/support`, request);
};

export const listUserDevice = (request: any) => {
  return post(`communication/device/list`, request);
};

export const removeDevice = (request: any) => {
  return post(`communication/device/remove`, request);
};

export const clearNotifications = (request: any) => {
  return post(`communication/push/clear`, request);
};
