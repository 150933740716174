const constants = {
  oidcConfig: {
    authority: "https://login.vandwater.com",
    client_id: "adminwebportal",
    redirect_uri: "https://vandwater.com",
    response_type: "code",
    scope: "openid profile verification roles IdentityServerApi api_company api_well api_waterright api_restriction api_lookup api_field api_communication api_aggregator api_farm offline_access",
    post_logout_redirect_uri: "https://vandwater.com",
  },
  baseApiUrl: "https://vandwater-us-cen-prod.azure-api.net",
  dateTimeFormat: "MM/DD/YYYY h:mm A",
  dateFormat: "MM/DD/YYYY",
  dateTimeFormatWithSeconds: "MM/DD/YYYY h:mm:ss A",
  timeFormat: "hh:mm A",
  timeFormatWithSeconds: "h:mm:ss A",
  googleApiKey: "AIzaSyBk6jVAEPMUscn2c3MQMFtj84lIL7Q3CW4",
  primaryColor: "rgba(2,3,129)",
  secondaryColor: "#00ff00",
};

export default constants;
