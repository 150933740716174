import { Button, Divider, Select, Space, Spin, Switch } from "antd";
import { getLookups } from "@/apis/lookups.api";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@/stores";
import { saveLookups } from "@/stores/lookups.store";

interface Props {
  onSelect: (val: any[]) => void;
  onSelectAll?: () => void;
  onClear?: () => void;
  value?: any[];
  lookupType: string;
  disabled: boolean;
}

const LookupFilter: FC<Props> = (props) => {
  const { onSelect, onSelectAll, onClear, lookupType, disabled, value } = props;
  const { lookups } = useSelector((state: any) => state.lookups);

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (lookups?.length === 0) refreshLookupsList();
    else setLoading(false);
  }, []);

  const refreshLookupsList = async () => {
    setLoading(true);
    const response = await getLookups();
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        dispatch(saveLookups(data.value));
      }
    }
    setLoading(false);
  };

  const renderOptions = (tempLookups: any[]) => {
    let lookup = tempLookups.find((lookup: any) => lookup.map === lookupType.toLowerCase());
    return lookup ? (
      lookup.options?.map((option: any) => (
        <Select.Option style={{ display: option.hidden ? "none" : undefined }} value={option.value} key={option.value} label={option.name}>
          {option.name}
        </Select.Option>
      ))
    ) : (
      <></>
    );
  };

  const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().startsWith(input.toLowerCase());

  return loading ? (
    <Spin />
  ) : (
    <Select
      disabled={disabled}
      onChange={onSelect}
      loading={loading}
      placeholder={"Filter on Permit Types"}
      showSearch
      filterOption={filterOption}
      optionFilterProp="label"
      mode={"multiple"}
      style={{ width: 275 }}
      defaultValue={[]}
      maxTagCount={1}
      allowClear
      showArrow
      value={value}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: "8px 0" }} />
          <Space style={{ padding: "0 8px 4px" }}>
            <Button onClick={() => onSelectAll && onSelectAll()}>Select All</Button>
            <Button danger onClick={() => onClear && onClear()}>
              Clear List
            </Button>
          </Space>
        </>
      )}
    >
      {renderOptions(lookups)}
    </Select>
  );
};

export default LookupFilter;
