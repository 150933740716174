import { getCompanyMonthlyUsage } from "@/apis/aggregator.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSelector } from "react-redux";

export default function useCompanyMonthlyUsageQuery(companyId?: string, permitType?: string) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();

  const { isFetched: companyMonthlyUsagesFetched, data: companyMonthlyUsages } = useQuery({
    queryKey: [
      CacheKeys.waterRights,
      CacheKeys.calculation,
      CacheKeys.analytics,
      CacheKeys.companyMonthlyUsage,
      {
        profileId: profile?.id,
        companyId,
        permitType,
      },
    ],
    queryFn: async () => {
      const response = await getCompanyMonthlyUsage({
        companyId,
        permitType: permitType === "overview" ? undefined : permitType,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;

        throw new Error("Failed to fetch company monthly usages");
      }
    },
    placeholderData: keepPreviousData,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
  });

  const invalidateCompanyMonthlyUsages = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.waterRights, CacheKeys.calculation, CacheKeys.analytics],
    });
  }, [queryClient]);

  return {
    companyMonthlyUsagesFetched,
    companyMonthlyUsages,
    invalidateCompanyMonthlyUsages,
  };
}
