import { Button, Card, Table, Tag } from "antd";
import { listContactUs } from "@/apis/communication.api";
import { constants, routes } from "@/configs";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import useCustomNavigate from "@/services/useCustomNavigate";

const AdminContactUsList: FC = () => {
  const { navigate } = useCustomNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [contactUsMessages, setContactUsMessages] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    refreshContactUsMessages();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [contactUsMessages]);

  const refreshContactUsMessages = async () => {
    setLoading(true);

    const response = await listContactUs();

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setContactUsMessages(data.value);
      }
    }

    setLoading(false);
  };

  const handleViewContactUsMessage = (record: any) => {
    navigate(routes.adminContactUsView, { id: record.id });
  };

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Date",
        key: "createdDate",
        dataIndex: "createdDate",
        width: 150,
        render: (val: any, record: any) => dayjs(val).format(constants.dateTimeFormat),
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        width: 75,
        render: (val: any, record: any) => <>{val === "Open" ? <Tag color="blue">Open</Tag> : <Tag color="green">Closed</Tag>}</>,
      },
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
        render: (val: any) => val,
      },
      {
        title: "Message",
        key: "message",
        dataIndex: "message",
        render: (val: any, record: any) => (val?.length > 50 ? `${val?.substring(0, 50)} ...` : val),
      },
      {
        title: "Actions",
        width: 75,
        render: (val: any, record: any) => (
          <>
            <Button style={{ paddingLeft: 0 }} onClick={() => handleViewContactUsMessage(record)} type="link">
              View
            </Button>
          </>
        ),
      },
      // {
      //     title: 'Contact Details',
      //     children: [
      //         {
      //             title: 'Email',
      //             dataIndex: 'userEmail',
      //             width: 100,
      //             render: (val: any, record: any) => val ?? '-'
      //         },
      //         {
      //             title: 'Phone Number',
      //             dataIndex: 'phoneNumber',
      //             width: 100,
      //             render: (val: any, record: any) => val ?? '-'
      //         }
      //     ],
      // },
      // {
      //     title: 'Name',
      //     key: 'name',
      //     width: 100,
      //     render: (val: any, record: any) => <>{record?.firstName && record?.lastName ? `${record?.firstName} ${record?.lastName}` : 'N/A'}</>
      // },
      // {
      //     title: 'Body',
      //     key: 'body',
      //     dataIndex: 'body',
      //     width: 200,
      //     render: (val: any, record: any) => val?.length > 50 ? `${val?.substring(0, 50)} ...` : val
      // },
      // // {
      // //     title: 'Status',
      // //     key: 'status',
      // //     dataIndex: 'status',
      // //     width: 200,
      // //     render: (val: any, record: any) => val?.length > 50 ? `${val?.substring(0, 50)} ...` : val
      // // },
      // {
      //     title: 'Actions',
      //     width: 75,
      //     render: (val: any, record: any) => <><Button style={{ paddingLeft: 0 }} onClick={() => handleViewSupportQuery(record)} type="link">View</Button></>
      // }
    ];

    setColumns(tempColumns);
  };

  return (
    <Card className="adminContactUsList" id="adminContactUsList" title="Conact Us Messages">
      <Table rowKey={(row: any) => row.id} loading={loading} columns={columns} dataSource={contactUsMessages ?? []} size="small" />
    </Card>
  );
};

export default AdminContactUsList;
