import { CustomerServiceOutlined } from "@ant-design/icons";
import { Button, FloatButton, Space, notification } from "antd";
import { SupportQuery } from "@/components";
import { FC, useState } from "react";
import { useAppDispatch } from "@/stores";
import { clearSupportQueryData } from "@/stores/communication.store";

import "./SupportQueryFloatingButton.scss";
import { useSelector } from "react-redux";

const SupportQueryFloatingButton: FC = () => {
  const dispatch = useAppDispatch();

  const { profile } = useSelector((state: any) => state.user);

  const [supportEmailState, setSupportEmailState] = useState<any>({
    open: false,
  });
  const [api, contextHolder] = notification.useNotification();

  const handleOnClick = () => {
    setSupportEmailState({ open: true });
  };

  const handleSupportQueryCancel = () => {
    setSupportEmailState({ open: false });
  };

  const openSupportQueryCompleteNotification = (ref?: any) => {
    handleSupportQueryCancel();
    dispatch(clearSupportQueryData());

    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button type="default" onClick={() => api.destroy(key)}>
          Close
        </Button>
      </Space>
    );
    api.success({
      message: "Support query successfully submitted",
      description: `Please use the reference ${ref} if you have any queries.`,
      btn,
      key,
      duration: 0,
      placement: "top",
    });
  };

  return profile?.roles?.length > 0 ? (
    <></>
  ) : (
    <>
      {contextHolder}
      <FloatButton className="floating-support-button" style={{ right: 50, marginBottom: 10 }} type="primary" onClick={handleOnClick} icon={<CustomerServiceOutlined />} />
      {supportEmailState.open && <SupportQuery onSuccess={openSupportQueryCompleteNotification} open={supportEmailState.open} onCancel={handleSupportQueryCancel} />}
    </>
  );
};

export default SupportQueryFloatingButton;
