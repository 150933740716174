import { ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Input, Space, Table } from "antd";
import { listProfile } from "@/apis/identity.api";
import { DeviceTable } from "@/components";
import { FC, useEffect, useState } from "react";
import { formatPhoneNumber } from "@/services/utils";

const AdminUsersTable: FC = () => {
  const [users, setUsers] = useState<any[]>([]);

  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string | undefined>(undefined);

  const [userColumns, setUserColumns] = useState<any[]>([]);

  useEffect(() => {
    refreshUsersList();
  }, []);

  useEffect(() => {
    calculateUsersColumns();
  }, [users]);

  const refreshUsersList = async (searchString: any = null) => {
    setLoadingUsers(true);

    const request = { searchString };
    const response = await listProfile(request);

    if (response.ok) {
      const data = await response.json();
      setUsers(data);
    }
    setLoadingUsers(false);
  };

  const calculateUsersColumns = () => {
    let tempColumns: any[] = [
      {
        title: "First Name",
        key: "firstName",
        dataIndex: "firstName",
        render: (val: any, record: any) => val,
      },
      {
        title: "Last Name",
        key: "lastName",
        dataIndex: "lastName",
        render: (val: any, record: any) => val,
      },
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
        render: (val: any, record: any) => val,
      },
      {
        title: "Phone Number",
        key: "phoneNumber",
        dataIndex: "phoneNumber",
        render: (val: any, record: any) => (val ? formatPhoneNumber(val) : "-"),
      },
    ];

    setUserColumns(tempColumns);
  };

  const onSearchChange = (searchString: any) => {
    setSearchString(searchString);
  };

  const onSearch = (searchString: any) => {
    setSearchString(searchString);
    refreshUsersList(searchString);
  };

  const handleRefresh = () => {
    setSearchString(undefined);
    refreshUsersList();
  };

  const renderExpandData = (record: any) => {
    return (
      <div style={{ padding: 10, paddingTop: 0, paddingRight: 50 }}>
        <div style={{ paddingLeft: 10 }}>
          <Divider orientation="left">Devices</Divider>
        </div>
        <DeviceTable userId={record.id} />
      </div>
    );
  };

  return (
    <Card className="adminUsersList" id="adminUsersList" title="Users">
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space style={{ paddingBottom: 10 }}>
          <Input.Search
            disabled={loadingUsers}
            placeholder="Search users"
            onSearch={(searchString) => {
              onSearch(searchString);
            }}
            onChange={(val) => onSearchChange(val.currentTarget.value)}
            value={searchString}
            style={{ width: 400 }}
            allowClear
          />
          <Button disabled={loadingUsers} icon={<ReloadOutlined />} onClick={handleRefresh}>
            Refresh
          </Button>
        </Space>
      </Space>
      <Table
        rowKey={(row: any) => row.id}
        loading={loadingUsers}
        columns={userColumns}
        dataSource={users || []}
        size="small"
        expandable={{
          expandedRowRender: (record: any) => renderExpandData(record),
        }}
      />
    </Card>
  );
};

export default AdminUsersTable;
