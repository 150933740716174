import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space, message, notification } from "antd";
import { enable2FaPhoneNumber, getProfile, resend2FaPhoneNumber, verify2FaPhoneNumber } from "@/apis/identity.api";
import { FC, useEffect, useState } from "react";
import { useAppDispatch } from "@/stores";
import { saveProfile } from "@/stores/user.store";
import useProfile from "@/queries/useProfile";

interface Props {
  expirationDate: any;
  validationCode: string;
  open: boolean;
  onSuccess: () => void;
  onCancel: () => void;
  phoneNumber: string;
}

const TwoFactorModal: FC<Props> = (props: Props) => {
  let { expirationDate, validationCode, open, onSuccess, onCancel, phoneNumber } = props;

  const dispatch = useAppDispatch();

  const { invalidateProfile } = useProfile();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [diffInTime, setDiffInTime] = useState<number | null>(500);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [timeLeft, setTimeLeft] = useState<number | null>(30);
  const [newExpirationDate, setNewExpirationDate] = useState<any>(undefined);
  const [newValidationCode, setNewValidationCode] = useState<string | undefined>(undefined);

  const [api, contextHolder] = notification.useNotification();

  const now = new Date();

  useEffect(() => {
    const expDate = new Date(expirationDate);
    setDiffInTime(diff_minutes(expDate, now));
    // eslint-disable-next-line
  }, [expirationDate]);

  useEffect(() => {
    if (newExpirationDate) {
      const expDate = new Date(newExpirationDate);
      setTimeLeft(30);
      setDisabled(true);
      setDiffInTime(diff_minutes(expDate, now));
    }
    // eslint-disable-next-line
  }, [newExpirationDate]);

  useEffect(() => {
    if (diffInTime === 0) {
      setDiffInTime(null);
    }

    if (!diffInTime) return;

    const intervalId = setInterval(() => {
      setDiffInTime(diffInTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [diffInTime]);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
      setDisabled(false);
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [timeLeft]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      handleTwoFactorSubmitData(values);
    });
  };

  const renderSendText = () => {
    if (timeLeft !== null && timeLeft >= 0) return `Resend code (${timeLeft} sec...)`;
    else if (timeLeft == null && diffInTime !== null && diffInTime >= 0) return `Resend code (${convertSecondsToMinutesText(diffInTime)}...)`;
    else return "Request new verification code";
  };

  const convertSecondsToMinutesText = (seconds: number) => {
    const minutes = ~~(seconds / 60);
    const secs = seconds % 60;

    if (minutes > 0) return minutes.toString() + " min " + secs.toString() + " sec";
    else return secs.toString() + " sec";
  };

  const diff_minutes = (dt2: Date, dt1: Date) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    return Math.round(diff);
  };

  const handleTwoFactorSubmitData = async (data: any) => {
    setLoading(true);

    const response = await verify2FaPhoneNumber({
      validationCode: newValidationCode ?? validationCode,
      verificationCode: data.verificationCode,
    });
    if (response.ok) {
      invalidateProfile();
      onSuccess && onSuccess();
    } else {
      const key = `open${Date.now()}`;
      const btn = (
        <Space>
          <Button type="default" onClick={() => api.destroy(key)}>
            Close
          </Button>
        </Space>
      );

      api.error({
        message: "Failed to verify code",
        description: "Incorrect verification code entered or verification code has expired.",
        btn,
        key,
        duration: 0,
        placement: "top",
      });
    }

    setLoading(false);
  };

  const resendTwoFactorCode = async () => {
    setLoading(true);

    const response = await resend2FaPhoneNumber({
      validationCode: newValidationCode ?? validationCode,
    });
    if (response.ok) {
      const key = `open${Date.now()}`;
      const btn = (
        <Space>
          <Button type="default" onClick={() => api.destroy(key)}>
            Close
          </Button>
        </Space>
      );

      api.success({
        message: "Resent verification code",
        description: "Successfully resent verification code.",
        btn,
        key,
        duration: 0,
        placement: "top",
      });
    }

    setLoading(false);
  };

  const enable2FaForPhoneNumber = async () => {
    setLoading(true);

    const response = await enable2FaPhoneNumber();

    if (response.ok) {
      const data = await response.json();

      if (data) {
        setNewValidationCode(data.validationCode);
        setNewExpirationDate(data.expirationDate);
      }
    }

    setLoading(false);
  };

  return (
    <>
      {contextHolder}
      <Modal
        className="twoFactorModal"
        open={open}
        footer={
          <Space>
            <Button icon={<CloseOutlined />} onClick={onCancel}>
              Close
            </Button>
            <Button loading={loading} disabled={loading} icon={<ThunderboltOutlined />} type="primary" onClick={handleSubmit}>
              Verify Phone Number
            </Button>
          </Space>
        }
        title="Verify your phone number to enable login with phone number"
        closable={false}
        onCancel={onCancel}
        width={800}
        maskClosable={false}
        onOk={handleSubmit}
        centered
      >
        <Form id="twoFactorModalForm" form={form} layout="vertical" name="form_in_modal_twofactor" labelAlign="left" labelCol={{ span: 8, offset: 3 }}>
          {`A verification code has been sent to the phone number ending in ${phoneNumber.slice(-4)}`}
          <br />
          <br />
          <Form.Item
            name="verificationCode"
            label="Verification Code"
            rules={[
              { required: true, message: "Code must be 5 digits long" },
              { len: 5, message: "Code must be 5 digits long" },
            ]}
            style={{ paddingBottom: 0, width: 500 }}
          >
            <Input placeholder="Enter verification code" />
          </Form.Item>
          <Button
            loading={loading}
            onClick={() => {
              if (timeLeft !== null && timeLeft >= 0) resendTwoFactorCode();
              else if (timeLeft == null && diffInTime !== null && diffInTime >= 0) resendTwoFactorCode();
              else enable2FaForPhoneNumber();
            }}
            disabled={disabled}
            style={{ paddingLeft: 0, paddingTop: 0, marginTop: 0 }}
            type="link"
          >
            {renderSendText()}
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default TwoFactorModal;
