import { downloadFinanceDocument } from "@/apis/aggregator.api";
import { openReportFromGeneratedFile } from "@/services/utils";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, message, Tooltip } from "antd";
import { FC, useState } from "react";

type DownloadInvoiceButtonProps = {
  id: string;
};

const DownloadInvoiceButton: FC<DownloadInvoiceButtonProps> = ({ id }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleDownloadInvoice = async () => {
    setLoading(true);

    const response = await downloadFinanceDocument(id);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        openReportFromGeneratedFile(data.value);
      }
    } else message.error("Failed to download invoice");

    setLoading(false);
  };

  return (
    <Tooltip title={"Download invoice"} placement="left">
      <Button loading={loading} style={{ paddingLeft: 0, paddingRight: 0 }} type="link" icon={<DownloadOutlined />} onClick={() => handleDownloadInvoice()} />
    </Tooltip>
  );
};

export default DownloadInvoiceButton;
