import { useQuery, useQueryClient } from "@tanstack/react-query";
import CacheKeys from "@/types/CacheKeys";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import { useCallback } from "react";
import { getProfile } from "@/apis/identity.api";
import { useAuth } from "react-oidc-context";
import { useAppDispatch } from "@/stores";
import { saveProfile } from "@/stores/user.store";

const useProfile = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const auth = useAuth();

  const { data, isFetching, isFetched, dataUpdatedAt } = useQuery({
    queryKey: [CacheKeys.profile],
    queryFn: async () => {
      const response = await getProfile();

      if (response.ok) {
        const data = await response.json();
        dispatch(saveProfile(data));
        return data;
      }
      throw new Error("Failed to fetch profile");
    },
    ...defaultQueryConfig,
    enabled: !!auth.isAuthenticated && !!auth?.user?.access_token,
    staleTime: 1000 * 60 * 5, //5 mins
  });

  const invalidateProfile = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.profile],
    });
  }, [queryClient]);

  return {
    profile: data,
    profileUpdatedAt: dataUpdatedAt,
    isFetched,
    isFetching,
    invalidateProfile,
  };
};

export default useProfile;
