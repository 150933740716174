import { Card, Tabs } from "antd";
import { FC } from "react";
import BillingReport from "./BillingReport/BillingReport";

const AdminReports: FC = () => {
  return (
    <Card className="adminReports" id="adminReports" title="Reports">
      <Tabs
        type="card"
        size="small"
        items={[
          {
            label: "Billing Report",
            key: "billingReport",
            children: <BillingReport />,
          },
        ]}
      />
    </Card>
  );
};

export default AdminReports;
