import { FC, useState } from "react";
import { Alert, Badge, Button, Card, Checkbox, Form, Input, message, Modal, Popconfirm, Typography } from "antd";
import { routes } from "@/configs";
import { MailOutlined, LockOutlined, UserOutlined, CheckCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";

import "./RegistrationModal.scss";
import { generatePath, useNavigate } from "react-router-dom";
import { registerUser } from "@/apis/identity.api";
interface Props {
  mobile?: boolean;
  open?: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
}

const RegistrationModal: FC<Props> = (props) => {
  const { open = true, mobile = false, onCancel, onSuccess } = props;

  const [form] = Form.useForm();

  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        const { termsAndConditions, confirmPassword, ...rest } = values;
        handleRegistrationSubmitData({ ...rest });
      })
      .catch((errors) => {
        //TODO
        setLoading(false);
      });
  };

  const handleRegistrationSubmitData = async (data: any) => {
    const response = await registerUser(data);

    setLoading(false);
    if (response.ok) {
      if (mobile) {
        //TODO
      } else {
        onSuccess && onSuccess();
      }
    } else {
      setError("A user with the same email already exists");
    }
  };

  const confirm = () => {
    message.success("Click on Yes");
  };

  const renderTick = (passed: boolean) => (
    <Badge count={passed ? <CheckCircleOutlined style={{ color: "#52c41a" }} /> : <MinusCircleOutlined style={{ color: "#f5222d" }} />} style={{ marginLeft: 2, marginRight: 5, marginBottom: 3 }} />
  );

  const [passwordChecks, setPasswordChecks] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
  });

  const renderContent = () => (
    <>
      <Card
        bordered={false}
        style={{
          boxShadow: "none",
          textAlign: "center",
          padding: 10,
          margin: 0,
        }}
        bodyStyle={{ padding: 0, paddingTop: 20 }}
        className="removeBoxShadow removeMargin"
      >
        <img src="/logo.png" style={{ marginTop: 15 }} width={240} height={70} />
        <Typography.Title level={3} style={{ textAlign: "center" }}>
          Register
        </Typography.Title>
        <Typography.Text style={{ textAlign: "center" }}>Create a new account</Typography.Text>
      </Card>
      <Card bordered={false} style={{ boxShadow: "none" }} styles={{ body: { padding: 0, paddingLeft: 25, paddingRight: 25 } }} className="removeBoxShadow removeMargin">
        <Form id="registrationModal" form={form} layout="horizontal">
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please enter your first name",
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="First Name" />
          </Form.Item>

          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please enter your last name",
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Last Name" />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not a valid e-mail",
              },
              {
                required: true,
                message: "Please input your e-mail",
              },
            ]}
          >
            <Input prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>

          <Form.Item
            style={{ paddingBottom: 0 }}
            name="password"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
              onChange={(e: any) => {
                let checks = {
                  length: e.target.value?.length >= 6,
                  lowercase: /[a-z]/.test(e.target.value),
                  uppercase: /[A-Z]/.test(e.target.value),
                  number: /[\d]/.test(e.target.value),
                  special: /[!@#$%^&*\\.]/.test(e.target.value),
                };
                setPasswordChecks(checks);
                form.setFieldsValue({
                  password: e.target.value,
                  confirmPassword: undefined,
                });
              }}
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords do not match!"));
                },
              }),
            ]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Confirm Password" />
          </Form.Item>

          <Alert
            type="info"
            style={{ marginBottom: 10, fontSize: "0.85em" }}
            message={
              <>
                Passwords must:
                <br />
                {renderTick(passwordChecks.length)}Be a minimum of 6 characters
                <br />
                {renderTick(passwordChecks.lowercase)}Include at least one lowercase letter (a-z)
                <br />
                {renderTick(passwordChecks.uppercase)}Include at least one uppercase letter (A-Z)
                <br />
                {renderTick(passwordChecks.number)}Include at least one number (0-9)
                <br />
                {renderTick(passwordChecks.special)}Include at least one special character (!@#$%^&*.)
              </>
            }
          />

          <Form.Item
            name="termsAndConditions"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: "Please accept terms and conditions",
              },
            ]}
            getValueFromEvent={(e) => {
              setDisabled(!e.target.checked);
              return e.target.checked;
            }}
          >
            <Checkbox style={{ marginTop: 10 }}>
              I have read and agree to the
              <br />
              <Button style={{ padding: 0, margin: 0, height: "1em" }} type="link" onClick={() => window.open(generatePath(routes.privacy.path), "_blank")}>
                Privacy Policy
              </Button>{" "}
              and{" "}
              <Button style={{ padding: 0, margin: 0, height: "1em" }} type="link" onClick={() => window.open(generatePath(routes.termsOfUse.path), "_blank")}>
                VandWater, Inc. Terms
              </Button>
            </Checkbox>
          </Form.Item>

          <Form.Item name="optInForMarketing" valuePropName="checked">
            <Checkbox>Send me news, events and exclusive offers (Optional)</Checkbox>
          </Form.Item>

          {error && (
            <Form.Item>
              <Alert type="error" showIcon description={error} />
            </Form.Item>
          )}
          <Form.Item>
            <Button type="primary" style={{ width: "100%" }} onClick={handleSubmit} disabled={disabled || loading} loading={loading}>
              Register Account
            </Button>
          </Form.Item>
          <Form.Item>
            <Button style={{ width: "100%" }} onClick={onCancel} disabled={loading}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );

  return mobile ? (
    renderContent()
  ) : (
    <Modal open={open} closable={false} cancelText="Cancel" maskClosable={false} footer={<></>} className="registrationModal" style={{ width: "95%", maxWidth: 500 }} bodyStyle={{ paddingBottom: 20 }}>
      {renderContent()}
    </Modal>
  );
};

export default RegistrationModal;
