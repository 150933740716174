import { getCompanyTotalUsage } from "@/apis/aggregator.api";
import PrintPage from "@/components/PrintPage/PrintPage";
import { Card, Descriptions, Image, Space, Typography } from "antd";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title as TitleChart, Tooltip } from "chart.js";
import { FC, useEffect, useState } from "react";
import CompanyUsageRender from "./CompanyUsageRender";
import useCompanyLookups from "@/queries/useCompanyLookups";

const { Title } = Typography;

interface Props {
  reportConfig: config;
}

type config = {
  companyIds: string[];
  companies: string;
  years: number[];
  generatedDate: any;
};

ChartJS.register(CategoryScale, LinearScale, BarElement, TitleChart, Tooltip, Legend);

const GeneratedCompanyTotalUsageReport: FC<Props> = (props) => {
  const { reportConfig } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [companyTotalUsage, setCompanyTotalUsage] = useState<any[]>([]);
  const [companyIds, setCompanyIds] = useState<string[]>([]);

  const { companies } = useCompanyLookups();

  useEffect(() => {
    if (reportConfig?.companyIds?.length > 0) {
      setCompanyIds(reportConfig.companyIds);
    } else setCompanyIds(companies?.map((company: any) => company?.id));
  }, [reportConfig, companies]);

  useEffect(() => {
    if (reportConfig) retrieveCompanyTotalUsage();
    // eslint-disable-next-line
  }, [companyIds]);

  const retrieveCompanyTotalUsage = async () => {
    setLoading(true);

    let list: any[] = [];

    for (let index = 0; index < companyIds.length; index++) {
      const companyId = companyIds[index];

      const response = await getCompanyTotalUsage(companyId);
      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          if (data.value?.length > 0) {
            const filteredYears = data.value.filter((usage: any) => usage.year >= reportConfig.years[0] && usage.year <= reportConfig.years[1]);

            filteredYears?.map((data: any) => list.push(data));
          }
        }
      }
    }
    if (list.length > 0) setCompanyTotalUsage(list);
    else setCompanyTotalUsage([]);

    setLoading(false);
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedSummaryUsageReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Company Total Usage Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
          loading={loading}
        >
          {companyIds?.map((companyId) => <CompanyUsageRender companyTotalUsage={companyTotalUsage?.filter((usage) => usage?.companyId === companyId)} companyId={companyId} loading={loading} />)}
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
                <Descriptions.Item label="Year Range">
                  {reportConfig.years[0]} - {reportConfig.years[1]}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
};

export default GeneratedCompanyTotalUsageReport;
