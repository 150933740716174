import { Button, notification, Result, Space } from "antd";
import { getCompanyInvite } from "@/apis/company.api";
import { checkIfNeedToRegister } from "@/apis/identity.api";
import { RegistrationModal } from "@/components";
import { routes } from "@/configs";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";

const AcceptCompanyInvite: FC = () => {
  const dispatch = useAppDispatch();
  const { navigate } = useCustomNavigate();
  const { inviteId }: any = useParams();

  const [companyInvite, setCompanyInvite] = useState<any>();
  const [loadingInvite, setLoadingInvite] = useState<boolean>(true);
  const [checkingRegistration, setCheckingRegistration] = useState<boolean>(true);
  const [needToRegister, setNeedToRegister] = useState<boolean>(true);

  const [registrationModalState, setRegistrationModalState] = useState<any>({
    data: undefined,
    open: false,
  });

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    loadCompanyInvite();
  }, []);

  const loadCompanyInvite = async () => {
    setLoadingInvite(true);
    try {
      const response = await getCompanyInvite(inviteId);
      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          setCompanyInvite(data.value);
        }
      }
    } catch (e) {
      //TODO: Handle error
    }
    setLoadingInvite(false);
  };

  useEffect(() => {
    if (companyInvite) {
      checkRegistration(companyInvite.email);
    }
  }, [companyInvite]);

  const checkRegistration = async (email: string) => {
    setCheckingRegistration(true);
    try {
      const response = await checkIfNeedToRegister(email);
      if (response.ok) {
        setNeedToRegister(true);
      }
    } catch (e) {
      //TODO: Handle error
    }
    setCheckingRegistration(false);
  };

  const handleHomeClicked = () => {
    navigate(routes.home);
  };

  const handleRegistrationCancel = () => {
    setRegistrationModalState({
      ...registrationModalState,
      open: false,
      data: undefined,
    });
  };

  const openRegistrationCompleteNotification = () => {
    handleRegistrationCancel();

    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button type="default" onClick={() => api.destroy(key)}>
          Close
        </Button>
      </Space>
    );
    api.success({
      message: "Signup success",
      description: "A confirmation email has been sent to your inbox, please follow the instructions in the email to confirm your account.",
      btn,
      key,
      duration: 0,
      placement: "top",
    });
  };

  const handleRegisterClick = () => {
    setRegistrationModalState({
      ...registrationModalState,
      open: true,
    });
  };

  return (
    <>
      {needToRegister ? (
        <Result
          status="info"
          title="Please register to accept the company invite"
          extra={
            <Button type="primary" onClick={handleRegisterClick}>
              Register
            </Button>
          }
        />
      ) : (
        <Result
          status="success"
          title="You have successfully accepted the company invite"
          extra={
            <Button type="primary" onClick={handleHomeClicked}>
              Navigate To Home
            </Button>
          }
        />
      )}
      {registrationModalState.open && <RegistrationModal onSuccess={openRegistrationCompleteNotification} open={registrationModalState.open} onCancel={handleRegistrationCancel} />}
    </>
  );
};

export default AcceptCompanyInvite;
