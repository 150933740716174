import { Tag } from "antd";
import { FC } from "react";

interface WellTagListProps {
  tags?: any[];
  style?: any;
  tagStyle?: any;
}

const WellTagList: FC<WellTagListProps> = (props) => {
  const { tags, style, tagStyle } = props;
  return (
    <div style={style}>
      {tags?.map((tag, index: number) => (
        <Tag style={{ ...tagStyle, margin: 2 }} key={index}>
          {tag}
        </Tag>
      ))}
    </div>
  );
};

export default WellTagList;
