import { getWellDetailsForMap } from "@/apis/aggregator.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSelector } from "react-redux";

type CompanyPagedListProps = {
  wellId: string;
};

export default function useWellDetails(props: CompanyPagedListProps) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();

  const { isFetched: wellFetched, data: well } = useQuery({
    queryKey: [
      CacheKeys.wells,
      CacheKeys.details,
      {
        profileId: profile?.id,
        ...props,
      },
    ],
    queryFn: async () => {
      const response = await getWellDetailsForMap(props.wellId);

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;

        throw new Error("Failed to fetch well details");
      }
    },
    placeholderData: keepPreviousData,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
  });

  const invalidateWellDetails = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [
        CacheKeys.wells,
        CacheKeys.details,
        {
          wellId: props.wellId,
        },
      ],
    });
  }, [queryClient, props.wellId]);

  return {
    wellFetched,
    well,
    invalidateWellDetails,
  };
}
