import { WaterRightReportSelector, WellSelector } from "@/components";
import { constants } from "@/configs";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Divider, Form, Select, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import GeneratedEndOfYearSummaryReport from "./GeneratedEndOfYearSummaryReport";

const { RangePicker } = DatePicker;

const EndOfYearSummaryReport: FC = () => {
  const { lookups } = useSelector((state: any) => state.lookups);
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [form] = Form.useForm();

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [wells, setWells] = useState<any[]>([]);
  const [waterRights, setWaterRights] = useState<any[]>([]);

  const [years, setYears] = useState<any[]>([]);

  const [reportConfig, setReportConfig] = useState<any>(undefined);

  const wellIds: any[] = useWatch("wellIds", form);
  const dates: any[] = useWatch("date", form);
  const permitTypes: any[] = useWatch("permitTypes", form);
  const priorityTypes: any[] = useWatch("priorityTypes", form);
  const waterRightIds: any[] = useWatch("waterRightIds", form);

  const currentYear = dayjs();
  const previousYear = dayjs().add(-1, "year");

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    setYears(dates?.map((date) => dayjs(date).year()));
    // eslint-disable-next-line
  }, [dates]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    const tempWells = [...(wellIds ?? [])];
    const tempWRIds = [...(waterRightIds ?? [])];

    const tempWRsForPermitTypes = waterRights?.filter((waterRight) => permitTypes && permitTypes?.includes(waterRight?.permitType)).map((waterRight) => waterRight?.id);
    const tempWRsForPriorityTypes = waterRights?.filter((waterRight) => priorityTypes && priorityTypes?.includes(waterRight?.priorityType)).map((waterRight) => waterRight?.id);

    tempWRsForPermitTypes.forEach((tempWr) => {
      const wr = tempWRIds.find((waterRight) => waterRight === tempWr);
      if (!wr) tempWRIds.push(tempWr);
    });

    tempWRsForPriorityTypes.forEach((tempWr) => {
      const wr = tempWRIds.find((waterRight) => waterRight === tempWr);
      if (!wr) tempWRIds.push(tempWr);
    });

    const wellsForWaterRight = wells?.filter((well) => tempWRIds.includes(well?.waterRightId)).map((well) => well?.id);

    wellsForWaterRight?.forEach((wellId) => {
      const well = tempWells?.find((well) => well === wellId);
      if (!well) tempWells.push(wellId);
    });

    const orderWells = wells
      ?.filter((well) => tempWells?.includes(well.id))
      .map((well) => well.name)
      .sort((a, b) => a.localeCompare(b, "en", { numeric: true, sensitivity: "base" }));
    const orderWaterRights = waterRights
      ?.filter((waterRight) => tempWRIds?.includes(waterRight?.id))
      .map((waterRight) => waterRight?.fileNumber)
      .sort((a, b) => a?.localeCompare(b, "en", { numeric: true, sensitivity: "base" }));

    const permitTypeLookups = lookups?.find((lookup: any) => lookup.map === "permittype");
    const permitTypeNames = permitTypeLookups?.options?.filter((option: any) => permitTypes?.includes(option.value)).map((option: any) => option.name);
    const priorityTypeLookups = lookups?.find((lookup: any) => lookup.map === "prioritytype");
    const priorityTypeNames = priorityTypeLookups?.options?.filter((option: any) => priorityTypes?.includes(option.value)).map((option: any) => option.name);

    setReportConfig({
      selectedWellNames:
        wells
          ?.filter((well) => wellIds?.includes(well?.id))
          ?.map((well) => well?.name)
          ?.join(", ") ?? "-",
      wellIds:
        tempWells.length === 0 && tempWRIds.length === 0 && permitTypeNames?.length === 0 && priorityTypeNames?.length === 0 ? wells?.map((well) => well.id) : tempWells.length === 0 ? [] : tempWells,
      waterRightIds: tempWRIds,
      selectedWaterRightFileNumbers:
        waterRights
          ?.filter((waterRight) => waterRightIds?.includes(waterRight?.id))
          .map((waterRight) => waterRight?.fileNumber)
          ?.join(", ") ?? "-",
      permitTypes: permitTypeNames.join(", "),
      priorityTypes: priorityTypeNames.join(", "),
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
      years,
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <Form.Item label="Year Range" name="date" style={{ margin: 0, marginBottom: 10 }} initialValue={[previousYear, currentYear]} required>
            <RangePicker allowClear={false} picker="year" disabledDate={(d) => d.isAfter(`${dayjs().year()}-12-31`) || d.isBefore("2020-01-01")} />
          </Form.Item>
          <WellSelector form={form} propertyToSet={"wellIds"} label={"Wells"} placeholder="Leave blank to select all wells" style={{ margin: 0, marginBottom: 10 }} setWells={setWells} />
          <WaterRightReportSelector
            form={form}
            propertyToSet={"waterRightIds"}
            label={"Water Rights"}
            setWaterRights={setWaterRights}
            placeholder="Leave blank to select all water rights"
            style={{ margin: 0, marginBottom: 10 }}
          />
          <Form.Item label="Permit Type" name="permitTypes" style={{ margin: 0 }}>
            <Select
              allowClear
              mode="multiple"
              loading={generatingReport}
              placeholder="Leave blank to select all permit types"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "permitTypes",
                          lookups?.find((lookup: any) => lookup.map === "permittype")?.options?.map((lookup: any) => lookup.value)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("permitTypes", [])}>
                      Clear List
                    </Button>
                  </Space>
                </>
              )}
            >
              {lookups
                ?.find((lookup: any) => lookup.map === "permittype")
                .options.map((lookup: any) => {
                  return (
                    <Select.Option value={lookup.value} key={lookup.id} label={lookup.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {lookup.name}
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item label="Priority Type" name="priorityTypes" style={{ margin: 0, paddingTop: 10 }}>
            <Select
              allowClear
              mode="multiple"
              loading={generatingReport}
              placeholder="Leave blank to select all priority types"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "priorityTypes",
                          lookups?.find((lookup: any) => lookup.map === "prioritytype")?.options?.map((lookup: any) => lookup.value)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("priorityTypes", [])}>
                      Clear List
                    </Button>
                  </Space>
                </>
              )}
            >
              {lookups
                ?.find((lookup: any) => lookup.map === "prioritytype")
                .options.map((lookup: any) => {
                  return (
                    <Select.Option value={lookup.value} key={lookup.id} label={lookup.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {lookup.name}
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedEndOfYearSummaryReport reportConfig={reportConfig} />}
    </>
  );
};

export default EndOfYearSummaryReport;
