import { listWaterRightAnalyticsOverview } from "@/apis/aggregator.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

export default function useWaterRightAnalyticsOverview(permitType: string) {
  const { profile } = useSelector((state: any) => state.user);

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const {
    data,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    dataUpdatedAt: waterRightsAnalyticsOverviewUpdatedAt,
  } = useInfiniteQuery({
    queryKey: [
      CacheKeys.waterRights,
      CacheKeys.calculation,
      CacheKeys.analytics,
      {
        profileId: profile?.id,
        companyId: selectedCompanyId,
        permitType: permitType === "overview" ? undefined : permitType,
      },
    ],
    queryFn: async ({ pageParam }) => {
      const response = await listWaterRightAnalyticsOverview({
        profileId: profile?.id,
        companyId: selectedCompanyId,
        permitType: permitType === "overview" ? undefined : permitType,
        pageSize: 150,
        page: pageParam,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch water right analytics overview");
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    gcTime: Infinity,
    staleTime: Infinity,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage?.pagination?.hasNextPage) return undefined;
      return lastPageParam + 1;
    },
  });

  const invalidateWaterRightAnalyticsOverview = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.waterRights, CacheKeys.calculation, CacheKeys.analytics],
    });
  }, [queryClient]);

  const waterRightsAnalyticsOverview = useMemo(() => data?.pages?.map((page) => page.list).flat() ?? [], [data]);

  useEffect(() => {
    if (!isFetchingNextPage && hasNextPage) fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return {
    waterRightsAnalyticsOverview,
    waterRightsAnalyticsOverviewUpdatedAt,
    waterRightAnalyticsOverviewIsLoading: isFetchingNextPage || isFetching,
    invalidateWaterRightAnalyticsOverview,
  };
}
