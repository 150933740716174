import { addCompany, getCompanies, getCompany, getCompanyTags, updateCompany, upsertCompanyTags } from "@/apis/company.api";
import { LookupSelector } from "@/components";
import { constants, routes } from "@/configs";
import scrollToTop from "@/services/scrollToTop";
import useCustomNavigate from "@/services/useCustomNavigate";
import { daysOfWeek, getBase64, nth } from "@/services/utils";
import { useAppDispatch } from "@/stores";
import { removeBreadcrumb, removeViewEditBreadcrumbs } from "@/stores/breadcrumbs.store";
import { clearCompanyListDataState, saveCompanies, saveCompany, saveSelectedCompany, setReloadCompanies } from "@/stores/company.store";
import CacheKeys from "@/types/CacheKeys";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Alert, Button, Card, Form, Image, Input, InputNumber, Select, Space, Steps, Switch, Typography, UploadFile, message } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { RcFile } from "antd/es/upload";
import { FC, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTimezoneSelect } from "react-timezone-select";

import "./CompanyAddEdit.scss";
import { useWatch } from "antd/es/form/Form";
import { range } from "lodash";

const labelStyle = "altName";
const timezones = {
  // ...allTimezones,
  "America/Phoenix": "Arizona",
  "America/Los_Angeles": "Pacific Time",
  "America/Chicago": "Central Time",
  "America/Detroit": "Eastern Time",
  "America/Denver": "Denver",
};

const CompanyAddEdit: FC = () => {
  const dispatch = useAppDispatch();
  const { navigate } = useCustomNavigate();
  const { id } = useParams<{ id: string }>();

  const [form] = Form.useForm();

  const queryClient = useQueryClient();

  const { companyData, selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const user = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [addAnother, setAddAnother] = useState<boolean>(false);
  const [loadingTags, setLoadingTags] = useState<boolean>(id ? true : false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const meterFrequency: string = useWatch(["settings", "meterReadingFrequency"], form);

  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });

  useEffect(() => {
    return () => {
      dispatch(clearCompanyListDataState());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (companyData?.logoId) {
      setFileList([
        {
          uid: "-1",
          name: "logo.png",
          status: "done",
          url: `${constants.baseApiUrl}/company/image/${companyData?.logoId}/download`,
        },
      ]);
    }
  }, [companyData]);

  useEffect(() => {
    setLoading(true);

    if (id) {
      getCompanyData();
      loadTags();
    } else {
      const tz = parseTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
      form.setFieldsValue({ timezone: tz?.value });
      resetDefaults();
    }

    setLoading(false);
    // eslint-disable-next-line
  }, [id]);

  const resetDefaults = () => {
    form.setFieldsValue({
      active: true,
      settings: {
        meterReadingFrequency: "none",
      },
    });
    scrollToTop();
  };

  useEffect(() => {
    if (companyData) {
      const { tags, ...rest } = companyData;

      let settings = { ...rest?.settings };
      if (!settings?.timezone) {
        const tz = parseTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
        settings.timezone = tz.value;
      }

      form.setFieldsValue({ ...rest, settings });
    }
  }, [companyData]);

  const getCompanyData = async () => {
    const response = await getCompany(id);

    if (response.ok) {
      const data = await response.json();

      dispatch(saveCompany(data.value));
    }
  };

  const loadTags = async () => {
    setLoadingTags(true);
    const response = await getCompanyTags(id!);

    if (response.ok) {
      const data = await response.json();
      const { tags } = data.value;
      if (tags) form.setFieldsValue({ tags: tags });
    }
    setLoadingTags(false);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    let { tags, ...data } = values;

    if (id) {
      const response = await updateCompany(id, {
        ...data,
        logoId: fileList?.[0]?.xhr?.response ? JSON.parse(fileList[0].xhr.response)?.value : companyData?.logoId,
      });
      if (response.ok) {
        queryClient.invalidateQueries({ queryKey: [CacheKeys.companies] });

        message.success("Successfully updated the company");

        if (id === selectedCompanyId) {
          let role = undefined;

          switch (selectedCompany?.userRole) {
            case "Administrator":
              role = "admin";
              break;
            case "Water Reader":
              role = "waterreader";
              break;
            case "View Only":
              role = "viewer";
              break;
            default:
              break;
          }

          const selectedCompanyRequest = {
            companyId: id,
            companyName: data.name,
            settings: data?.settings,
            userRole: role,
          };

          dispatch(saveSelectedCompany(selectedCompanyRequest));
        }
        await upsertCompanyTags(id, values.tags);
        navigate(routes.companyList);
      } else {
        message.error("Failed to update the company");
      }
    } else {
      const response = await addCompany({
        ...data,
        logoId: fileList?.[0]?.xhr?.response ? JSON.parse(fileList[0].xhr.response)?.value : undefined,
      });
      if (response.ok) {
        queryClient.invalidateQueries({ queryKey: [CacheKeys.companies] });

        const data = await response.json();
        await upsertCompanyTags(data.value, values.tags);

        message.success("Successfully added the company");

        if (addAnother) {
          dispatch(clearCompanyListDataState());
          form.resetFields();
          resetDefaults();
          navigate(routes.companyAdd);
        } else navigate(routes.companyList);
      } else {
        message.error("Failed to add the company");
      }
    }

    if (id) {
      dispatch(removeViewEditBreadcrumbs());
    } else if (!addAnother) dispatch(removeBreadcrumb());
    // dispatch(removeBreadcrumb());

    reloadCompanies();
    setLoading(false);
    setAddAnother(false);
  };

  const reloadCompanies = async () => {
    let result = await getCompanies(undefined, null);
    if (result.ok) {
      result.json().then((data) => {
        if (data.isSuccess) {
          if (data.value.length > 0) dispatch(saveCompanies(data.value));
          dispatch(setReloadCompanies(false));
        }
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleAddAnother = () => {
    form
      .validateFields()
      .then((values) => {
        setAddAnother(true);
        setFileList([]);
        form.submit();
      })
      .catch((errors) => {
        scrollToTop();
        message.error(`Please fill in the required fields`, 5);
      });
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onFinish(values);
      })
      .catch((errors) => {
        scrollToTop();
        message.error(`Please fill in the required fields`, 5);
      });
  };

  const renderActionButtons = (placement: TooltipPlacement) => (
    <Space>
      {!id && (
        <>
          <Button disabled={uploading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button disabled={uploading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={handleAddAnother}>
            Save + Add Another
          </Button>
          <Button
            disabled={uploading}
            loading={loading}
            icon={<CloseOutlined />}
            onClick={() => {
              dispatch(removeBreadcrumb());
              navigate(routes.companyList);
            }}
          >
            Cancel
          </Button>
        </>
      )}
      {id && (
        <>
          <Button disabled={uploading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button
            disabled={uploading}
            loading={loading}
            icon={<CloseOutlined />}
            onClick={() => {
              dispatch(removeBreadcrumb());
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </>
      )}
    </Space>
  );

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleDaysOfWeekRender = () => {
    let render: any = <></>;
  };

  return (
    <Card className="companyAddEdit" id="companyAddEdit" title={id ? "Edit Company" : "Add Company"} extra={renderActionButtons("bottomRight")} actions={[renderActionButtons("top")]}>
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
        <Steps
          direction="vertical"
          items={[
            {
              title: "Company Details",
              status: "process",
              description: (
                <Card>
                  <Form.Item
                    label="Company Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a company name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter a company name" />
                  </Form.Item>

                  {/* <Form.Item label="Logo" valuePropName="fileList">
                                    <Upload
                                        maxCount={1}
                                        multiple={false}
                                        action={`${constants.baseApiUrl}/company/image/upload`}
                                        headers={{
                                            Authorization: `Bearer ${user.user?.access_token}`,
                                        }}
                                        listType="picture-card"
                                        fileList={fileList}
                                        onChange={(info: any) => {
                                            if (info.file.status === 'uploading') {
                                                setUploading(true);
                                            }
                                            if (info.file.status === 'done') {
                                                setUploading(false);
                                            }
                                            setFileList(info.fileList)
                                        }}
                                        onPreview={handlePreview}
                                        beforeUpload={resizeImage}
                                    >
                                        <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>Upload</div>
                                        </div>
                                    </Upload>
                                </Form.Item> */}

                  <Form.Item label="Active" name="active" valuePropName="checked">
                    <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
                  </Form.Item>
                </Card>
              ),
            },
            {
              title: "Default Settings",
              status: "process",
              description: (
                <Card>
                  <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                    <Alert showIcon message="Info: The timezone will be used for all calculations related to this company, its reports and notifications." type="info" />
                  </Form.Item>
                  <Form.Item
                    label="Timezone"
                    name={["settings", "timezone"]}
                    rules={[
                      {
                        required: true,
                        message: "Please select the default timezone",
                      },
                    ]}
                  >
                    <Select placeholder="Select the default timezone" style={{ width: "100%" }} showSearch optionFilterProp="label">
                      {options.map((timezone) => (
                        <Select.Option key={timezone.value} value={timezone.value} label={timezone.label}>
                          {timezone.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                    <Alert
                      showIcon
                      message="Warning: This warning quantity is applicable to all Water Right and Restriction remaining usage. You will be notified if the Water Right or Restriction's remaining usage drops below this warning quantity.."
                      type="warning"
                    />
                  </Form.Item>
                  <Form.Item label="Warning Quantity">
                    <Space>
                      <Typography style={{ width: 95 }}>Water Right</Typography>
                      <Input.Group compact>
                        <Form.Item
                          name={["settings", "waterRightWarningQuantity"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter a default warning quantity",
                            },
                          ]}
                          noStyle
                          initialValue={10}
                        >
                          <InputNumber min={0} style={{ minWidth: 225 }} placeholder="Enter default warning quantity" />
                        </Form.Item>
                        <Form.Item name={["settings", "waterRightWarningQuantityUnits"]} noStyle>
                          <LookupSelector
                            form={form}
                            propertyToSet={["settings", "waterRightWarningQuantityUnits"]}
                            placeholder="Select Units"
                            lookupType="meterUnits"
                            style={{ minWidth: 160 }}
                            setDefault={true}
                            defaultOverride={"acrefeet"}
                          />
                        </Form.Item>
                      </Input.Group>
                    </Space>
                    <br />
                    <Space>
                      <Typography style={{ width: 95 }}>Restriction</Typography>
                      <Input.Group compact>
                        <Form.Item
                          name={["settings", "restrictionWarningQuantity"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter a default warning quantity",
                            },
                          ]}
                          noStyle
                          initialValue={10}
                        >
                          <InputNumber min={0} style={{ minWidth: 225 }} placeholder="Enter default warning quantity" />
                        </Form.Item>
                        <Form.Item name={["settings", "restrictionWarningUnits"]} noStyle>
                          <LookupSelector
                            form={form}
                            propertyToSet={["settings", "restrictionWarningUnits"]}
                            placeholder="Select Units"
                            lookupType="meterUnits"
                            style={{ minWidth: 160 }}
                            setDefault={true}
                            defaultOverride={"acrefeet"}
                          />
                        </Form.Item>
                      </Input.Group>
                    </Space>
                  </Form.Item>
                  <Form.Item
                    label="Display Units"
                    name={["settings", "metric"]}
                    rules={[
                      {
                        required: true,
                        message: "Please select the default units",
                      },
                    ]}
                  >
                    <LookupSelector form={form} propertyToSet={["settings", "metric"]} placeholder="Select the default display units" lookupType="meterUnits" />
                  </Form.Item>
                  <Form.Item label="Meter Reading Frequency" required>
                    <Input.Group compact>
                      <Form.Item
                        name={["settings", "meterReadingFrequency"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select the default meter reading frequency",
                          },
                        ]}
                        noStyle
                        getValueFromEvent={(val) => {
                          form.setFieldsValue({
                            settings: {
                              meterReadingFrequencyDayOfWeek: undefined,
                              meterReadingFrequencyDay: undefined,
                            },
                          });
                          return val;
                        }}
                      >
                        <LookupSelector
                          style={{ minWidth: 225 }}
                          form={form}
                          propertyToSet={["settings", "meterReadingFrequency"]}
                          placeholder="Select the meter reading frequency"
                          lookupType="meterreadingfrequency"
                          orderByOverride={["id"]}
                        />
                      </Form.Item>
                      {(meterFrequency === "bi-weekly" || meterFrequency === "weekly") && (
                        <Form.Item
                          name={["settings", "meterReadingFrequencyDayOfWeek"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select day of the week for which meter reading frequency will be triggered",
                            },
                          ]}
                          noStyle
                        >
                          <Select placeholder="Select day of week" style={{ minWidth: 225 }}>
                            {daysOfWeek.map((x) => (
                              <Select.Option key={x.value} value={x.value} label={x.day}>
                                {x.day}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}

                      {meterFrequency === "monthly" && (
                        <Form.Item
                          name={["settings", "meterReadingFrequencyDay"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select day of the month for which meter reading frequency will be triggered",
                            },
                          ]}
                          noStyle
                        >
                          <Select placeholder="Select day of month" style={{ minWidth: 225 }}>
                            {range(1, 32).map((x) => (
                              <Select.Option key={x} value={x} label={x < 31 ? x + nth(x) + " day of the Month" : "Last day of the Month"}>
                                {x < 31 ? x + nth(x) + " day of the Month" : "Last day of the Month"}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Input.Group>
                  </Form.Item>
                </Card>
              ),
            },
            {
              title: "User Specific Settings",
              subTitle: "(The values below are only visible to you and not shared with other users)",
              status: "process",
              description: (
                <Card>
                  <Form.Item label="Tags" name="tags">
                    <Select mode="tags" placeholder="Enter tags for the water right (Optional)" loading={loadingTags} disabled={loadingTags} />
                  </Form.Item>
                </Card>
              ),
            },
          ]}
        />
      </Form>
      <Image
        style={{ display: "none" }}
        preview={{
          visible: previewOpen,
          scaleStep: 0.5,
          src: previewImage,
          onVisibleChange: (value) => {
            setPreviewOpen(value);
          },
        }}
      />
    </Card>
  );
};

export default CompanyAddEdit;
