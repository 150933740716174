import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface ILookupsState {
  lookups: string[];
}

const initialState: ILookupsState = {
  lookups: [],
};

const companyStore = createSlice({
  name: "lookups",
  initialState,
  reducers: {
    saveLookups: (state: ILookupsState, action: PayloadAction<any>) => {
      state.lookups = action.payload;
    },
  },
});

export const { saveLookups } = companyStore.actions;

export default companyStore.reducer;
