import { clearNotifications, putMarkPushAsRead } from "@/apis/communication.api";
import useNotifications from "@/queries/useNotifications";
import CacheKeys from "@/types/CacheKeys";
import { ReloadOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { Button, Card, Empty, Input, Skeleton, Space, message } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Notification from "./components/Notification";

const NotificaitonList: FC = () => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);

  const { selectedCompanyId } = useSelector((state: any) => state.company);
  const [searchStringInput, setSearchStringInput] = useState<string | undefined>(undefined);
  const [searchString, setSearchString] = useState<string | undefined>(undefined);

  const { notifications, fetchNextPage, isFetchingNextPage, hasNextPage, isFetching, isFetched, isLoading } = useNotifications(searchString);

  const [downref, downentry] = useIntersectionObserver();

  useEffect(() => {
    if (downentry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [downentry?.isIntersecting, hasNextPage]);

  const handleRefresh = useCallback(() => {
    setSearchStringInput(undefined);
    setSearchString(undefined);
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.notifications, CacheKeys.list],
    });
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.unreadNotificationCount],
    });
  }, [queryClient]);

  const markAllRead = async () => {
    setLoading(true);

    const response = await putMarkPushAsRead({ markAllRead: true });

    if (response.ok) {
      message.success("Notifications successfully marked as read");
      handleRefresh();
    } else {
      message.success("Failed to mark all notifications as read");
    }
    setLoading(false);
  };

  const onSearch = (searchString: any) => {
    setSearchString(searchString);
  };

  const onSearchChange = (searchString: any) => {
    setSearchStringInput(searchString);
  };

  const clearNotification = async (request: any) => {
    setLoading(true);

    const response = await clearNotifications(request);
    if (response.ok) {
      handleRefresh();
      message.success("Successfully cleared all notifications");
      queryClient.invalidateQueries({
        queryKey: [CacheKeys.unreadNotificationCount],
      });
    } else message.error("Failed to clear notification");

    setLoading(false);
  };

  return (
    <Card
      className="notificationList"
      id="notificationList"
      title="Notifications"
      extra={
        <Space direction="horizontal" style={{ width: "100%" }}>
          <Button onClick={() => clearNotification({ ids: undefined, clearAll: true })} type="default">
            Clear All Notifications
          </Button>
          <Button onClick={() => markAllRead()} type="primary">
            Mark All As Read
          </Button>
        </Space>
      }
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space style={{ paddingBottom: 10, marginLeft: 5 }}>
          <Input.Search
            disabled={isFetching || loading}
            placeholder="Search notifications"
            onSearch={onSearch}
            onChange={(val) => onSearchChange(val.currentTarget.value)}
            value={searchStringInput}
            style={{ width: 400 }}
            allowClear
          />
          <Button icon={<ReloadOutlined />} disabled={isFetching || loading} onClick={handleRefresh}>
            Refresh
          </Button>
        </Space>
        <Card loading={!isFetched || loading} bordered={false} className="removeBoxShadow">
          {!isLoading && notifications?.length > 0 ? (
            notifications.map((notification: any, index, pages) => (
              <Card.Grid>
                <Notification refreshNotifications={handleRefresh} {...{ selectedCompanyId, notification }} />
                {index === pages.length - 4 ? <div ref={downref} /> : null}
              </Card.Grid>
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Notifications" />
          )}
          {isFetchingNextPage && (
            <Card.Grid>
              <Skeleton active />
            </Card.Grid>
          )}
        </Card>
      </Space>
    </Card>
  );
};

export default NotificaitonList;
