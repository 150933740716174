import { CalendarOutlined, LogoutOutlined, MailOutlined, MoneyCollectOutlined, PhoneOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Divider, Popover, Space, Spin, Typography, notification } from "antd";
import { ProfileUpdateModal } from "@/components";
import { constants } from "@/configs";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { clearPersistedStore } from "@/stores";
import "./ProfileDropdown.scss";

const ProfileDropdown: FC = () => {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [profileModalState, setProfileModalState] = useState<any>({
    data: undefined,
    open: false,
  });
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);

  const { profile } = useSelector((state: any) => state.user);
  const { selectedCompany } = useSelector((state: any) => state.company);
  const { selectedRole } = useSelector((state: any) => state.user);

  const handleManageProfile = () => {
    setOpen(false);
    setProfileModalState({ ...profileModalState, open: true });
  };

  const handleProfileCancel = () => {
    setProfileModalState({ ...profileModalState, open: false });
  };

  const openProfileUpdateCompleteNotification = (closeModal?: boolean) => {
    // handleProfileCancel();
    setProfileModalState({ ...profileModalState, open: closeModal });

    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button type="default" onClick={() => api.destroy(key)}>
          Close
        </Button>
      </Space>
    );
    api.success({
      message: "Update profile success",
      description: "Your profile was successfuly updated.",
      btn,
      key,
      duration: 0,
      placement: "top",
    });
  };

  const handleLogout = () => {
    setLoading(true);
    clearPersistedStore();
    auth
      .signoutRedirect()
      .then(() => setLoading(false))
      .catch((e) => setLoading(false));
  };

  const formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3];
    } else return phoneNumberString;
  };

  const renderAdminProfileDropdown = () => {
    return (
      <Card
        id="profileDropdown"
        bordered={false}
        actions={[
          <Button type="text" icon={<LogoutOutlined />} loading={loading} onClick={handleLogout}>
            Logout
          </Button>,
        ]}
        bodyStyle={{ padding: 20 }}
        className="removeBoxShadow removeMargin"
      >
        <Card.Meta avatar={<Avatar icon={<UserOutlined />} size={50} />} title={profile.displayName} />
        <Divider orientation="left" style={{ marginBottom: 5 }}>
          User Information
        </Divider>
        <Card className="removeBoxShadow removeMargin" bordered={false} style={{ boxShadow: "none", paddingLeft: 20, marginTop: 5 }}>
          <Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <MailOutlined style={{ paddingRight: 10 }} />
              {profile.email}
            </div>
          </Typography>
          {profile.phoneNumber && (
            <Typography>
              <PhoneOutlined style={{ paddingRight: 10 }} />
              {formatPhoneNumber(profile.phoneNumber)}
            </Typography>
          )}
        </Card>
      </Card>
    );
  };

  const renderProfileDropdown = () => {
    return (
      <Card
        id="profileDropdown"
        bordered={false}
        actions={[
          <Button type="text" icon={<SettingOutlined />} loading={loading} onClick={handleManageProfile}>
            Settings
          </Button>,
          <Button type="text" icon={<LogoutOutlined />} loading={loading} onClick={handleLogout}>
            Logout
          </Button>,
        ]}
        style={{ width: 350 }}
        bodyStyle={{ padding: 20, width: 350 }}
        className="removeBoxShadow removeMargin"
      >
        <Card.Meta
          avatar={<Avatar icon={<UserOutlined />} size={50} />}
          title={profile.displayName}
          description={
            <>
              {selectedCompany?.companyName ?? "No active company"}
              <br />
              {selectedCompany?.userRole ?? "No active role"}
            </>
          }
        />
        <Divider orientation="left" style={{ marginBottom: 5 }}>
          User Information
        </Divider>
        <Card className="removeBoxShadow removeMargin" bordered={false} style={{ boxShadow: "none", paddingLeft: 25, marginTop: 5 }}>
          <Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <MailOutlined style={{ paddingRight: 10 }} />
              {profile.email}
            </div>
          </Typography>
          {profile.phoneNumber && (
            <Typography>
              <PhoneOutlined style={{ paddingRight: 10 }} />
              {formatPhoneNumber(profile.phoneNumber)}
            </Typography>
          )}
        </Card>
        <Divider orientation="left" style={{ marginBottom: 5 }}>
          Billing Emails
        </Divider>
        <Card className="removeBoxShadow removeMargin" bordered={false} style={{ boxShadow: "none", paddingLeft: 25, marginTop: 5 }}>
          {profile?.billingEmails?.length > 0 ? profile.billingEmails.map((email: any) => <Typography>{email}</Typography>) : profile.email}
        </Card>
        <Divider orientation="left" style={{ marginBottom: 5 }}>
          Billing Address
        </Divider>
        <Card className="removeBoxShadow removeMargin" bordered={false} style={{ boxShadow: "none", paddingLeft: 25, marginTop: 5 }}>
          {profile?.addressLine1 || profile?.addressLine2 || profile.city || profile?.state || profile?.zipCode || profile?.country ? (
            <>
              <Typography>{profile?.addressLine1}</Typography>
              <Typography>{profile?.addressLine2}</Typography>
              <Typography>{profile?.city}</Typography>
              <Typography>{profile?.state}</Typography>
              <Typography>{profile?.zipCode}</Typography>
              <Typography>{profile?.country}</Typography>
            </>
          ) : (
            "Billing address incomplete"
          )}
        </Card>
        <Divider orientation="left" style={{ marginBottom: 5 }}>
          Subscription
        </Divider>
        <Card className="removeBoxShadow removeMargin" bordered={false} style={{ boxShadow: "none", paddingLeft: 25, marginTop: 5 }}>
          <Typography>
            <MoneyCollectOutlined style={{ paddingRight: 10 }} />
            {profile?.subscriptionName}
          </Typography>
          <Typography>
            <CalendarOutlined style={{ paddingRight: 10 }} />
            {profile?.subscriptionDate ? dayjs(profile?.subscriptionDate).format(constants.dateTimeFormat) : "N/A"}
          </Typography>
        </Card>
        <Divider orientation="left" style={{ marginBottom: 5 }}>
          Fee Schedule
        </Divider>
        <Card className="removeBoxShadow removeMargin" bordered={false} style={{ boxShadow: "none", paddingLeft: 25, marginTop: 5 }}>
          <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
            <div style={{ width: 60, fontWeight: "bold" }}>Well:</div>
            <div>$</div>
            <div style={{ width: 45, textAlign: "right" }}>100.00</div>
          </div>
          <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
            <div style={{ width: 60, fontWeight: "bold" }}>Token:</div>
            <div>$</div>
            <div style={{ width: 45, textAlign: "right" }}>8.00</div>
          </div>
          {/* <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                    <div style={{ width: 60, fontWeight: 'bold' }}>User:</div>
                    <div>$</div>
                    <div style={{ width: 45, textAlign: 'right' }}>10.00</div>
                    <div style={{ marginLeft: 10 }}>(Per well assigned)</div>
                </div> */}
          {/* <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                    <div style={{ width: 60, fontWeight: 'bold' }}>Admin:</div>
                    <div>One Included</div>
                </div> */}
        </Card>
      </Card>
    );
  };

  return (
    <>
      {contextHolder}
      <Popover
        content={selectedRole ? renderAdminProfileDropdown : renderProfileDropdown}
        trigger={profile ? ["click"] : []}
        placement="bottomRight"
        overlayInnerStyle={{ padding: 0, minWidth: 350 }}
        open={open}
        onOpenChange={setOpen}
      >
        <Avatar icon={profile ? <UserOutlined /> : <Spin />} style={profile ? { cursor: "pointer" } : {}} />
      </Popover>
      {profileModalState.open && <ProfileUpdateModal open={profileModalState.open} onSuccess={openProfileUpdateCompleteNotification} onCancel={handleProfileCancel} />}
    </>
  );
};

export default ProfileDropdown;
