import { LookupLabel, StatusTag, WellTagList } from "@/components";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import WaterRightTagList from "@/components/WaterRightTagList/WaterRightTagList";
import { constants } from "@/configs";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import useWaterRightUsagePerMonth from "@/queries/useWaterRightUsagePerMonth";
import useWellUsageReportQuery from "@/queries/useWellUsageReportQuery";
import { convertNumToMonth, convertUnits } from "@/services/utils";
import { getDaysInMonthForYear } from "@/utils/getDaysInMonthForYear";
import { WarningOutlined } from "@ant-design/icons";
import { Alert, Card, ConfigProvider, Descriptions, Image, Space, Table, Typography } from "antd";
import { ColumnType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { forEach, groupBy, orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./GeneratedDetailedEndOfYearWaterUsageReport.scss";

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  reportConfig: any;
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

export default function GeneratedDetailedEndOfYearWaterUsageReport(props: Props) {
  const { reportConfig } = props;

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const [waterRightIdsForUsage, setWaterRightIdsForUsage] = useState<any[]>([]);

  const { companyConfigs } = useComapnyConfigLookup();

  const {
    wellUsageReport: reportData,
    isFetching,
    hasNextPage,
  } = useWellUsageReportQuery({
    permitTypes: reportConfig?.permitTypes,
    priorityTypes: reportConfig?.priorityTypes,
    waterRightIds: reportConfig?.waterRightIds,
    generatedDate: reportConfig?.generatedDate,
  });

  const {
    waterRightUsagePerMonth: usageData,
    isFetching: isUsageLoading,
    hasNextPage: usageHasNextPage,
  } = useWaterRightUsagePerMonth({
    generatedDate: reportConfig?.generatedDate,
    year: reportConfig?.year,
    waterRightIds: waterRightIdsForUsage,
  });

  useEffect(() => {
    if (reportData) {
      const waterRightIds = new Set(reportData?.map((data: any) => data?.waterRightId));
      setWaterRightIdsForUsage(Array.from(waterRightIds.values()));
    }
    // eslint-disable-next-line
  }, [reportData]);

  const generateTitle = (wellName: string, sharedWith: any[], reductionWells: any[], companyId: string) => {
    return (
      <>
        <Typography
          style={{
            whiteSpace: "pre-wrap",
            fontSize: 16,
            paddingBottom: 0,
            paddingTop: 10,
          }}
        >
          {wellName}
        </Typography>
        {sharedWith?.length > 0 && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ fontSize: 12, width: 125 }}>Shares usage with: </div>
            <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{sharedWith.map((x) => x.wellName).join(", ")}</div>
          </div>
        )}
        {reductionWells?.length > 0 && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ fontSize: 12, width: 125 }}>Reduced by: </div>
            <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{reductionWells.map((x) => x.reductionWellName).join(", ")}</div>
          </div>
        )}
      </>
    );
  };

  const generateHeader = (
    item: any,
    totalsForTheYear: any,
    gallonsPossibleAfterMds: number,
    gallonsPossible: number,
    totalGallonsUsed: number,
    gallonsLeft: number,
    lastMeterReading: any,
    beginningMeterReading: any,
    totalPenalty: number,
    totalTransferred: number
  ) => {
    return (
      <>
        <Descriptions bordered size="small" column={3} labelStyle={{ textAlign: "end" }} className="removeBoxShadow removeMargin">
          <Descriptions.Item label="Well">
            {item.name}
            {item?.wellTags?.length > 0 ? <WellTagList tags={item.wellTags} /> : <></>}
          </Descriptions.Item>
          <Descriptions.Item label="Authorized Irrigated Acres">{item.irrigationAcresNotApplicable ? "N/A" : item.authorizedIrrigatedAcres}</Descriptions.Item>
          <Descriptions.Item label="Gallons Possible">{item.quantityNotApplicable ? "N/A" : <UnitsConverter fromUnits={"gallons"} toUnits="gallons" value={gallonsPossible} />}</Descriptions.Item>

          <Descriptions.Item label="Water Right">
            {item.waterRight}
            {item?.internalTags?.length > 0 || item?.externalTags?.length > 0 ? <WaterRightTagList internalTags={item?.internalTags} externalTags={item?.externalTags} /> : <></>}
          </Descriptions.Item>
          <Descriptions.Item label="Authorized Pump Rate">{item.rateNotApplicable ? "N/A" : `${item.authorizedRate} GPM`}</Descriptions.Item>
          <Descriptions.Item label="Gallons Used">
            <UnitsConverter fromUnits="gallons" toUnits="gallons" value={totalGallonsUsed} />
          </Descriptions.Item>

          {item.section && item.range ? (
            <Descriptions.Item label="Legal">
              {item.section}-{item.township}
              {item.townshipDirection}-{item.range}
              {item.rangeDirection}{" "}
              <span style={{ paddingLeft: 10 }}>
                <LookupLabel showCode={false} lookupType="states" value={item.state} placeholder="" />
              </span>
            </Descriptions.Item>
          ) : (
            <Descriptions.Item label="Legal">Not Configured</Descriptions.Item>
          )}
          <Descriptions.Item label="Original Authorized Quantity">
            {item.quantityNotApplicable ? (
              "N/A"
            ) : (
              <>
                {item.authorizedQuantity} <LookupLabel lookupType="meterUnits" value={item.authorizedQuantityUnits} />
              </>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Gallons Left">
            <UnitsConverter fromUnits="gallons" toUnits="gallons" value={item?.quantityNotApplicable ? "N/A" : gallonsLeft} />
          </Descriptions.Item>

          <Descriptions.Item label="Permit Type">
            <LookupLabel lookupType="permittype" value={item.permitType} />
          </Descriptions.Item>
          <Descriptions.Item label="Actual Usage Irrigation Acres">{item.irrigationAcresNotApplicable ? "N/A" : item.usageIrrigatedAcres ? item.usageIrrigatedAcres : "-"}</Descriptions.Item>
          <Descriptions.Item label="Acre Feet Possible">{item.quantityNotApplicable ? "N/A" : <UnitsConverter fromUnits={"gallons"} toUnits="acrefeet" value={gallonsPossible} />}</Descriptions.Item>

          <Descriptions.Item label="Field">{item.field}</Descriptions.Item>
          <Descriptions.Item label="Actual Usage Pump Rate">{item.rateNotApplicable ? "N/A" : item.usageRate ? item.usageRate + " GPM" : "-"}</Descriptions.Item>
          <Descriptions.Item label="Acre Feet Used">
            <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={totalGallonsUsed} />
          </Descriptions.Item>

          <Descriptions.Item label="Priority Type">
            <LookupLabel lookupType="prioritytype" value={item.priorityType} />
          </Descriptions.Item>
          <Descriptions.Item label="Actual Usage Quantity">
            {item.quantityNotApplicable ? "N/A" : item.usageQuantity ? item.usageQuantity : "-"} {item.usageQuantity ? <LookupLabel lookupType="meterUnits" value={item.usageQuantityUnits} /> : ""}
          </Descriptions.Item>
          <Descriptions.Item label="Acre Feet Left">
            <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={item?.quantityNotApplicable ? "N/A" : gallonsLeft} />
          </Descriptions.Item>

          <Descriptions.Item label="Energy Type">
            {item.energyTypeCode ? `${item.energyTypeCode} - ` : ""}
            {item.energyType ? item.energyType : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Penalty">
            <UnitsConverter value={totalPenalty} fromUnits={"gallons"} showUnitsLabel />
          </Descriptions.Item>
          <Descriptions.Item label=""> </Descriptions.Item>

          <Descriptions.Item label="GMD">{item.gmd}</Descriptions.Item>
          {item.subjectToMds ? (
            <Descriptions.Item label="MDS Quantity">
              {item.quantityNotApplicable ? (
                "N/A"
              ) : (
                <>
                  <UnitsConverter value={gallonsPossibleAfterMds} fromUnits={"gallons"} toUnits={item?.usageQuantity ? item?.usageQuantityUnits : item?.authorizedQuantityUnits} showUnitsLabel />
                </>
              )}
            </Descriptions.Item>
          ) : (
            <Descriptions.Item label="Transferred">
              <UnitsConverter value={totalTransferred} fromUnits={"gallons"} showUnitsLabel />
            </Descriptions.Item>
          )}
          <Descriptions.Item label=""> </Descriptions.Item>

          <Descriptions.Item label="System Type">
            {item.systemTypeCode ? `${item.systemTypeCode} - ` : ""}
            {item.systemType ? item.systemType : "-"}
          </Descriptions.Item>
          {item.subjectToMds ? (
            <Descriptions.Item label="Transferred">
              <UnitsConverter value={totalTransferred} fromUnits={"gallons"} showUnitsLabel />
            </Descriptions.Item>
          ) : (
            <Descriptions.Item label=""> </Descriptions.Item>
          )}
          <Descriptions.Item label=""> </Descriptions.Item>

          <Descriptions.Item label="Crop Type">{item.cropType ? item.cropType : "-"}</Descriptions.Item>
          <Descriptions.Item label=""> </Descriptions.Item>
          <Descriptions.Item label=""> </Descriptions.Item>

          <Descriptions.Item label="Water Source">{item.waterSource ? item.waterSource : "-"}</Descriptions.Item>
          <Descriptions.Item label=""> </Descriptions.Item>
          <Descriptions.Item label=""> </Descriptions.Item>

          <Descriptions.Item label="PDIV">{item.pdiv ? item.pdiv : "-"}</Descriptions.Item>
          <Descriptions.Item label="Is Digital Meter">
            {" "}
            <StatusTag status={item.digital === "YES" ? "YES" : "No"} />
          </Descriptions.Item>
          <Descriptions.Item label="Number of Decimal Places">{item.decimals ?? "N/A"} </Descriptions.Item>

          <Descriptions.Item label="CIN">{item.cin ? item.cin : "-"}</Descriptions.Item>
          <Descriptions.Item label="Beginning Meter Reading">
            {beginningMeterReading !== null ? (
              <>
                Reading: {beginningMeterReading?.reading} <br />
                Date: {dayjs(beginningMeterReading?.date).format(constants.dateTimeFormat)}
              </>
            ) : (
              "No readings recorded"
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Ending Meter Reading">
            {lastMeterReading !== null ? (
              <>
                Reading: {lastMeterReading?.reading} <br />
                Date: {dayjs(lastMeterReading?.date).format(constants.dateTimeFormat)}
              </>
            ) : (
              "No readings recorded"
            )}
          </Descriptions.Item>

          {/* <Descriptions.Item label="Water Right Tags">
            {item?.internalTags?.length > 0 || item?.externalTags?.length > 0 ? <WaterRightTagList internalTags={item?.internalTags} externalTags={item?.externalTags} /> : "-"}
          </Descriptions.Item> */}
        </Descriptions>
        {item.disableWarnings && (
          <Alert
            style={{ marginTop: 10 }}
            type="error"
            showIcon
            message="Warnings Disabled"
            icon={<WarningOutlined />}
            description={
              <>
                This water right has been marked as having warnings disabled. This means that the system will not send any warning notifications for this water right. <br />
                To re-enable warnings, click the 'Edit' button above and uncheck the 'Disable Warnings' checkbox. <br />
                <br />
                Acknowledged By: {item?.markedAsDisabledUserUserName} <br />
                Date: {dayjs(item?.markedAsDisabledDate).format(constants.dateTimeFormat)}
              </>
            }
          />
        )}
      </>
    );
  };

  const renderExpandData = (record: any) => {
    return (
      <div style={{ padding: 5 }}>
        <b>Note: </b> {record?.extraNote ? record?.extraNote : record?.notes}
      </div>
    );
  };

  const generateReadings = (
    item: any,
    totalsForTheYear: any,
    totalGallonsUsedBeforeShared: number,
    totalGallonsUsedBeforeAdjustment: number,
    totalGallonsUsedAfterAdjustment: number,
    gallonsPossibleWithoutPenalty: number,
    gallonsPossibleAfterPenalty: number,
    gallonsPossibleAfterMds: number,
    gallonsPossibleAfterTransfers: number,
    gallonsLeft: number,
    waterRightTotalWithAdjustments: number,
    beginningMeterReading: any
  ) => {
    const companyConfig = companyConfigs?.find((company: any) => company?.id === item.companyId);
    let readingsForTheYear = item.readings?.find((x: any) => x.year === reportConfig.year)?.readings ?? [];

    readingsForTheYear = orderBy(readingsForTheYear, "date", "asc");

    let meterConfigHistory: any[] = [];

    let previousYearReading = beginningMeterReading ?? undefined;
    if (previousYearReading !== undefined && previousYearReading?.id !== readingsForTheYear?.filter((x: any) => x.type !== "Not Collected")?.[0]?.id) {
      previousYearReading.consumedSinceLastReading = 0;
      previousYearReading.extraNote = "This is the final meter reading for the previous year";
      readingsForTheYear.unshift(previousYearReading);
    }

    if (reportConfig.showGPM) {
      let lastReading = undefined;
      for (let index = 0; index < readingsForTheYear.length; index++) {
        const reading = readingsForTheYear[index];

        let gpm = undefined;

        // if
        if (reading.type !== "Not Collected") {
          if (lastReading) {
            // calculate gpm between readings
            const readingDate = dayjs(reading.date);
            const prevReadingDate = dayjs(lastReading.date);

            const diffInMinutes = readingDate.diff(prevReadingDate, "minute", true);

            gpm = diffInMinutes === 0 ? 0 : reading.consumedSinceLastReading / diffInMinutes;
          }
          lastReading = reading;
        }
        reading.gpm = gpm;
      }
    }

    if (readingsForTheYear?.length > 0) {
      if (previousYearReading !== undefined) {
        meterConfigHistory.push(previousYearReading);
      }
      readingsForTheYear
        ?.filter((x: any) => x.type !== "Not Collected")
        .forEach((reading: any, index: any) => {
          if (
            reading?.meterUnits !== meterConfigHistory[meterConfigHistory?.length - 1]?.meterUnits ||
            reading?.meterMultiplier !== meterConfigHistory[meterConfigHistory?.length - 1]?.meterMultiplier ||
            reading?.meterMaxValue !== meterConfigHistory[meterConfigHistory?.length - 1]?.meterMaxValue ||
            (reading?.newMeter !== "NO" && reading.type !== "Not Collected")
          ) {
            meterConfigHistory.push(reading);
          }
        });
    }

    const notes = item?.notes ?? [];

    const stockUsageDataForTheYear = item?.stockUsageData?.find((x: any) => x.year === reportConfig.year);

    const stockUsageData = stockUsageDataForTheYear
      ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((x) => {
          var monthData = stockUsageDataForTheYear?.monthlyUsage?.find((y: any) => y.month === x);
          return {
            month: x,
            headCount: monthData?.headCount,
            monthUsage: monthData?.monthUsage,
            daysInMonth: monthData?.daysInMonth,
            averageHeadCount: stockUsageDataForTheYear?.averageHeadCount,
          };
        })
      : null;

    const waterRightInterpolatedUsageForYear = usageData?.filter((data) => data?.returnWaterRightId === item.waterRightId && reportConfig?.year === data?.returnYear);
    let combinedStockUsageData: any[] | null = [];
    const year = reportConfig.year;

    const daysInMonth = getDaysInMonthForYear(year, selectedCompany?.settings?.timezone);

    if (reportConfig.interpolate && stockUsageData) {
      for (let index = 0; index < 12; index++) {
        combinedStockUsageData.push({
          month: index + 1,
          headCount: stockUsageData[index].headCount,
          monthUsage: waterRightInterpolatedUsageForYear[index]?.returnUsage,
          daysInMonth: daysInMonth[index] ? daysInMonth[index] : null,
          averageHeadCount: stockUsageData[index].averageHeadCount,
        });
      }
    } else combinedStockUsageData = stockUsageData;

    /**
     * Places Of Use Logic
     */
    const currentYearReadings = item.readings?.find((reading: any) => reading.year === reportConfig.year);
    const newReadings = currentYearReadings?.readings?.map((reading: any) => {
      return { ...reading, placesOfUse: reading?.placesOfUse?.join(", ") };
    });

    const groups = groupBy(newReadings, "placesOfUse");

    const placesOfUseKeys = Object.keys(groups);

    let finalPlacesOfUseSummary: any[] = [];
    if (placesOfUseKeys.length > 0) {
      placesOfUseKeys.forEach((key) => {
        const group = groups[key];
        const sum = group.map((reading) => reading.consumedSinceLastReading).reduce((sum, currentVal: any) => sum + currentVal);
        finalPlacesOfUseSummary.push({
          name: key === "" ? "(Unallocated)" : key,
          value: sum,
        });
      });
    }

    const columns: ColumnType<any>[] = [
      {
        title: "#",
        width: 25,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: 170,
        render: (val: string) => dayjs(val).format(constants.dateTimeFormatWithSeconds),
      },
      {
        title: "Reader",
        dataIndex: "reader",
        key: "reader",
      },
      {
        title: "New Meter",
        dataIndex: "newMeter",
        key: "newMeter",
        render: (val: string, record: any) => (record.type === "Not Collected" ? "-" : val),
      },
      {
        title: "Reading",
        dataIndex: "reading",
        key: "reading",
        render: (val: string, record: any) => (record.type === "Not Collected" ? "-" : val ?? "-"),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "Units",
        dataIndex: "meterUnits",
        key: "meterUnits",
        width: 250,
        render: (val: string, record: any) => (record.type === "Not Collected" ? "-" : <LookupLabel lookupType="meterUnits" value={val} />),
      },
      {
        title: "Places of Use",
        dataIndex: "placesOfUse",
        key: "placesOfUse",
        render: (val: any[], record: any) => (record?.placesOfUse ? record?.placesOfUse?.join(", ") : "-"),
      },
    ];

    if (companyConfig?.settings?.metric !== "gallons")
      columns.push({
        title: <LookupLabel lookupType="meterUnits" value={companyConfig?.settings?.metric} />,
        dataIndex: "consumedSinceLastReading",
        key: "consumedSinceLastReadingInCompanyMetric",
        align: "right",
        render: (val: number, record: any) => (record.type === "Not Collected" ? "-" : <UnitsConverter value={val} fromUnits="gallons" toUnits={companyConfig?.settings?.metric} />),
      });

    if (!item.irrigationAcresNotApplicable && !item.quantityNotApplicable) {
      columns.push({
        title: "Inches / Acre",
        dataIndex: "consumedSinceLastReading",
        key: "incesacre",
        align: "right",
        render: (val: number, record: any) =>
          record.type === "Not Collected" ? "-" : <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={val} devideBy={item.authorizedIrrigatedAcres} />,
      });
    }

    columns.push({
      title: "Gallons",
      dataIndex: "consumedSinceLastReading",
      key: "gallons",
      align: "right",
      render: (val: number, record: any) => (record.type === "Not Collected" ? "-" : <UnitsConverter fromUnits="gallons" toUnits="gallons" value={val} />),
    });

    if (reportConfig.showGPM)
      columns.push({
        title: "GPM",
        dataIndex: "gpm",
        key: "gpm",
        align: "right",
        render: (val: number, record: any) => (record.type === "Not Collected" ? "-" : <UnitsConverter overrideDecimalPlaces={2} fromUnits="gallons" toUnits="gallons" value={val} />),
      });

    const noteColumns: ColumnType<any>[] = [
      {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 170,
        render: (val: string) => dayjs(val).format(constants.dateTimeFormatWithSeconds),
      },
      {
        title: "Created By",
        dataIndex: "createdByUserName",
        key: "createdByUserName",
        width: 170,
      },
      {
        title: "Well Notes",
        dataIndex: "note",
        key: "note",
        render: (val: any, record: any) => <pre style={{ margin: 0, fontFamily: "inherit" }}>{val}</pre>,
      },
    ];

    const meterConfigColumns: ColumnType<any>[] = [
      {
        title: "#",
        width: 25,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: 170,
        render: (val: string) => dayjs(val).format(constants.dateTimeFormatWithSeconds),
      },
      {
        title: "Units",
        dataIndex: "meterUnits",
        key: "meterUnits",
        render: (val: string, record: any) => (record.type === "Not Collected" ? "-" : <LookupLabel lookupType="meterUnits" value={val} />),
      },
      {
        title: "Meter Multiplier",
        dataIndex: "meterMultiplier",
        key: "meterMultiplier",
        render: (val: string, record: any) => <LookupLabel lookupType="meterMultiplier" value={val} />,
      },
      {
        title: "Meter Max Value",
        dataIndex: "meterMaxValue",
        key: "meterMaxValue",
        render: (val: string, record: any) => {
          return <LookupLabel lookupType="metermaxvalue" value={val} />;
        },
      },
      {
        title: "Meter Serial Number",
        dataIndex: "meterSerialNumber",
        key: "meterSerialNumber",
        render: (val: string, record: any) => val ?? "-",
      },
      {
        title: "Is Digital Meter",
        dataIndex: "digital",
        key: "digital",
        render: (val: string, record: any) => val,
      },
      {
        title: "Number of Decimal Places",
        dataIndex: "decimals",
        key: "decimals",
        render: (val: string, record: any) => (val ? val : "N/A"),
      },
    ];

    const headCountColumns: ColumnType<any>[] = [
      {
        title: "Month",
        dataIndex: "month",
        key: "month",
        render: (val: number, record: any) => convertNumToMonth(val),
      },
      {
        title: "Days In Month",
        dataIndex: "daysInMonth",
        key: "daysInMonth",
        align: "right",
        render: (val: string, record: any) => val ?? "-",
      },
      {
        title: "Head Count",
        dataIndex: "headCount",
        key: "headCount",
        align: "right",
        render: (val: string, record: any) => val ?? (record?.daysInMonth && record?.averageHeadCount > 0 ? "* " + record?.averageHeadCount.toFixed(0) : null) ?? "-",
      },
      {
        title: (
          <>
            Usage For The Month <small>(Acre Feet)</small>
          </>
        ),
        dataIndex: "monthUsage",
        key: "monthUsage",
        align: "right",
        render: (val: string, record: any) => <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={val} />,
      },
      {
        title: (
          <>
            Usage For The Month <small>(Million Gallons)</small>
          </>
        ),
        dataIndex: "monthUsage",
        key: "monthUsage",
        align: "right",
        render: (val: string, record: any) => <UnitsConverter fromUnits="gallons" toUnits="milliongallons" value={val} />,
      },
      {
        title: (
          <>
            Usage For The Month <small>(Gallons)</small>
          </>
        ),
        dataIndex: "monthUsage",
        key: "monthUsage",
        align: "right",
        render: (val: string, record: any) => <UnitsConverter fromUnits="gallons" toUnits="gallons" value={val} />,
      },
      {
        title: "Gallons / Head / Day",
        dataIndex: "monthUsage",
        key: "monthUsage",
        align: "right",
        render: (val: string, record: any) =>
          (record.headCount ?? record.averageHeadCount) > 0 && record.daysInMonth ? (
            <UnitsConverter
              fromUnits="gallons"
              toUnits="gallons"
              value={val}
              devideBy={(record.headCount ?? Math.round(record.averageHeadCount)) * record.daysInMonth * 1.0}
              overrideDecimalPlaces={2}
            />
          ) : (
            "-"
          ),
      },
    ];

    const placesOfUseColumns: ColumnType<any>[] = [
      {
        title: "Places of Use",
        dataIndex: "name",
        key: "name",
        render: (val: number, record: any) => val,
      },
    ];

    if (selectedCompany?.settings?.metric !== "gallons")
      placesOfUseColumns.push({
        title: <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />,
        dataIndex: "value",
        key: "value",
        align: "right",
        render: (val: number, record: any) => (record.type === "Not Collected" ? "-" : <UnitsConverter value={val} fromUnits="gallons" />),
      });

    if (!item.irrigationAcresNotApplicable && !item.quantityNotApplicable) {
      placesOfUseColumns.push({
        title: "Inches / Acre",
        dataIndex: "value",
        key: "value",
        align: "right",
        render: (val: number, record: any) => <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={val} devideBy={item.authorizedIrrigatedAcres} />,
      });
    }

    placesOfUseColumns.push({
      title: "Gallons",
      dataIndex: "value",
      key: "value",
      align: "right",
      render: (val: number, record: any) => <UnitsConverter fromUnits="gallons" toUnits="gallons" value={val} />,
    });

    return (
      <Card className="removeBoxShadow removeMargin media" bordered={false}>
        <Title style={{ marginTop: 5, marginBottom: 5 }} level={5}>
          Meter Config History
        </Title>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Table
            style={{ maxWidth: "75%", marginBottom: 10 }}
            rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
            rowKey={(row: any) => row.id}
            size="small"
            columns={meterConfigColumns}
            dataSource={meterConfigHistory}
            pagination={false}
          />
          <br />
          <Table
            style={{ width: "100%" }}
            rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
            rowKey={(row: any) => row.id}
            size="small"
            columns={columns}
            dataSource={readingsForTheYear}
            pagination={false}
            expandable={{
              showExpandColumn: false,
              expandedRowKeys: readingsForTheYear.filter((reading: any) => reading.notes !== null || reading.extraNote !== undefined).map((reading: any) => reading.id),
              expandedRowClassName: (record: any, index: number) => (index % 2 === 0 ? "table-row-light" : "table-row-dark"),
              expandedRowRender: (record: any) => renderExpandData(record),
            }}
            summary={(pageData) => {
              let showAdjustment = false;
              item.waterRightAdjustments?.map((adjustments: any) => {
                if (adjustments.year === reportConfig.year) {
                  showAdjustment = (adjustments.adjustments ?? []).filter((x: any) => x.type === "adjustment").length > 0;
                }
              });

              // const startColumn = 7;
              // const col1 = 7;
              // const col2 = 8;
              // const col3 = selectedCompany?.settings?.metric !== "gallons" ? 9 : 8;
              // const col4 = !item.irrigationAcresNotApplicable ? (selectedCompany?.settings?.metric !== "gallons" ? 10 : 9) : 8;
              // let totalColumns = 10;

              const gpmAdjust = reportConfig.showGPM ? 0 : 0;

              const startColumn = 7 + gpmAdjust;
              const col1 = 7 + gpmAdjust;
              const col2 = 8 + gpmAdjust;
              const col3 = selectedCompany?.settings?.metric !== "gallons" ? 9 + gpmAdjust : 8 + gpmAdjust;
              const col4 = !item.irrigationAcresNotApplicable ? (selectedCompany?.settings?.metric !== "gallons" ? 10 + gpmAdjust : 9 + gpmAdjust) : 8 + gpmAdjust;
              let totalColumns = 10 + gpmAdjust;

              // const startColumn = 8;
              // const col1 = 8;
              // const col2 = 9;
              // const col3 = selectedCompany?.settings?.metric !== "gallons" ? 10 : 9;
              // const col4 = !item.irrigationAcresNotApplicable ? (selectedCompany?.settings?.metric !== "gallons" ? 11 : 10) : 9;
              // let totalColumns = 11;
              if (item.irrigationAcresNotApplicable) totalColumns -= 1;
              if (selectedCompany?.settings?.metric === "gallons") totalColumns -= 1;
              /**
               * Col 0, span 7
               * Col 1 => index 7 (always 7)
               * Col 2 => if company settings is not gallons => not display, other index 8
               * Col 3 => if not applicable, don't display else if company settings is not gallons => index 9, index 8
               * Col 4 => if if irrigation not applicable, then
               */

              return (
                <>
                  <Table.Summary.Row className="moveDown">
                    <Table.Summary.Cell index={0} colSpan={startColumn}></Table.Summary.Cell>
                    <Table.Summary.Cell index={col1} align="right">
                      <b>Sub Total:</b>
                    </Table.Summary.Cell>
                    {selectedCompany?.settings?.metric !== "gallons" && (
                      <Table.Summary.Cell index={col2} align="right">
                        <b>
                          <UnitsConverter fromUnits="gallons" value={totalsForTheYear?.totalConsumed ?? 0} />
                        </b>
                      </Table.Summary.Cell>
                    )}
                    {!item.irrigationAcresNotApplicable && (
                      <Table.Summary.Cell index={col3} align="right">
                        <b>
                          <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={totalsForTheYear?.totalConsumed ?? 0} devideBy={item.authorizedIrrigatedAcres} />
                        </b>
                      </Table.Summary.Cell>
                    )}
                    <Table.Summary.Cell index={col4} align="right">
                      <b>
                        <UnitsConverter fromUnits="gallons" toUnits="gallons" value={totalsForTheYear?.totalConsumed ?? 0} />
                      </b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>

                  {item.reductionWells
                    ?.sort((a: any, b: any) => a.wellName.localeCompare(b.wellName))
                    .map((reductionWell: any) => {
                      const reducedTotalsForTheYear = reductionWell.readings?.find((x: any) => x.year === reportConfig.year)?.totalConsumed ?? 0;
                      return (
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={startColumn}></Table.Summary.Cell>
                          <Table.Summary.Cell index={col1} align="right">
                            {reductionWell.reductionWellName} (Deduction):
                          </Table.Summary.Cell>
                          {selectedCompany?.settings?.metric !== "gallons" && (
                            <Table.Summary.Cell index={col2} align="right">
                              -
                              <UnitsConverter fromUnits="gallons" value={reducedTotalsForTheYear} />
                            </Table.Summary.Cell>
                          )}
                          {!item.irrigationAcresNotApplicable && (
                            <Table.Summary.Cell index={col3} align="right">
                              -
                              <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={reducedTotalsForTheYear} devideBy={item.authorizedIrrigatedAcres} />
                            </Table.Summary.Cell>
                          )}
                          <Table.Summary.Cell index={col4} align="right">
                            -
                            <UnitsConverter fromUnits="gallons" toUnits="gallons" value={reducedTotalsForTheYear} />
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      );
                    })}

                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={startColumn}></Table.Summary.Cell>
                    <Table.Summary.Cell index={col1} align="right">
                      <b>Well Total:</b>
                    </Table.Summary.Cell>
                    {selectedCompany?.settings?.metric !== "gallons" && (
                      <Table.Summary.Cell index={col2} align="right">
                        <b>
                          <UnitsConverter fromUnits="gallons" value={totalGallonsUsedBeforeShared} />
                        </b>
                      </Table.Summary.Cell>
                    )}
                    {!item.irrigationAcresNotApplicable && (
                      <Table.Summary.Cell index={col3} align="right">
                        <b>
                          <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={totalGallonsUsedBeforeShared} devideBy={item.authorizedIrrigatedAcres} />
                        </b>
                      </Table.Summary.Cell>
                    )}
                    <Table.Summary.Cell index={col4} align="right">
                      <b>
                        <UnitsConverter fromUnits="gallons" toUnits="gallons" value={totalGallonsUsedBeforeShared} />
                      </b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>

                  {item.sharedWith
                    ?.sort((a: any, b: any) => a.wellName.localeCompare(b.wellName))
                    .map((sharedWith: any) => {
                      const sharedWithTotalsForTheYear = sharedWith.readings?.find((x: any) => x.year === reportConfig.year)?.totalConsumed ?? 0;
                      return (
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={startColumn}></Table.Summary.Cell>
                          <Table.Summary.Cell index={col1} align="right">
                            {sharedWith.wellName}:
                          </Table.Summary.Cell>
                          {selectedCompany?.settings?.metric !== "gallons" && (
                            <Table.Summary.Cell index={col2} align="right">
                              <UnitsConverter fromUnits="gallons" value={sharedWithTotalsForTheYear} />
                            </Table.Summary.Cell>
                          )}
                          {!item.irrigationAcresNotApplicable && (
                            <Table.Summary.Cell index={col3} align="right">
                              <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={sharedWithTotalsForTheYear} devideBy={item.authorizedIrrigatedAcres} />
                            </Table.Summary.Cell>
                          )}
                          <Table.Summary.Cell index={col4} align="right">
                            <UnitsConverter fromUnits="gallons" toUnits="gallons" value={sharedWithTotalsForTheYear} />
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      );
                    })}

                  {showAdjustment && (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={startColumn}></Table.Summary.Cell>
                      <Table.Summary.Cell index={col1} align="right">
                        <b>Water Right Total (Before Adjustments):</b>
                      </Table.Summary.Cell>
                      {selectedCompany?.settings?.metric !== "gallons" && (
                        <Table.Summary.Cell index={col2} align="right">
                          <b>
                            <UnitsConverter fromUnits="gallons" value={totalGallonsUsedBeforeAdjustment} />
                          </b>
                        </Table.Summary.Cell>
                      )}
                      {!item.irrigationAcresNotApplicable && (
                        <Table.Summary.Cell index={col3} align="right">
                          <b>
                            <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={totalGallonsUsedBeforeAdjustment} devideBy={item.authorizedIrrigatedAcres} />
                          </b>
                        </Table.Summary.Cell>
                      )}
                      <Table.Summary.Cell index={col4} align="right">
                        <b>
                          <UnitsConverter fromUnits="gallons" toUnits="gallons" value={totalGallonsUsedBeforeAdjustment} />
                        </b>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}

                  {item.waterRightAdjustments &&
                    item.waterRightAdjustments?.map((adjustments: any) => {
                      let elements: any[] = [];
                      if (adjustments.year === reportConfig.year) {
                        forEach(
                          (adjustments.adjustments ?? []).filter((x: any) => x.type === "adjustment"),
                          (adjustment: any) => {
                            elements.push(
                              <Table.Summary.Row style={!adjustment.completed ? { color: "red" } : {}}>
                                <Table.Summary.Cell index={0} align="left" colSpan={2}>
                                  {dayjs(adjustment.date).format(constants.dateTimeFormat)}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={2} align="left" colSpan={5}>
                                  {adjustment.notes} {!adjustment.completed ? <StatusTag status="incomplete" color="red" /> : ""}
                                </Table.Summary.Cell>
                                {adjustment.type === "transfer" ? (
                                  <Table.Summary.Cell index={col1} align="right">
                                    Adjustment (Transfer):
                                  </Table.Summary.Cell>
                                ) : adjustment.type === "penalty" ? (
                                  <Table.Summary.Cell index={col1} align="right">
                                    Adjustment (Penalty):
                                  </Table.Summary.Cell>
                                ) : (
                                  <Table.Summary.Cell index={col1} align="right">
                                    Adjustment ({adjustment.adjustmentType === "subtraction" ? <>Increase Total Used</> : <>Decrease Total Used</>}
                                    ):
                                  </Table.Summary.Cell>
                                )}
                                {selectedCompany?.settings?.metric !== "gallons" && (
                                  <Table.Summary.Cell index={col2} align="right">
                                    <UnitsConverter fromUnits="gallons" value={-adjustment.quantityInGallons} removeStyling />
                                  </Table.Summary.Cell>
                                )}
                                {!item.irrigationAcresNotApplicable && (
                                  <Table.Summary.Cell index={col3} align="right">
                                    <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={-adjustment.quantityInGallons} devideBy={item.authorizedIrrigatedAcres} removeStyling />
                                  </Table.Summary.Cell>
                                )}
                                <Table.Summary.Cell index={!item.irrigationAcresNotApplicable ? 10 : 9} align="right">
                                  <UnitsConverter fromUnits="gallons" toUnits="gallons" value={-adjustment.quantityInGallons} removeStyling />
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            );
                          }
                        );
                      }

                      return elements;
                    })}

                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={startColumn}></Table.Summary.Cell>
                    <Table.Summary.Cell index={col1} align="right">
                      <b>
                        Water Right Total
                        {showAdjustment ? " (After Adjustments)" : ""}:
                      </b>
                    </Table.Summary.Cell>
                    {selectedCompany?.settings?.metric !== "gallons" && (
                      <Table.Summary.Cell index={col2} align="right">
                        <b>
                          <UnitsConverter fromUnits="gallons" value={totalGallonsUsedAfterAdjustment} />
                        </b>
                      </Table.Summary.Cell>
                    )}
                    {!item.irrigationAcresNotApplicable && (
                      <Table.Summary.Cell index={col3} align="right">
                        <b>
                          <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={totalGallonsUsedAfterAdjustment} devideBy={item.authorizedIrrigatedAcres} />
                        </b>
                      </Table.Summary.Cell>
                    )}
                    <Table.Summary.Cell index={col4} align="right">
                      <b>
                        <UnitsConverter fromUnits="gallons" toUnits="gallons" value={totalGallonsUsedAfterAdjustment} />
                      </b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>

                  {!item.quantityNotApplicable && (
                    <>
                      <Table.Summary.Row style={{ height: 30 }}>
                        <Table.Summary.Cell index={0} colSpan={totalColumns + 1}></Table.Summary.Cell>
                      </Table.Summary.Row>
                      {!item.quantityNotApplicable &&
                        item.waterRightAdjustments &&
                        item.waterRightAdjustments?.map((adjustments: any) => {
                          let elements: any[] = [];
                          if (adjustments.year === reportConfig.year) {
                            return (adjustments.adjustments ?? []).filter((x: any) => x.type === "penalty").length > 0 ? (
                              <>
                                <Table.Summary.Row>
                                  <Table.Summary.Cell index={0} colSpan={startColumn}></Table.Summary.Cell>
                                  <Table.Summary.Cell index={col1} align="right">
                                    <b>Left on Water Right (Before Penalties):</b>
                                  </Table.Summary.Cell>
                                  {selectedCompany?.settings?.metric !== "gallons" && (
                                    <Table.Summary.Cell index={col2} align="right">
                                      <b>
                                        <UnitsConverter fromUnits="gallons" value={gallonsPossibleWithoutPenalty} />
                                      </b>
                                    </Table.Summary.Cell>
                                  )}
                                  {!item.irrigationAcresNotApplicable && (
                                    <Table.Summary.Cell index={col3} align="right">
                                      <b>
                                        <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={gallonsPossibleWithoutPenalty} devideBy={item.authorizedIrrigatedAcres} />
                                      </b>
                                    </Table.Summary.Cell>
                                  )}
                                  <Table.Summary.Cell index={col4} align="right">
                                    <b>
                                      <UnitsConverter fromUnits="gallons" toUnits="gallons" value={gallonsPossibleWithoutPenalty} />
                                    </b>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>

                                {!item.quantityNotApplicable &&
                                  item.waterRightAdjustments &&
                                  item.waterRightAdjustments?.map((adjustments: any) => {
                                    let elements: any[] = [];
                                    if (adjustments.year === reportConfig.year) {
                                      forEach(
                                        (adjustments.adjustments ?? []).filter((x: any) => x.type === "penalty"),
                                        (adjustment: any) => {
                                          elements.push(
                                            <Table.Summary.Row style={!adjustment.completed ? { color: "red" } : {}}>
                                              <Table.Summary.Cell index={0} align="left" colSpan={2}>
                                                {dayjs(adjustment.date).format(constants.dateTimeFormat)}
                                              </Table.Summary.Cell>
                                              <Table.Summary.Cell index={2} align="left" colSpan={5}>
                                                {adjustment.notes} {!adjustment.completed ? <StatusTag status="incomplete" color="red" /> : ""}
                                              </Table.Summary.Cell>
                                              <Table.Summary.Cell index={col1} align="right">
                                                Adjustment (Penalty):
                                              </Table.Summary.Cell>
                                              {selectedCompany?.settings?.metric !== "gallons" && (
                                                <Table.Summary.Cell index={col2} align="right">
                                                  <UnitsConverter fromUnits="gallons" value={-adjustment.quantityInGallons} removeStyling />
                                                </Table.Summary.Cell>
                                              )}
                                              {!item.irrigationAcresNotApplicable && (
                                                <Table.Summary.Cell index={col3} align="right">
                                                  <UnitsConverter
                                                    fromUnits="gallons"
                                                    toUnits="acreinches"
                                                    value={-adjustment.quantityInGallons}
                                                    devideBy={item.authorizedIrrigatedAcres}
                                                    removeStyling
                                                  />
                                                </Table.Summary.Cell>
                                              )}
                                              <Table.Summary.Cell index={col4} align="right">
                                                <UnitsConverter fromUnits="gallons" toUnits="gallons" value={-adjustment.quantityInGallons} removeStyling />
                                              </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                          );
                                        }
                                      );
                                    }

                                    return elements;
                                  })}

                                {!item.quantityNotApplicable && item.waterRightAdjustments?.length > 0 && (
                                  <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={startColumn}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={col1} align="right">
                                      <b>Left on Water Right (After Penalty):</b>
                                    </Table.Summary.Cell>
                                    {selectedCompany?.settings?.metric !== "gallons" && (
                                      <Table.Summary.Cell index={col2} align="right">
                                        <b>
                                          <UnitsConverter fromUnits="gallons" value={gallonsPossibleAfterPenalty} />
                                        </b>
                                      </Table.Summary.Cell>
                                    )}
                                    {!item.irrigationAcresNotApplicable && (
                                      <Table.Summary.Cell index={col3} align="right">
                                        <b>
                                          <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={gallonsPossibleAfterPenalty} devideBy={item.authorizedIrrigatedAcres} />
                                        </b>
                                      </Table.Summary.Cell>
                                    )}
                                    <Table.Summary.Cell index={col4} align="right">
                                      <b>
                                        <UnitsConverter fromUnits="gallons" toUnits="gallons" value={gallonsPossibleAfterPenalty} />
                                      </b>
                                    </Table.Summary.Cell>
                                  </Table.Summary.Row>
                                )}
                              </>
                            ) : (
                              <></>
                            );
                          }
                        })}

                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={startColumn}></Table.Summary.Cell>
                        <Table.Summary.Cell index={col1} align="right">
                          <b>
                            Left on Water Right ({item.subjectToMds ? "After MDS" : "At Beginning Of Year"}
                            ):
                          </b>
                        </Table.Summary.Cell>
                        {selectedCompany?.settings?.metric !== "gallons" && (
                          <Table.Summary.Cell index={col2} align="right">
                            <b>
                              <UnitsConverter fromUnits="gallons" value={gallonsPossibleAfterMds} />
                            </b>
                          </Table.Summary.Cell>
                        )}
                        {!item.irrigationAcresNotApplicable && (
                          <Table.Summary.Cell index={col3} align="right">
                            <b>
                              <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={gallonsPossibleAfterMds} devideBy={item.authorizedIrrigatedAcres} />
                            </b>
                          </Table.Summary.Cell>
                        )}
                        <Table.Summary.Cell index={col4} align="right">
                          <b>
                            <UnitsConverter fromUnits="gallons" toUnits="gallons" value={gallonsPossibleAfterMds} />
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>

                      {!item.quantityNotApplicable &&
                        item.waterRightAdjustments &&
                        item.waterRightAdjustments?.map((adjustments: any) => {
                          let elements: any[] = [];
                          if (adjustments.year === reportConfig.year) {
                            forEach(
                              (adjustments.adjustments ?? []).filter((x: any) => x.type === "transfer"),
                              (adjustment: any) => {
                                elements.push(
                                  <Table.Summary.Row style={!adjustment.completed ? { color: "red" } : {}}>
                                    <Table.Summary.Cell index={0} align="left" colSpan={2}>
                                      {dayjs(adjustment.date).format(constants.dateTimeFormat)}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={2} align="left" colSpan={5}>
                                      {adjustment.notes} {!adjustment.completed ? <StatusTag status="incomplete" color="red" /> : ""}
                                    </Table.Summary.Cell>
                                    {adjustment.type === "transfer" ? (
                                      <Table.Summary.Cell index={col1} align="right">
                                        Adjustment (Transfer):
                                      </Table.Summary.Cell>
                                    ) : adjustment.type === "penalty" ? (
                                      <Table.Summary.Cell index={col1} align="right">
                                        Adjustment (Penalty):
                                      </Table.Summary.Cell>
                                    ) : (
                                      <Table.Summary.Cell index={col1} align="right">
                                        Adjustment ({adjustment.adjustmentType === "subtraction" ? <>Increase Total Used</> : <>Decrease Total Used</>}
                                        ):
                                      </Table.Summary.Cell>
                                    )}
                                    {selectedCompany?.settings?.metric !== "gallons" && (
                                      <Table.Summary.Cell index={col2} align="right">
                                        <UnitsConverter fromUnits="gallons" value={-adjustment.quantityInGallons} removeStyling />
                                      </Table.Summary.Cell>
                                    )}
                                    {!item.irrigationAcresNotApplicable && (
                                      <Table.Summary.Cell index={col3} align="right">
                                        <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={-adjustment.quantityInGallons} devideBy={item.authorizedIrrigatedAcres} removeStyling />
                                      </Table.Summary.Cell>
                                    )}
                                    <Table.Summary.Cell index={col4} align="right">
                                      <UnitsConverter fromUnits="gallons" toUnits="gallons" value={-adjustment.quantityInGallons} removeStyling />
                                    </Table.Summary.Cell>
                                  </Table.Summary.Row>
                                );
                              }
                            );
                          }

                          return elements;
                        })}

                      {!item.quantityNotApplicable &&
                        item.waterRightAdjustments &&
                        item.waterRightAdjustments?.map((adjustments: any) => {
                          let elements: any[] = [];
                          if (adjustments.year === reportConfig.year) {
                            return (adjustments.adjustments ?? []).filter((x: any) => x.type === "transfer").length > 0 ? (
                              <>
                                <Table.Summary.Row>
                                  <Table.Summary.Cell index={0} colSpan={startColumn}></Table.Summary.Cell>
                                  <Table.Summary.Cell index={col1} align="right">
                                    <b>Left on Water Right (After Transfers):</b>
                                  </Table.Summary.Cell>
                                  {selectedCompany?.settings?.metric !== "gallons" && (
                                    <Table.Summary.Cell index={col2} align="right">
                                      <b>
                                        <UnitsConverter fromUnits="gallons" value={gallonsPossibleAfterTransfers} />
                                      </b>
                                    </Table.Summary.Cell>
                                  )}
                                  {!item.irrigationAcresNotApplicable && (
                                    <Table.Summary.Cell index={col3} align="right">
                                      <b>
                                        <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={gallonsPossibleAfterTransfers} devideBy={item.authorizedIrrigatedAcres} />
                                      </b>
                                    </Table.Summary.Cell>
                                  )}
                                  <Table.Summary.Cell index={col4} align="right">
                                    <b>
                                      <UnitsConverter fromUnits="gallons" toUnits="gallons" value={gallonsPossibleAfterTransfers} />
                                    </b>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                              </>
                            ) : (
                              <></>
                            );
                          }
                        })}

                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={startColumn}></Table.Summary.Cell>
                        <Table.Summary.Cell index={col1} align="right">
                          <b>
                            Water Right Total
                            {showAdjustment ? " (After Adjustments)" : ""}:
                          </b>
                        </Table.Summary.Cell>
                        {selectedCompany?.settings?.metric !== "gallons" && (
                          <Table.Summary.Cell index={col2} align="right">
                            <b>
                              - <UnitsConverter fromUnits="gallons" value={totalGallonsUsedAfterAdjustment} removeStyling />
                            </b>
                          </Table.Summary.Cell>
                        )}
                        {!item.irrigationAcresNotApplicable && (
                          <Table.Summary.Cell index={col3} align="right">
                            <b>
                              - <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={totalGallonsUsedAfterAdjustment} devideBy={item.authorizedIrrigatedAcres} />
                            </b>
                          </Table.Summary.Cell>
                        )}
                        <Table.Summary.Cell index={col4} align="right">
                          <b>
                            - <UnitsConverter fromUnits="gallons" toUnits="gallons" value={totalGallonsUsedAfterAdjustment} />
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>

                      {!item.quantityNotApplicable ? (
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={startColumn}></Table.Summary.Cell>
                          <Table.Summary.Cell index={col1} align="right">
                            <b>Left on Water Right:</b>
                          </Table.Summary.Cell>
                          {selectedCompany?.settings?.metric !== "gallons" && (
                            <Table.Summary.Cell index={col2} align="right">
                              <b>
                                <UnitsConverter fromUnits="gallons" value={gallonsLeft} />
                              </b>
                            </Table.Summary.Cell>
                          )}
                          {!item.irrigationAcresNotApplicable && (
                            <Table.Summary.Cell index={col3} align="right">
                              <b>
                                <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={gallonsLeft} devideBy={item.authorizedIrrigatedAcres} />
                              </b>
                            </Table.Summary.Cell>
                          )}
                          <Table.Summary.Cell index={col4} align="right">
                            <b>
                              <UnitsConverter fromUnits="gallons" toUnits="gallons" value={gallonsLeft} />
                            </b>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      ) : (
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={startColumn}></Table.Summary.Cell>
                          <Table.Summary.Cell index={col1} align="right">
                            <b>Water Right Total With Adjustments:</b>
                          </Table.Summary.Cell>
                          {selectedCompany?.settings?.metric !== "gallons" && (
                            <Table.Summary.Cell index={col2} align="right">
                              <b>
                                <UnitsConverter fromUnits="gallons" value={waterRightTotalWithAdjustments} />
                              </b>
                            </Table.Summary.Cell>
                          )}
                          {!item.irrigationAcresNotApplicable && (
                            <Table.Summary.Cell index={col3} align="right">
                              <b>
                                <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={waterRightTotalWithAdjustments} devideBy={item.authorizedIrrigatedAcres} />
                              </b>
                            </Table.Summary.Cell>
                          )}
                          <Table.Summary.Cell index={col4} align="right">
                            <b>
                              <UnitsConverter fromUnits="gallons" toUnits="gallons" value={waterRightTotalWithAdjustments} />
                            </b>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      )}
                    </>
                  )}
                </>
              );
            }}
          />
          <br />
          <Table
            rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
            rowKey={(row: any) => row?.name}
            columns={placesOfUseColumns}
            dataSource={finalPlacesOfUseSummary ?? []}
            pagination={false}
            size="small"
            summary={(pageData) => {
              let finalTotal = 0;

              if (finalPlacesOfUseSummary.length > 0) {
                finalTotal = finalPlacesOfUseSummary.map((x) => x.value).reduce((sum, currentValue) => sum + currentValue);
              }

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} align="right">
                      <b>Total:</b>
                    </Table.Summary.Cell>
                    {selectedCompany?.settings?.metric !== "gallons" && (
                      <Table.Summary.Cell index={1} align="right">
                        <b>
                          <UnitsConverter fromUnits="gallons" value={finalTotal ?? 0} />
                        </b>
                      </Table.Summary.Cell>
                    )}
                    {!item.irrigationAcresNotApplicable && (
                      <Table.Summary.Cell index={selectedCompany?.settings?.metric !== "gallons" ? 2 : 1} align="right">
                        <b>
                          <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={finalTotal ?? 0} devideBy={item.authorizedIrrigatedAcres} />
                        </b>
                      </Table.Summary.Cell>
                    )}
                    <Table.Summary.Cell index={!item.irrigationAcresNotApplicable ? (selectedCompany?.settings?.metric !== "gallons" ? 3 : 2) : 1} align="right">
                      <b>
                        <UnitsConverter fromUnits="gallons" toUnits="gallons" value={finalTotal ?? 0} />
                      </b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
          <br />
          {item?.permitType === "stockwater" && (
            <>
              <Table
                rowKey={(row: any) => row?.id}
                columns={headCountColumns}
                dataSource={combinedStockUsageData ?? []}
                pagination={false}
                size="small"
                footer={() => (
                  <>
                    {reportConfig.interpolate && (
                      <>
                        <b>Note: </b>Continuous usage is assumed. Therefore, water usage will be spread equally across the time between reading dates to give a more accurate breakdown of water usage.
                        <br />
                      </>
                    )}
                    {!reportConfig.interpolate && (
                      <>
                        <b>Note: </b>Allocated usage is selected. Therefore, water usage is allocated in the month it was recorded or allocated in.
                        <br />
                      </>
                    )}
                    * No value configured, thus the average head count between all configured months are used. <br />
                    If the data in this section does not populate, please ensure you have one months' head count configured, and that one reading per month has been captured.
                  </>
                )}
              />
              <br />
            </>
          )}
          {reportConfig.showNotes && notes.length > 0 && (
            <>
              <Title
                style={{
                  marginTop: 10,
                  marginBottom: 5,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                level={5}
              >
                Notes
              </Title>
              <br />
              <Table rowKey={(row: any) => row.id} size="small" columns={noteColumns} dataSource={notes} pagination={false} />
            </>
          )}
        </ConfigProvider>
      </Card>
    );
  };

  return (
    <>
      <PrintPage
        content={
          <Card
            id="generatedWellUsageReport"
            title={
              <div>
                <Space size="middle">
                  <Image
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      alignContent: "center",
                      maxHeight: 200,
                      maxWidth: 200,
                      borderRadius: 10,
                      objectFit: "contain",
                    }}
                    src="/logo.png"
                    preview={false}
                  />
                  <Space direction="vertical" size="small" style={{ display: "flex" }}>
                    <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Detailed End Of Year Water Usage Report`}</Title>
                    <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                  </Space>
                </Space>
              </div>
            }
            loading={isFetching || hasNextPage || isUsageLoading || usageHasNextPage}
            className="remove-border-and-padding-on-print"
          >
            {!(isFetching || hasNextPage || isUsageLoading || usageHasNextPage) && reportData.length === 0 && <Alert type="warning" description="The selected water rights does not have any wells" />}
            {!(isFetching || hasNextPage || isUsageLoading || usageHasNextPage) && reportData.length > 0 && (
              <>
                {reportData.map((item: any, index, array) => {
                  const snapshot = item.wrSnapshots?.find((x: any) => x.year === reportConfig.year);
                  const finalItem = { ...item, ...(snapshot ?? {}) };

                  const totalsForTheYear = finalItem.totals?.find((x: any) => x.year === reportConfig.year);

                  let totalPenalty = 0;
                  let totalAdjustment = 0;
                  let totalTransferred = 0;

                  forEach(finalItem.waterRightAdjustments ?? [], (adjustment: any) => {
                    if (adjustment.year === reportConfig.year) {
                      forEach(adjustment.adjustments ?? [], (adjustmentForYear: any) => {
                        if (adjustmentForYear.completed) {
                          if (adjustmentForYear.type === "penalty") totalPenalty += adjustmentForYear.quantityInGallons;
                          else if (adjustmentForYear.type === "transfer") totalTransferred += adjustmentForYear.quantityInGallons;
                          else if (adjustmentForYear.type === "adjustment") {
                            // Do not switch the calculation around, the values are returned as negative from the API
                            if (adjustmentForYear.adjustmentType === "subtraction") totalAdjustment -= adjustmentForYear.quantityInGallons;
                            else if (adjustmentForYear.adjustmentType === "addition") totalAdjustment -= adjustmentForYear.quantityInGallons;
                          }
                        }
                      });
                    }
                  });

                  const gallonsPossibleWithoutPenalty = convertUnits(
                    finalItem.usageQuantity && finalItem.usageQuantityUnits ? finalItem.usageQuantity : finalItem.authorizedQuantity,
                    finalItem.usageQuantity && finalItem.usageQuantityUnits ? finalItem.usageQuantityUnits : finalItem.authorizedQuantityUnits,
                    "gallons"
                  );
                  const gallonsPossibleAfterPenalty = gallonsPossibleWithoutPenalty - totalPenalty;
                  const gallonsPossibleAfterMds = Math.floor(gallonsPossibleAfterPenalty * (finalItem.subjectToMds ? 0.32 : 1));
                  const gallonsPossibleAfterTransfers = gallonsPossibleAfterMds - totalTransferred;

                  let totalSharedConsumed = 0;
                  forEach(finalItem.sharedWith ?? [], (sharedWith: any) => {
                    const sharedWithTotalsForTheYear = sharedWith.readings?.find((x: any) => x.year === reportConfig.year)?.totalConsumed ?? 0;
                    totalSharedConsumed += sharedWithTotalsForTheYear;
                  });

                  let totalReduced = 0;
                  forEach(finalItem.reductionWells ?? [], (reductionWell: any) => {
                    const reducedTotalsForTheYear = reductionWell.readings?.find((x: any) => x.year === reportConfig.year)?.totalConsumed ?? 0;
                    totalReduced += reducedTotalsForTheYear;
                  });

                  const totalGallonsUsedBeforeShared = (totalsForTheYear?.totalConsumed ?? 0) - totalReduced;
                  const totalGallonsUsedBeforeAdjustment = (totalsForTheYear?.totalConsumed ?? 0) + totalSharedConsumed - totalReduced;
                  const totalGallonsUsedAfterAdjustment = totalGallonsUsedBeforeAdjustment + totalAdjustment;
                  const gallonsLeft = gallonsPossibleAfterTransfers - totalGallonsUsedAfterAdjustment;

                  const waterRightTotal = totalGallonsUsedAfterAdjustment + totalSharedConsumed;
                  const waterRightTotalWithAdjustments = waterRightTotal;
                  const leftBefore = gallonsPossibleAfterMds - waterRightTotal;
                  const left = gallonsPossibleAfterMds - waterRightTotal;

                  let lastMeterReading = null;
                  const lastReadings = orderBy(finalItem.readings?.find((x: any) => x.year === reportConfig.year)?.readings?.filter((x: any) => x.type !== "Not Collected") ?? [], "date", "desc");
                  if (lastReadings?.length > 0) lastMeterReading = lastReadings[0];

                  let beginningMeterReading = null;
                  let reportYear = reportConfig.year;
                  while (beginningMeterReading === null) {
                    const tempReportYear = reportYear;
                    const previousYearReading = orderBy(
                      finalItem.readings?.find((x: any) => x.year === tempReportYear - 1)?.readings?.filter((x: any) => x.type !== "Not Collected") ?? [],
                      "date",
                      "desc"
                    );
                    if (previousYearReading?.length > 0) {
                      beginningMeterReading = previousYearReading[0];
                      break;
                    }
                    //Hardcode hard stop at 2010
                    else if (reportYear > 2010) {
                      reportYear--;
                    } else {
                      break;
                    }
                  }
                  if (beginningMeterReading === null && lastReadings?.length > 0) beginningMeterReading = lastReadings[lastReadings.length - 1];

                  return (
                    <>
                      <Card
                        key={finalItem.wellId}
                        title={generateTitle(finalItem.name, finalItem.sharedWith, finalItem.reductionWells, finalItem.companyId)}
                        bodyStyle={{ padding: 10 }}
                        extra={!selectedCompanyId && <CompanyLabel companyId={finalItem.companyId} />}
                      >
                        {generateHeader(
                          finalItem,
                          totalsForTheYear,
                          gallonsPossibleAfterMds,
                          gallonsPossibleAfterTransfers,
                          totalGallonsUsedAfterAdjustment,
                          gallonsLeft,
                          lastMeterReading,
                          beginningMeterReading,
                          totalPenalty,
                          totalTransferred
                        )}
                        {generateReadings(
                          finalItem,
                          totalsForTheYear,
                          totalGallonsUsedBeforeShared,
                          totalGallonsUsedBeforeAdjustment,
                          totalGallonsUsedAfterAdjustment,
                          gallonsPossibleWithoutPenalty,
                          gallonsPossibleAfterPenalty,
                          gallonsPossibleAfterMds,
                          gallonsPossibleAfterTransfers,
                          gallonsLeft,
                          waterRightTotalWithAdjustments,
                          beginningMeterReading
                        )}
                      </Card>
                      {index < array.length - 1 && <div className="page-break" />}
                    </>
                  );
                })}
              </>
            )}
            <div className="page-break" />
            {reportConfig && (
              <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
                <Descriptions bordered size="small" column={1} labelStyle={{ width: 200 }} className="removeBoxShadow removeMargin">
                  <Descriptions.Item label="Year">{reportConfig.year}</Descriptions.Item>
                  <Descriptions.Item label="Water Rights">{reportConfig.waterRights === "" ? "-" : reportConfig.waterRights}</Descriptions.Item>
                  <Descriptions.Item label="Permit Types">{reportConfig?.permitTypeNames === "" ? "-" : reportConfig?.permitTypeNames}</Descriptions.Item>
                  <Descriptions.Item label="Priority Types">{reportConfig?.priorityTypeNames === "" ? "-" : reportConfig?.priorityTypeNames}</Descriptions.Item>
                  <Descriptions.Item label="Show Notes">{reportConfig.showNotes ? "Yes" : "No"}</Descriptions.Item>
                  <Descriptions.Item label="Assume continuous usage for Stock Water calculations">{reportConfig.interpolate ? "Yes" : "No"}</Descriptions.Item>
                  <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
                </Descriptions>
              </Card>
            )}
          </Card>
        }
        isReport
      />
    </>
  );
}
