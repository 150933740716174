import { get, post, put } from "@/services/request";

export const addStock = (request: any) => {
  return post(`farm/stock`, request);
};

export const updateStock = (id: any, request: any) => {
  return put(`farm/stock/${id}/update`, request);
};

export const getStock = (id: any) => {
  return get(`farm/stock/${id}`);
};

export const getStocks = (request: { companyId?: string; searchString?: string; stockIds?: string[] }) => {
  return post(`farm/stock/list`, request);
};

export const upsertOccupancy = (request: any) => {
  return post(`farm/occupancy/upsert`, request);
};

export const listOccupancy = (request: any) => {
  return post(`farm/occupancy/list`, request);
};

export const countStock = (companyId?: string) => {
  const queryParams = companyId ? `?companyId=${companyId}` : "";

  return get(`farm/stock/count` + queryParams);
};

export const getStockUsageData = (request: { stockIds?: string[]; page: number; pageSize: number }) => {
  return post(`farm/stock/usage`, request);
};

export const getPagedStocks = (request: any) => {
  return post(`farm/stock/paged/list`, request);
};

export const getStockLookup = (request: { companyId: string; page: number; pageSize: number }) => {
  return post(`farm/stock/lookup/list`, request);
};
