import { getStocks, listOccupancy } from "@/apis/stock.api";
import { getWaterRights } from "@/apis/waterright.api";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import { Card, ConfigProvider, Descriptions, Image, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import "./GeneratedStockConfigurationReport.scss";
import "./GeneratedSummaryReport.scss";
import useStockLookupQuery from "@/queries/useStockLookupQuery";

interface Props {
  reportConfig: any;
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

export default function GeneratedStockConfigurationReport(props: Props) {
  const { reportConfig } = props;

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [loadingWaterRights, setLoadingWaterRights] = useState<boolean>(true);
  const [loadingOccupancy, setLoadingOccupancy] = useState<boolean>(true);
  const [loadingStock, setLoadingStock] = useState<boolean>(true);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [waterRights, setWaterRights] = useState<any[]>([]);
  const [occupancy, setOccupancy] = useState<any[]>([]);
  const [tempStock, setStock] = useState<any[]>([]);

  const [stockColumns, setStockColumns] = useState<any[]>([]);
  const [headCountsColumns, setHeadCountsColumns] = useState<any[]>([]);

  const [stockIds, setStockIds] = useState<string[]>([]);
  const { stockLookup: stocks } = useStockLookupQuery({});

  const stock: any[] = useMemo(() => {
    const data = tempStock.map((stock: any) => {
      const waterRightsForStock = waterRights.filter((waterRight) => stock.waterRightIds.includes(waterRight.id)).map((waterRight) => waterRight.fileNumber);

      const occupancyForStock = occupancy.filter((occupancy) => stock.id === occupancy.stockId);
      const occupanyDates = _.groupBy(occupancyForStock, "year");
      let occupancyForYear = [];

      for (const [key, value] of Object.entries(occupanyDates)) {
        const year = key;
        const valuesForYear = value;
        occupancyForYear.push({
          year: year,
          januaryHeadCount: valuesForYear.find((value) => value.month === 1)?.headCount ?? "-",
          februaryHeadCount: valuesForYear.find((value) => value.month === 2)?.headCount ?? "-",
          marchHeadCount: valuesForYear.find((value) => value.month === 3)?.headCount ?? "-",
          aprilHeadCount: valuesForYear.find((value) => value.month === 4)?.headCount ?? "-",
          mayHeadCount: valuesForYear.find((value) => value.month === 5)?.headCount ?? "-",
          juneHeadCount: valuesForYear.find((value) => value.month === 6)?.headCount ?? "-",
          julyHeadCount: valuesForYear.find((value) => value.month === 7)?.headCount ?? "-",
          augustHeadCount: valuesForYear.find((value) => value.month === 8)?.headCount ?? "-",
          septemberHeadCount: valuesForYear.find((value) => value.month === 9)?.headCount ?? "-",
          octoberHeadCount: valuesForYear.find((value) => value.month === 10)?.headCount ?? "-",
          novemberHeadCount: valuesForYear.find((value) => value.month === 11)?.headCount ?? "-",
          decemberHeadCount: valuesForYear.find((value) => value.month === 12)?.headCount ?? "-",
          averageHeadCount: +(valuesForYear.map((value) => value.headCount)?.reduce((partialSum, a) => partialSum + a ?? 0, 0) / valuesForYear?.length).toFixed(2),
        });
      }

      return {
        ...stock,
        waterRightNames: waterRightsForStock?.length > 0 ? waterRightsForStock : ["-"],
        headCounts: occupancyForYear?.length > 0 ? occupancyForYear : null,
      };
    });
    return data;
  }, [waterRights, occupancy, tempStock]);

  useEffect(() => {
    if (reportConfig?.stockIds?.length > 0) {
      setStockIds(reportConfig?.stockIds);
    } else setStockIds(stocks?.map((stock: any) => stock?.id));
  }, [stocks, reportConfig]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  useEffect(() => {
    if (stockIds) {
      refreshStock();
      refreshWaterRightsList();
      refreshOccupancyList();
    }
    // eslint-disable-next-line
  }, [stockIds]);

  useEffect(() => {
    calculateStockColumns();
    // eslint-disable-next-line
  }, [stockIds, stock, waterRights, occupancy]);

  useEffect(() => {
    calculateHeadCountsColumns();
    // eslint-disable-next-line
  }, [stockIds, stock]);

  const refreshOccupancyList = async () => {
    if (!stockIds || stockIds.length === 0) return;
    setLoadingOccupancy(true);

    const request = { stockIds: stockIds };

    const response = await listOccupancy(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setOccupancy(data.value);
      }
    }

    setLoadingOccupancy(false);
  };

  const refreshStock = async () => {
    if (!stockIds || stockIds.length === 0) return;

    setLoadingStock(true);
    const response = await getStocks({ companyId: selectedCompanyId, stockIds: stockIds });
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setStock(data.value);
      }
    }
    setLoadingStock(false);
  };

  const refreshWaterRightsList = async () => {
    setLoadingWaterRights(true);

    const request = {
      companyId: selectedCompanyId,
      isActive: undefined,
      waterRightIds: reportConfig.waterRightIds,
    };

    const response = await getWaterRights(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWaterRights(data.value);
      }
    }
    setLoadingWaterRights(false);
  };

  const calculateStockColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Stock Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        width: 300,
        render: (val: any, record: any) => (
          <>
            <div>{val}</div>
            {!selectedCompanyId && (
              <div style={{ fontSize: 11 }}>
                (<CompanyLabel companyId={record?.companyId} />)
              </div>
            )}
          </>
        ),
      },
      {
        title: "Water Rights",
        key: "waterRightNames",
        dataIndex: "waterRightNames",
        render: (val: any) => val && <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{val.map((x: any) => x).join(", ")}</div>,
      },
    ];

    setStockColumns(tempColumns);
  };

  const calculateHeadCountsColumns = () => {
    let tempColumns = [
      {
        title: "Head Counts",
        children: [
          {
            title: "Year",
            key: "year",
            dataIndex: "year",
            render: (val: any, record: any) => val,
          },
          {
            title: <>January</>,
            key: "januaryHeadCount",
            dataIndex: "januaryHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>February</>,
            key: "februaryHeadCount",
            dataIndex: "februaryHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>March</>,
            key: "marchHeadCount",
            dataIndex: "marchHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>April</>,
            key: "aprilHeadCount",
            dataIndex: "aprilHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>May</>,
            key: "mayHeadCount",
            dataIndex: "mayHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>June</>,
            key: "juneHeadCount",
            dataIndex: "juneHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>July</>,
            key: "julyHeadCount",
            dataIndex: "julyHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>August</>,
            key: "augustHeadCount",
            dataIndex: "augustHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>September</>,
            key: "septemberHeadCount",
            dataIndex: "septemberHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>October</>,
            key: "octoberHeadCount",
            dataIndex: "octoberHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>November</>,
            key: "novemberHeadCount",
            dataIndex: "novemberHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>December</>,
            key: "decemberHeadCount",
            dataIndex: "decemberHeadCount",
            render: (val: any, record: any) => val,
          },
          {
            title: <>Average</>,
            key: "averageHeadCount",
            dataIndex: "averageHeadCount",
            render: (val: any, record: any) => val,
          },
        ],
      },
    ];

    setHeadCountsColumns(tempColumns);
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedSummaryReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Stock Configuration Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
          // loading={loadingWaterRights || loadingStock}
        >
          {
            <Card title="Stock">
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                  rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  rowKey={(row: any) => row.id}
                  loading={loadingStock || loadingWaterRights}
                  columns={stockColumns}
                  dataSource={stockIds ? stock.filter((stock) => stockIds.includes(stock.id)) : []}
                  size="small"
                  pagination={false}
                  expandable={{
                    showExpandColumn: false,
                    expandedRowKeys: stockIds ? stock.filter((stock) => stock.headCounts !== null).map((stock) => stock.id) : [],
                    expandedRowClassName: (record: any, index: number) => (index % 2 === 0 ? "table-row-light" : "table-row-dark"),
                    expandedRowRender: (record: any, index: number) => {
                      const headerClassName = index % 2 === 0 ? "headerColorWhite" : "headerColorBlue";
                      return <Table className={headerClassName} dataSource={record?.headCounts} columns={headCountsColumns} pagination={false} />;
                    },
                  }}
                />
              </ConfigProvider>
            </Card>
          }
          <div className="page-break" />
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Stock">{reportConfig.stock === "" ? "-" : reportConfig.stock}</Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
}
