import { LookupLabel } from "@/components";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import useWaterRightLookups from "@/queries/useWaterRightLookups";
import useWellUsageReportQuery from "@/queries/useWellUsageReportQuery";
import { Card, ConfigProvider, Descriptions, Image, Space, Table, Typography } from "antd";
import { ColumnType } from "antd/es/table";
import { groupBy, uniqueId } from "lodash";
import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const { Title } = Typography;

interface Props {
  reportConfig: any;
}

type TableData = {
  scheme: string;
  index: any;
  id: string;
  pdiv: string;
  cin: string;
  well: string;
  waterRight: string;
  field: string;
  placeOfUseName: string;
  placeOfUseValue: any;
  companyId: string;
};

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

const GeneratedPlacesOfUseReport: FC<Props> = (props) => {
  const { reportConfig } = props;

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const { companyConfigs } = useComapnyConfigLookup();

  const { waterRights } = useWaterRightLookups();

  // const [reportData, setReportData] = useState<any[]>([]);

  const {
    wellUsageReport: reportData,
    isFetching,
    hasNextPage,
  } = useWellUsageReportQuery({
    waterRightIds: reportConfig?.waterRightIds?.length > 0 ? reportConfig?.waterRightIds : waterRights?.map((waterRight: any) => waterRight?.id),
    generatedDate: reportConfig?.generatedDate,
  });

  const tableData: any[] = useMemo(() => {
    let indx = 1;
    let finalList: TableData[] = [];

    reportData?.map((data: any, reportDataIndex: number) => {
      const well = data.name;
      const waterRight = data.waterRight;
      const field = data.field;
      const pdiv = data.pdiv ?? "-";
      const cin = data.cin ?? "-";
      const companyId = data.companyId;

      const currentYearReadings = data.readings?.find((reading: any) => reading.year === reportConfig.year);
      const newReadings = currentYearReadings?.readings?.map((reading: any) => {
        return { ...reading, placesOfUse: reading?.placesOfUse?.join(", ") };
      });

      const groups = groupBy(newReadings, "placesOfUse");

      const placesOfUseKeys = Object.keys(groups);

      let finalPlacesOfUseSummary: any[] = [];
      if (placesOfUseKeys.length > 0) {
        placesOfUseKeys.forEach((key) => {
          const group = groups[key];
          const sum = group.map((reading) => reading.consumedSinceLastReading).reduce((sum, currentVal: any) => sum + currentVal);
          finalPlacesOfUseSummary.push({
            name: key === "" ? "(Unallocated)" : key,
            value: sum,
          });
        });

        for (let index = 0; index < placesOfUseKeys.length; index++) {
          const element = finalPlacesOfUseSummary[index];

          finalList.push({
            index: index === 0 ? indx : "",
            scheme: reportDataIndex % 2 === 0 ? "light" : "dark",
            id: uniqueId(),
            pdiv: index === 0 ? pdiv : "",
            cin: index === 0 ? cin : "",
            waterRight: index === 0 ? waterRight : "",
            field: index === 0 ? field : "",
            well: index === 0 ? well : "",
            placeOfUseName: element.name,
            placeOfUseValue: element.value,
            companyId: index === 0 ? companyId : "",
          });
        }
      } else {
        finalList.push({
          index: indx,
          scheme: reportDataIndex % 2 === 0 ? "light" : "dark",
          id: uniqueId(),
          pdiv: pdiv ?? "",
          cin: cin ?? "",
          waterRight: waterRight ?? "",
          field: field ?? "",
          well: well ?? "",
          placeOfUseName: "(Unallocated)",
          placeOfUseValue: 0,
          companyId: companyId,
        });
      }

      indx++;
    });

    return finalList;
  }, [reportData]);

  const generateTable = () => {
    const columns: ColumnType<any>[] = [
      {
        title: "#",
        width: 50,
        key: "index",
        dataIndex: "index",
      },
      {
        title: (
          <>
            Water Right /<br /> File Number
          </>
        ),
        key: "waterRight",
        dataIndex: "waterRight",
        render: (val: any, record: any) => (
          <>
            <div>{val}</div>
            {!selectedCompanyId && record?.companyId !== "" && (
              <div style={{ fontSize: 11 }}>
                (<CompanyLabel companyId={record?.companyId} />)
              </div>
            )}
          </>
        ),
      },
      {
        title: "PDIV",
        key: "pdiv",
        dataIndex: "pdiv",
        width: 50,
        render: (val: any, record: any) => val,
      },
      {
        title: "CIN",
        key: "cin",
        dataIndex: "cin",
        width: 50,
        render: (val: any, record: any) => val,
      },
      {
        title: "Field Name",
        key: "field",
        dataIndex: "field",
        render: (val: any, record: any) => val,
      },
      {
        title: "Well Name",
        key: "well",
        dataIndex: "well",
        render: (val: any, record: any) => val,
      },
      {
        title: "Place of Use",
        key: "placeOfUseName",
        dataIndex: "placeOfUseName",
        render: (val: any, record: any) => val,
      },
    ];

    columns.push({
      title: <LookupLabel lookupType="meterUnits" value={selectedCompanyId ? selectedCompany?.settings?.metric : "acrefeet"} />,
      dataIndex: "placeOfUseValue",
      key: "placeOfUseValue",
      align: "right",
      render: (val: number, record: any) =>
        record.type === "Not Collected" ? "-" : <UnitsConverter value={val} fromUnits="gallons" toUnits={selectedCompanyId ? selectedCompany?.settings?.metric : "acrefeet"} />,
    });

    columns.push({
      title: "Gallons",
      dataIndex: "placeOfUseValue",
      key: "placeOfUseValue",
      align: "right",
      render: (val: number, record: any) => <UnitsConverter fromUnits="gallons" toUnits="gallons" value={val} />,
    });

    return (
      <Card title="Places of Use Report">
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Table
            rowClassName={(record, index) => (record.scheme === "light" ? "table-row-light" : "table-row-dark")}
            rowKey={(row: any) => row.id}
            dataSource={tableData ?? []}
            columns={columns}
            loading={isFetching || hasNextPage}
            size="small"
            pagination={false}
          />
        </ConfigProvider>
      </Card>
    );
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedPlacesOfUseReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Places of Use Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
        >
          {generateTable()}
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
                <Descriptions.Item label="Year">{reportConfig.year}</Descriptions.Item>
                <Descriptions.Item label="Water Rights">{reportConfig?.waterRights === "" ? "-" : reportConfig?.waterRights}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
};

export default GeneratedPlacesOfUseReport;
