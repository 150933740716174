import { getRestrictionDashboard } from "@/apis/aggregator.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

type RestrictionReportProps = {
  restrictionIds?: string[];
  generatedDate: string;
  isActive?: boolean;
};

const useRestrictionReportQuery = ({ restrictionIds, generatedDate, isActive }: RestrictionReportProps) => {
  const { profile } = useSelector((state: any) => state.user);

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const { data, isFetching, isFetched, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading } = useInfiniteQuery({
    queryKey: [
      CacheKeys.restrictions,
      CacheKeys.reports,
      {
        profileId: profile?.id,
        companyId: selectedCompanyId,
        restrictionIds,
        generatedDate,
        isActive: isActive,
      },
    ],
    queryFn: async ({ pageParam }) => {
      const response = await getRestrictionDashboard({
        page: pageParam,
        pageSize: 10,
        companyId: selectedCompanyId,
        restrictionIds: restrictionIds,
        isActive,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch restriction report data");
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    gcTime: Infinity,
    staleTime: Infinity,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage?.pagination?.hasNextPage) return undefined;
      return lastPageParam + 1;
    },
  });

  const invalidateRestrictionReport = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.restrictions, CacheKeys.reports],
    });
  }, [queryClient]);

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage && !isFetching) fetchNextPage();
  }, [hasNextPage, isFetchingNextPage, isFetching]);

  const restrictionReport = useMemo(() => (hasNextPage ? [] : data?.pages?.map((page) => page.list).flat() ?? []), [data, hasNextPage]);
  const totalItemCount = useMemo(() => (hasNextPage ? 0 : data?.pages?.[(data?.pages?.length ?? 0) - 1]?.pagination?.totalItemCount ?? 0), [data, hasNextPage]);

  return {
    restrictionReport,
    isLoading,
    fetchNextPage,
    invalidateRestrictionReport,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
    isFetched,
    totalItemCount,
  };
};

export default useRestrictionReportQuery;
