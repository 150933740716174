import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface ICommunicationState {
  faqs: string[];
  supportQueryDataState: any | undefined;
}

const initialState: ICommunicationState = {
  faqs: [],
  supportQueryDataState: undefined,
};

const communicationStore = createSlice({
  name: "communication",
  initialState,
  reducers: {
    saveFaqs: (state: ICommunicationState, action: PayloadAction<any>) => {
      state.faqs = action.payload;
    },
    saveSupportQueryData: (state: ICommunicationState, action: PayloadAction<any>) => {
      state.supportQueryDataState = action.payload;
    },
    clearSupportQueryData: (state: ICommunicationState) => {
      state.supportQueryDataState = undefined;
    },
  },
});

export const { saveFaqs, saveSupportQueryData, clearSupportQueryData } = communicationStore.actions;

export default communicationStore.reducer;
