import { WaterRightReportSelector, WellSelector } from "@/components";
import { constants } from "@/configs";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Form, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";

import GeneratedLastReadingReport from "./GeneratedLastReadingReport";
import { useSelector } from "react-redux";

const LastReadingReport: FC = () => {
  const [form] = Form.useForm();
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [year, setYear] = useState<any>(null);
  const [reportConfig, setReportConfig] = useState<any>(undefined);
  const [waterRights, setWaterRights] = useState<any[]>([]);
  const [wells, setWells] = useState<any[]>([]);

  const wellIds: any[] = useWatch("wellIds", form);
  const waterRightIds: any[] = useWatch("waterRightIds", form);
  const date: any = useWatch("date", form);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (date === null) setYear(null);
    else setYear(dayjs(date)?.year());
    // eslint-disable-next-line
  }, [date]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    const tempWells = [...(wellIds ?? [])];
    const tempWRIds = [...(waterRightIds ?? [])];

    const waterRightsForWell = wells.filter((well) => tempWRIds.includes(well.waterRightId)).map((well) => well.id);
    waterRightsForWell.forEach((well) => {
      const w = tempWells.find((w) => well === w);
      if (!w) tempWells.push(well);
    });

    const wellsForWaterRights = wells.filter((well) => tempWells.includes(well.id)).map((well) => well.waterRightId);
    wellsForWaterRights.forEach((waterRightId) => {
      const waterRight = tempWRIds.find((w) => w === waterRightId);
      if (!waterRight) tempWRIds.push(waterRightId);
    });

    const orderWells = wells
      ?.filter((well) => wellIds?.includes(well.id))
      .map((well) => well.name)
      .sort((a, b) => a.localeCompare(b, "en", { numeric: true, sensitivity: "base" }));
    const orderWaterRights = waterRights
      ?.filter((waterRight) => waterRightIds?.includes(waterRight.id))
      .map((waterRight) => waterRight.fileNumber)
      .sort((a, b) => a.localeCompare(b, "en", { numeric: true, sensitivity: "base" }));

    setReportConfig({
      selectedWellNames: orderWells?.join(", "),
      selectedWaterRightNames: orderWaterRights?.join(", "),
      wellIds: tempWells,
      waterRightIds: tempWRIds,
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
      year,
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <Form.Item label="Year" name="date" style={{ margin: 0, marginBottom: 10 }} initialValue={null}>
            <DatePicker
              picker="year"
              style={{ marginRight: 30 }}
              placeholder="All Readings"
              allowClear={true}
              renderExtraFooter={() => (
                <div style={{ marginLeft: 5, paddingTop: 5, paddingBottom: 5 }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      form.setFieldValue("date", null);
                    }}
                  >
                    Clear
                  </Button>
                </div>
              )}
            />
          </Form.Item>
          <WellSelector form={form} propertyToSet={"wellIds"} label={"Wells"} placeholder="Leave blank to select all wells" style={{ margin: 0, marginBottom: 10 }} setWells={setWells} />
          <WaterRightReportSelector
            form={form}
            propertyToSet={"waterRightIds"}
            label={"Water Rights"}
            setWaterRights={setWaterRights}
            placeholder="Leave blank to select all water rights"
            style={{ margin: 0, marginBottom: 0 }}
          />
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedLastReadingReport reportConfig={reportConfig} />}
    </>
  );
};

export default LastReadingReport;
