import { PageContent, PageHeader } from "@/components";
import PageFooter from "@/components/PageComponents/PageFooter/PageFooter";
import { FC } from "react";

import "./MissionVision.scss";
import { Col, Row } from "antd";

const MissionVision: FC = () => {
  return (
    <div id="mission-vision">
      <PageHeader title="Vision And Mission" backgroundImageUri="images/VandWater-Banner.png" />
      <PageContent
        content={
          <div>
            <div className="center">
              <h2 style={{ textAlign: "center" }}>
                <span className="dipl_text_stack dipl_pre_text_stack">
                  <span className="dipl_pre_text">Our Vision</span>
                </span>
              </h2>
            </div>
            <div className="center">
              <p style={{ maxWidth: 400 }} className="para_style">
                To enhance people's lives and the environment though innovative and sustainable farming and manufacturing practices.
              </p>
            </div>
            <div className="center">
              <h2 style={{ textAlign: "center" }}>
                <span className="dipl_text_stack dipl_pre_text_stack">
                  <span className="dipl_pre_text">Our Mission</span>
                </span>
              </h2>
            </div>
            <Row gutter={[100, 100]}>
              <Col span={12}>
                <div className="center">
                  <h2 style={{ textAlign: "center" }}>
                    <span className="dipl_text_stack dipl_pre_text_stack">
                      <span className="dipl_pre_text">OUR CUSTOMERS</span>
                    </span>
                  </h2>
                </div>
                <div className="center">
                  <p style={{ maxWidth: 400 }} className="para_style">
                    To be the most customer focused agriculture and consumer products company in the world.
                  </p>
                </div>
              </Col>
              <Col span={12}>
                <div className="center">
                  <h2 style={{ textAlign: "center" }}>
                    <span className="dipl_text_stack dipl_pre_text_stack">
                      <span className="dipl_pre_text">OUR SUPPLIERS</span>
                    </span>
                  </h2>
                </div>
                <div className="center">
                  <p style={{ maxWidth: 400 }} className="para_style">
                    To provide the best strategic partnership opportunity for our suppliers.
                  </p>
                </div>
              </Col>
            </Row>
            <Row gutter={[100, 100]}>
              <Col span={12}>
                <div className="center">
                  <h2 style={{ textAlign: "center" }}>
                    <span className="dipl_text_stack dipl_pre_text_stack">
                      <span className="dipl_pre_text">OUR TEAM</span>
                    </span>
                  </h2>
                </div>
                <div className="center">
                  <p style={{ maxWidth: 400 }} className="para_style">
                    To provide our employees with outstanding personal and career growth opportunities while enabling them to continue the lifelong journey of learning and personal development.
                  </p>
                </div>
              </Col>
              <Col span={12}>
                <div className="center">
                  <h2 style={{ textAlign: "center" }}>
                    <span className="dipl_text_stack dipl_pre_text_stack">
                      <span className="dipl_pre_text">OUR PERFORMANCE</span>
                    </span>
                  </h2>
                </div>
                <div className="center">
                  <p style={{ maxWidth: 400 }} className="para_style">
                    To be the premier company in all markets served, focusing on liquidity, profitability, and growth. To continue a strategy of growth both organically and through acquisition.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        }
      />
      <PageFooter />
    </div>
  );
};

export default MissionVision;
