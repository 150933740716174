import { Tag } from "antd";
import WaterRightTag from "@/components/WaterRightTag/WaterRightTag";
import { FC } from "react";

interface Props {
  tags?: any[];
}

const CompanyTagList: FC<Props> = (props) => {
  const { tags } = props;
  return <div>{tags?.map((tag) => <Tag id="companyTag">{tag}</Tag>)}</div>;
};

export default CompanyTagList;
