import RestrictionSelector from "@/components/ReportSelectors/RestrictionSelector";
import { constants } from "@/configs";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Form, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import GeneratedRestrictionConfigurationReport from "./GeneratedRestrictionConfigurationReport";

const RestrictionConfigurationReport: FC = () => {
  const [form] = Form.useForm();

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [reportConfig, setReportConfig] = useState<any>(undefined);

  const restrictionIds: any[] = useWatch("restrictionIds", form);
  const [restrictions, setRestrictions] = useState<any[]>([]);
  const [isActive, setIsActive] = useState<boolean | null>(true);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    const tempRestrictionIds: any[] = restrictionIds;

    setReportConfig({
      restrictionIds: tempRestrictionIds ?? [],
      restrictions:
        restrictions
          ?.filter((restriction) => restrictionIds?.includes(restriction?.id))
          ?.map((restriction) => restriction?.name)
          ?.join(", ") ?? [],
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
      active: isActive,
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <RestrictionSelector
            setActive={setIsActive}
            form={form}
            propertyToSet={"restrictionIds"}
            label={"Restrictions"}
            placeholder="Leave blank to select all restrictions"
            style={{ margin: 0, marginBottom: 10 }}
            setRestrictions={setRestrictions}
          />
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedRestrictionConfigurationReport reportConfig={reportConfig} />}
    </>
  );
};

export default RestrictionConfigurationReport;
