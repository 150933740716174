import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Divider, Form, Select, Space, Switch, Tag } from "antd";
import { useWatch } from "antd/es/form/Form";
import { getWells } from "@/apis/well.api";
import { StatusTag, WellSelector } from "@/components";
import { constants } from "@/configs";
import dayjs from "dayjs";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GeneratedEndOfYearMeterReadingReport from "./GeneratedEndOfYearMeterReadingReport";

const { RangePicker } = DatePicker;

const EndOfYearMeterReadingReport: FC = () => {
  const [form] = Form.useForm();
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [wells, setWells] = useState<any[]>([]);
  const [years, setYears] = useState<any[]>([]);
  const [reportConfig, setReportConfig] = useState<any>(undefined);

  const wellIds: any[] = useWatch("wellIds", form);
  const dates: any[] = useWatch("date", form);

  const currentYear = dayjs();
  const previousYear = dayjs().add(-1, "year");

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    setYears(dates?.map((date) => dayjs(date).year()));
    // eslint-disable-next-line
  }, [dates]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    const tempWells = [...(wellIds ?? [])];

    const orderWells = wells
      ?.filter((well) => tempWells?.includes(well.id))
      .map((well) => well.name)
      .sort((a, b) => a.localeCompare(b, "en", { numeric: true, sensitivity: "base" }));

    setReportConfig({
      selectedWellNames: orderWells?.join(", ") ?? "-",
      wellIds: tempWells.length > 0 ? tempWells : wells?.map((well) => well.id),
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
      years,
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {wells?.find((well) => well?.id === value)?.name}
      </Tag>
    );
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <Form.Item label="Year Range" name="date" style={{ margin: 0, marginBottom: 10 }} initialValue={[previousYear, currentYear]} required>
            <RangePicker allowClear={false} picker="year" disabledDate={(d) => d.isAfter(`${dayjs().year()}-12-31`) || d.isBefore("2020-01-01")} />
          </Form.Item>
          <WellSelector form={form} propertyToSet={"wellIds"} label={"Wells"} placeholder="Leave blank to select all wells" style={{ margin: 0, marginBottom: 10 }} setWells={setWells} />
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedEndOfYearMeterReadingReport reportConfig={reportConfig} />}
    </>
  );
};

export default EndOfYearMeterReadingReport;
