import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import useGroupingLookupQuery from "@/queries/useGroupingLookupQuery";
import useStockLookupQuery from "@/queries/useStockLookupQuery";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { Button, Divider, Form, FormInstance, Select, Space, Tag } from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";

type GroupingSelectorProps = {
  placeholder?: string;
  form: FormInstance<any> | undefined;
  style?: React.CSSProperties;
  propertyToSet: string;
  label: string;
  setGroupings: any;
};

const GroupingSelector: FC<GroupingSelectorProps> = ({ placeholder, form, style, propertyToSet, label, setGroupings }) => {
  const [groupingDownRef, groupingDownEntry] = useIntersectionObserver();

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const { groupingLookup, invalidateGroupingLookup, fetchNextPage, hasNextPage, isFetched, isFetching, isFetchingNextPage, isLoading, totalItemCount } = useGroupingLookupQuery();

  const { companyConfigs } = useComapnyConfigLookup();

  useEffect(() => {
    if (groupingDownEntry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line
  }, [groupingDownEntry?.isIntersecting, hasNextPage]);

  useEffect(() => {
    handleReset();
  }, [selectedCompanyId]);

  useEffect(() => {
    if (groupingLookup.length > 0) {
      setGroupings(groupingLookup);
    }
    // eslint-disable-next-line
  }, [groupingLookup]);

  const handleReset = () => {
    form!.setFieldValue(propertyToSet, []);
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {groupingLookup?.find((grouping) => grouping?.id === value)?.name}
      </Tag>
    );
  };

  return (
    <Form.Item label={label} name={propertyToSet} style={style}>
      <Select
        allowClear
        mode={"multiple"}
        loading={isLoading || isFetching}
        tagRender={tagRender}
        placeholder={placeholder}
        showSearch
        filterOption={(input, option) => {
          const searchVal = input?.toLowerCase();
          let list: any[] = [];
          groupingLookup?.forEach((group) => {
            let match: boolean = false;

            if (group?.name?.toLowerCase()?.includes(searchVal)) match = true;

            if (match) list.push(group?.id);
          });
          return list.includes(option?.value) ?? [];
        }}
        optionFilterProp="value"
        maxTagCount={3}
        disabled={isLoading}
        options={groupingLookup?.map((grouping: any) => {
          return {
            value: grouping.id,
            label: grouping.name,
          };
        })}
        optionRender={(option, { index }) => {
          if (option.value === "loading") return null;
          const grouping = groupingLookup?.find((grouping) => grouping.id === option?.value);
          if (!grouping) return null;

          return (
            <div key={grouping.id}>
              {index === (groupingLookup?.length ?? 0) - 1 ? <span ref={groupingDownRef} /> : null}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  // paddingBottom: 5,
                }}
              >
                {grouping?.name}
              </div>
              {!selectedCompanyId && (
                <div style={{ fontWeight: "normal", fontSize: 11, paddingTop: 0, paddingBottom: 5 }}>({companyConfigs?.find((company: any) => company?.id === grouping?.companyId)?.name})</div>
              )}
            </div>
          );
        }}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <Space style={{ padding: "0 8px 4px" }}>
              <Button
                danger
                onClick={() => {
                  handleReset();
                }}
              >
                Clear List
              </Button>
            </Space>
          </>
        )}
      ></Select>
    </Form.Item>
  );
};

export default GroupingSelector;
