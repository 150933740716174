import { updateWellReading, updateWellReadingImages } from "@/apis/wellreading.api";
import { constants } from "@/configs";
import useWellLookups from "@/queries/useWellLookups";
import { CloseOutlined, LeftCircleFilled, RightCircleFilled, RotateLeftOutlined, RotateRightOutlined, ThunderboltOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { message, Modal, Space } from "antd";
import { FC, useEffect, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

interface ReadingImagesModalProps {
  wellId: string;
  readingData: any;
  imageIds: any[];
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}

const ReadingImagesModal: FC<ReadingImagesModalProps> = (props) => {
  const { imageIds, open, onCancel, onSuccess, wellId, readingData } = props;

  const { well } = useWellLookups(wellId);

  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  const [imageIndex, setImageIndex] = useState<number | undefined>(undefined);
  const [imageToView, setImageToView] = useState("");

  const [originalImages, setOriginalImages] = useState<any[]>([]);

  const [angles, setAngles] = useState<any[]>([]);

  useEffect(() => {
    if (imageIds.length > 0 && imageIndex === undefined) {
      let images: any[] = [];
      setImageIndex(0);
      setImageToView(`${constants.baseApiUrl}/well/image/${imageIds[0]}/download`);
      imageIds?.map((imageId: string) => images.push({ id: imageId, angle: 0 }));

      setOriginalImages(images);
    }
    // eslint-disable-next-line
  }, [imageIds, imageIndex]);

  useEffect(() => {
    if (angles.length === 0) {
      const angleList = imageIds.map((id: any) => {
        return { id: id, angle: 0 };
      });
      setAngles(angleList);
    } else {
      let newList: any[] = [];
      imageIds.map((id) => {
        const found = angles?.find((angleDto) => angleDto?.id === id);
        if (found) newList.push(found);
        else newList.push({ id: id, angle: 0 });
      });

      setAngles(newList);
    }
    // eslint-disable-next-line
  }, [imageIds, angles]);

  useEffect(() => {
    let match: boolean = false;

    if (originalImages.length > 0 && angles.length > 0) {
      originalImages.forEach((originalImages: any, index: number) => {
        if (originalImages.angle !== angles[index].angle) {
          match = true;
        }
      });

      if (match) setDisabled(false);
      else setDisabled(true);
    }
  }, [originalImages, angles]);

  const handleLeftClick = () => {
    if (imageIndex && imageIndex === 0) return;
    else if (imageIndex !== undefined && imageIndex > 0) {
      setImageIndex(imageIndex - 1);
      setImageToView(`${constants.baseApiUrl}/well/image/${imageIds[imageIndex - 1]}/download`);
    }
  };

  const handleRightClick = () => {
    if (imageIndex && imageIndex === imageIds.length - 1) return;
    else if (imageIndex !== undefined && imageIndex < imageIds.length - 1) {
      setImageIndex(imageIndex + 1);
      setImageToView(`${constants.baseApiUrl}/well/image/${imageIds[imageIndex + 1]}/download`);
    }
  };

  const handleSubmitImagesData = async () => {
    setLoading(true);
    let images: any[] = [];
    let newImageData: any[] = [];
    let newImageIds: any[] = [];
    angles.map((angleDto) => {
      if (angleDto.angle !== 0) images.push({ imageId: angleDto.id, angle: angleDto.angle });
    });

    if (images.length > 0) {
      const imageResponse = await updateWellReadingImages({ images: images });
      if (imageResponse.ok) {
        const data = await imageResponse.json();
        newImageData = data.value;
      }
    }

    readingData.imageIds.map((imageId: any) => {
      const foundImage = newImageData.find((image: any) => image.oldId === imageId);
      if (foundImage) newImageIds.push(foundImage.newId);
      else newImageIds.push(imageId);
    });

    const response = await updateWellReading(readingData.id, {
      ...readingData,
      imageIds: newImageIds,
    });
    if (response.ok) {
      onSuccess && onSuccess();
    } else {
      message.error("Failed to update the meter images");
    }

    setLoading(false);
  };

  return (
    <Modal
      width={1200}
      open={open}
      title={`Well - ${well?.name} Images`}
      okText="Save"
      cancelText="Cancel"
      onOk={handleSubmitImagesData}
      onCancel={onCancel}
      closable={false}
      cancelButtonProps={{
        icon: <CloseOutlined />,
        disabled: loading,
        loading: loading,
      }}
      okButtonProps={{
        icon: <ThunderboltOutlined />,
        disabled: disabled || loading,
        loading: loading,
      }}
    >
      {imageToView !== "" && (
        <TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0}>
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <>
              <TransformComponent contentStyle={{ height: 800, width: 1200 }}>
                <img
                  src={imageToView}
                  alt="noimage"
                  style={{
                    maxWidth: 800,
                    maxHeight: 1200,
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    transform: `rotate(${angles.find((angleDto) => angleDto.id === imageToView.split("/")[imageToView.split("/").length - 2])?.angle}deg)`,
                  }}
                />
              </TransformComponent>
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Space style={{ marginLeft: -10 }}>
                  {imageIds.length > 1 && (
                    <LeftCircleFilled
                      onClick={() => {
                        handleLeftClick();
                        resetTransform();
                      }}
                      style={{ fontSize: 30 }}
                    />
                  )}
                  <ZoomOutOutlined onClick={() => zoomOut()} style={{ fontSize: 30 }} />
                  <RotateLeftOutlined
                    onClick={() => {
                      setAngles((oldValues: any[]) => {
                        let newValues = [...oldValues];
                        const id = imageToView.split("/")[imageToView.split("/").length - 2];
                        const index = oldValues?.findIndex((x) => x?.id === id);

                        newValues[index].angle = (newValues[index].angle - 90) % 360 === 0 ? 0 : newValues[index].angle - 90;
                        return newValues;
                      });
                    }}
                    style={{ fontSize: 30 }}
                  />
                  <RotateRightOutlined
                    onClick={() => {
                      setAngles((oldValues: any[]) => {
                        let newValues = [...oldValues];
                        const id = imageToView.split("/")[imageToView.split("/").length - 2];
                        const index = oldValues?.findIndex((x) => x?.id === id);

                        newValues[index].angle = (newValues[index].angle + 90) % 360 === 0 ? 0 : newValues[index].angle + 90;
                        return newValues;
                      });
                    }}
                    style={{ fontSize: 30 }}
                  />
                  <ZoomInOutlined onClick={() => zoomIn()} style={{ fontSize: 30 }} />
                  {imageIds.length > 1 && (
                    <RightCircleFilled
                      onClick={() => {
                        handleRightClick();
                        resetTransform();
                      }}
                      style={{ fontSize: 30 }}
                    />
                  )}
                </Space>
              </div>
            </>
          )}
        </TransformWrapper>
      )}
    </Modal>
  );
};

export default ReadingImagesModal;
