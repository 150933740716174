import { CloseOutlined, ThunderboltOutlined, WarningOutlined } from "@ant-design/icons";
import { Alert, Modal } from "antd";
import { FC } from "react";

interface Props {
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}

const DisableWarningsModalState: FC<Props> = (props) => {
  const { open, onCancel, onSuccess } = props;

  return (
    <Modal
      width={"800px"}
      open={open}
      okText="I Acknowledge"
      title={"Confirm Disable Warnings"}
      centered
      maskClosable={false}
      cancelButtonProps={{ icon: <CloseOutlined /> }}
      okButtonProps={{ icon: <ThunderboltOutlined /> }}
      onOk={onSuccess}
      onCancel={onCancel}
    >
      <Alert
        showIcon
        icon={<WarningOutlined />}
        message="By disabling warnings, you take full responsiblity for managing your own water right usage. The system will not send any warning notifications for this water right."
        description="Are you sure you wish to disable warnings for this waterright?"
        type="error"
      />
    </Modal>
  );
};

export default DisableWarningsModalState;
