import { Button, Card, Space } from "antd";
import { generateBillingBreakdownReport, generateBillingReport } from "@/apis/aggregator.api";
import { FC, useState } from "react";
import { openReportFromGeneratedFile } from "@/services/utils";

const BillingReport: FC = () => {
  const [generatingBillingReport, setGeneratingBillingReport] = useState<boolean>(false);
  const [generatingBillingBreakdownReport, setGeneratingBillingBreakdownReport] = useState<boolean>(false);

  const downloadBillingReport = async () => {
    setGeneratingBillingReport(true);

    const request = {};

    const response = await generateBillingReport(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        openReportFromGeneratedFile(data.value);
      }
    }

    setGeneratingBillingReport(false);
  };

  const downloadBillingBreakdownReport = async () => {
    setGeneratingBillingBreakdownReport(true);

    const request = {};

    const response = await generateBillingBreakdownReport(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        openReportFromGeneratedFile(data.value);
      }
    }

    setGeneratingBillingBreakdownReport(false);
  };

  return (
    <>
      <Card title="Billing Report" bodyStyle={{ padding: 12, margin: 0 }}>
        <Space>
          <Button loading={generatingBillingReport} disabled={generatingBillingReport} type="primary" onClick={(e) => downloadBillingReport()}>
            Download Billing Report
          </Button>
          {/* <Button
                    loading={generatingBillingBreakdownReport}
                    disabled={generatingBillingBreakdownReport}
                    type="primary"
                    onClick={e => downloadBillingBreakdownReport()}
                >
                    Download Billing Breakdown Report
                </Button> */}
        </Space>
      </Card>
    </>
  );
};

export default BillingReport;
