import { Button, Modal, Popconfirm, Space, Spin, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { IoMailOpenOutline, IoMailUnread } from "react-icons/io5";
import useCustomNavigate from "@/services/useCustomNavigate";
import { ClearOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useAppDispatch } from "@/stores";
import { saveSelectedCompanyId } from "@/stores/company.store";
import { clearNotifications, putMarkPushAsRead, putMarkPushAsUnRead } from "@/apis/communication.api";
import { constants, routes } from "@/configs";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import CacheKeys from "@/types/CacheKeys";

interface Props {
  refreshNotifications: () => void;
  selectedCompanyId: any;
  notification: any;
}

export default function Notification(props: Props) {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { selectedCompanyId, notification, refreshNotifications } = props;

  const { navigate } = useCustomNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [read, setRead] = useState<boolean>(!!notification?.readAt);

  const switchToCompany = (selectedCompanyId: any, companyId: any, callback: () => void) => {
    if (selectedCompanyId === companyId) callback();
    else {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        title: "Warning",
        content: (
          <>
            <Typography style={{ marginBottom: 10 }}>You are about to switch to a different company.</Typography>
            <Typography>Are you sure you want to continue?</Typography>
          </>
        ),
        okText: "Continue",
        onOk: () => {
          dispatch(saveSelectedCompanyId(companyId));
          setTimeout(callback, 50);
        },
        cancelText: "Cancel",
        onCancel() {},
      });
    }
  };

  const generateActions = (selectedCompanyId: any, notification: any) => {
    const buttons: JSX.Element[] = [];

    if (notification?.data?.waterRightId)
      buttons.push(
        <Button
          disabled={loading}
          onClick={() => {
            switchToCompany(selectedCompanyId, notification.companyId, () => {
              markAsRead({ pushIds: [notification.id] });
              navigate(routes.waterRightView, {
                id: notification.data.waterRightId,
              });
            });
          }}
        >
          View Water Right
        </Button>
      );

    if (notification?.data?.restrictionId)
      buttons.push(
        <Button
          disabled={loading}
          onClick={() => {
            switchToCompany(selectedCompanyId, notification.companyId, () => {
              markAsRead({ pushIds: [notification.id] });
              navigate(routes.restrictionView, {
                id: notification.data.restrictionId,
              });
            });
          }}
        >
          View Restriction
        </Button>
      );

    if (notification?.data?.wellId)
      buttons.push(
        <Button
          disabled={loading}
          onClick={() => {
            switchToCompany(selectedCompanyId, notification.companyId, () => {
              markAsRead({ pushIds: [notification.id] });
              navigate(routes.wellView, { id: notification.data.wellId });
            });
          }}
        >
          View Well / Meter
        </Button>
      );

    return buttons;
  };

  const markAsRead = async (pushIds: any) => {
    setLoading(true);

    const response = await putMarkPushAsRead(pushIds);
    if (response.ok) {
      setRead(true);
      queryClient.invalidateQueries({
        queryKey: [CacheKeys.unreadNotificationCount],
      });
    } else {
      message.error("Failed to make this message as read");
    }

    setLoading(false);
  };

  const markAsUnread = async (pushIds: any) => {
    setLoading(true);

    const response = await putMarkPushAsUnRead(pushIds);
    if (response.ok) {
      message.success("Successfully marked message as unread");
      setRead(false);
      queryClient.invalidateQueries({
        queryKey: [CacheKeys.unreadNotificationCount],
      });
    } else {
      message.error("Failed to make this message as unread");
    }

    setLoading(false);
  };

  const clearNotification = async (request: any) => {
    setLoading(true);

    const response = await clearNotifications(request);
    if (response.ok) {
      refreshNotifications();
      message.success("Successfully cleared notification");
      queryClient.invalidateQueries({
        queryKey: [CacheKeys.unreadNotificationCount],
      });
    } else message.error("Failed to clear notification");

    setLoading(false);
  };

  useEffect(() => {
    setRead(!!notification?.readAt);
  }, [notification?.readAt]);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        opacity: read ? 0.7 : 1,
      }}
    >
      <div>
        <Typography.Title
          level={5}
          style={{
            margin: 0,
            paddingBottom: 10,
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {notification.title}{" "}
          {loading ? (
            <LoadingOutlined style={{ fontSize: "2em" }} />
          ) : read ? (
            <Space direction="horizontal">
              <Popconfirm
                title="Clear notification"
                description="Are you sure you want to clear this notification?"
                onConfirm={() => clearNotification({ ids: [notification.id], clearAll: false })}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined style={{ fontSize: "1.5em" }} />
              </Popconfirm>
              <Popconfirm
                title="Mark notification as unread"
                description="Are you sure you want to mark this notification as unread?"
                onConfirm={() => markAsUnread({ pushIds: [notification.id] })}
                okText="Yes"
                cancelText="No"
              >
                <IoMailOpenOutline style={{ fontSize: "2em", color: "rgba(0,0,0,0.5)", marginBottom: 4, cursor: "pointer" }} />
              </Popconfirm>
            </Space>
          ) : (
            <Space direction="horizontal">
              <Popconfirm
                title="Clear notification"
                description="Are you sure you want to clear this notification?"
                onConfirm={() => clearNotification({ ids: [notification.id], clearAll: false })}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined style={{ fontSize: "1.5em" }} />
              </Popconfirm>
              <IoMailUnread onClick={() => markAsRead({ pushIds: [notification.id] })} style={{ fontSize: "2em", color: constants.primaryColor, marginBottom: 4, cursor: "pointer" }} />
            </Space>
          )}
        </Typography.Title>
        <Typography>
          <pre style={{ margin: 0, fontFamily: "inherit" }}>{notification.body}</pre>
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: 15,
          alignItems: "flex-end",
        }}
      >
        {generateActions(selectedCompanyId, notification)}
        <Typography style={{ flex: 1, textAlign: "right", fontSize: "0.8em" }}>{dayjs(notification.createdDate).format(constants.dateTimeFormat)}</Typography>
      </div>
    </div>
  );
}
