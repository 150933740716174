import { Button, Divider, Form, FormInstance, Select, Space, Switch, Tag } from "antd";
import { listWaterRightTags } from "@/apis/waterright.api";
import StatusTag from "@/components/StatusTag/StatusTag";
import WaterRightTagList from "@/components/WaterRightTagList/WaterRightTagList";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface WaterRightSelectorProps {
  loading?: boolean;
  label: string;
  placeholder: string;
  propertyToSet: string;
  form?: FormInstance<any> | undefined;
  style?: any;
  formRef?: React.RefObject<FormInstance<any>> | undefined;
  required?: boolean;
  singleSelect?: boolean;
  showActiveFilter?: boolean;
  onLoad?: (waterRightTags: any[]) => void;
}

const WaterRightSelector: FC<WaterRightSelectorProps> = (props) => {
  const { loading, form, formRef, style, propertyToSet, label, placeholder, required = false, singleSelect = false, showActiveFilter = true, onLoad } = props;

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [waterRightTags, setWaterRightTags] = useState<any[]>([]);

  const [isActive, setIsActive] = useState<boolean | null>(true);
  const [loadingWaterRightTags, setLoadingWaterRightTags] = useState<boolean>(false);

  useEffect(() => {
    refreshWaterRightTags();
  }, [isActive]);

  const refreshWaterRightTags = async () => {
    setLoadingWaterRightTags(true);

    const request = {
      companyId: selectedCompanyId,
      isActive: isActive,
    };

    const response = await listWaterRightTags(request);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWaterRightTags(data.value);
        onLoad?.(data.value);
      }
    }
    setLoadingWaterRightTags(false);
  };

  useEffect(() => {
    if (selectedCompanyId) {
      refreshWaterRightTags();
    }
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {waterRightTags?.find((waterRight) => waterRight?.id === value)?.fileNumber}
      </Tag>
    );
  };

  const handleOnChange = (value: boolean) => {
    if (!value) setIsActive(null);
    else setIsActive(true);
  };

  return (
    <Form.Item label={label} name={propertyToSet} style={style} required={required}>
      <Select
        allowClear
        mode={singleSelect ? undefined : "multiple"}
        loading={loading || loadingWaterRightTags}
        tagRender={tagRender}
        placeholder={placeholder}
        showSearch
        filterOption={(input, option) => {
          const searchVal = input?.toLowerCase();
          let list: any[] = [];
          waterRightTags?.forEach((waterRight) => {
            const fields = waterRight?.fields ?? [];
            const wells = waterRight?.wells ?? [];

            let match: boolean = false;

            fields.forEach((field: string) => {
              if (field && field.toLowerCase().includes(searchVal)) match = true;
            });

            wells.forEach((well: string) => {
              if (well && well.toLowerCase().includes(searchVal)) match = true;
            });

            if (waterRight?.fileNumber?.toLowerCase()?.includes(searchVal)) match = true;

            if (match) list.push(waterRight.id);
          });
          return list.includes(option?.value) ?? [];
        }}
        optionFilterProp="value"
        maxTagCount={3}
        disabled={loading}
        options={
          singleSelect
            ? waterRightTags.map((waterRight: any) => {
                return {
                  value: waterRight.id,
                  label: waterRight?.fileNumber,
                };
              })
            : waterRightTags?.map((waterRight: any) => {
                return {
                  value: waterRight.id,
                  label: (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {waterRight.fileNumber} <StatusTag status={waterRight.status} style={{ marginBottom: 5, marginTop: 5 }} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          paddingLeft: 0,
                          marginLeft: 0,
                        }}
                      >
                        <WaterRightTagList
                          style={{
                            marginLeft: -2,
                            marginRight: 50,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                          internalTags={waterRight.internalTags}
                          externalTags={waterRight.externalTags}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <b style={{ paddingRight: 4 }}>PDIV:</b> {waterRight?.pdiv ?? "-"}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <b style={{ paddingRight: 4 }}>CIN:</b> {waterRight?.cin ?? "-"}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <b style={{ paddingRight: 4 }}>Wells:</b> {waterRight?.wells?.join(", ")}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <b style={{ paddingRight: 4 }}>Fields:</b> {waterRight?.fields?.join(", ")}
                      </div>
                      <Divider style={{ margin: 0, padding: 0 }} />
                    </>
                  ),
                };
              })
        }
        optionRender={
          singleSelect
            ? (option) => {
                const waterRight = waterRightTags?.find((waterRight) => waterRight.id === option.value);

                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      {waterRight.fileNumber}
                    </div>
                    <div
                      style={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                        paddingLeft: 0,
                        marginLeft: 0,
                      }}
                    >
                      <StatusTag style={{ margin: 2, marginLeft: 0 }} status={waterRight.status} /> <WaterRightTagList internalTags={waterRight.internalTags} externalTags={waterRight.externalTags} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        fontSize: 12,
                      }}
                    >
                      <b style={{ paddingRight: 4 }}>PDIV:</b> {waterRight?.pdiv ?? "-"}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        fontSize: 12,
                      }}
                    >
                      <b style={{ paddingRight: 4 }}>CIN:</b> {waterRight?.cin ?? "-"}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        fontSize: 12,
                      }}
                    >
                      <b style={{ paddingRight: 4 }}>Wells:</b> {waterRight?.wells?.join(", ")}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        fontSize: 12,
                      }}
                    >
                      <b style={{ paddingRight: 4 }}>Fields:</b> {waterRight?.fields?.join(", ")}
                    </div>
                    <Divider style={{ margin: 0, padding: 0 }} />
                  </>
                );
              }
            : undefined
        }
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <Space style={{ padding: "0 8px 4px" }}>
              <Button
                onClick={() =>
                  form
                    ? form!.setFieldValue(
                        propertyToSet,
                        waterRightTags?.filter((item: any) => item !== undefined).map((waterRight) => waterRight.id)
                      )
                    : formRef?.current?.setFieldValue(
                        propertyToSet,
                        waterRightTags?.filter((item: any) => item !== undefined).map((waterRight) => waterRight.id)
                      )
                }
              >
                Select All
              </Button>
              <Button danger onClick={() => (form ? form!.setFieldValue(propertyToSet, []) : formRef?.current?.setFieldValue(propertyToSet, []))}>
                Clear List
              </Button>
              {showActiveFilter && <Switch defaultChecked onChange={handleOnChange} checkedChildren="Active Only" unCheckedChildren="All" />}
            </Space>
          </>
        )}
      ></Select>
    </Form.Item>
  );
};

export default WaterRightSelector;
