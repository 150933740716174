import { routes } from "@/configs";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useCustomNavigate from "@/services/useCustomNavigate";

export default function RedirectHandler() {
  const { navigate } = useCustomNavigate();

  const { selectedCompany } = useSelector((state: any) => state.company);
  const location = useLocation();

  useEffect(() => {
    if (location.state?._component && location.state._component.requiredAccess && !location.state._component.requiredAccess.includes(selectedCompany.userRole)) {
      navigate(routes.dashboard);
    }

    // if (location.state?._component?.path && location.state?._component.path === routes.reportList.path && selectedCompany?._companyId === "all") {
    //   navigate(routes.dashboard);
    // }
  }, [selectedCompany, location.state]);

  return <></>;
}
