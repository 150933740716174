import React from "react";
import { Breadcrumb, Card, Layout, theme } from "antd";
import { Link, Outlet, useLocation } from "react-router-dom";
import { routes } from "@/configs";
import { SingletonHooksContainer } from "react-singleton-hook";

interface Props {
  children?: React.ReactNode;
  useBackground?: boolean;
  displayBreadcrumbs?: boolean;
  style?: any;
}

const breadcrumbNameMap: Record<string, string> = {
  "/apps": "Application List",
  "/apps/1": "Application1",
  "/apps/2": "Application2",
  "/apps/1/detail": "Detail",
  "/apps/2/detail": "Detail",
  "/company-list": "Company List",
};

const Content: React.FC<Props> = (props: any) => {
  const { useBackground = true, displayBreadcrumbs = false, style } = props;
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  let customStyle: any = {
    padding: 0,
    margin: 0,
    overflowY: "auto",
  };
  if (useBackground) {
    customStyle.background = colorBgContainer;
    customStyle.padding = 24;
  }

  return (
    <Layout.Content
      id="contentContainer"
      style={{
        ...(customStyle ?? {}),
        ...(style ?? {}),
        overflow: "auto",
      }}
    >
      <Outlet />
    </Layout.Content>
  );
};

export default Content;
