import useWaterRightLookups from "@/queries/useWaterRightLookups";

type WaterRightLabelProps = {
  waterRightId: string;
};

export default function WaterRightLabel(props: WaterRightLabelProps) {
  const { waterRightId } = props;
  const { waterRight } = useWaterRightLookups(waterRightId);

  if (waterRight) return <>{waterRight.fileNumber}</>;
  else return <>Loading...</>;
}
