import { getFields } from "@/apis/field.api";
import { getWaterRightSnapsotList, getWaterRights, syncWaterRightCalculation } from "@/apis/waterright.api";
import { getEndOfYearReadings, getWells } from "@/apis/well.api";
import { LookupLabel } from "@/components";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import RemainingDays from "@/components/RemainingDays/RemainingDays";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import useWellLookups from "@/queries/useWellLookups";
import { Alert, Card, ConfigProvider, Descriptions, Image, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import { uniqueId } from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import "./GeneratedEndOfYearSummaryReport.scss";

interface Props {
  reportConfig: any;
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

const GeneratedEndOfYearSummaryReport: FC<Props> = (props) => {
  const { reportConfig } = props;

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const [tempWells, setWells] = useState<any[]>([]);
  const [recentWellReadings, setRecentWellReadings] = useState<any[]>([]);
  const [waterRights, setWaterRights] = useState<any[]>([]);
  const [fields, setFields] = useState<any[]>([]);
  const [tempEndOfYearWellReadings, setEndOfYearWellReadings] = useState<any[]>([]);
  const [calculations, setCalulations] = useState<any[]>([]);
  const [snapshots, setSnapshots] = useState<any[]>([]);

  const [wellIds, setWellIds] = useState<any[]>([]);
  const [waterRightIds, setWaterRightIds] = useState<any[]>([]);

  const [loadingWells, setLoadingWells] = useState<boolean>(false);
  const [loadingFields, setLoadingFields] = useState<boolean>(false);
  const [loadingWaterRights, setLoadingWaterRights] = useState<boolean>(false);
  const [loadingRecentWellReadings, setLoadingRecentWellReadings] = useState<boolean>(false);
  const [loadingCalculations, setLoadingCalculations] = useState<boolean>(false);
  const [loadingSnapshots, setLoadingSnapshots] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const { companyConfigs } = useComapnyConfigLookup();
  const { wells: wellLookups } = useWellLookups();

  const [wellColumns, setWellColumns] = useState<any[]>([]);

  const currentYear = dayjs().year();

  const wells: any[] = useMemo(() => {
    let finalList: any[] = [];

    tempWells
      ?.sort((a: any, b: any) =>
        a.waterRightId.localeCompare(b.waterRightId, "en", {
          numeric: true,
          sensitivity: "base",
        })
      )
      ?.forEach((well: any) => {
        const fieldName = fields.find((field: any) => well.fieldId === field.id)?.name;
        const waterRightFileNumber = waterRights.find((waterRight: any) => well.waterRightId === waterRight.id)?.fileNumber;

        for (let index = reportConfig?.years[1]; index >= reportConfig?.years[0]; index--) {
          const year = index;
          const currentYearLastReading = tempEndOfYearWellReadings
            ?.filter((reading: any) => reading.wellId === well.id && dayjs(reading.date).year() === year)
            ?.sort((a: any, b: any) => (dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1))?.[0];
          const currentYearFirstReading = tempEndOfYearWellReadings
            ?.filter((reading: any) => reading.wellId === well.id && dayjs(reading.date).year() === year)
            ?.sort((a: any, b: any) => (dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1))?.[0];
          const previousYearReadings = tempEndOfYearWellReadings?.filter((reading: any) => reading.wellId === well.id && dayjs(reading.date).year() === year - 1);
          const previousYearLastReading = previousYearReadings?.sort((a: any, b: any) => (dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1))?.[0];
          const snapshot = snapshots.find((snapshot) => snapshot?.waterRightId === well?.waterRightId && snapshot.year === year);
          const waterRightData = waterRights?.find((waterRight: any) => waterRight?.id === well?.waterRightId);

          finalList.push({
            id: uniqueId(),
            wellId: well.id,
            wellName: well.name,
            companyId: well?.companyId,
            year,
            fieldName,
            fieldId: well.fieldId,
            waterRightId: well?.waterRightId,
            waterRightFileNumber,
            endingReading: currentYearLastReading,
            beginningReading: previousYearLastReading ?? currentYearFirstReading,
            waterRightData: { ...waterRightData, ...(snapshot || {}) },
          });
        }
      });

    var filteredList = finalList.filter((x) => wellIds?.includes(x.wellId));

    const orderedList = filteredList.sort((a: any, b: any) => {
      let result = a?.waterRightFileNumber?.localeCompare(b?.waterRightFileNumber, "en", { numeric: true, sensitivity: "base" });
      if (result === 0)
        result = a?.fieldName?.localeCompare(b?.fieldName, "en", {
          numeric: true,
          sensitivity: "base",
        });
      if (result === 0)
        result = a?.name?.localeCompare(b?.name, "en", {
          numeric: true,
          sensitivity: "base",
        });
      if (result === 0) result = a?.year - b?.year;
      return result;
    });

    let indexCounter = 1;
    const indexedList = orderedList.map((item, index, list) => {
      return {
        ...item,
        index: item.waterRightId === list?.[index - 1]?.waterRightId && item.fieldId === list?.[index - 1]?.fieldId && item.wellId === list?.[index - 1]?.wellId ? "" : indexCounter++,
        indexCounter,
      };
    });

    return indexedList;
  }, [tempWells, recentWellReadings, waterRights, tempEndOfYearWellReadings, reportConfig, fields, snapshots]);

  useEffect(() => {
    if (reportConfig && wellIds && waterRightIds) {
      refreshWells();
      refreshWaterRightsList();
      refreshFields();
    }
    // eslint-disable-next-line
  }, [reportConfig, wellIds, waterRightIds]);

  useEffect(() => {
    if (reportConfig) {
      const wellIds = reportConfig?.wellIds;
      const waterRightIds = reportConfig?.waterRightIds;

      const waterRightWellIds = wellLookups?.filter((well: any) => waterRightIds?.includes(well?.waterRightId))?.map((well: any) => well?.id);
      const wellWaterRightIds = wellLookups?.filter((well: any) => wellIds?.includes(well?.id))?.map((well: any) => well?.waterRightId);

      const finalWellIds = new Set([...wellIds, ...waterRightWellIds]);
      const finalWaterRightIds = new Set([...waterRightIds, ...wellWaterRightIds]);

      setWellIds(Array.from(finalWellIds.values()));
      setWaterRightIds(Array.from(finalWaterRightIds.values()));
    }
  }, [reportConfig]);

  useEffect(() => {
    if (reportConfig?.years[0] < currentYear || reportConfig?.years[1] < currentYear) {
      const latestYear = 2023;
      for (let index = latestYear; index >= reportConfig?.years[0]; index--) {
        const year = index;
        refreshSnapshots(year);
      }
    }
  }, [reportConfig]);

  useEffect(() => {
    if (reportConfig) {
      refreshCalculations();
    }
    // eslint-disable-next-line
  }, [reportConfig]);

  useEffect(() => {
    if (tempWells?.length > 0) refreshEndOfYearWellReadings();
    // eslint-disable-next-line
  }, [tempWells]);

  useEffect(() => {
    if (generatingReport) {
      setTimeout(() => setGeneratingReport(false), 500);
      setSnapshots([]);
    }
    // eslint-disable-next-line
  }, [generatingReport]);

  useEffect(() => {
    calculateWellColumns();
    // eslint-disable-next-line
  }, [wells, calculations]);

  const refreshSnapshots = async (year: any) => {
    setLoadingSnapshots(true);

    const request = {
      companyId: selectedCompanyId,
      year,
      waterRightIds: waterRightIds,
    };
    const response = await getWaterRightSnapsotList(request);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setSnapshots((prevData: any[]) => {
          return [...prevData, ...data.value];
        });
      }
    }

    setLoadingSnapshots(false);
  };

  const refreshCalculations = async () => {
    setLoadingCalculations(true);
    const response = await syncWaterRightCalculation({});
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setCalulations(data.value);
      }
    }
    setLoadingCalculations(false);
  };

  const refreshWaterRightsList = async () => {
    setLoadingWaterRights(true);

    const request = {
      companyId: selectedCompanyId,
      isActive: undefined,
    };

    const response = await getWaterRights(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWaterRights(data.value);
      }
    }
    setLoadingWaterRights(false);
  };

  const refreshFields = async () => {
    setLoadingFields(true);
    const request = { companyId: selectedCompanyId };
    const response = await getFields(request);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setFields(data.value);
      }
    }
    setLoadingFields(false);
  };

  const refreshEndOfYearWellReadings = async () => {
    setLoadingRecentWellReadings(true);

    const request = {
      wellIds: wellIds,
      startYear: reportConfig?.years[0] - 1, //TODO Ryan - The backend needs to bring back the correct readings. If the last reading was taken in 2020 and we add a new reading in 2024, this will not show the 2020 as the beginning reading thus it needs to correctly bring back the readings - I have added -1 to cater for single readings in the previous year, but this will not always work, this is not a priority though, please add it to the list for maintenance when there is time to do it
      endYear: reportConfig?.years[1],
    };

    const response = await getEndOfYearReadings(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setEndOfYearWellReadings(data.value);
      }
    }

    setLoadingRecentWellReadings(false);
  };

  const refreshWells = async () => {
    setLoadingWells(true);
    const request = { companyId: selectedCompanyId };
    const response = await getWells(request);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWells(data.value);
      }
    }
    setLoadingWells(false);
  };

  const determineValue = (
    id: string,
    type: string,
    year: any,
    postfix: any = undefined,
    irrigationAcresNotApplicable: boolean | undefined = undefined,
    quantityNotApplicable: boolean | undefined = undefined,
    rateNotApplicable: boolean | undefined = undefined
  ) => {
    const existingCalculation = calculations.find((calculation) => calculation.waterRightId === id && year === calculation.year);
    if (existingCalculation) {
      if (type === "remainingUsage" && existingCalculation.quantityNotApplicable === true) return "N/A";
      else if (type === "remainingUsage" && existingCalculation.quantityNotApplicable === true) return "N/A";
      else if (type === "remainingDays" && (existingCalculation.quantityNotApplicable === true || existingCalculation.rateNotApplicable === true)) return "N/A";
      return existingCalculation[type];
    } else {
      if (type === "remainingUsage" && quantityNotApplicable === true) return "N/A";
      else if (type === "remainingDays" && (quantityNotApplicable === true || rateNotApplicable === true)) return "N/A";
      else return undefined;
    }
  };

  const calculateWaterRightAvailablePercentage = (record: any) => {
    if (record?.waterRightData?.authorizedQuantityNotApplicable === true) return "N/A";
    else return ((determineValue(record?.waterRightId, "remainingUsage", record?.year) / determineValue(record?.waterRightId, "availableQuantity", record?.year)) * 100).toFixed(2);
  };

  const calculateWellColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        key: "index",
        dataIndex: "index",
      },
      {
        title: (
          <>
            Water Right /<br /> File Number
          </>
        ),
        key: "waterRightFileNumber",
        dataIndex: "waterRightFileNumber",
        render: (val: any, record: any, index: number) =>
          index > 0 ? (
            record.waterRightId === wells?.[index - 1]?.waterRightId && record.fieldId === wells?.[index - 1]?.fieldId && record.wellId === wells?.[index - 1]?.wellId ? (
              ""
            ) : (
              <>
                <div>{val}</div>
                {!selectedCompanyId && (
                  <div style={{ fontSize: 11 }}>
                    (<CompanyLabel companyId={record?.companyId} />)
                  </div>
                )}
              </>
            )
          ) : (
            <>
              <div>{val}</div>
              {!selectedCompanyId && (
                <div style={{ fontSize: 11 }}>
                  (<CompanyLabel companyId={record?.companyId} />)
                </div>
              )}
            </>
          ),
      },
      {
        title: "Field Name",
        key: "fieldName",
        dataIndex: "fieldName",
        render: (val: any, record: any, index: number) =>
          index > 0 ? (record.waterRightId === wells?.[index - 1]?.waterRightId && record.fieldId === wells?.[index - 1]?.fieldId && record.wellId === wells?.[index - 1]?.wellId ? "" : val) : val,
      },
      {
        title: "Well Name",
        key: "wellName",
        dataIndex: "wellName",
        render: (val: any, record: any, index: number) =>
          index > 0 ? (record.waterRightId === wells?.[index - 1]?.waterRightId && record.fieldId === wells?.[index - 1]?.fieldId && record.wellId === wells?.[index - 1]?.wellId ? "" : val) : val,
      },
      {
        title: "Year",
        key: "year",
        dataIndex: "year",
        render: (val: any, record: any) => val,
      },
      {
        title: (
          <>
            Authorized
            <br />
            <small>Irrigation Acres</small>
            <br />
            <small>Quantity (Acre Feet)</small>
            <br />
            <small>Rate (GPM)</small>
          </>
        ),
        key: "authorized",
        align: "right",
        render: (val: any, record: any) => {
          if (currentYear === record?.year)
            return (
              <>
                {record?.waterRightData?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.waterRightData?.authorizedIrrigationAcres}
                <br />
                {record?.waterRightData?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <UnitsConverter fromUnits={record?.waterRightData?.authorizedQuantityUnits} toUnits="acrefeet" value={record?.waterRightData?.authorizedQuantity} />
                )}
                <br />
                {record?.waterRightData?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.waterRightData?.authorizedRate}</div>}
              </>
            );
          else if (record?.year < currentYear && record?.waterRightData?.referenceYear)
            return (
              <>
                {record?.waterRightData?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.waterRightData?.authorizedIrrigationAcres}
                <br />
                {record?.waterRightData?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <UnitsConverter fromUnits={record?.waterRightData?.authorizedQuantityUnits} toUnits="acrefeet" value={record?.waterRightData?.authorizedQuantity} />
                )}
                <br />
                {record?.waterRightData?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.waterRightData?.authorizedRate}</div>}
              </>
            );
          else
            return (
              <>
                {record?.waterRightData?.authorizedIrrigationNotApplicable === true ? "N/A" : `${record?.waterRightData?.authorizedIrrigationAcres} *`}
                <br />
                {record?.waterRightData?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <>
                    <UnitsConverter fromUnits={record?.waterRightData?.authorizedQuantityUnits} toUnits="acrefeet" value={record?.waterRightData?.authorizedQuantity} /> *
                  </>
                )}
                <br />
                {record?.waterRightData?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.waterRightData?.authorizedRate} *</div>}
              </>
            );
        },
      },
      {
        title: (
          <>
            Actual Usage
            <br />
            <small>Irrigation Acres</small>
            <br />
            <small>Quantity (Acre Feet)</small>
            <br />
            <small>Rate (GPM)</small>
          </>
        ),
        key: "usage",
        align: "right",
        render: (val: any, record: any) => {
          if (currentYear === record?.year)
            return (
              <>
                {record?.waterRightData?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.waterRightData?.usageIrrigationAcres ?? record?.waterRightData?.authorizedIrrigationAcres}
                <br />
                {record?.waterRightData?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <UnitsConverter
                    fromUnits={record.waterRightData?.usageQuantity ? record.waterRightData?.usageQuantityUnits : record.waterRightData?.authorizedQuantityUnits}
                    toUnits="acrefeet"
                    value={record?.waterRightData?.usageQuantity ?? record?.waterRightData?.authorizedQuantity}
                  />
                )}
                <br />
                {record?.waterRightData?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.waterRightData?.usageRate ?? record?.waterRightData?.authorizedRate}</div>}
              </>
            );
          else if (record?.year < currentYear && record?.waterRightData?.referenceYear)
            return (
              <>
                {record?.waterRightData?.authorizedIrrigationNotApplicable === true ? "N/A" : record?.waterRightData?.usageIrrigationAcres ?? record?.waterRightData?.authorizedIrrigationAcres}
                <br />
                {record?.waterRightData?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <UnitsConverter
                    fromUnits={record.waterRightData?.usageQuantity ? record.waterRightData?.usageQuantityUnits : record.waterRightData?.authorizedQuantityUnits}
                    toUnits="acrefeet"
                    value={record?.waterRightData?.usageQuantity ?? record?.waterRightData?.authorizedQuantity}
                  />
                )}
                <br />
                {record?.waterRightData?.authorizedRateNotApplicable === true ? "N/A" : <div>{record?.waterRightData?.usageRate ?? record?.waterRightData?.authorizedRate}</div>}
              </>
            );
          else
            return (
              <>
                {record?.waterRightData?.authorizedIrrigationNotApplicable === true
                  ? "N/A"
                  : record?.waterRightData?.usageIrrigationAcres
                    ? `${record?.waterRightData?.usageIrrigationAcres} *`
                    : `${record?.waterRightData?.authorizedIrrigationAcres} *`}
                <br />
                {record?.waterRightData?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <>
                    <UnitsConverter
                      fromUnits={record.waterRightData?.usageQuantity ? record.waterRightData?.usageQuantityUnits : record.waterRightData?.authorizedQuantityUnits}
                      toUnits="acrefeet"
                      value={record?.waterRightData?.usageQuantity ?? record?.waterRightData?.authorizedQuantity}
                    />{" "}
                    *
                  </>
                )}
                <br />
                {record?.waterRightData?.authorizedRateNotApplicable === true ? (
                  "N/A"
                ) : (
                  <div>{record?.waterRightData?.usageRate ? `${record?.waterRightData?.usageRate} *` : `${record?.waterRightData?.authorizedRate} *`}</div>
                )}
              </>
            );
        },
      },
      {
        title: (
          <>
            Available Quantity
            <br />
            <small>(Gallons)</small>
            <br />
            <small>(Acre Feet)</small>
          </>
        ),
        align: "right",
        render: (val: any, record: any) =>
          record?.authorizedQuantityNotApplicable === true ? (
            <>N/A</>
          ) : (
            <>
              <UnitsConverter fromUnits="gallons" toUnits="gallons" value={determineValue(record.waterRightId, "availableQuantity", record?.year)} /> <br />
              <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={determineValue(record.waterRightId, "availableQuantity", record?.year)} />
            </>
          ),
      },
      {
        title: (
          <>
            Used
            <br />
            {selectedCompanyId && selectedCompany?.settings?.metric !== "acrefeet" && (
              <>
                <small>
                  (
                  <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
                </small>
                <br />
              </>
            )}
            <small>(Gallons)</small>
            <br />
            <small>(Acre Feet)</small>
          </>
        ),
        align: "right",
        render: (val: any, record: any) => (
          <>
            <UnitsConverter fromUnits="gallons" toUnits="gallons" value={determineValue(record.waterRightId, "currentUsage", record?.year)} />
            <br />
            <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={determineValue(record.waterRightId, "currentUsage", record?.year)} />
          </>
        ),
      },
      {
        title: (
          <>
            Transferred
            <br />
            <small>(Gallons)</small>
            <br />
            <small>(Acre Feet)</small>
          </>
        ),
        align: "right",
        render: (val: any, record: any) => (
          <>
            <UnitsConverter fromUnits="gallons" toUnits="gallons" value={determineValue(record?.waterRightId, "transferQuantity", record?.year)} /> <br />
            <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={determineValue(record?.waterRightId, "transferQuantity", record?.year)} />
          </>
        ),
      },
      {
        title: (
          <>
            Penalties
            <br />
            <small>(Gallons)</small>
            <br />
            <small>(Acre Feet)</small>
          </>
        ),
        align: "right",
        render: (val: any, record: any) => (
          <>
            <UnitsConverter fromUnits="gallons" toUnits="gallons" value={determineValue(record?.waterRightId, "penaltyQuantity", record?.year)} /> <br />
            <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={determineValue(record?.waterRightId, "penaltyQuantity", record?.year)} />
          </>
        ),
      },
      {
        title: (
          <>
            Remaining Usage
            <br />
            {selectedCompanyId && selectedCompany?.settings?.metric !== "acrefeet" && (
              <>
                <small>
                  (
                  <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
                </small>
                <br />
              </>
            )}
            <small>(Acre Feet)</small>
            <br />
            <small>(Inches Per Acre)</small>
          </>
        ),
        key: "remainingUsage",
        dataIndex: "remainingUsage",
        align: "right",
        render: (val: any, record: any) => {
          const companyConfig = selectedCompanyId ? companyConfigs?.find((company: any) => company?.id === record?.waterRightId?.companyId) : undefined;

          if (currentYear === record?.year)
            return (
              <>
                {selectedCompanyId && companyConfig?.settings?.metric !== undefined && companyConfig?.settings?.metric !== "acrefeet" && (
                  <>
                    <UnitsConverter
                      fromUnits="gallons"
                      toUnits={companyConfig?.settings?.metric}
                      value={determineValue(
                        record?.waterRightId,
                        "remainingUsage",
                        record?.year,
                        null,
                        record?.waterRightData?.authorizedIrrigationNotApplicable === true ? true : undefined,
                        record?.waterRightData?.authorizedQuantityNotApplicable === true ? true : undefined,
                        record?.waterRightData?.authorizedRateNotApplicable === true ? true : undefined
                      )}
                    />
                    <br />
                  </>
                )}
                <UnitsConverter
                  fromUnits="gallons"
                  toUnits="acrefeet"
                  value={determineValue(
                    record?.waterRightId,
                    "remainingUsage",
                    record?.year,
                    null,
                    record?.waterRightData?.authorizedIrrigationNotApplicable === true ? true : undefined,
                    record?.waterRightData?.authorizedQuantityNotApplicable === true ? true : undefined,
                    record?.waterRightData?.authorizedRateNotApplicable === true ? true : undefined
                  )}
                />
                <br />
                <UnitsConverter
                  fromUnits="gallons"
                  toUnits="acreinches"
                  value={determineValue(
                    record?.waterRightId,
                    "remainingUsage",
                    record?.year,
                    null,
                    record?.waterRightData?.authorizedIrrigationNotApplicable === true ? true : undefined,
                    record?.waterRightData?.authorizedQuantityNotApplicable === true ? true : undefined,
                    record?.waterRightData?.authorizedRateNotApplicable === true ? true : undefined
                  )}
                  devideBy={record.waterRightData?.usageIrrigationAcres ?? record.waterRightData?.authorizedIrrigationAcres}
                />
              </>
            );
          else if (record?.year < currentYear && record?.waterRightData?.referenceYear)
            return (
              <>
                {selectedCompanyId && companyConfig?.settings?.metric !== "acrefeet" && (
                  <>
                    <UnitsConverter
                      fromUnits="gallons"
                      toUnits={companyConfig?.settings?.metric}
                      value={determineValue(
                        record?.waterRightId,
                        "remainingUsage",
                        record?.year,
                        null,
                        record?.waterRightData?.authorizedIrrigationNotApplicable === true ? true : undefined,
                        record?.waterRightData?.authorizedQuantityNotApplicable === true ? true : undefined,
                        record?.waterRightData?.authorizedRateNotApplicable === true ? true : undefined
                      )}
                    />
                    <br />
                  </>
                )}
                <UnitsConverter
                  fromUnits="gallons"
                  toUnits="acrefeet"
                  value={determineValue(
                    record?.waterRightId,
                    "remainingUsage",
                    record?.year,
                    null,
                    record?.waterRightData?.authorizedIrrigationNotApplicable === true ? true : undefined,
                    record?.waterRightData?.authorizedQuantityNotApplicable === true ? true : undefined,
                    record?.waterRightData?.authorizedRateNotApplicable === true ? true : undefined
                  )}
                />
                <br />
                <UnitsConverter
                  fromUnits="gallons"
                  toUnits="acreinches"
                  value={determineValue(
                    record?.waterRightId,
                    "remainingUsage",
                    record?.year,
                    null,
                    record?.waterRightData?.authorizedIrrigationNotApplicable === true ? true : undefined,
                    record?.waterRightData?.authorizedQuantityNotApplicable === true ? true : undefined,
                    record?.waterRightData?.authorizedRateNotApplicable === true ? true : undefined
                  )}
                  devideBy={record.waterRightData?.usageIrrigationAcres ?? record.waterRightData?.authorizedIrrigationAcres}
                />
              </>
            );
          else
            return (
              <>
                {selectedCompanyId && companyConfig?.settings?.metric !== "acrefeet" && (
                  <>
                    <UnitsConverter
                      fromUnits="gallons"
                      toUnits={companyConfig?.settings?.metric}
                      value={determineValue(
                        record?.waterRightId,
                        "remainingUsage",
                        record?.year,
                        null,
                        record?.waterRightData?.authorizedIrrigationNotApplicable === true ? true : undefined,
                        record?.waterRightData?.authorizedQuantityNotApplicable === true ? true : undefined,
                        record?.waterRightData?.authorizedRateNotApplicable === true ? true : undefined
                      )}
                    />{" "}
                    *<br />
                  </>
                )}
                <UnitsConverter
                  fromUnits="gallons"
                  toUnits="acrefeet"
                  value={determineValue(
                    record?.waterRightId,
                    "remainingUsage",
                    record?.year,
                    null,
                    record?.waterRightData?.authorizedIrrigationNotApplicable === true ? true : undefined,
                    record?.waterRightData?.authorizedQuantityNotApplicable === true ? true : undefined,
                    record?.waterRightData?.authorizedRateNotApplicable === true ? true : undefined
                  )}
                />{" "}
                *
                <br />
                <UnitsConverter
                  fromUnits="gallons"
                  toUnits="acreinches"
                  value={determineValue(
                    record?.waterRightId,
                    "remainingUsage",
                    record?.year,
                    null,
                    record?.waterRightData?.authorizedIrrigationNotApplicable === true ? true : undefined,
                    record?.waterRightData?.authorizedQuantityNotApplicable === true ? true : undefined,
                    record?.waterRightData?.authorizedRateNotApplicable === true ? true : undefined
                  )}
                  devideBy={record.waterRightData?.usageIrrigationAcres ?? record.waterRightData?.authorizedIrrigationAcres}
                />{" "}
                *
              </>
            );
        },
      },
      {
        title: (
          <>
            Remaining
            <br />
            <small>Days</small>
            <br />
            <small>%</small>
          </>
        ),
        key: "remainingDays",
        dataIndex: "remainingDays",
        align: "center",
        render: (val: any, record: any) => {
          const percentage = calculateWaterRightAvailablePercentage(record);

          if (determineValue(record?.waterRightId, "remainingDays", record.year) === undefined)
            return (
              <>
                - <br /> 0.00%
              </>
            );
          else
            return (
              <>
                <RemainingDays remainingDays={determineValue(record?.waterRightId, "remainingDays", record.year)} /> <br />{" "}
                {+percentage > 0 ? `${percentage}%` : percentage === "N/A" ? "N/A" : "0.00%"}
              </>
            );
        },
      },
    ];

    setWellColumns(tempColumns.filter((x) => x).map((col) => ({ ...col, onCell: undefined })));
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedLastReadingReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`End Of Year Summary Water Usage Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
        >
          {
            <Card title="End Of Year Summary Water Usage Report">
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                  rowClassName={(record, index) => (record.indexCounter % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  rowKey={(row: any) => row.id}
                  dataSource={wells ?? []}
                  columns={wellColumns}
                  loading={loadingWells || loadingFields || loadingWaterRights || loadingCalculations}
                  size="small"
                  pagination={false}
                  footer={() => {
                    if (reportConfig?.years[0] < currentYear || reportConfig?.years[1] < currentYear)
                      return (
                        <Alert
                          description={`The Authorized, Usage and Remaining Usage columns may contain "*" alongside the values if a snapshot is configured for the water right for any year range that is not the current year (${currentYear})`}
                          type="warning"
                          showIcon
                        />
                      );
                    else return undefined;
                  }}
                />
              </ConfigProvider>
            </Card>
          }
          <div className="page-break" />
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Year Range">
                  {reportConfig?.years[0]} - {reportConfig.years[1]}
                </Descriptions.Item>
                <Descriptions.Item label="Wells">{reportConfig?.selectedWellNames === "" ? "-" : reportConfig.selectedWellNames}</Descriptions.Item>
                <Descriptions.Item label="Water Rights">{reportConfig?.selectedWaterRightFileNumbers === "" ? "-" : reportConfig?.selectedWaterRightFileNumbers}</Descriptions.Item>
                <Descriptions.Item label="Permit Types">{reportConfig?.permitTypes === "" ? "-" : reportConfig?.permitTypes}</Descriptions.Item>
                <Descriptions.Item label="Priority Types">{reportConfig?.priorityTypes === "" ? "-" : reportConfig?.priorityTypes}</Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
};

export default GeneratedEndOfYearSummaryReport;
