import { useQuery } from "@tanstack/react-query";
import CacheKeys from "@/types/CacheKeys";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import useProfile from "./useProfile";

const useActionsCounter = () => {
  const { profile } = useProfile();

  const { data, isFetching, isFetched } = useQuery({
    queryKey: [CacheKeys.actionsCounter, profile],
    queryFn: async () => {
      let count = 0;
      if (!profile) return count;
      if (!profile?.phoneNumber) count++;
      if (!profile?.phoneNumberConfirmed) count++;
      return count;
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    initialData: 0,
  });

  return {
    actionsCount: data,
    isFetched,
    isFetching,
  };
};

export default useActionsCounter;
