import { RestrictionTable } from "@/components";
import { FC } from "react";

import "./RestrictionList.scss";

const RestrictionList: FC = () => {
  return <RestrictionTable showAddRestriction showHeader />;
};

export default RestrictionList;
