import GaugeComponent from "react-gauge-component";
import UnitsConverter from "../UnitsConverter/UnitsConverter";
import { Card } from "antd";
import RemainingDays from "../RemainingDays/RemainingDays";
import CompanyLabel from "../Lookups/CompanyLabel";
import { CSSProperties } from "react";
import { convertUnits, formatNumber } from "@/services/utils";
import useCompanyConfigLookup from "@/queries/useCompanyConfigLookup";

interface RadialGaugeProps {
  header?: string;
  companyId?: string;
  availableQuantity: number;
  usedQuantity: number;
  remainingDays?: number | string;
  lastReadingDate?: string;
  borderless?: boolean;
  quantityNotApplicable: boolean;
  style?: CSSProperties;
}

const remainingBlockStyle = {
  height: 50,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  padding: 0,
};

const RadialGauge = ({ header, companyId, availableQuantity, usedQuantity, remainingDays, quantityNotApplicable, borderless = false, style }: RadialGaugeProps) => {
  let remainingQuantity = quantityNotApplicable ? usedQuantity : availableQuantity - usedQuantity;
  let remainingPercentage = quantityNotApplicable ? 100 : availableQuantity === null || availableQuantity === undefined || availableQuantity === 0 ? 0 : (remainingQuantity / availableQuantity) * 100;

  if (remainingQuantity < 0) remainingQuantity = 0;
  if (remainingPercentage < 0) remainingPercentage = 0;

  const { companyConfig } = useCompanyConfigLookup(companyId);

  const displayUnits = companyConfig?.settings?.metric ?? "acrefeet";

  const warningQuantity = companyConfig?.settings?.waterRightWarningQuantity ?? 10;
  const warningQuantityUnits = companyConfig?.settings?.waterRightWarningQuantityUnits ?? displayUnits;
  const availableQuantityInAF = quantityNotApplicable ? convertUnits(remainingQuantity, "gallons", displayUnits) : convertUnits(availableQuantity, "gallons", displayUnits);
  const warningQuantityInAF = convertUnits(warningQuantity, warningQuantityUnits, displayUnits);

  if (header === "1 - test") console.log("quantity => ", availableQuantityInAF);

  const formatTextValue = (value: number) => {
    const calculatedValue = quantityNotApplicable ? convertUnits((remainingQuantity * value) / 100, "gallons", displayUnits) : convertUnits((availableQuantity * value) / 100, "gallons", displayUnits);
    if (header === "1 - test") console.log("calculated value => ", calculatedValue);
    if (value === 10 && availableQuantityInAF * 0.25 > warningQuantityInAF) {
      return formatNumber(warningQuantityInAF?.toFixed(displayUnits !== "gallons" ? 2 : 1)) + " *";
    }
    return formatNumber(calculatedValue?.toFixed(displayUnits !== "gallons" ? 2 : 1));
  };

  const scaleTickFont = (value: number, metric: string) => {
    const length = value?.toFixed(0)?.length;
    switch (true) {
      case length > 8:
        return "0.5em";
      case length > 7:
        return "0.6em";
      case length > 6:
        return "0.7em";
      case length > 5:
        return "0.8em";
      case length > 4:
        return "0.9em";
      default:
        return "0.95em";
    }
  };

  return (
    <Card
      styles={{
        body: {
          textAlign: "center",
          padding: 0,
          paddingBottom: 20,
          width: 300,
          ...(style ?? {}),
        },
      }}
      bordered={!borderless}
      className={borderless ? "removeBoxShadow removeMargin" : undefined}
    >
      {header && (
        <div style={{ textAlign: "left", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", paddingBottom: 10 }}>
          <div style={{ flex: 1, fontWeight: "semibold", fontSize: 16 }}>{header}</div>
          {companyId && <div style={{ paddingRight: 10, fontSize: 11 }}>({<CompanyLabel companyId={companyId} />})</div>}
        </div>
      )}
      {companyConfig && (
        <GaugeComponent
          marginInPercent={{ bottom: 0, top: 0.1, left: 0.12, right: 0.12 }}
          style={{ width: 285 }}
          type="semicircle"
          arc={{
            cornerRadius: 1,
            width: 0.3,
            subArcs: [
              {
                limit: 10,
                color: "#f32423",
                showTick: true,
              },
              {
                limit: 25,
                color: "#f36823",
                showTick: true,
              },
              {
                limit: 50,
                color: "#fed000",
                showTick: true,
              },
              {
                limit: 75,
                color: "#33c635",
                showTick: true,
              },
              {
                limit: 100,
                color: "#33c635",
                showTick: true,
              },
            ],
          }}
          pointer={{
            color: "black",
            length: 0.9,
            animationDelay: 0,
            animationDuration: 2000,
          }}
          labels={{
            valueLabel: {
              hide: true,
            },
            tickLabels: {
              type: "outer",
              defaultTickValueConfig: {
                formatTextValue: formatTextValue,
                style: {
                  fontSize: scaleTickFont(availableQuantityInAF, displayUnits),
                  fill: "black",
                  textShadow: "none",
                },
              },
              defaultTickLineConfig: {
                char: "_",
                style: {
                  fill: "black",
                  textShadow: "none",
                  fontWeight: "bold",
                },
              },
              ticks: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 75 }],
            },
          }}
          value={remainingPercentage}
          minValue={0}
          maxValue={100}
        />
      )}
      {!quantityNotApplicable ? (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", flex: 1, paddingRight: 15, paddingTop: 10 }}>
          {availableQuantity !== null && availableQuantity !== undefined && (
            <div className="ant-card ant-card-grid removeShadow" style={{ ...remainingBlockStyle }}>
              {remainingPercentage?.toFixed(2)}
              <br />%
            </div>
          )}
          {remainingDays !== null && remainingDays !== undefined && (
            <div className="ant-card ant-card-grid removeShadow" style={{ ...remainingBlockStyle }}>
              <RemainingDays remainingDays={remainingDays} breakLines />
            </div>
          )}
          {availableQuantity !== null && availableQuantity !== undefined && (
            <div className="ant-card ant-card-grid removeShadow" style={{ ...remainingBlockStyle }}>
              <UnitsConverter fromUnits="gallons" toUnits={displayUnits} value={remainingQuantity} showUnitsLabel placeholder="Loading..." breakLines />
            </div>
          )}
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", flex: 1, paddingRight: 15, paddingTop: 10 }}>
          <div className="ant-card ant-card-grid removeShadow" style={{ ...remainingBlockStyle }}>
            <UnitsConverter fromUnits="gallons" toUnits={displayUnits} value={remainingQuantity} showUnitsLabel placeholder="Loading..." />
            <br />
            Current Year Usage
          </div>
        </div>
      )}
    </Card>
  );
};

export default RadialGauge;
