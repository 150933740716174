import React, { useState } from "react";
import { Breadcrumb, Button, Layout, Result, Space } from "antd";
import { Breadcrumbs, Content, Footer, Sider } from "@/components";
import { useAuth } from "react-oidc-context";
import { routes } from "@/configs";
import useCustomNavigate from "@/services/useCustomNavigate";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "@/services/utils";

interface Props {
  children?: React.ReactNode;
}

const SideMenuContentLayout: React.FC<Props> = (props: any) => {
  const auth = useAuth();
  const { navigate } = useCustomNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const handleLoginClicked = () => {
    setLoading(true);
    auth
      .signinRedirect()
      .then(() => setLoading(false))
      .catch((e) => setLoading(false));
  };

  const handleHomeClicked = () => {
    navigate(routes.home);
  };

  return !auth?.isAuthenticated ? (
    <Result
      status="403"
      title="403"
      subTitle={<>Sorry, you are not authorized to access this page.</>}
      extra={
        <>
          <Space direction="vertical">
            <Button loading={loading} disabled={loading} style={{ width: 250 }} type="primary" onClick={handleLoginClicked}>
              Login
            </Button>
            <Button type="default" loading={loading} disabled={loading} style={{ width: 250 }} onClick={handleHomeClicked}>
              Navigate To Home
            </Button>
          </Space>
        </>
      }
    />
  ) : (
    <Layout hasSider>
      <Sider />
      <Layout style={{ padding: "0px 8px 0px 8px", height: "calc(100vh - 94px)" }}>
        <Breadcrumbs />
        <Content useBackground={false} />
        <Footer />
      </Layout>
    </Layout>
  );
};

export default SideMenuContentLayout;
