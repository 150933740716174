import { EditOutlined, LeftOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Card, Descriptions, Popconfirm, Table, Tabs, message } from "antd";
import { downloadBulkNotificationAttachment, getBulkNotification, listBulkNotificationAttachment, sendBulkNotification } from "@/apis/communication.api";
import { listProfile } from "@/apis/identity.api";
import { StatusTag } from "@/components";
import { routes } from "@/configs";
import { IdentityRole } from "@/dtos/user.dto";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useCustomNavigate from "@/services/useCustomNavigate";
import { openAttachmentFromGeneratedFile } from "@/services/utils";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb, removeBreadcrumb, replaceUrlStateBreadcrumbType } from "@/stores/breadcrumbs.store";

const AdminCommunicationView: FC = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  const { selectedRole } = useSelector((state: any) => state.user);

  const pathUrl = window.location.pathname;

  const { navigate } = useCustomNavigate();

  const [notification, setNotification] = useState<any>(undefined);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [activeKey, setActiveKey] = useState("users");
  const [users, setUsers] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [attachmentColumns, setAttachmentColumns] = useState<any[]>([]);

  const [loadingNotification, setLoadingNotification] = useState<boolean>(false);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [sendingNotification, setSendingNotification] = useState<boolean>(false);
  const [loadingAttachments, setLoadingAttachments] = useState<boolean>(false);
  const [donwloadingAttachment, setDonwloadingAttachment] = useState<boolean>(false);

  useEffect(() => {
    if (pathUrl.includes("view") && notification) {
      dispatch(replaceUrlStateBreadcrumbType(notification?.title ? notification?.title : notification?.subject));
    }
  }, [pathUrl, notification]);

  useEffect(() => {
    getBulkNotifcation();
    refreshUsers();
  }, [id]);

  useEffect(() => {
    calculateColumns();
  }, [users]);

  useEffect(() => {
    if (notification?.attachments?.length > 0) {
      getNotificationAttachments(notification.attachments);
    }
  }, [notification]);

  useEffect(() => {
    calculateAttachmentColumns();
  }, [attachments, notification]);

  const getNotificationAttachments = async (attachments: any[]) => {
    setLoadingAttachments(true);

    const request = { attachmentIds: attachments };

    const response = await listBulkNotificationAttachment(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setAttachments(data.value);
      }
    }

    setLoadingAttachments(false);
  };

  const downloadAttachment = async (id: string) => {
    setDonwloadingAttachment(true);

    const response = await downloadBulkNotificationAttachment(id);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        openAttachmentFromGeneratedFile(data.value);
      }
    }

    setDonwloadingAttachment(false);
  };

  const getBulkNotifcation = async () => {
    setLoadingNotification(true);

    const response = await getBulkNotification(id);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setNotification(data.value);
      }
    }

    setLoadingNotification(false);
  };

  const refreshUsers = async () => {
    setLoadingUsers(true);

    const response = await listProfile({ searchString: null });

    if (response.ok) {
      const data = await response.json();
      setUsers(data);
    }

    setLoadingUsers(false);
  };

  const navigateEdit = () => {
    dispatch(
      addBreadcrumb({
        type: `Edit`,
        url: `communication/${id}/edit`,
      })
    );
    navigate(routes.adminCommunicationEdit, { id });
  };

  const navigateBack = () => {
    dispatch(removeBreadcrumb());
    navigate(-1);
  };

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  const calculateAttachmentColumns = () => {
    let tempColumns = [
      {
        title: "File Name",
        key: "fileName",
        dataIndex: "fileName",
        width: 150,
        render: (val: any, record: any) => val,
      },
      {
        title: "Download Attachment",
        width: 150,
        render: (val: any, record: any) => (
          <Button loading={donwloadingAttachment} disabled={donwloadingAttachment} style={{ paddingLeft: 0 }} type="link" onClick={() => downloadAttachment(record.id)}>
            Download
          </Button>
        ),
      },
    ];

    setAttachmentColumns(tempColumns.filter((x) => x));
  };

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Display Name",
        key: "displayName",
        dataIndex: "displayName",
        width: 150,
        render: (val: any, record: any) => val,
      },
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
        width: 300,
        render: (val: any, record: any) => val,
      },
    ];

    setColumns(tempColumns.filter((x) => x));
  };

  const renderTabs = (notification: any) => {
    let tabs: any[] = [
      {
        label: "Users",
        key: "users",
        children: (
          <Card id="users" title="Users">
            <Table
              rowKey={(row: any) => row.id}
              loading={loadingUsers}
              columns={columns}
              dataSource={notification?.userIds ? users.filter((user) => notification?.userIds?.includes(user.id)) : users.filter((user) => notification?.userEmails?.includes(user?.email))}
              size="small"
            />
          </Card>
        ),
      },
      {
        label: "Attachments",
        key: "attachments",
        children: (
          <Card id="attachments" title="Attachments">
            <Table rowKey={(row: any) => row.id} loading={loadingAttachments} columns={attachmentColumns} dataSource={attachments ?? []} size="small" />
          </Card>
        ),
      },
    ];

    return tabs;
  };

  const handleSendNotification = async (id: any) => {
    setSendingNotification(true);

    const response = await sendBulkNotification(id, { id: id });
    if (response.ok) {
      message.success("Successfully sent bulk communication");
    } else message.error("Error in sending bulk communication");

    setSendingNotification(false);
    navigateBack();
  };

  const renderUserTypes = (userTypes: string[]) => {
    let formatedUserTypes: string[] = [];
    userTypes?.forEach((user) => {
      switch (user) {
        case "admin":
          formatedUserTypes.push("Admin");
          break;
        case "waterreader":
          formatedUserTypes.push("Water Reader");
          break;
        case "viewer":
          formatedUserTypes.push("View Only");
          break;
        default:
          break;
      }
    });
    return formatedUserTypes.length > 0 ? formatedUserTypes.join(", ") : "-";
  };

  const renderContent = () => {
    return (
      <div>
        <Descriptions
          bordered
          size="small"
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          labelStyle={{ width: 200 }}
          style={{ width: 415, height: "100%" }}
          title={
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                padding: 5,
              }}
            >
              Summary
            </div>
          }
          className="removeDescriptionHeaderPadding"
        >
          {notification?.subject ? (
            <>
              <Descriptions.Item label="User Types">{renderUserTypes(notification?.userTypes)}</Descriptions.Item>
              <Descriptions.Item label="Status">
                <StatusTag status={notification?.status} />
              </Descriptions.Item>
              <Descriptions.Item label="Subject">{notification?.subject}</Descriptions.Item>
              <Descriptions.Item label="Body">{notification?.body}</Descriptions.Item>
              <Descriptions.Item label="Footer">{notification?.footer}</Descriptions.Item>
            </>
          ) : (
            <>
              <Descriptions.Item label="User Types">{notification?.userTypes ? notification?.userTypes.join(", ") : ""}</Descriptions.Item>
              <Descriptions.Item label="Status">
                <StatusTag status={notification?.status} />
              </Descriptions.Item>
              <Descriptions.Item label="Title">{notification?.title}</Descriptions.Item>
              <Descriptions.Item label="Body">{notification?.body}</Descriptions.Item>
            </>
          )}
        </Descriptions>
        <br />
        <Tabs defaultActiveKey="1" type="card" size="small" style={{ marginBottom: 32 }} onChange={onChange} items={renderTabs(notification)} />
      </div>
    );
  };

  return (
    <Card
      className="bulkNotificationView"
      loading={loadingNotification}
      id="bulkNotificationView"
      title={
        notification?.title ? `Push Notification - ${loadingNotification ? "loading..." : notification?.title}` : `Email Notification - ${loadingNotification ? "loading..." : notification?.subject}`
      }
      extra={
        <>
          <Button style={{ marginRight: 5 }} onClick={navigateBack} icon={<LeftOutlined />}>
            Back
          </Button>
          {selectedRole === IdentityRole.MasterAdministrator.role && (
            <Button style={{ marginRight: 5 }} type="primary" onClick={navigateEdit} icon={<EditOutlined />}>
              Edit
            </Button>
          )}
          {selectedRole === IdentityRole.MasterAdministrator.role && (
            <Popconfirm title="Send communication" description="Are you sure you want to send this communication?" onConfirm={() => handleSendNotification(id)} okText="Yes" cancelText="No">
              <Button loading={sendingNotification} disabled={sendingNotification} type="primary" icon={<SendOutlined />}>
                Send
              </Button>
            </Popconfirm>
          )}
        </>
      }
    >
      {renderContent()}
    </Card>
  );
};

export default AdminCommunicationView;
