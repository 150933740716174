import useRoleBasedFeature, { RoleBasedFeature } from "@/services/useRoleBasedFeature";
import { Card } from "antd";
import { FC } from "react";

const AdminDashboard: FC = () => {
  const hasAccessToFeature = useRoleBasedFeature();
  return hasAccessToFeature?.[RoleBasedFeature.Dashboard] ? (
    <Card className="adminDashboard" id="adminDashboard" title="Dashboard" bordered={false}>
      Admin dashboard
    </Card>
  ) : (
    <></>
  );
};

export default AdminDashboard;
