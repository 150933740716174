import { constants } from "@/configs";
import { User } from "oidc-client-ts";

export function getUser() {
  let oidcStorage = localStorage.getItem(`oidc.user:${constants.oidcConfig.authority}:${constants.oidcConfig.client_id}`);
  if (!oidcStorage) oidcStorage = sessionStorage.getItem(`oidc.user:${constants.oidcConfig.authority}:${constants.oidcConfig.client_id}`);
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}
