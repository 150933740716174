import { addRestriction, getRestriction, updateRestriction } from "@/apis/restriction.api";
import { LookupSelector, TableTransfer } from "@/components";
import RestrictionCalculations from "@/components/RestrictionCalculations/RestrictionCalculations";
import { constants, routes } from "@/configs";
import scrollToTop from "@/services/scrollToTop";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { removeBreadcrumb, removeViewEditBreadcrumbs } from "@/stores/breadcrumbs.store";
import { clearRestrictionState, saveRestriction } from "@/stores/restriction.store";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Alert, Button, Card, DatePicker, Form, Input, InputNumber, Space, Steps, Switch, Typography, message } from "antd";
import Tooltip, { TooltipPlacement } from "antd/es/tooltip";
import { TransferDirection } from "antd/es/transfer";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import "./RestrictionAddEdit.scss";
import CacheKeys from "@/types/CacheKeys";

const RestrictionAddEdit: FC = () => {
  const dispatch = useAppDispatch();

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();

  const [form] = Form.useForm<any>();

  const { navigate } = useCustomNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [addAnother, setAddAnother] = useState<boolean>(false);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [restrictionData, setRestrictionData] = useState<any>(undefined);

  const restrictionType = Form.useWatch("type", form);
  const startDate = Form.useWatch("startDate", form);
  const endDate = Form.useWatch("endDate", form);
  const termQuantity = Form.useWatch("termQuantity", form);
  const previousYearQuantities = Form.useWatch("previousYearQuantities", form);

  const [waterRights, setWaterRights] = useState<any[]>([]);
  const onWaterRightsChange = useCallback(
    (waterRights: any[]) => {
      setWaterRights((t) => [...(waterRights ?? [])]);
    },
    [waterRights]
  );

  useEffect(() => {
    if (restrictionType === "myfa") form.setFieldValue("myfa", true);
    else if (!restrictionData) form.setFieldValue("myfa", false);
  }, [restrictionType]);

  useEffect(() => {
    if (id) getRestrictionData();
    else {
      resetDefaults();
    }
    // eslint-disable-next-line
  }, [id]);

  const resetDefaults = () => {
    form?.setFieldsValue({
      status: true,
      startDate: "2023-01-01T10:00:00.000Z",
      endDate: "2027-12-31T10:00:00.000Z",
    });
    scrollToTop();
  };

  useEffect(() => {
    if (restrictionData) {
      form.setFieldsValue(restrictionData);
    }
    // eslint-disable-next-line
  }, [restrictionData]);

  const getRestrictionData = async () => {
    setLoading(true);
    const response = await getRestriction(id);
    if (response.ok) {
      const data = await response.json();
      setRestrictionData(data.value);
      setTargetKeys(data.value.waterRightIds);
    }
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const data = {
      ...values,
      companyId: restrictionData?.companyId ?? selectedCompanyId,
      waterRightIds: targetKeys,
    };

    if (id) {
      const response = await updateRestriction(id, data);
      if (response.ok) {
        queryClient.invalidateQueries({ queryKey: [CacheKeys.restrictions] });

        dispatch(saveRestriction(undefined));
        message.success("Successfully updated the restriction");
        navigate(routes.restrictionList);
      } else {
        message.error("Failed to update restriction");
      }
    } else {
      const response = await addRestriction(data);
      if (response.ok) {
        queryClient.invalidateQueries({ queryKey: [CacheKeys.restrictions] });

        message.success("Successfully added the restriction");
        if (addAnother) {
          dispatch(clearRestrictionState());
          form?.resetFields();
          resetDefaults();
          setTargetKeys([]);
          navigate(routes.restrictionAdd);
        } else navigate(routes.restrictionList);
      } else {
        message.error("Failed to add the restriction");
      }
    }

    if (id) {
      dispatch(removeViewEditBreadcrumbs());
    } else if (!addAnother) dispatch(removeBreadcrumb());

    setAddAnother(false);
    setLoading(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onFinish(values);
      })
      .catch((errors) => {
        scrollToTop();
        message.error(`Please fill in the required fields`, 5);
      });
  };

  const handleAddAnother = () => {
    form
      .validateFields()
      .then((values) => {
        setAddAnother(true);
        form?.submit();
      })
      .catch((errors) => {
        scrollToTop();
        message.error(`Please fill in the required fields`, 5);
      });
  };

  const filterOption = (inputValue: string, option: any) => option.fileNumber.indexOf(inputValue) > -1;

  const handleSearch = (dir: TransferDirection, value: string) => {
    console.log("search:", dir, value);
  };

  const onKeysChange = (nextTargetsKeys: string[]) => {
    setTargetKeys(nextTargetsKeys.filter((item: any) => item !== undefined));
  };

  const renderActionButtons = (placement: TooltipPlacement) => (
    <Space>
      {!id && (
        <>
          <Button disabled={loading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button disabled={loading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={handleAddAnother}>
            Save + Add Another
          </Button>
        </>
      )}
      {id && (
        <Button disabled={loading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
          Save
        </Button>
      )}
      <Button
        disabled={loading}
        loading={loading}
        icon={<CloseOutlined />}
        onClick={() => {
          if (id) {
            dispatch(removeBreadcrumb());
            navigate(-1);
          } else {
            dispatch(removeBreadcrumb());
            navigate(routes.restrictionList);
          }
        }}
      >
        Cancel
      </Button>
    </Space>
  );

  const calculateDateRange = (startDate: any, endDate: any, asText: boolean = true) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    const years = end.year() - start.year() + 1;

    let message = "";

    if (years > 1) message += `${years} years`;
    else if (years === 1) message += `${years} year`;
    else message += "0 years";

    return asText ? message : years;
  };

  const generatePreviousYearQuantities = (startDate: any, existingPreviousYearQuantities: any, createdAt: any) => {
    const start = dayjs(startDate);

    const maxYear = existingPreviousYearQuantities
      ? Math.max(...Object.keys(existingPreviousYearQuantities).map((x) => Number(x)))
      : createdAt
        ? dayjs(new Date(createdAt)).year() - 1
        : dayjs().year() - 1;

    if (start.year() >= dayjs().year()) return <></>;

    const startYear = start.year();

    const previousYearQuantities: any = {};

    for (let i = 0; i <= maxYear - startYear; i++) {
      previousYearQuantities[startYear + i] = undefined;
    }

    if (isEmpty(previousYearQuantities)) return <></>;

    return (
      <Form.Item label="Previous Year Quantities" required>
        {Object.keys(previousYearQuantities)
          .reverse()
          .map((year: any, index: number) => (
            <>
              <Space>
                <Typography style={{ width: 35 }}>{year}: </Typography>
                <Input.Group compact>
                  <Form.Item
                    name={["previousYearQuantities", year]}
                    rules={[
                      {
                        required: true,
                        message: `Please enter the quantity for ${year}`,
                      },
                    ]}
                    noStyle
                  >
                    <InputNumber style={{ minWidth: 225 }} min={0} placeholder={`Enter the quantity for ${year}`} addonAfter="Acre Feet" />
                  </Form.Item>
                </Input.Group>
              </Space>
              <br />
            </>
          ))}
      </Form.Item>
    );
  };

  const renderCalculations = (startDate: any, endDate: any, waterRights: any, termQuantity: any, activePreviousYearQuantities: any, previousYearQuantities: any, createdAt: any) => {
    return (
      <RestrictionCalculations
        startDate={startDate}
        endDate={endDate}
        termQuantity={termQuantity}
        waterRights={waterRights}
        previousYearQuantities={previousYearQuantities}
        activePreviousYearQuantities={activePreviousYearQuantities}
        createdAt={createdAt}
        headerText={
          <>
            The values below are used for the restriction calculation.
            <br />
            If any changes are made to this restriction or any water rights selected above, the calculation values below may be affected.
            <br />
          </>
        }
        showTotals={false}
      />
    );
    // <Card>
    //     <Alert showIcon description={<>
    //         The values below are used for the restriction calculation.
    //         <br />If any changes are made to this restriction or any water rights selected above, the calculation values below may be affected.
    //         <br />
    //         {renderTabbedItemTotal(<b>Restriction Constants</b>, undefined, undefined, 140)}
    //         {renderTabbedItemTotal('Total Years:', calculateDateRange(startDate, endDate))}
    //         {renderTabbedItemTotal('Irrigation Acres:', irrigationAcres, 'The maximum usage irrigation acres between all water rights in this restriction')}
    //         {renderTabbedItemTotal('Total Quantity (Gallons):', <UnitsConverter fromUnits='acrefeet' toUnits='gallons' value={termQuantity} />)}
    //         {renderTabbedItemTotal('Total Quantity (Acre Feet):', <UnitsConverter fromUnits='acrefeet' toUnits='acrefeet' value={termQuantity} />)}
    //         {renderTabbedItemTotal('Inches / Acre:', <UnitsConverter fromUnits='acrefeet' toUnits='acreinches' value={termQuantity} devideBy={irrigationAcres} />)}

    //         {renderTabbedItemTotal(<b>Baseline Quantity</b>)}
    //         {renderTabbedItemTotal('The target yearly average usage.', null, undefined, 400)}
    //         {renderTabbedItemTotal('Calculated as the total quantity divided by the total years.', null, undefined, 400)}
    //         {renderTabbedItemTotal('Gallons:', <UnitsConverter fromUnits='acrefeet' toUnits='gallons' value={baseline} />)}
    //         {renderTabbedItemTotal('Acre Feet:', <UnitsConverter fromUnits='acrefeet' toUnits='acrefeet' value={baseline} />)}
    //         {renderTabbedItemTotal('Inches / Acre:', <UnitsConverter fromUnits='acrefeet' toUnits='acreinches' value={baseline} devideBy={irrigationAcres} />,)}

    //         {renderTabbedItemTotal(<b>Remaining Quantity</b>)}
    //         {renderTabbedItemTotal('Calculated as the total quantity minus previous year quantities.', null, undefined, 400)}
    //         {renderTabbedItemTotal('Gallons:', <UnitsConverter fromUnits='acrefeet' toUnits='gallons' value={remaining} />)}
    //         {renderTabbedItemTotal('Acre Feet:', <UnitsConverter fromUnits='acrefeet' toUnits='acrefeet' value={remaining} />)}
    //         {renderTabbedItemTotal('Inches / Acre:', <UnitsConverter fromUnits='acrefeet' toUnits='acreinches' value={remaining} devideBy={irrigationAcres} />)}

    //         {renderTabbedItemTotal(<b>Remaining Yearly Average</b>)}
    //         {renderTabbedItemTotal('Calculated as the remaining quantity divided by the remaining years.', null, undefined, 500)}
    //         {renderTabbedItemTotal('Years:', remainingYears > 0 ? remainingYears : 0)}
    //         {renderTabbedItemTotal('Gallons:', remainingYears > 0 ? <UnitsConverter fromUnits='acrefeet' toUnits='gallons' value={yearlyRemaining} /> : 0)}
    //         {renderTabbedItemTotal('Acre Feet:', remainingYears > 0 ? <UnitsConverter fromUnits='acrefeet' toUnits='acrefeet' value={yearlyRemaining} /> : 0)}
    //         {renderTabbedItemTotal('Inches / Acre:', remainingYears > 0 ? <UnitsConverter fromUnits='acrefeet' toUnits='acreinches' value={yearlyRemaining} devideBy={irrigationAcres} /> : 0)}

    //         {renderTabbedItemTotal(<b>Current Year Usage Quantity</b>, undefined, undefined, 500)}
    //         {renderTabbedItemTotal('The used quantity for all water rights in this restriction for this year.', null, undefined, 500)}
    //         {renderTabbedItemTotal('Gallons:', remainingYears > 0 ? <UnitsConverter fromUnits='gallons' toUnits='gallons' value={currentUsage} /> : 0)}
    //         {renderTabbedItemTotal('Acre Feet:', remainingYears > 0 ? <UnitsConverter fromUnits='gallons' toUnits='acrefeet' value={currentUsage} /> : 0)}
    //         {renderTabbedItemTotal('Inches / Acre:', remainingYears > 0 ? <UnitsConverter fromUnits='gallons' toUnits='acreinches' value={currentUsage} devideBy={irrigationAcres} /> : 0)}

    //         <Row gutter={10}>
    //             <Col span={12}>
    //                 {renderTabbedItemTotal(<b>Current Year Remaining Quantity</b>, undefined, undefined, 500)}
    //                 {renderTabbedItemTotal('The baseline quantity minus the current year used quantity.', null, undefined, 500)}
    //                 {renderTabbedItemTotal('Gallons:', remainingYears > 0 ? <UnitsConverter fromUnits='gallons' toUnits='gallons' value={currentYearRemaining} /> : 0)}
    //                 {renderTabbedItemTotal('Acre Feet:', remainingYears > 0 ? <UnitsConverter fromUnits='gallons' toUnits='acrefeet' value={currentYearRemaining} /> : 0)}
    //                 {renderTabbedItemTotal('Inches / Acre:', remainingYears > 0 ? <UnitsConverter fromUnits='gallons' toUnits='acreinches' value={currentYearRemaining} devideBy={irrigationAcres} /> : 0)}
    //             </Col>
    //             {/* <Col span={6} style={{ height: '100%', width: '100%', display: 'flex', flex: 1, alignItems: "center", justifyContent: "flex-start", paddingTop: 35 }}>
    //                 <Typography.Title level={5}>Remaining For The Year</Typography.Title>
    //                 <GaugeChart
    //                     textColor='black'
    //                     nrOfLevels={24}
    //                     id="gauge-chart2"
    //                     style={{
    //                         maxHeight: 250,
    //                         maxWidth: 250,
    //                         padding: 0,
    //                         color: 'black',
    //                     }}
    //                     colors={["#FF0000", "#00FF00"]}
    //                     percent={0.2}
    //                     needleColor={"black"}
    //                     needleBaseColor={'black'}
    //                 />
    //             </Col>
    //             <Col span={6} style={{ height: '100%', width: '100%', display: 'flex', flex: 1, alignItems: "center", justifyContent: "flex-start", paddingTop: 35 }}>
    //                 <Typography.Title level={5}>Surplus / Deficit From Baseline</Typography.Title>
    //                 <GaugeChart
    //                     nrOfLevels={2}
    //                     id="gauge-chart1"
    //                     style={{
    //                         maxHeight: 250,
    //                         maxWidth: 250,
    //                         padding: 0,
    //                         color: 'black',
    //                     }}
    //                     percent={0.45}
    //                     needleColor={"black"}
    //                     needleBaseColor={'black'}
    //                     textColor='black'
    //                     formatTextValue={value => {
    //                         if (Number(value) > 0.5)
    //                             return 'Surplus'
    //                         else return 'Deficit'
    //                     }}
    //                 />
    //             </Col> */}
    //         </Row>
    //     </>} type="warning" />
    // </Card>
  };

  return (
    <Card className="restrictionAddEdit" id="restrictionAddEdit" title={id ? "Edit Restriction" : "Add Restriction"} extra={renderActionButtons("bottomRight")} actions={[renderActionButtons("top")]}>
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} onFinish={onFinish} autoComplete="off">
        <Steps
          direction="vertical"
          items={[
            {
              title: "Details",
              status: "process",
              description: (
                <Card>
                  <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please enter a name" }]}>
                    <Input placeholder="Enter a name" />
                  </Form.Item>
                  <Form.Item label="Type" name="type" rules={[{ required: true, message: "Please select a type" }]}>
                    <LookupSelector propertyToSet="type" form={form} placeholder="Select a type" lookupType="restrictiontype" />
                  </Form.Item>
                  <Form.Item label="Status" name="status" valuePropName="checked">
                    <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
                  </Form.Item>
                  <Form.Item name="myfa" label={<Tooltip title="Multi Year Flex Account">MYFA</Tooltip>} valuePropName="checked">
                    <Switch disabled={restrictionType === "myfa"} checkedChildren="Yes" unCheckedChildren="No" />
                  </Form.Item>
                  <Form.Item
                    label="Start Date"
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: "Please select an start date",
                      },
                    ]}
                    getValueProps={(i) => ({ value: i ? dayjs(i) : undefined })}
                  >
                    <DatePicker placeholder="Select start date" format={constants.dateFormat} />
                  </Form.Item>

                  <Form.Item required label="End Date" name="endDate" rules={[{ required: true, message: "Please select an end date" }]} getValueProps={(i) => ({ value: i ? dayjs(i) : undefined })}>
                    <DatePicker placeholder="Select end date" format={constants.dateFormat} />
                  </Form.Item>
                  {startDate && endDate && (
                    <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                      <Alert showIcon description={`You are setting this restriction up for ${calculateDateRange(startDate, endDate)}`} type="warning" />
                    </Form.Item>
                  )}
                  <Form.Item name="renew" label="Auto Renew" valuePropName="checked">
                    <Switch checkedChildren="Yes" unCheckedChildren="No" />
                  </Form.Item>
                  <Form.Item
                    label="Term Quantity"
                    name="termQuantity"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a term quantity",
                      },
                    ]}
                  >
                    <InputNumber style={{ minWidth: 255 }} min={0} placeholder="Enter a term quantity" addonAfter={"Acre Feet"} />
                  </Form.Item>

                  {startDate && generatePreviousYearQuantities(startDate, restrictionData?.previousYearQuantities, restrictionData?.createdAt)}
                </Card>
              ),
            },
            {
              title: "Water Rights",
              status: "process",
              description: (
                <Card>
                  <TableTransfer
                    companyId={restrictionData?.companyId ?? selectedCompanyId}
                    filterOption={filterOption}
                    showSearch
                    onSearch={handleSearch}
                    targetKeys={targetKeys}
                    onChange={onKeysChange}
                    disabled={loading}
                    loading={loading}
                    leftHeader="Available Water Rights"
                    rightHeader="Water Rights In Restriction"
                    onValuesChange={onWaterRightsChange}
                  />
                </Card>
              ),
            },
            {
              title: "Calculations",
              status: "process",
              description: renderCalculations(startDate, endDate, waterRights, termQuantity, previousYearQuantities, restrictionData?.previousYearQuantities, restrictionData?.createdAt),
            },
            {
              title: "Override Warning Quantities",
              status: "process",
              description: (
                <Card>
                  <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                    <Alert
                      showIcon
                      description="Please leave this section blank if you do not want to override the default warning quantity in company settings for this restriction."
                      type="warning"
                    />
                  </Form.Item>

                  <Form.Item label="Warning Quantity">
                    <Input.Group compact>
                      <Form.Item
                        name="warningQuantity"
                        //  rules={[{ required: true, message: 'Please input a default water right warning quantity' }]}
                        noStyle
                      >
                        <InputNumber style={{ minWidth: 320 }} placeholder="Enter an override warning quantity (Optional)" />
                      </Form.Item>
                      <Form.Item name="warningQuantityUnits" noStyle>
                        <LookupSelector form={form} propertyToSet="warningQuantityUnits" placeholder="Select Units" lookupType="meterUnits" style={{ minWidth: 120 }} />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Card>
              ),
            },
          ]}
        />
      </Form>
    </Card>
  );
};

export default RestrictionAddEdit;
