import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getWellsLookup } from "@/apis/well.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CacheKeys from "@/types/CacheKeys";

export default function useWellLookups(wellId?: string, companyId?: string, isActive?: boolean) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();
  const {
    data: wells,
    isFetching,
    isRefetching,
    isFetched,
    isError,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: [
      CacheKeys.wells,
      CacheKeys.lookup,
      {
        profileId: profile?.id,
        companyId,
        isActive,
      },
    ],
    queryFn: async () => {
      const response = await getWellsLookup({
        companyId: companyId,
        isActive: isActive,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch fields");
    },
    gcTime: Infinity,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    placeholderData: (currentData) => currentData || [],
  });

  const invalidateWells = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [CacheKeys.wells, CacheKeys.lookup] });
  }, [queryClient]);

  const [well, setWell] = useState<any>(null);

  useEffect(() => {
    if (isFetched && !isError && !isFetching && !isRefetching && isSuccess && wellId && wells) {
      const tempWell = wells.find((w: any) => w.id === wellId);
      if (tempWell) {
        if (!isEqual(well, tempWell)) setWell(tempWell);
      } else {
        invalidateWells();
      }
    }
  }, [isError, isFetched, isFetching, isRefetching, wellId, wells, invalidateWells, isSuccess, well]);

  return { well, wells, isLoading, invalidateWells };
}
