import { getWellMapDashboardList } from "@/apis/aggregator.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { keepPreviousData, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

type WellDasboardPagedList = {
  searchString?: string;
  isActive?: boolean;
};

const useListWellMapDashboard = (props: WellDasboardPagedList) => {
  const { profile } = useSelector((state: any) => state.user);

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const { data, isFetching, isFetched, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading } = useInfiniteQuery({
    queryKey: [
      CacheKeys.wellMapDashboard,
      CacheKeys.list,
      {
        profileId: profile?.id,
        searchString: props.searchString,
        companyId: selectedCompanyId,
        isActive: props.isActive,
      },
    ],
    queryFn: async ({ pageParam }) => {
      const response = await getWellMapDashboardList({
        searchString: props.searchString,
        isActive: props.isActive,
        page: pageParam,
        // pageSize: 10,
        companyId: selectedCompanyId,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch well map dashboard infinite query");
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    gcTime: 30 * 1000, //30 seconds
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage?.pagination?.hasNextPage) return undefined;
      return lastPageParam + 1;
    },
    placeholderData: keepPreviousData,
  });

  const invalidateDashboardMapWells = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.wellMapDashboard, CacheKeys.list],
    });
  }, [queryClient]);

  const resetDashboardMapWells = useCallback(() => {
    queryClient.resetQueries({
      queryKey: [CacheKeys.wellMapDashboard, CacheKeys.list],
    });
  }, [queryClient]);

  const mapDashboardWells = useMemo(() => data?.pages?.map((page) => page.list).flat() ?? [], [data]);
  const currentItemCount = mapDashboardWells.length;
  const totalItemCounts = data?.pages?.map((page) => page.pagination?.totalItemCount).flat() ?? [];

  return {
    mapDashboardWells,
    isLoading,
    currentItemCount,
    totalItemCount: totalItemCounts[totalItemCounts.length - 1],
    fetchNextPage,
    invalidateDashboardMapWells,
    resetDashboardMapWells,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
    isFetched,
  };
};

export default useListWellMapDashboard;
