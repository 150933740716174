import { Card, DatePicker, Form, Input, InputNumber, message, Modal } from "antd";
import { FC, useEffect, useState } from "react";
import { ThunderboltOutlined, CloseOutlined } from "@ant-design/icons";
import { createDate, getDate, updateDate } from "@/apis/waterright.api";
import dayjs from "dayjs";
import { constants } from "@/configs";
import LookupSelector from "@/components/LookupSelector/LookupSelector";

interface Props {
  dateId: string;
  waterRightId: string;
  waterRightFileNumber: string;
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}

const DatesAddEditModal: FC<Props> = (props) => {
  const { open, onCancel, onSuccess, waterRightId, dateId, waterRightFileNumber } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const [perfectBy, setPerfectBy] = useState<any>(undefined);

  useEffect(() => {
    if (dateId) {
      getDateData();
    }
  }, [dateId]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const submitData = { ...values, waterRightId: waterRightId };
        handleSubmitDateData(submitData);
      })
      .catch((errors) => {
        // TODO:
      });
  };

  const handleSubmitDateData = async (data: any) => {
    if (dateId) {
      const response = await updateDate(dateId, data);
      if (response.ok) {
        onSuccess && onSuccess();
      } else {
        message.error("Failed to update the dates");
      }
    } else {
      const response = await createDate(data);
      if (response.ok) {
        onSuccess && onSuccess();
      } else {
        message.error("Failed to create the dates");
      }
    }
  };

  const getDateData = async () => {
    setLoading(true);
    const response = await getDate(waterRightId);
    if (response.ok) {
      const data = await response.json();
      const { perfectBy = undefined, certificateIssued = undefined, completeBy = undefined, completionAck = undefined, ...rest } = data.value;
      form.setFieldsValue({
        perfectBy,
        certificateIssued,
        completeBy,
        completionAck,
        ...rest,
      });
    }
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      title={`Edit Dates For Water Right - ${waterRightFileNumber}`}
      okText="Save"
      cancelText="Cancel"
      onOk={handleSubmit}
      onCancel={onCancel}
      maskClosable={false}
      cancelButtonProps={{ icon: <CloseOutlined />, loading: loading }}
      okButtonProps={{ icon: <ThunderboltOutlined />, loading: loading }}
      width={480}
    >
      <Card bodyStyle={{ padding: 20 }} className="removeBoxShadow removeMargin">
        <Form form={form} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }} labelAlign="left">
          <Form.Item label="Perfected By" name="perfectBy" getValueProps={(i) => ({ value: i ? dayjs(i) : undefined })}>
            <DatePicker style={{ width: "100%" }} format={constants.dateFormat} />
          </Form.Item>

          <Form.Item label="Certificate Issued" name="certificateIssued" getValueProps={(i) => ({ value: i ? dayjs(i) : undefined })}>
            <DatePicker style={{ width: "100%" }} format={constants.dateFormat} />
          </Form.Item>

          <Form.Item label="Complete By" name="completeBy" getValueProps={(i) => ({ value: i ? dayjs(i) : undefined })}>
            <DatePicker style={{ width: "100%" }} format={constants.dateFormat} />
          </Form.Item>

          <Form.Item label="Completion Acknowledged" name="completionAck" getValueProps={(i) => ({ value: i ? dayjs(i) : undefined })}>
            <DatePicker style={{ width: "100%" }} format={constants.dateFormat} />
          </Form.Item>

          <Form.Item label="Base Acres" name="baseAcres" style={{ marginBottom: 0 }}>
            <InputNumber style={{ width: "100%" }} min={0} placeholder="Enter the base acres" />
          </Form.Item>
        </Form>
      </Card>
    </Modal>
  );
};

export default DatesAddEditModal;
