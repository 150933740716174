import { getBillableStatus } from "@/apis/identity.api";
import { syncRestrictionCalculations } from "@/apis/restriction.api";
import { getWaterRightTags, syncWaterRightCalculation } from "@/apis/waterright.api";
import { getRecentWellReading } from "@/apis/well.api";
import LastReadingFrequency from "@/components/LastReadingFrequency/LastReadingFrequency";
import LookupFilter from "@/components/LookupFilter/LookupFilter";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import FieldLabel from "@/components/Lookups/FieldLabel";
import WaterRightLabel from "@/components/Lookups/WaterRightLabels/WaterRightLabel";
import WaterRightPermitTypeLabel from "@/components/Lookups/WaterRightLabels/WaterRightPermitTypeLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import RemainingDays from "@/components/RemainingDays/RemainingDays";
import RestrictionPopover from "@/components/RestrictionPopover/RestrictionPopover";
import StatusTag from "@/components/StatusTag/StatusTag";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import WaterRightTagList from "@/components/WaterRightTagList/WaterRightTagList";
import { constants, routes } from "@/configs";
import { UserRole } from "@/dtos/user.dto";
import { WellView } from "@/pages";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import useListWellQuery from "@/queries/useListWellQuery";
import useRestrictionLookups from "@/queries/useRestrictionLookups";
import useWaterRightLookups from "@/queries/useWaterRightLookups";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import { PlusOutlined, ReloadOutlined, WarningOutlined } from "@ant-design/icons";
import { useIntersectionObserver, useWindowSize } from "@uidotdev/usehooks";
import { Button, Card, Empty, Input, Popover, Space, Switch, Table, message } from "antd";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import dayjs from "dayjs";
import moment from "moment";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AddSelectCompanyModal, { AddSelectCompanyModalRef, EnitityName } from "../AddSelectCompanyModal/AddSelectCompanyModal";
import WellTagList from "../WellTagList/WellTagList";

import "./WellTable.scss";

interface Props {
  addWell: boolean;
  header: boolean;
  style?: React.CSSProperties;
  fieldId?: any;
  waterRightId?: any;
  reductionWellIds?: any[];
  trackTableState?: boolean;
  isReport?: boolean;
  isDashBoard?: boolean;
  refreshWaterRightViewCalculations?: () => void;
  refreshWaterRightCalculations?: () => void;
}

const WellTable: FC<Props> = (props) => {
  const {
    style,
    header,
    fieldId,
    waterRightId,
    addWell,
    reductionWellIds,
    trackTableState = false,
    isReport = false,
    isDashBoard = false,
    refreshWaterRightViewCalculations,
    refreshWaterRightCalculations,
  } = props;

  const dispatch = useAppDispatch();

  const { navigate, updateQueryParams, queryParams } = useCustomNavigate();

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);
  const { lookups } = useSelector((state: any) => state.lookups);

  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");
  const [columns, setColumns] = useState<any[]>([]);
  const [isActive, setIsActive] = useState<boolean | null>(true);
  const [recentWellReadings, setRecentWellReadings] = useState<any[]>([]);
  const [waterRightTags, setWaterRightTags] = useState<any[]>([]);
  const [permitTypes, setPermitTypes] = useState<any[]>([]);

  const [calculations, setCalculations] = useState<any[]>([]);
  const [loadingCalculations, setLoadingCalculations] = useState<boolean>(true);
  const [loadingBillable, setLoadingBillable] = useState<boolean>(false);

  const [loadingRestrictionCalculations, setLoadingRestrictionCalculations] = useState<boolean>(true);
  const [restrictionCalculations, setRestrictionCalculations] = useState<any[]>([]);

  const [filteredWellInfo, setFilteredWellInfo] = useState<Record<string, FilterValue | null>>({});
  const [sortedWellInfo, setSortedWellInfo] = useState<SorterResult<any>>({});

  const { companyConfigs } = useComapnyConfigLookup();

  const [wellsDownRef, wellsDownEntry] = useIntersectionObserver();

  const windowSize = useWindowSize();

  // const {
  //   wells: tempWells,
  //   isLoading: isWellsLoading,
  //   invalidateWells,
  // } = useWellPagedList({
  //   fieldId,
  //   waterRightId,
  //   reductionWellIds,
  //   isActive,
  //   pagination: {
  //     page: isChild ? queryParamState.page : queryParams?.page ?? "1",
  //     pageSize: isChild ? queryParamState.pageSize : queryParams?.pageSize ?? "10",
  //   },
  //   searchString,
  // });

  const {
    wellList: tempWells,
    isLoading,
    invalidateWells,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    totalItemCount,
  } = useListWellQuery({
    isActive,
    fieldId,
    reductionWellIds,
    searchString,
    waterRightId,
  });

  const { restrictions } = useRestrictionLookups();

  const { waterRights } = useWaterRightLookups();

  const wells: any[] = useMemo(() => {
    const data: any[] = tempWells?.map((well: any) => {
      const waterRight = waterRights?.find((waterRight: any) => waterRight?.id === well?.waterRightId);
      return {
        ...well,
        wellTags: well?.tags,
        permitType: waterRight?.permitType,
        wellReading: recentWellReadings?.find((reading) => reading?.wellId === well?.id),
        tags: waterRightTags?.find((tag) => tag?.waterRightId === well?.waterRightId),
        disableWarnings: waterRight?.disableWarnings,
        markedAsDisabledUserUserName: waterRight?.markedAsDisabledUserUserName,
        markedAsDisabledDate: waterRight?.markedAsDisabledDate,
        pdiv: waterRight?.pdiv,
        cin: waterRight?.cin,
      };
    });

    return data;
  }, [tempWells, recentWellReadings, waterRightTags, waterRights, restrictions]);

  const refreshWellCalculations = useCallback(() => {
    handleRefresh();
  }, []);

  useEffect(() => {
    if (wellsDownEntry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line
  }, [wellsDownEntry?.time, wellsDownEntry?.isIntersecting, hasNextPage]);

  useEffect(() => {
    if (tempWells?.length > 0) {
      getMostRecentWellReading();
      getWRTags();
    }
    // eslint-disable-next-line
  }, [selectedCompanyId, tempWells]);

  useEffect(() => {
    refreshCalculations();
    refreshRestrictionCalculations();
    // eslint-disable-next-line
  }, [isActive, reductionWellIds, selectedCompanyId]);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [wells, calculations, restrictions, restrictionCalculations, lookups, companyConfigs, sortedWellInfo, filteredWellInfo, selectedCompanyId]);

  const getWRTags = async () => {
    const waterRightIds = wells.map((well) => well.waterRightId);

    const request = {
      waterRightIds: waterRightIds,
    };

    const response = await getWaterRightTags(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWaterRightTags(data.value);
      }
    }
  };

  const getMostRecentWellReading = async () => {
    const wellIds = wells?.map((well: any) => well.id);

    const request = {
      wellIds: wellIds,
    };

    const response = await getRecentWellReading(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setRecentWellReadings(data.value.filter((x: any) => x?.reading !== null));
      }
    }
  };

  const refreshCalculations = async () => {
    setLoadingCalculations(true);
    const response = await syncWaterRightCalculation({});
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setCalculations(data.value);
      }
    }
    setLoadingCalculations(false);
  };

  const refreshRestrictionCalculations = async () => {
    setLoadingRestrictionCalculations(true);
    const response = await syncRestrictionCalculations({});
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setRestrictionCalculations(data.value);
      }
    }
    setLoadingRestrictionCalculations(false);
  };

  const handleViewWell = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record.name}`,
        url: `well/${record.id}/view`,
      })
    );
    navigate(routes.wellView, { id: record.id });
  };

  const handleViewField = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record.name}`,
        url: `field/${record.fieldId}/view`,
      })
    );
    navigate(routes.fieldView, { id: record.fieldId });
  };

  const handleViewWaterRight = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record.waterRightFileNumber}`,
        url: `waterright/${record.waterRightId}/view`,
      })
    );

    navigate(routes.waterRightView, { id: record.waterRightId });
  };

  const handleAddWell = async (e: any) => {
    e.preventDefault();
    if (selectedCompany?._companyId === "all") {
      addModalRef.current?.open();
      return;
    } else {
      const billableStatus = await handleNavigateToAdd();
      if (billableStatus === true) {
        navigate(routes.wellAdd);
        dispatch(
          addBreadcrumb({
            type: `Well / Add`,
            url: `well/add`,
          })
        );
      } else {
        message.error("Billing information is required for a company marked as billable");
      }
    }
  };

  const handleNavigateToAdd = async (companyId?: string) => {
    setLoadingBillable(true);

    const request = { companyId: companyId ?? selectedCompanyId };
    const response = await getBillableStatus(request);
    if (response.ok) {
      const data = await response.json();

      setLoadingBillable(false);

      return data;
    }

    setLoadingBillable(false);
  };

  const determineValue = (
    id: string,
    type: string,
    postfix: any = undefined,
    irrigationAcresNotApplicable: boolean | undefined = undefined,
    quantityNotApplicable: boolean | undefined = undefined,
    rateNotApplicable: boolean | undefined = undefined
  ) => {
    const existingCalculation = calculations.find((calculation) => calculation.waterRightId === id && calculation.year === dayjs().year());

    if (existingCalculation) {
      if (type === "remainingUsage" && existingCalculation.quantityNotApplicable === true) return "N/A";
      else if (type === "remainingDays" && (existingCalculation.quantityNotApplicable === true || existingCalculation.rateNotApplicable === true)) return "N/A";
      return existingCalculation[type];
    } else {
      if (type === "remainingUsage" && quantityNotApplicable === true) return "N/A";
      else if (type === "remainingDays" && (quantityNotApplicable === true || rateNotApplicable === true)) return "N/A";
      else return undefined;
    }
  };

  const renderRestrictionSummaries = (record: any) => {
    const restrictionIds = restrictions
      .filter((restriction: any) => restriction?.waterRightIds?.includes(record.waterRightId) && restriction?.status === true)
      ?.map((restriction: any) => restriction.id);
    const existingCalculations = restrictionCalculations.filter((calculation: any) => restrictionIds.includes(calculation.id));

    if (existingCalculations.length === 0) return <>-</>;

    return existingCalculations.map((calculation: any, index: number, list: any[]) => (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: index < list.length - 1 ? 10 : undefined,
        }}
      >
        <RestrictionPopover
          status={calculation?.status}
          companyId={record?.companyId}
          selectedCompany={selectedCompany}
          startDate={dayjs(calculation?.startDate)}
          endDate={dayjs(calculation?.endDate)}
          remainingYears={calculation?.remainingYears}
          calculation={calculation}
          isReport={isReport}
          type={calculation?.type}
        />
        <div style={{ fontSize: "0.8em" }}>
          {selectedCompanyId && selectedCompany?.settings?.metric !== "acrefeet" && (
            <div>
              <UnitsConverter fromUnits="gallons" showUnitsLabel value={calculation.remainingYearlyAverage} />
            </div>
          )}
          <div>
            <UnitsConverter fromUnits="gallons" toUnits="acrefeet" showUnitsLabel value={calculation.remainingYearlyAverage} />
          </div>
          {calculation.inchesPerAcre !== null && (
            <div>
              <UnitsConverter fromUnits="inchesperacre" toUnits="inchesperacre" value={calculation.inchesPerAcre} postFix="Inches / Acre" />{" "}
            </div>
          )}
        </div>
      </div>
    ));
  };

  const calculateWaterRightTagsFilter = () => {
    const restrictionType = lookups.find((lookup: any) => lookup.map === "restrictiontype");
    if (restrictionType !== undefined) {
      const typeForRestriction = restrictionType.options.map((lookup: any) => {
        return { text: lookup.name, value: lookup.value };
      });

      return typeForRestriction;
    } else return null;
  };

  const percentageAvailable = (remainingUsage: number, availableQuantity: number) => {
    if (remainingUsage === undefined || availableQuantity === undefined || availableQuantity === 0) return undefined;
    if (remainingUsage < 0) return "0.00 %";
    return ((remainingUsage / availableQuantity) * 100).toFixed(2) + " %";
  };

  const calculateColumns = () => {
    let tempColumns = [
      isReport
        ? {
            title: "#",
            width: 50,
            render: (val: any, record: any, index: any) => index + 1,
          }
        : undefined,
      isDashBoard
        ? {
            title: "Well Name",
            key: "name",
            dataIndex: "name",
            fixed: "left",
            sorter: (a: any, b: any) =>
              a?.name?.localeCompare(b?.name, "en", {
                numeric: true,
                sensitivity: "base",
              }),
            sortOrder: sortedWellInfo?.columnKey === "name" ? sortedWellInfo?.order : null,
            render: (val: any, record: any) => (
              <>
                <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewWell(record)}>
                  {val}
                </Button>
                <WellTagList tags={record?.tags} />
              </>
            ),
          }
        : {
            title: "Well Name",
            key: "name",
            dataIndex: "name",
            fixed: "left",
            render: (val: any, record: any, index: any) => (
              <>
                <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewWell(record)}>
                  {val}
                </Button>
                <WellTagList style={{ maxWidth: 200 }} tags={record?.wellTags} />
                {index === wells?.length - 1 ? <span ref={wellsDownRef} /> : null}
              </>
            ),
          },
      isDashBoard
        ? {
            title: "Field Name",
            key: "fieldName",
            dataIndex: "fieldName",
            sorter: (a: any, b: any) =>
              a?.fieldName?.localeCompare(b?.fieldName, "en", {
                numeric: true,
                sensitivity: "base",
              }),
            sortOrder: sortedWellInfo?.columnKey === "fieldName" ? sortedWellInfo?.order : null,
            render: (val: any, record: any) => (
              <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewField(record)}>
                <FieldLabel fieldId={record?.fieldId} />
              </Button>
            ),
          }
        : {
            title: "Field Name",
            key: "fieldName",
            dataIndex: "fieldName",
            render: (val: any, record: any) => (
              <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewField(record)}>
                <FieldLabel fieldId={record?.fieldId} />
              </Button>
            ),
          },
      isDashBoard
        ? {
            title: (
              <>
                Water Right /<br /> File Number
              </>
            ),
            key: "waterRightFileNumber",
            dataIndex: "waterRightFileNumber",
            filters: calculateWaterRightTagsFilter(),
            onFilter: (value: string, record: any) => {
              let found = false;
              if (record?.tags?.externalTags?.includes(value)) found = true;

              if (record?.tags?.internalTags && record?.tags?.internalTags?.length > 0) {
                record?.tags?.internalTags?.forEach((tag: any) => {
                  if (tag?.label?.toLowerCase() === value) found = true;
                });
              }

              return found;
            },
            sorter: (a: any, b: any) => a?.waterRightFileNumber?.localeCompare(b?.waterRightFileNumber, "en", { numeric: true, sensitivity: "base" }),
            sortOrder: sortedWellInfo?.columnKey === "waterRightFileNumber" ? sortedWellInfo?.order : null,
            render: (val: any, record: any) => (
              <>
                <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewWaterRight(record)}>
                  {<WaterRightLabel waterRightId={record?.waterRightId} />}{" "}
                  {record?.disableWarnings ? (
                    <Popover
                      content={
                        <>
                          <b>Warnings Disabled</b> <br />
                          Acknowledged By: {record?.markedAsDisabledUserUserName} <br />
                          Date: {dayjs(record?.markedAsDisabledDate ?? undefined).format(constants.dateTimeFormat)} <br />
                        </>
                      }
                    >
                      <WarningOutlined style={{ color: "red" }} />
                    </Popover>
                  ) : undefined}
                </Button>
                {!isReport && <WaterRightTagList internalTags={record.tags?.internalTags} externalTags={record.tags?.externalTags} />}
              </>
            ),
          }
        : {
            title: (
              <>
                Water Right /<br /> File Number
              </>
            ),
            key: "waterRightFileNumber",
            dataIndex: "waterRightFileNumber",
            render: (val: any, record: any) => (
              <>
                <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewWaterRight(record)}>
                  <WaterRightLabel waterRightId={record?.waterRightId} />{" "}
                  {record?.disableWarnings ? (
                    <Popover
                      content={
                        <>
                          <b>Warnings Disabled</b> <br />
                          Acknowledged By: {record?.markedAsDisabledUserUserName} <br />
                          Date: {dayjs(record?.markedAsDisabledDate ?? undefined).format(constants.dateTimeFormat)} <br />
                        </>
                      }
                    >
                      <WarningOutlined style={{ color: "red" }} />
                    </Popover>
                  ) : undefined}
                </Button>
                {!isReport && <WaterRightTagList internalTags={record.tags?.internalTags} externalTags={record.tags?.externalTags} />}
              </>
            ),
          },
      // {
      //   title: (
      //     <>
      //       PDIV <br /> CIN
      //     </>
      //   ),
      //   key: "pdivcin",
      //   width: 100,
      //   render: (val: any, record: any, index: any) => (
      //     <>
      //       <div style={{ padding: 0, margin: 0 }}>{record?.pdiv ?? "-"}</div>
      //       <div style={{ padding: 0, margin: 0 }}>{record?.cin ?? "-"}</div>
      //     </>
      //   ),
      // },
      isDashBoard
        ? {
            title: "Permit Type",
            key: "permitType",
            dataIndex: "permitType",
            sorter: (a: any, b: any) =>
              a?.permitType?.localeCompare(b?.permitType, "en", {
                numeric: true,
                sensitivity: "base",
              }),
            sortOrder: sortedWellInfo?.columnKey === "permitType" ? sortedWellInfo?.order : null,
            render: (val: any, record: any) => <WaterRightPermitTypeLabel waterRightId={record?.waterRightId} />,
          }
        : {
            title: "Permit Type",
            key: "permitType",
            dataIndex: "permitType",
            render: (val: any, record: any) => <WaterRightPermitTypeLabel waterRightId={record?.waterRightId} />,
          },
      !isReport
        ? {
            title: "Status",
            key: "status",
            dataIndex: "status",
            render: (val: any, record: any) => <StatusTag status={val} />,
          }
        : undefined,
      {
        title: "Last Reading Date",
        key: "wellReading",
        dataIndex: "wellReading",
        sorter: isDashBoard ? (a: any, b: any) => moment(a?.wellReading?.date).unix() - moment(b?.wellReading?.date).unix() : undefined,
        sortOrder: isDashBoard ? (sortedWellInfo?.columnKey === "wellReading" ? sortedWellInfo?.order : null) : undefined,
        render: (val: any, record: any) => {
          const companyConfig = companyConfigs?.find((c: any) => c?.id === record?.companyId);
          const meterReadingFrequency = record.meterReadingFrequency ? record.meterReadingFrequency : companyConfig?.settings?.meterReadingFrequency;
          const meterReadingFrequencyDay = record.meterReadingFrequency ? record.meterReadingFrequencyDay : companyConfig?.settings?.meterReadingFrequencyDay;
          const meterReadingFrequencyDayOfWeek = record.meterReadingFrequency ? record.meterReadingFrequencyDayOfWeek : companyConfig?.settings?.meterReadingFrequencyDayOfWeek;
          const timezone = companyConfig?.settings?.timezone;

          if (record?.wellReading?.date) {
            return (
              <LastReadingFrequency
                isReport={isReport}
                type={"meter"}
                date={record?.wellReading?.date}
                meterReadingFrequency={meterReadingFrequency}
                meterReadingFrequencyDay={meterReadingFrequencyDay}
                meterReadingFrequencyDayOfWeek={meterReadingFrequencyDayOfWeek}
                timezone={timezone}
              />
            );
          } else return "-";
        },
      },
      {
        title: "Estimated Pump Days Left / %",
        key: "estimatedPumpDays",
        dataIndex: "estimatedPumpDays",
        sorter: (a: any, b: any) => (isDashBoard ? determineValue(a?.waterRightId, "remainingDays") - determineValue(b?.waterRightId, "remainingDays") : undefined),
        sortOrder: isDashBoard ? (sortedWellInfo?.columnKey === "estimatedPumpDays" ? sortedWellInfo?.order : null) : undefined,
        // sorter: (a: any, b: any) => a.termQuantity - b.termQuantity,
        render: (val: any, record: any) => {
          const existingCalculation = calculations.find((calculation) => calculation.waterRightId === record.waterRightId && calculation.year === dayjs().year());
          const quantityNotApplicable = existingCalculation?.quantityNotApplicable ?? false;

          return (
            <>
              <RemainingDays remainingDays={determineValue(record.waterRightId, "remainingDays")} />
              <br />
              {!!quantityNotApplicable === false && percentageAvailable(determineValue(record.waterRightId, "remainingUsage"), determineValue(record.waterRightId, "availableQuantity", null))}
            </>
          );
        },
      },
      {
        title: "Remaining Yearly Average",
        key: "yearlyAverage",
        dataIndex: "yearlyAverage",
        width: 200,
        render: (val: any, record: any) => renderRestrictionSummaries(record),
      },
      !isReport && !isDashBoard
        ? {
            title: "Actions",
            key: "action",
            width: 80,
            render: (value: any, record: any) => (
              <>
                <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewWell(record)}>
                  View
                </Button>
              </>
            ),
          }
        : undefined,
    ];

    if (!isReport && !selectedCompanyId) {
      tempColumns.splice(!isDashBoard ? 9 : 8, 0, {
        title: "Company",
        key: "companyId",
        dataIndex: "companyId",
        render: (val: any, record: any) => <CompanyLabel companyId={val} isActive={false} />,
      });
    }

    setColumns(tempColumns.filter((x) => x));
  };

  const handleRefresh = () => {
    setSearchString("");
    setSearchStringValue("");
    invalidateWells();
    refreshCalculations();
    refreshRestrictionCalculations();

    if (tempWells?.length > 0) getMostRecentWellReading();
  };

  const handleOnChange = (value: boolean) => {
    if (!value) setIsActive(null);
    else setIsActive(true);
  };

  const renderExpandData = (record: any, expanded: boolean) => {
    return (
      <WellView
        expanded={expanded}
        refreshWaterRightCalculations={refreshWaterRightCalculations}
        refreshWaterRightViewCalculations={refreshWaterRightViewCalculations}
        refreshWellCalculations={refreshWellCalculations}
        overrideId={record.id}
        showHeader={false}
      />
    );
  };

  const renderTable = (isReport: boolean, queryParams: any, loading: boolean, columns: any[], trackTableState: boolean) => (
    <Table
      className="customScrollBarStyle"
      pagination={false}
      rowKey={(row: any) => row.id}
      scroll={{
        y:
          (reductionWellIds !== undefined && reductionWellIds && reductionWellIds.length > 0) || (waterRightId !== undefined && waterRightId) || (fieldId !== undefined && fieldId)
            ? "calc(100vh - 650px)"
            : "calc(100vh - 385px)",
      }}
      expandable={{
        columnWidth: 55,
        expandedRowRender: isReport ? undefined : (record: any, index: number, indent: number, expanded: boolean) => (expanded ? renderExpandData(record, expanded) : null),
      }}
      loading={loading || isFetchingNextPage}
      columns={columns}
      dataSource={wells?.filter((well) => (permitTypes?.length > 0 ? permitTypes?.includes(well.permitType) : well)) || []}
      size="small"
      locale={{
        emptyText: <Empty description="No Wells" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
      }}
    />
  );

  const addModalRef = useRef<AddSelectCompanyModalRef>(null);

  const renderContent = () => {
    return <div>{renderTable(isReport, queryParams, isLoading, columns, trackTableState)}</div>;
  };

  return isReport ? (
    renderTable(isReport, queryParams, isLoading, columns, trackTableState)
  ) : (
    <>
      <Card
        style={style}
        styles={{
          body: {
            height:
              (waterRightId !== undefined && waterRightId) || (fieldId !== null && fieldId) || (reductionWellIds !== undefined && reductionWellIds && reductionWellIds.length > 0)
                ? "calc(100vh - 595px)"
                : "calc(100vh - 320px)",
            padding: 0,
          },
        }}
        className="wellList"
        id="wellList"
        title={
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <span>{!isReport && `${reductionWellIds ? "Deduction Wells / Meters" : "Wells / Meters"}`}</span>
            <Space direction="horizontal">
              {header && (
                <Space>
                  <Input.Search
                    disabled={isLoading}
                    placeholder="Search wells"
                    onSearch={(val) => {
                      setSearchString(val);
                    }}
                    onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                    value={searchStringValue}
                    style={{ width: 400 }}
                    allowClear
                  />
                  <Button icon={<ReloadOutlined />} disabled={isLoading} onClick={handleRefresh}>
                    Refresh
                  </Button>
                  <Switch defaultChecked onChange={handleOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
                  <LookupFilter
                    disabled={isLoading}
                    onSelect={(values) => {
                      setPermitTypes(values);
                    }}
                    lookupType={"permittype"}
                    value={permitTypes}
                    onClear={() => setPermitTypes([])}
                    onSelectAll={() => setPermitTypes(lookups?.find((lookup: any) => lookup.map === "permittype")?.options?.map((options: any) => options.value))}
                  />
                </Space>
              )}
            </Space>

            <span style={{ display: "flex", alignItems: "center" }}>
              <span style={{ paddingRight: 16 }}>Total: {totalItemCount ?? "-"}</span>
              {addWell && (selectedCompany?.userRole === UserRole.admin.label || selectedCompany?._companyId === "all") ? (
                <>
                  <Button icon={<PlusOutlined />} loading={loadingBillable} onClick={handleAddWell} type="primary">
                    Add Well / Meter
                  </Button>
                </>
              ) : (
                <></>
              )}
            </span>
          </div>
        }
      >
        {addWell ? <PrintPage content={renderContent()} /> : renderContent()}
      </Card>
      <AddSelectCompanyModal entityName={EnitityName.Well} ref={addModalRef} onSubmit={handleNavigateToAdd} />
    </>
  );
};

export default WellTable;
