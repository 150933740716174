import { getFaqs } from "@/apis/communication.api";
import { PageContent, PageHeader } from "@/components";
import { FC, useEffect, useState } from "react";
import { Card, Collapse } from "antd";
import PageFooter from "@/components/PageComponents/PageFooter/PageFooter";
import { useSelector } from "react-redux";
import { PlusCircleFilled, LoadingOutlined } from "@ant-design/icons";
import { constants } from "@/configs";

const { Panel } = Collapse;

const Faq: FC = () => {
  const [faqs, setFaqs] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  // const { faqs } = useSelector((state: any) => state.communication);

  useEffect(() => {
    refreshFaqList();
  }, []);

  const refreshFaqList = async () => {
    setLoading(true);

    const response = await getFaqs();
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setFaqs(data.value);
      }
    }
    setLoading(false);
  };
  return (
    <div id="faq">
      <PageHeader title="Frequently Asked Questions" backgroundImageUri="images/VandWater-Banner.png" />
      <PageContent
        content={
          <>
            {loading && <LoadingOutlined style={{ fontSize: 50, color: "#00007f" }} spin />}
            {!loading && (
              <Collapse
                expandIcon={({ isActive }) => (isActive ? <PlusCircleFilled style={{ color: "green" }} /> : <PlusCircleFilled style={{ color: "green" }} />)}
                expandIconPosition="end"
                accordion
                defaultActiveKey={[faqs[0]?.order]}
                style={{
                  borderRadius: 0,
                  width: "100%",
                  maxWidth: 760,
                  margin: "auto",
                }}
              >
                {faqs.map((faq) => (
                  <Panel
                    style={{ width: "100%", maxWidth: 760 }}
                    header={
                      <h1
                        style={{
                          wordWrap: "break-word",
                          lineHeight: 1.5,
                          boxSizing: "border-box",
                          margin: 0,
                          // paddingBottom: 10,
                          border: 0,
                          outline: 0,
                          verticalAlign: "baseline",
                          background: "transparent",
                          fontWeight: "normal",
                          fontSize: "18px",
                          color: "#00007F",
                        }}
                      >
                        {faq.title}
                      </h1>
                    }
                    key={faq.order}
                  >
                    <p
                      style={{
                        whiteSpace: "pre-wrap",
                        color: "#666",
                        lineHeight: "1.7em",
                        fontWeight: "normal",
                        wordWrap: "break-word",
                        textAlign: "justify",
                        boxSizing: "border-box",
                        margin: 0,
                        // paddingRight: '20px',
                        border: 0,
                        outline: 0,
                        fontSize: "16px",
                        verticalAlign: "baseline",
                        background: "transparent",
                        paddingBottom: "1em",
                      }}
                    >
                      {faq.description}
                    </p>
                  </Panel>
                ))}
              </Collapse>
            )}
          </>
        }
      />
      <PageFooter />
    </div>
  );
};

export default Faq;
